import type { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  getEmitterTypeToEnum,
  getValueTypeFromEmitterType,
  IRRIGATION_APPLIED_VOLUME_VALUE_TYPE_PREFIX,
} from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import type { TIrrigationDeviceStatus } from '@semios/app-platform-value-type-definitions/src/valuesTimeseriesTypes/irrigation'
import { getVolumeUnit } from 'App/Map/PanelDetails/_utils/getVolumeUnit'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { EAggregationInterval, TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import type {
  GridTableContentSection,
  GridTableContentSectionItem,
  PossibleValuesRecord,
} from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isEmpty, isNil } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TValuesCurrentPointsValueTypes } from 'stores/mapControlsStore/types'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { getWaterVolumeStringWithUnits } from 'utils/getWaterVolumeStringWithUnits'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'
import { minutesToHoursAndMinutes } from 'utils/minutesToHoursAndMinutes'
import { sortByKey } from 'utils/sortByKey'
import { pressureSensorTimestampToValuesDictionaryMaker } from '../../_utils'
import { getRowHeightForLabelCell } from '../_utils/getRowHeightForLabelCell'
import { IrrigationActivityLabel } from '../_utils/IrrigationActivityLabel/IrrigationActivityLabel'
import { makeLabelForNameAndEmitterType } from '../_utils/makeLabelForNameAndEmitterType'
import { IrrigationActivityTitleBarChildren } from '../IrrigationActivityTitleBarChildren/IrrigationActivityTitleBarChildren'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_IRRIGATION_DETAILS' })

type TGenericValueType =
  VV.DomainTypes.Irrigation.TValuesReturnWithMetaIgnoringKeying['points']['irrigationVolumeDurationSemios_emitterBuriedDrip1'][number]

type TValueTypeResponse = {
  metadata: TGenericValueType['metadata']
  timeseries: Record<string, TGenericValueType['timeseries'][number]>
}

type TShapedValuesType = Record<string, TValueTypeResponse>

const preferredAggregationInterval = {
  preferredAggregationInterval: 'DAILY' as EAggregationInterval.DAILY,
}

export const apiArgsForPressureSensors = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedValueGroups.irrigation_activity || !selectedFieldAssets.property) return {}

  const property = fieldAssetStore.getState().properties?.[Number(selectedFieldAssets.property)]

  if (!property) return {}

  const lngLats: TFieldAssetKeyTypes.TLngLat[] = []
  const valuesRequested: Record<string, typeof preferredAggregationInterval> = {}

  Object.values(property.points || {}).forEach((point) => {
    const valueTypesForPressure = point.valuesTimeseries.filter((vt) =>
      vt.includes(IRRIGATION_APPLIED_VOLUME_VALUE_TYPE_PREFIX),
    )

    if (valueTypesForPressure.length > 0) {
      lngLats.push(point.lngLat)

      valueTypesForPressure.forEach((vt) => (valuesRequested[vt] = preferredAggregationInterval))
    }
  })

  if (isEmpty(lngLats) || isEmpty(valuesRequested)) return {}

  return {
    points: {
      lngLats: lngLats,
      valuesRequested,
    },
  }
}

const generateItem = ({
  id,
  values,
  currentValuesData,
}: {
  id: string
  values: TValueTypeResponse
  currentValuesData: routes.ValuesCurrent.Response['points']
}): {
  height: GridTableContentSectionItem['height']
  id: string
  title: string
  label: React.ReactNode
  labelMinWidth: GridTableContentSectionItem['labelMinWidth']
  render: GridTableContentSectionItem['render']
  valueMinWidth: GridTableContentSectionItem['valueMinWidth']
  values: Record<string, PossibleValuesRecord>
} => {
  const { metadata } = values
  const pressureSensorName = metadata.name as string
  const emitterTypeForZoneEmitter = metadata.emitterType

  const label = makeLabelForNameAndEmitterType({
    deviceName: pressureSensorName,
    emitterType: emitterTypeForZoneEmitter,
  })

  const height = getRowHeightForLabelCell({ label })
  const { appliedIrrigationActivityDisplay } = smallStore.getState()
  const isDuration = appliedIrrigationActivityDisplay === 'DURATION'
  const { rain: rainUnitFromUserSetting } = userDetailsStore.getState()
  const volumeUnit = getVolumeUnit({ rainUnitFromUserSetting })
  const isImperial = volumeUnit === 'INCH'
  //@ts-ignore
  const currentValuesForSensor = currentValuesData?.[metadata.pressureSensorEmitterId]

  let currentValueStatus: TIrrigationDeviceStatus | null = null

  if (currentValuesForSensor) {
    const emitterType = getEmitterTypeToEnum(emitterTypeForZoneEmitter)
    const currentValueKey = `irrigationIsOn_${emitterType}_status` as TValuesCurrentPointsValueTypes

    currentValueStatus = currentValuesForSensor[currentValueKey]?.value as TIrrigationDeviceStatus
  }

  return {
    id,
    title: label,
    label: (
      <IrrigationActivityLabel label={label} currentValueStatus={currentValueStatus} showPlanned={false} />
    ),
    height,
    labelMinWidth: 160,
    valueMinWidth: 120,
    render: (dataPoint: { value: typeof values[keyof typeof values] }) => {
      if (!dataPoint?.value) return translate.phrases.templates('-')

      //@ts-ignore - will be resolved with better PossibleValuesRecord from API
      const { duration, volume_mm } = dataPoint.value
      const noValueDefaultReturn = translate.phrases.templates('-')

      if (isDuration) {
        if (isNil(duration)) return noValueDefaultReturn

        const durationMinutes = duration ? duration / 60000 : 0

        return minutesToHoursAndMinutes(durationMinutes)
      } else {
        const millimeters = volume_mm

        if (isNil(millimeters)) return noValueDefaultReturn

        return getWaterVolumeStringWithUnits({
          waterApplied: millimeters,
          volumeUnitToDisplay: isImperial ? 'INCH' : 'MILLIMETER',
          flowUnitPerHour: 'MILLIMETER',
        })
      }
    },
    // @ts-ignore
    values: values?.timeseries || {},
  }
}

export const pressureSensorsActivityContent = ({
  data,
  selectedFieldAssets,
  currentValuesData,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  currentValuesData: routes.ValuesCurrent.Response
}): GridTableContentSection => {
  const title = translate.phrases.templates('{{labelA}} - {{labelB}}', {
    labelA: translate.phrases.banyanApp('Irrigation Activity by Pressure Sensors'),
    labelB: translate.phrases.banyanApp('Semios'),
  })

  const commonReturnItems = {
    title,
    titleChildren: <IrrigationActivityTitleBarChildren showToggle={false} />,
    id: 'summary-grid-pressureSensors',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const property = fieldAssetStore.getState().properties?.[Number(selectedFieldAssets.property)]
  const allPoints: TFieldAssetValueTypes.TPoint[] = Object.values(property?.points || {})

  const hasIrrigationAppliedVolume = allPoints.some(({ valuesTimeseries }) =>
    valuesTimeseries.some((vt) => vt.includes(IRRIGATION_APPLIED_VOLUME_VALUE_TYPE_PREFIX)),
  )

  if (!hasIrrigationAppliedVolume) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const pointsForPressure = allPoints.filter((p) => !isEmpty(p.configuration.irrigationEmitterTypesAvailable))

  const values = pointsForPressure.reduce((all, point) => {
    point.configuration.irrigationEmitterTypesAvailable?.forEach((emitterType) => {
      all.push(
        pressureSensorTimestampToValuesDictionaryMaker(
          data,
          getValueTypeFromEmitterType(
            IRRIGATION_APPLIED_VOLUME_VALUE_TYPE_PREFIX,
            emitterType,
          ) as VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysMergedForByPointIrrigation,
          point.lngLat,
        ),
      )
    })

    return all
  }, [] as ReturnType<typeof pressureSensorTimestampToValuesDictionaryMaker>[])

  const flattenedValues: TShapedValuesType = values?.flat().reduce((acc, v) => {
    if (v?.metadata?.name && v?.metadata?.emitterType) {
      acc[`${v?.metadata?.name}|||${v?.metadata?.emitterType}`] = v as unknown as TValueTypeResponse
    }

    return acc
  }, {} as TShapedValuesType)

  const gridItems = Object.entries(flattenedValues).map(([key, v]) => {
    const generatedItem = generateItem({
      id: key,
      values: v,
      currentValuesData: currentValuesData.points,
    })

    return {
      ...generatedItem,
      name: v?.metadata?.name,
      emitterTypeLabel: mapApiEmitterTypeToLabel(v?.metadata?.emitterType),
    }
  })

  return {
    title,
    titleChildren: <IrrigationActivityTitleBarChildren showToggle={true} />,
    id: 'summary-grid-pressureSensors',
    items: (gridItems || []).sort(sortByKey('emitterTypeLabel')).sort(sortByKey('name')),
  }
}
