import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DatePickerInput } from '@mantine/dates'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { NumberInput } from 'components/NumberInput/NumberInput'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { apiFetch } from 'utils/apiFetch'
import { handleDeleteClick } from 'utils/refillPointDatesUtils/handleRefillPointDeleteClick'
import { ONGOING_KEY } from 'utils/refillPointDatesUtils/ongoingKey'
import { updateStoresCurrentMADValue } from 'utils/refillPointDatesUtils/updateStoresCurrentMADValue'
import { showNotification } from 'utils/showNotification'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { useScreenSize } from 'utils/useScreenSize'
import type { TEditRefillPointDateModalProps } from '../types'

export const EditRefillPointDateModal = ({
  opened,
  setShowEditRPDModal,
  setEditRPDData,
  id,
  startDate,
  soilMoisture,
  originalStartDate,
  setRefreshChartAndSummary,
  refillPointDates,
  propertyId,
  lngLat,
  setAllStationsRPDsData,
}: TEditRefillPointDateModalProps) => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const { isWideScreen } = useScreenSize()
  const datePickerStyle = isWideScreen ? {} : { width: '100%' }
  const timezone = getTimezoneForSelectedPropertyOrRegion()

  const editRefillPointDatesInDB = async () => {
    if (id === null || !startDate || soilMoisture === null) {
      showNotification({
        message: translate.phrases.banyanApp('Failed to update {{label}}', {
          label: translate.phrases.banyanApp('Refill Point Date'),
        }),
        type: 'error',
      })

      return
    }

    const response = await apiFetch({
      url: routes.FieldAssetSettingsSet.path,
      body: {
        soilMADUpdate: [
          {
            id: id,
            dateStart: startDate,
            soilMoistureMAD: soilMoisture,
          },
        ],
      },
    })

    if (!Array.isArray(response?.soilMADUpdate)) {
      showNotification({
        message: translate.phrases.banyanApp('Failed to update {{label}}', {
          label: translate.phrases.banyanApp('Refill Point Date'),
        }),
        type: 'error',
      })
    } else {
      const updatedRPDFromResponse = response?.soilMADUpdate?.[0]

      const updatedRefillPointDates = refillPointDates
        .map((refillPointDate) => {
          if (refillPointDate.id === updatedRPDFromResponse.id) {
            return {
              id: updatedRPDFromResponse.id,
              startDate: updatedRPDFromResponse.dateStart,
              endDate: refillPointDate.endDate, //temp
              soilMoisture: updatedRPDFromResponse.soilMoisture,
            }
          } else {
            return refillPointDate
          }
        })
        .sort((a, b) => +new Date(b.startDate) - +new Date(a.startDate))
        .map((refillPointDate, index, sortedDates) => ({
          ...refillPointDate,
          endDate: index === 0 ? ONGOING_KEY : sortedDates[index - 1].startDate,
        }))

      setAllStationsRPDsData((prev) => {
        return prev.map((station) => {
          if (station.lngLat === lngLat) {
            return {
              ...station,
              refillPointDates: updatedRefillPointDates,
            }
          }

          return station
        })
      })

      updateStoresCurrentMADValue(updatedRefillPointDates, propertyId, lngLat)

      showNotification({
        message: translate.phrases.banyanApp('Successfully updated {{label}}', {
          label: translate.phrases.banyanApp('Refill Point Date'),
        }),
        type: 'success',
      })

      setRefreshChartAndSummary(true)

      closeEditModal()
    }
  }

  const closeEditModal = () => {
    setShowEditRPDModal(false)

    setEditRPDData({
      id: null,
      startDate: null,
      soilMoisture: null,
    })
  }

  const disabledDatesInCalendar = refillPointDates
    .map((refillPointDate) => {
      return moment.tz(refillPointDate.startDate, timezone).startOf('day').format('YYYY MM DD')
    })
    .filter(
      (startDate) => startDate !== moment.tz(originalStartDate, timezone).startOf('day').format('YYYY MM DD'),
    )

  return (
    <ModalDrawer
      opened={opened}
      title={translate.phrases.banyanApp('Edit Refill Point Date')}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
      primaryButtonText={translate.phrases.banyanApp('Save')}
      secondaryButtonText={translate.phrases.banyanApp('Delete')}
      secondaryButtonOnPress={() =>
        !isDeleteModalShown &&
        handleDeleteClick({
          id: id,
          propertyId: propertyId,
          refillPointDates: refillPointDates,
          lngLat: lngLat,
          setRefreshChartAndSummary: setRefreshChartAndSummary,
          closeEditModal: closeEditModal,
          setAllStationsRPDsData: setAllStationsRPDsData,
          setIsDeleteModalShown,
        })
      }
      secondaryButtonVariant="negative"
      onClose={closeEditModal}
      primaryButtonOnPress={() => {
        editRefillPointDatesInDB()
      }}
    >
      {id !== null && !!startDate && soilMoisture !== null && (
        <div css={{ maxWidth: '240px', marginLeft: '10px', marginTop: '20px' }}>
          <div css={{ marginBottom: '15px' }}>
            <span>{translate.phrases.banyanApp('Date')}</span>
            <DatePickerInput
              style={datePickerStyle}
              valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
              rightSection={
                <FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />
              }
              value={new Date(startDate)}
              onChange={(date) => {
                setEditRPDData((prev) => ({
                  ...prev,
                  startDate: moment.tz(date, timezone).startOf('day').toDate().toISOString(),
                }))
              }}
              excludeDate={(date) => {
                return disabledDatesInCalendar.includes(
                  moment.tz(date, timezone).startOf('day').format('YYYY MM DD'),
                )
              }}
            ></DatePickerInput>
          </div>
          <div>
            <span>{unitConverter.soilAwc().categoryTitle()}</span>
            <NumberInput
              value={soilMoisture}
              onChange={(value) => {
                setEditRPDData((prev) => ({
                  ...prev,
                  soilMoisture: value === '' ? 0 : value,
                }))
              }}
              max={100}
              min={0}
              hideControls
            ></NumberInput>
          </div>
        </div>
      )}
    </ModalDrawer>
  )
}
