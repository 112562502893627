import { useMantineTheme } from '@mantine/core'
import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { Button } from 'components/Button/Button'
import { IconChevron } from 'components/icons/IconChevron'
import React from 'react'
import type { HelpGuideGroup } from '../../types'
import { getHelpGuideGroupTitle, getHelpGuideTitle } from '../../utils/getHelpGuideGroupTitle'

interface HelpGuideGroupCardProps {
  group: HelpGuideGroup
  guides: Record<string, SharedTypes.THelpGuideStep[]>
  expanded: boolean

  onToggleExpand(): void

  onSelectGuide(section: string): void
}

export const HelpGuideGroupCard: React.FC<HelpGuideGroupCardProps> = ({
  group,
  guides,
  expanded,
  onToggleExpand,
  onSelectGuide,
}) => {
  const theme = useMantineTheme()

  return (
    <div
      css={{
        border: `1px solid ${theme.colors.grey[1]}`,
        borderRadius: 3,
        color: theme.colors.midnight[0],
        marginBottom: 10,
        lineHeight: '20px',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          backgroundColor: theme.colors.grey[0],
          padding: '14px 10px',
        }}
        onClick={onToggleExpand}
      >
        <div>{getHelpGuideGroupTitle(group, true)}</div>

        <div>
          <span
            css={{
              display: 'inline-block',
              marginLeft: 10,
              transform: expanded ? 'rotate(180deg)' : undefined,
            }}
          >
            <IconChevron />
          </span>
        </div>
      </div>

      {expanded && (
        <div css={{ padding: '8px 5px', borderTop: `1px solid ${theme.colors.grey[1]}` }}>
          {Object.keys(guides).map((guideAlias) => {
            return (
              <Button
                key={guideAlias}
                variant="link"
                css={{ display: 'block', margin: '12px 0' }}
                onClick={() => onSelectGuide(guideAlias)}
              >
                {getHelpGuideTitle(group, guideAlias)}
              </Button>
            )
          })}
        </div>
      )}
    </div>
  )
}
