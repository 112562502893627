import { Button } from 'components/Button/Button'

type ClickHereLinkProps = {
  children: React.ReactNode
  onClick: () => void
  fontSize?: number
}

export const ClickHereLink = ({ children, onClick, fontSize }: ClickHereLinkProps) => {
  return (
    <Button
      variant="link"
      css={{ padding: 0, fontSize: fontSize ? `${fontSize}px` : '12px' }}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
