import { SettingsDeleteButton } from 'App/Map/UserSettingsMenu/components/SettingsDeleteButton/SettingsDeleteButton'
import { SettingsDuplicateButton } from 'App/Map/UserSettingsMenu/components/SettingsDuplicateButton/SettingsDuplicateButton'
import { SettingsEditButton } from 'App/Map/UserSettingsMenu/components/SettingsEditButton/SettingsEditButton'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { colors } from 'settings/colors'
import type { Report } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { fetchApiCustomer } from 'utils/fetchApiCustomer'
import { showNotification } from 'utils/showNotification'
import { showConfirmSettingModal } from '../../../_utils/showConfirmSettingModal'
import { getReports, reportDeleteQuery } from '../../_utils/queries'
import type { TReportType } from '../../ReportForm/ReportType/ReportType'
import type { TModalDrawerForm } from '../../ReportsMain'
import type { WeeklyReport, WeeklyTrapReport } from '../../types'

type DeleteReportResponse = {
  data: {
    deleteReport: boolean
  }
}

type getReportResponse = {
  data: {
    user: {
      reports: Report
    }
  }
}

export const ReportCard = ({
  report,
  setSelectedReport,
  setModalDrawerForm,
  reportType,
  setReportType,
  setIsDuplicate,
}: {
  setSelectedReport: Dispatch<SetStateAction<WeeklyReport | WeeklyTrapReport | null>>
  setModalDrawerForm: Dispatch<SetStateAction<TModalDrawerForm>>
  reportType: TReportType
  report: WeeklyReport | WeeklyTrapReport
  setReportType: Dispatch<SetStateAction<TReportType>>
  setIsDuplicate: Dispatch<SetStateAction<boolean>>
}) => {
  const {
    id,
    name,
    rules: { properties },
  } = report

  const handleDeleteAlert = async (id: string) => {
    try {
      const response: DeleteReportResponse = await fetchApiCustomer({
        body: {
          query: reportDeleteQuery,
          variables: {
            id,
          },
        },
      })

      const success = response?.data?.deleteReport

      if (success) {
        const reportResponse: getReportResponse = await fetchApiCustomer({
          body: {
            query: getReports,
          },
        })

        const newReport: Report = { ...reportResponse?.data?.user?.reports }

        userDetailsStore.setState((s) => {
          return {
            ...s,
            reports: newReport,
          }
        })

        showNotification({
          type: 'success',
          message: translate.phrases.banyanApp('Report successfully deleted'),
        })
      } else {
        showNotification({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to delete report'),
        })
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to delete report'),
      })
    }
  }

  return (
    <div
      css={{
        borderBottom: `1px solid ${colors.grey200}`,
        padding: 15,
        marginBottom: 5,
      }}
    >
      <div
        css={{
          display: 'flex',
          gridTemplateRows: 'auto',
          gridTemplateColumns: '1fr 0.25fr auto',
          justifyContent: 'space-between',
          justifyItems: 'start',
          flexWrap: 'nowrap',
        }}
      >
        <div css={{ fontWeight: 500 }}>{name}</div>
        <div
          css={{
            display: 'flex',
            marginLeft: '10px',
            paddingRight: '10px',
            minWidth: '110px',
            justifyContent: 'end',
          }}
        >
          {properties.length === 0
            ? translate.phrases.banyanApp('All Properties')
            : `${properties.length} ${translate.phrases.banyanApp('Properties')}`}
        </div>
      </div>
      <div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'end',
          marginTop: '10px',
        }}
      >
        <SettingsDeleteButton
          onClick={() => {
            showConfirmSettingModal({
              title: translate.phrases.banyanApp('Delete report {{reportName}}', { reportName: name }),
              content: (
                <div>
                  <p>{translate.phrases.banyanApp('Are you sure you want to delete this report?')}</p>
                </div>
              ),
              onConfirm: () => handleDeleteAlert(id),
              confirmButtonText: translate.phrases.banyanApp('Delete Report'),
            })
          }}
        />
        <SettingsEditButton
          onClick={() => {
            setSelectedReport(report)

            setReportType(reportType)

            setModalDrawerForm('editReport')
          }}
        />
        <SettingsDuplicateButton
          onClick={() => {
            setReportType(reportType)

            setIsDuplicate(true)

            // @ts-ignore report id is null for new report
            setSelectedReport({ ...report, id: null })

            setModalDrawerForm('createReport')
          }}
        />
      </div>
    </div>
  )
}
