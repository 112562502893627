import { css } from '@emotion/css'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Text, useMantineTheme } from '@mantine/core'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import React, { useState } from 'react'
import { apiFetch } from 'utils/apiFetch'
import { z } from 'zod'
import type { InputPropsType } from './AuthenticationForm'
import { AuthenticationForm } from './AuthenticationForm'
import { useToast } from './AuthenticationForm/Toast/useToast'

const getValidationSchema = () =>
  z.object({
    email: z
      .string()
      .min(1, {
        message: translate.phrases.validation('{{label}} is required', {
          label: translate.phrases.banyanApp('Email'),
        }),
      })
      .email({
        message: translate.phrases.validation('{{label}} is invalid', {
          label: translate.phrases.banyanApp('Email'),
        }),
      }),
  })

export const ForgotPassword: FC<{ setCurrentFormType: React.Dispatch<React.SetStateAction<string>> }> = ({
  setCurrentFormType,
}) => {
  const theme = useMantineTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [successfullySubmitted, setSuccessfullySubmitted] = useState<boolean>(false)
  const { showToast, Toast } = useToast({ durationMillisecond: 3000, type: 'error' })

  const inputProps: InputPropsType[] = [
    {
      placeholder: translate.phrases.banyanApp('Email'),
      key: 'email',
      initialValue: '',
      isPasswordInput: false,
    },
  ]

  const handleSubmit = async (values: routes.ForgotPassword.Request) => {
    setLoading(true)

    try {
      await apiFetch({ url: '/forgot-password', body: values })

      setEmail(values.email)

      setSuccessfullySubmitted(true)
    } catch (error) {
      showToast(translate.phrases.banyanApp('A user with that email does not exist'))
    }

    setLoading(false)
  }

  const defaultFormProps = {
    header: (
      <>
        {Toast}
        <Text size={24} weight={300}>
          {translate.phrases.banyanApp('Forgot Password?')}
        </Text>
        <Text size={14} weight={400}>
          {translate.phrases.banyanApp(
            'Enter your email address below and we will send you an email with a link to reset your password.',
          )}
        </Text>
      </>
    ),
    inputProps: inputProps,
    buttonText: translate.phrases.banyanApp('Send Password Reset Email'),
    anchorText: translate.phrases.banyanApp('Back to Login'),
    onAnchorPress: () => setCurrentFormType('login'),
    onSubmit: (values: { [k: string]: string }) => handleSubmit(values as routes.ForgotPassword.Request),
    submitLoading: loading,
  }

  const successfullySubmittedFormProps = {
    header: (
      <>
        <Text size={24} weight={300}>
          {translate.phrases.banyanApp('Reset Password')}
        </Text>
        <div
          className={css`
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
          `}
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            color={theme.colors.green[0]}
            className={css`
              margin-right: 15px;
            `}
            size="lg"
          />
          <Text size={14} weight={400}>
            <translate.Phrases.banyanApp
              k="An email to reset your password was sent to <strong>{{email}}</strong>"
              v={{ email }}
            />
          </Text>
        </div>
        <Text size={14} weight={400}>
          {translate.phrases.banyanApp('Please check your email now.')}
        </Text>
      </>
    ),
    inputProps: [],
    buttonText: translate.phrases.banyanApp('Login Now'),
    onSubmit: () => setCurrentFormType('login'),
  }

  return (
    <AuthenticationForm
      {...(successfullySubmitted ? successfullySubmittedFormProps : defaultFormProps)}
      validationSchema={getValidationSchema()}
    />
  )
}
