import { Input } from '@mantine/core'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { validateStatusErrorHelper } from '../../../_utils/validateStatusErrorHelper'
import { getErrorStyle } from '../../_utils/getErrorStyle'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { ErrorTextWrapper } from '../../components/ErrorTextWrapper'

export const ReportName = ({
  name,
  setName,
  triedToSubmit,
  setNameIsValid,
}: {
  name: string | null
  setName: Dispatch<SetStateAction<string | null>>
  setNameIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  const [nameTouched, setNameTouched] = useState(false)
  const placeholder = 'Enter Name'

  let helpText = ''
  let validateStatusError = false

  const nameIsDirty = nameTouched || name !== null || triedToSubmit

  if (!name || !name.trim() || name.trim().length < 4 || name.length > 45) {
    helpText = translate.phrases.banyanApp('The report name must be between 4 and 45 characters long')

    validateStatusError = true
  }

  const help = helpTextErrorHelper(nameIsDirty && helpText)
  const validateStatus = validateStatusErrorHelper(nameIsDirty && validateStatusError)
  const errorStyle = getErrorStyle()

  useEffect(() => {
    setNameIsValid(!validateStatusError)
  }, [name])

  return (
    <div css={{ borderBottom: `1px solid ${colors.grey200}` }}>
      <h4>{translate.phrases.banyanApp('Name')}</h4>
      <Input
        type="text"
        value={name || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value)
        }}
        onBlur={() => {
          setNameTouched(true)
        }}
        placeholder={placeholder}
        style={{ width: '100%' }}
        classNames={{ input: validateStatus ? errorStyle : undefined }}
      />
      {help && <ErrorTextWrapper>{help}</ErrorTextWrapper>}
    </div>
  )
}
