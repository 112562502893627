import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'
import { parse as parseWKT } from 'wellknown'

export const serviceCenterPlannedNodesGet = async (propertyIds: number[]) => {
  const plannedNodes = await apiFetch({ url: '/service-center-planned-nodes-get', body: { propertyIds } })

  const shapedPlannedNodes = plannedNodes.map((node) => ({
    ...node,
    // Transform string WKT to serialized GeoJSON
    location: JSON.stringify(parseWKT(node.location as string)),
  })) as routes.ServiceCenterPlannedNodesGet.Response

  return shapedPlannedNodes
}
