import { useDisclosure } from '@mantine/hooks'
import { getMapControlsPointValueTypeAndPointCategory } from 'App/Map/CurrentValuesMap/caches/StationValuesCache/StationBadgeComponent/_utils/getMapControlsPointValueTypeAndPointCategory'
import { isNil } from 'lodash'
import { useEffect, useRef } from 'react'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapSelectedCardStore } from 'stores/mapSelectedCardStore'
import { mapStore } from 'stores/mapStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { useSetMapOverlayZIndex } from 'utils/useSetMapOverlayZIndex'
import { DataSourceOverlay } from '../../DataSourceOverlay/DataSourceOverlay'
import { Label } from '../../Label/Label'
import type { TWindMachineValueTypesToPropsDictionary, WIND_MACHINE_META } from '../WindMachineValuesCache'

export type TWindMachineBadgeComponentProps = {
  meta: WIND_MACHINE_META
  valueTypesToPropsDictionary: TWindMachineValueTypesToPropsDictionary
}

export const WindMachineBadgeComponent = (props: TWindMachineBadgeComponentProps) => {
  const [isHovered, { close, open }] = useDisclosure(false)
  const zoom = mapStore.useSelector((s) => s.zoom)
  const primaryValueGroup = usePrimaryValueGroup()

  const { showMapBadgeNames, valueType, pointCategory } = mapControlsStore.useSelector((s) => {
    const { valueType, pointCategory } = getMapControlsPointValueTypeAndPointCategory({
      mapControlsStoreState: s,
      valueGroup: primaryValueGroup,
      isOutOfBlock: props.meta.isOutOfBlock,
    })

    return {
      showMapBadgeNames: s.showMapBadgeNames,
      valueType,
      pointCategory,
      otherThingsWeWantToTriggerARefreshOn: [s.heatmapColoring, s.heatmapExtremes],
    }
  })

  const selectedFieldAssets = selectedFieldAssetsStore.useSelector((s) => s)
  const ref = useRef<HTMLDivElement>(null)
  const isSelected = selectedFieldAssets[pointCategory] === props.meta.lngLat

  useSetMapOverlayZIndex({
    baseZIndex: props.valueTypesToPropsDictionary?.[String(valueType)]?.baseZIndex,
    ref,
    mItem: 'M_InBlockPoint',
    isSelected,
    isHighlighted: false,
    isHovered,
  })

  useEffect(() => {
    close()
  }, [zoom])

  if (isNil(valueType)) return null

  const diameter = props?.valueTypesToPropsDictionary?.[valueType]?.getSize?.() ?? 50
  const children = props.valueTypesToPropsDictionary?.[valueType]?.children

  if (isNil(children)) return null

  let fontSize = 14

  if (children.length > 3) {
    fontSize = Math.floor((14 + children.length) / (children.length / 3))
  }

  return (
    <div
      css={{
        position: 'relative',
        backgroundColor: colors.midnight,
        color: 'white',
        fontSize,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        border: `2px solid ${(isSelected && 'yellow') || 'white'}`,
        borderRadius: '50%',
        padding: '0 3px',
        flexDirection: 'column',
        maxWidth: 220,
        width: diameter,
        height: diameter,
        ...props?.valueTypesToPropsDictionary?.[valueType]?.getContainerCSS?.(),
      }}
      ref={ref}
      onClick={() => {
        setSelectedFieldAsset({ lngLat: props.meta.lngLat })

        mapSelectedCardStore.actions.showWindMachine(props.meta)
      }}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      {(isHovered || showMapBadgeNames) && <Label bottom={diameter - 3}>{props.meta.name}</Label>}
      <div
        css={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 40,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.valueTypesToPropsDictionary?.[valueType]?.shouldAddDataSourceOverlay && (
          <DataSourceOverlay
            overlayCSS={{
              height: '100%',
              width: '100%',
              left: 0,
            }}
          />
        )}
        <div css={{ width: '100%' }}>{children}</div>
      </div>
    </div>
  )
}
