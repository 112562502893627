import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import type {
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentPointsValueTypes,
} from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'

const updateMapVisuals = (newValueType: TValuesCurrentBlocksValueTypes & TValuesCurrentPointsValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        wind_machine: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].wind_machine ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
    },
  }))
}

export const WindMachineSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.wind_machine?.valueType,
  )

  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const data = useMemo(() => {
    const returner: { value: TValuesCurrentPointsValueTypes; label: string }[] = [
      {
        value: 'windMachine_temperature_c',
        label: translate.phrases.banyanApp('Temperature'),
      },
      {
        value: 'windMachine_batteryVoltage_v',
        label: translate.phrases.banyanApp('Battery Voltage'),
      },
      {
        value: 'windMachine_isRunning',
        label: translate.phrases.banyanApp('Running State'),
      },
      {
        value: 'windMachine_rpm',
        label: translate.phrases.banyanApp('Fan Speed'),
      },
      {
        value: 'windMachine_status',
        label: translate.phrases.banyanApp('Status'),
      },
    ]

    return returner
  }, [properties, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
