import { isNil } from '@semios/app-platform-common'
import { getWindMachineActivityTitleAndColor } from 'App/Map/_utils/getWindMachineActivityTitleAndColor'

export const StatusTile = ({ status }: { status: string; auto: number }) => {
  const { color: tileColor, textColor } = getWindMachineActivityTitleAndColor(status)
  // Linear interpolation from shortest (ON) to longest (NO CONNECTION)
  const fontSize = !isNil(status) ? 10 + 6 - (status.length - 2) * (6 / 11) : 14

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '5px 8px 5px 8px',
          background: tileColor,
          borderRadius: '16px',
          position: 'relative',
        }}
      >
        <h3
          css={{
            color: textColor,
            margin: '0',
            fontSize: fontSize + 'px',
            fontWeight: '500',
          }}
        >
          {status ? status : 'N/A'}
        </h3>
      </div>
    </div>
  )
}
