import { getWindMachineActivityTitleAndColor } from 'App/Map/_utils/getWindMachineActivityTitleAndColor'
import { translate } from 'i18n/i18n'

export const StatusTile = ({ status }: { status: string }) => {
  const { color: tileColor, textColor } = getWindMachineActivityTitleAndColor(status)

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '2px 10px 2px 10px',
          background: tileColor,
          borderRadius: '16px',
          position: 'relative',
        }}
      >
        <p
          css={{
            color: textColor,
            margin: '0px',
          }}
        >
          {status ? status : translate.phrases.banyanApp('N/A')}
        </p>
      </div>
    </div>
  )
}
