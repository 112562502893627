import { translate } from 'i18n/i18n'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'

export const getWindMachineActivityTitleAndColor = (
  windMachineActivity: string | null,
): { color: TRGBAColorWith1AtTheEnd; name: string; textColor: TRGBAColorWith1AtTheEnd } => {
  if (windMachineActivity === 'OFF') {
    return {
      color: colors.offBadge,
      name: translate.phrases.banyanApp('Off'),
      textColor: colors.white,
    }
  }

  if (windMachineActivity === 'ON') {
    return {
      color: colors.fullRunBadge,
      name: translate.phrases.banyanApp('On'),
      textColor: colors.midnight,
    }
  }

  if (windMachineActivity === 'NO CONNECTION') {
    return {
      color: colors.noConnectionBadge,
      name: translate.phrases.banyanApp('No Connection'),
      textColor: colors.midnight,
    }
  }

  if (windMachineActivity === 'WARM UP') {
    return {
      color: colors.warmUpBadge,
      name: translate.phrases.banyanApp('Warm Up'),
      textColor: colors.white,
    }
  }

  if (windMachineActivity === 'COOL DOWN') {
    return {
      color: colors.coolDownBadge,
      name: translate.phrases.banyanApp('Cool Down'),
      textColor: colors.white,
    }
  }

  if (windMachineActivity === 'ERROR') {
    return {
      color: colors.errorBadge,
      name: translate.phrases.banyanApp('Error'),
      textColor: colors.midnight,
    }
  }

  return {
    color: colors.grey50,
    name: translate.phrases.banyanApp('Unknown'),
    textColor: colors.midnight,
  }
}
