import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export type NodeServiceIssueResolutionActionsAddMeta =
  routes.ServiceCenterNodeServiceIssueResolutionActionsAdd.Request

export const request = async (meta: NodeServiceIssueResolutionActionsAddMeta) => {
  return await apiFetch({ url: '/service-center-node-service-issue-resolution-actions-add', body: meta })
}

export const serviceCenterNodeServiceIssueResolutionActionsAdd = {
  request,
}
