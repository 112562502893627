import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import { useCurrentValuesWindSpeed } from 'App/Map/CurrentValuesMap/caches/WindMachineValuesCache/WindMachineBadgeComponent/_utils/useCurrentValuesWindSpeed'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { DeviceInfoBlock } from './DeviceInfoBlock/DeviceInfoBlock'

type TWindMachineDetailsElement = routes.WindMachine.TWindMachineDetailsElement

export const WindMachineInfo = ({
  content,
  loading,
  background = colors.grey50,
}: {
  content: TWindMachineDetailsElement['windMachineDetails'][0]
  loading?: boolean
  background?: string
}) => {
  const windSpeedData = useCurrentValuesWindSpeed()
  const batteryData = content?.equipmentStatus?.batteryExternalV
  const fuelData = content?.equipmentStatus?.fuelLevel
  const rpmData = content?.equipmentStatus?.rpm
  const tempData = content?.equipmentStatus?.temperature
  const fuelThreshold = 40
  const voltageThreshold = 11.8
  const numericFuelDataValue = isNaN(Number(fuelData)) ? null : Number(fuelData)
  const isFuelNotConnected = fuelData === 'Not Connected'

  let isFuelLow = false

  if (!isFuelNotConnected) {
    isFuelLow = isNil(numericFuelDataValue) || numericFuelDataValue < fuelThreshold
  }

  const infoObjects = [
    {
      data: windSpeedData.windSpeed || translate.phrases.banyanApp('N/A'),
      icon: 'windSpeed',
    },
    {
      data: unitConverter.windMachineTemperature(tempData).valueWithSuffix(),
      icon: 'temperature',
    },
    {
      data: unitConverter.windMachineRPM(rpmData).valueWithSuffix(),
      icon: 'rpm',
    },
    {
      data: `${
        isFuelNotConnected
          ? translate.phrases.banyanApp('N/A')
          : translate.measurements.percentage.valueWithUnit(numericFuelDataValue, 0)
      }`,
      icon: 'fuel',
      isLow: isFuelLow,
    },
    {
      data: unitConverter.windMachineVoltage(batteryData).valueWithSuffix(),
      icon: 'battery',
      isLow: isNaN(Number(batteryData)) ? true : Number(batteryData) < voltageThreshold,
    },
  ]

  return (
    <div
      css={{
        'display': 'flex',
        'flexDirection': 'row',
        'justifyContent': 'space-between',
        'alignItems': 'center',
        'padding': '10px 5px',
        'backgroundColor': background,
        'borderRadius': '3px',
        'marginTop': '10px',
        'flexWrap': 'wrap',
        'fontSize': '13px',
        '@media (max-width: 600px)': {
          justifyContent: 'left',
        },
      }}
    >
      {!loading &&
        infoObjects.map((item, index) => {
          return <DeviceInfoBlock key={index} data={item.data} icon={item.icon} isLow={item.isLow} />
        })}
    </div>
  )
}
