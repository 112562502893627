import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import type { EAggregationInterval, VV } from '@semios/app-platform-value-type-definitions'
import { GoogleMap } from 'components/GoogleMap/GoogleMap'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapStore } from 'stores/mapStore'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { isAggregatedType } from 'utils/isAggregatedValueType'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getHeatmapCSS } from '../CurrentValuesMap/_utils/by-domain/_utils/getHeatmapCSS'
import { DATA_SOURCE_OVERLAY_BACKGROUND_COLOR } from '../CurrentValuesMap/caches/DataSourceOverlay/DataSourceOverlay'
import { valueGroupsToUnitConverterFunctionForRendering } from '../MapControls/HeatmapControlsRow/_utils/valueGroupsToUnitConverterFunctionForRendering'
import { getLngLatFromString } from '../_utils/getLngLatFromString'
import { makeBlockBounds } from '../_utils/makeBlockBounds'
import { findClosestTimeseries } from './_utils/findClosestTimeseries'

const initValuesMap = ({
  propertyBlocks,
  centroidCoordinates,
  valueGroupData,
  currentTime,
  mapRef,
  valueGroup,
  aggregationInterval,
  newDatesSelected,
}: {
  propertyBlocks?: {
    [blockId: string]: routes.UserAppStartup.TFieldAssetValueTypes.TBlock
  } | null
  centroidCoordinates?: {
    lat: number
    lng: number
  }
  valueGroupData?: routes.Values.Response['heatmapPoints']
  currentTime: Date
  mapRef: React.MutableRefObject<google.maps.Map | null>
  valueGroup: TValueGroup
  aggregationInterval: EAggregationInterval
  newDatesSelected: boolean
}) => {
  const valueGroupFnc =
    valueGroupsToUnitConverterFunctionForRendering[valueGroup] || unitConverter.temperatureInCanopy

  const properties = fieldAssetStore.useSelector((s) => s.properties)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const shouldAddDataSourceOverlay = smallStore.useSelector((s) => s.showDataSource)

  const timeseriesRef = useRef<{
    [lngLatText: string]: {
      timestamp: string
      value: VV.DomainTypes.Weather.TWeatherReturn
      isDeviceReported?: boolean | null
    }[]
  }>({})

  const markersRef = useRef<Record<string, { overlay: google.maps.OverlayView; element: HTMLElement }>>({})

  const mapOptions = useMemo<google.maps.MapOptions>(() => {
    const { center, zoom } = mapStore.getState()

    return {
      center: centroidCoordinates ? centroidCoordinates : center,
      zoom: centroidCoordinates ? 14 : zoom,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      disableDefaultUI: true,
      fullscreenControl: false,
      mapTypeId: 'satellite',
      tilt: 0,
      minZoom: 3,
    }
  }, [centroidCoordinates])

  const createMarkerElement = (
    dataPointValue: number,
    isDeviceReported: boolean | null | undefined,
    blockIds: number[] | null,
    propertyId: number,
  ) => {
    const div = document.createElement('div')
    const { heatmapExtremes } = mapControlsStore.getState()

    const containerCSS = getHeatmapCSS({
      value: dataPointValue,
      heatmapExtremes,
    })

    Object.assign(div.style, {
      fontSize: '12px',
      fontWeight: 'bold',
      width: '32px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      border: '1px solid white',
      position: 'absolute',
      ...containerCSS,
    })

    const valueElement = document.createElement('div')

    valueElement.innerHTML = valueGroupFnc(dataPointValue).valueAsString()

    if (shouldAddDataSourceOverlay && isDeviceReported === false) {
      const overlayElement = document.createElement('div')

      Object.assign(overlayElement.style, {
        backgroundColor: DATA_SOURCE_OVERLAY_BACKGROUND_COLOR,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        position: 'absolute',
      })

      div.appendChild(overlayElement)
    }

    div.appendChild(valueElement)

    if (blockIds && blockIds.length > 0) {
      blockIds.forEach((blockId) => {
        div.onclick = () => setSelectedFieldAsset({ block: blockId })
      })
    } else {
      div.onclick = () => setSelectedFieldAsset({ property: propertyId })
    }

    return div
  }

  const clearMarkers = useCallback(() => {
    markersRef.current = {}

    timeseriesRef.current = {}
  }, [])

  const updateMarkers = useCallback(() => {
    if (!map || !valueGroupData || Object.keys(valueGroupData).length === 0) return

    const { heatmapExtremes } = mapControlsStore.getState()

    Object.entries(valueGroupData).forEach(([lngLat, pointData]) => {
      const { values, blockIds, propertyId } = pointData
      const timezone = propertyId ? String(properties?.[Number(propertyId)].timezone) : moment.tz.guess()
      const [lng, lat] = getLngLatFromString(lngLat)
      const markerKey = lngLat

      if (!timeseriesRef.current[markerKey]) {
        timeseriesRef.current[markerKey] = values?.temperature_IN?.[0]?.timeseries || []
      }

      const timeseries = timeseriesRef.current[markerKey]
      const closestTimeseries = findClosestTimeseries(timeseries, currentTime, timezone)

      const dataPointValue = isAggregatedType(closestTimeseries?.value)
        ? closestTimeseries?.value?.average
        : closestTimeseries?.value

      const isDeviceReported = closestTimeseries?.isDeviceReported

      if (isNil(dataPointValue)) return

      const updateMarkerElement = (element: HTMLElement) => {
        const containerCSS = getHeatmapCSS({ value: dataPointValue, heatmapExtremes })

        Object.assign(element.style, containerCSS)

        element.innerHTML = ''

        if (shouldAddDataSourceOverlay && isDeviceReported === false) {
          const overlayElement = document.createElement('div')

          Object.assign(overlayElement.style, {
            backgroundColor: DATA_SOURCE_OVERLAY_BACKGROUND_COLOR,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            position: 'absolute',
          })

          element.appendChild(overlayElement)
        }

        const valueElement = document.createElement('div')

        valueElement.innerHTML = valueGroupFnc(dataPointValue).valueAsString()

        element.appendChild(valueElement)
      }

      if (!markersRef.current[markerKey]) {
        const markerElement = createMarkerElement(dataPointValue, isDeviceReported, blockIds, propertyId)
        const overlay = new google.maps.OverlayView()

        overlay.onAdd = function () {
          const overlayMouseTarget = this.getPanes()?.overlayMouseTarget

          if (overlayMouseTarget) {
            overlayMouseTarget.appendChild(markerElement)
          }
        }

        overlay.draw = function () {
          const position = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(lat, lng))

          if (position) {
            markerElement.style.left = `${position.x - 16}px`

            markerElement.style.top = `${position.y - 16}px`

            markerElement.style.position = 'absolute'
          }
        }

        overlay.setMap(map)

        markersRef.current[markerKey] = { overlay, element: markerElement }
      } else {
        const { element } = markersRef.current[markerKey]

        updateMarkerElement(element)
      }
    })
  }, [map, valueGroupData, currentTime, aggregationInterval, clearMarkers, shouldAddDataSourceOverlay])

  const heatmapExtremes = mapControlsStore.useSelector((s) => s.heatmapExtremes)

  useEffect(() => {
    if (map && valueGroupData) {
      if (newDatesSelected) {
        clearMarkers()
      }

      updateMarkers()
    }
  }, [map, valueGroupData, currentTime, updateMarkers, clearMarkers, newDatesSelected, heatmapExtremes])

  useEffect(() => {
    if (map && propertyBlocks) {
      makeBlockBounds(map, propertyBlocks)
    }
  }, [map, propertyBlocks])

  const onInit = useCallback(
    (map: google.maps.Map) => {
      setMap(map)

      mapRef.current = map

      if (centroidCoordinates) {
        map.setCenter(centroidCoordinates)
      }
    },
    [centroidCoordinates, mapRef],
  )

  return (
    <GoogleMap
      width={'100%'}
      onInit={onInit}
      height={'100%'}
      mapOptions={mapOptions}
      // eslint-disable-next-line no-console
      onError={(err) => console.log(err)}
      defaultErrorMessage={translate.phrases.banyanApp('Error loading map.')}
    />
  )
}

export const ValuesMap = memo(initValuesMap) as typeof initValuesMap
