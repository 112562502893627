import { LoadingOverlay, Table } from '@mantine/core'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { sortByKey } from '@semios/app-platform-common'
import { refreshMapData } from 'App/Map/PanelDetails/_utils/refreshMapData'
import { Authorization } from 'components/Authorization/Authorization'
import { Button } from 'components/Button/Button'
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { NumberInput } from 'components/NumberInput/NumberInput'
import { translate } from 'i18n/i18n'
import React, { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { ValueGroupDictionary } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { apiFetch } from 'utils/apiFetch'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { showNotification } from 'utils/showNotification'
import { useApiREST } from 'utils/useApiREST'

const permission = 'EDIT_ALTERNARIA_DSV_THRESHOLD'

type TAlternariaSettings = {
  blockId: number
  name: string
  threshold: number
}

type TAlternariaSettingsProps = {
  handleAfterSave: (blockIdToNewThreshold: Record<number, number>) => void
}

export const AlternariaSettings = ({ handleAfterSave }: TAlternariaSettingsProps) => {
  const [isAlternariaSettingsOpen, setIsAlternariaSettingsOpen] = useState(false)
  const [currentBlocksValues, setCurrentBlocksValues] = useState<Record<number, number>>({})
  const [currentFocusBlock, setCurrentFocusBlock] = useState<TAlternariaSettings['blockId'] | null>(null)
  const [hasChanges, setHasChanges] = useState(false)
  const [confirmModalOpened, setConfirmModalOpened] = useState(false)
  const { propertyId } = selectedFieldAssetsStore.useSelector((s) => ({ propertyId: s.property }))
  const allBlocks = fieldAssetStore.getState()?.properties?.[Number(propertyId)]?.blocks

  const blocks = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(allBlocks || {}),
    valuesTimeseries: ['alternaria'],
  })

  const blockIds = blocks.map((b) => b.blockId)

  const { data: initialAlternariaSettings, loading } = useApiREST({
    body: { alternariaDSV: { blockIds } },
    url: '/field-asset-settings-get',
    initialLoading: true,
    initialState: [],
    preventFetch: !isAlternariaSettingsOpen || !propertyId || blockIds.length === 0,
    watchers: [isAlternariaSettingsOpen],
    shaper: (data) => {
      const alternariaData = data.alternariaDSV ?? []

      if (!(alternariaData instanceof Array)) return []

      return alternariaData.sort(sortByKey('name'))
    },
  })

  useEffect(() => {
    const shapedBlocks: Record<number, number> = initialAlternariaSettings.reduce((acc, block) => {
      return { ...acc, [block.blockId]: block.threshold }
    }, {})

    setCurrentBlocksValues(shapedBlocks)

    setHasChanges(false)
  }, [initialAlternariaSettings])

  const handleResetAndClose = () => {
    setCurrentBlocksValues({})

    setCurrentFocusBlock(null)

    setIsAlternariaSettingsOpen(false)
  }

  const updateFocusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFocusBlock(Number(e.target.name))
  }

  const handleFormChange = (value: number | '', blockId: number) => {
    if (value === '') return

    setCurrentBlocksValues((prevState) => {
      const previousValue = prevState[blockId]
      const newValue = value

      if (previousValue !== newValue) {
        setHasChanges(true)

        return {
          ...prevState,
          [blockId]: newValue,
        }
      }

      return prevState
    })
  }

  const handleSave = async () => {
    if (hasChanges) {
      const args: routes.FieldAssetSettingsSet.Request['alternariaDSVUpdate'] = Object.keys(
        currentBlocksValues,
      ).map((blockId) => ({
        blockId: Number(blockId),
        threshold: currentBlocksValues[Number(blockId)],
      }))

      try {
        await apiFetch({ url: '/field-asset-settings-set', body: { alternariaDSVUpdate: args } })

        showNotification({
          message: translate.phrases.banyanApp('Successfully updated settings'),
          type: 'success',
        })

        refreshMapData(ValueGroupDictionary.alternaria)

        handleAfterSave(currentBlocksValues)

        handleResetAndClose()
      } catch (error) {
        showNotification({ message: translate.phrases.banyanApp('Failed to update settings'), type: 'error' })
      }
    } else {
      handleResetAndClose()
    }
  }

  const handleCloseWithoutSaving = () => {
    if (hasChanges) {
      setConfirmModalOpened(true)
    } else {
      handleResetAndClose()
    }
  }

  const applyToAll = (blockId: number) => {
    const threshold = currentBlocksValues[blockId]

    const blocksSetBySameValue = Object.keys(currentBlocksValues).reduce((newBlocks, blockId) => {
      newBlocks[Number(blockId)] = threshold

      return newBlocks
    }, {} as typeof currentBlocksValues)

    setCurrentBlocksValues(blocksSetBySameValue)
  }

  return (
    <Authorization requires={{ permission, entity: Number(propertyId) }}>
      <>
        <ModalDrawer
          title={translate.phrases.banyanApp('Edit Spray Thresholds')}
          opened={isAlternariaSettingsOpen}
          primaryButtonText={translate.phrases.banyanApp('Save Changes')}
          primaryButtonOnPress={handleSave}
          secondaryButtonText={translate.phrases.banyanApp('Cancel')}
          secondaryButtonOnPress={handleCloseWithoutSaving}
          onClose={handleCloseWithoutSaving}
          zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
        >
          <LoadingOverlay visible={loading} />
          <Table>
            <thead>
              <tr>
                {/* a small hack to keep focus from hiding value of an input */}
                <th data-autofocus>{translate.phrases.banyanApp('Block')}</th>
                <th colSpan={2}>{translate.phrases.banyanApp('Threshold')}</th>
              </tr>
            </thead>
            <tbody>
              {initialAlternariaSettings.map((block) => {
                return (
                  <tr key={block.blockId}>
                    <td>{block.name}</td>
                    <td>
                      <div>
                        <NumberInput
                          min={0}
                          value={currentBlocksValues[block.blockId]}
                          name={block.blockId.toString()}
                          data-block-name={block.name}
                          onFocus={updateFocusChange}
                          onChange={(value) => handleFormChange(value, block.blockId)}
                          hideControls
                        />
                      </div>
                    </td>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => applyToAll(block.blockId)}
                        css={{
                          visibility:
                            Object.keys(currentBlocksValues).length > 1 && currentFocusBlock === block.blockId
                              ? 'unset'
                              : 'hidden',
                        }}
                      >
                        {translate.phrases.banyanApp('Apply to All')}
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <ConfirmationModal
            modalOpened={confirmModalOpened}
            closeModal={() => setConfirmModalOpened(false)}
            title={translate.phrases.banyanApp('Unsaved Changes')}
            content={
              <div>
                {translate.phrases.banyanApp("Any changes you have made won't be saved.")}
                <br />
                {translate.phrases.banyanApp('Select an option below.')}
              </div>
            }
            modalWindowSize={350}
            buttonContent={
              <div
                style={{
                  color: colors.midnight,
                  display: 'grid',
                  gridTemplateColumns: '50% 50%',
                  width: '100%',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  variant={'negative'}
                  style={{ gridColumn: 1, gridRow: 1, marginRight: 2, width: 160, color: colors.midnight }}
                  onClick={() => {
                    setConfirmModalOpened(false)

                    handleResetAndClose()
                  }}
                >
                  {translate.phrases.banyanApp('Discard Changes')}
                </Button>
                <Button
                  variant={'secondary'}
                  css={{
                    'gridColumn': 2,
                    'gridRow': 1,
                    'width': 160,
                    'justifySelf': 'end',
                    'borderColor': colors.primary,
                    'color': colors.primary,
                    '&:not([disabled],[data-loading="true"]):hover': {
                      color: colors.primary,
                      background: colors.white,
                      shadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', // Matches mantine style
                    },
                  }}
                  onClick={() => {
                    setConfirmModalOpened(false)
                  }}
                >
                  {translate.phrases.banyanApp('Keep Editing')}
                </Button>
                <Button
                  variant={'primary'}
                  style={{
                    gridArea: '2 / 1 / 2 / 3',
                    marginTop: 10,
                  }}
                  onClick={() => {
                    setConfirmModalOpened(false)

                    handleSave()
                  }}
                >
                  {translate.phrases.banyanApp('Save Changes')}
                </Button>
              </div>
            }
          />
        </ModalDrawer>
        <Button
          variant={'link'}
          onClick={() => setIsAlternariaSettingsOpen(true)}
          style={{
            marginLeft: 'auto',
            marginRight: 0,
            display: blockIds.length > 0 ? 'unset' : 'none',
          }}
        >
          {translate.phrases.banyanApp('Edit Spray Thresholds')}
        </Button>
      </>
    </Authorization>
  )
}
