import { colors } from 'settings/colors'

export const BadgeCell = ({
  backgroundColor,
  color = colors.midnight,
  height = 'max-content',
  alignItems = 'center',
  padding = '4px 12px',
  children,
}: {
  backgroundColor: string
  color?: string
  height?: string
  alignItems?: string
  padding?: string
  children: React.ReactNode
}) => {
  return (
    <div
      css={{
        alignItems: alignItems,
        backgroundColor,
        borderRadius: 3,
        color,
        display: 'flex',
        fontWeight: 'bold',
        justifyContent: 'center',
        padding: padding,
        transition: '0.2s ease all',
        width: 'max-content',
        minWidth: 74,
        maxWidth: '100%',
        height: height,
      }}
    >
      {children}
    </div>
  )
}
