import type { Interpolation, Theme } from '@emotion/react'
import { Menu, Text } from '@mantine/core'
import { IconEllipsis } from 'components/icons/IconEllipsis'
import type { FC, ReactElement, ReactNode } from 'react'
import { colors } from 'settings/colors'
import { BackIcon } from '../BackIcon/BackIcon'
import { CloseIcon } from '../CloseIcon/CloseIcon'

type WideHeaderProps = {
  onClose: () => void
  title: string
  rightIconButton?: ReactNode
  isSecondaryModal?: boolean
  style?: Interpolation<Theme>
  DropdownMenuItems?: ReactElement
}

export const WideHeader: FC<WideHeaderProps> = ({
  onClose,
  title,
  rightIconButton,
  isSecondaryModal,
  style,
  DropdownMenuItems,
}) => {
  return (
    <div
      css={[
        {
          position: 'sticky',
          top: 0,
          zIndex: 15,
          backgroundColor: colors.grey50,
          display: 'flex',
          alignItems: 'center',
          paddingTop: 'max(18px, calc(8px + env(safe-area-inset-top)))',
          paddingBottom: 18,
        },
        style,
      ]}
    >
      <div
        css={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          paddingRight: 20,
        }}
      >
        <div
          css={{
            'marginLeft': 20,
            'marginRight': 25,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={onClose}
        >
          {isSecondaryModal ? <BackIcon /> : <CloseIcon />}
        </div>

        <div
          css={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minWidth: 0,
          }}
        >
          <Text
            color={colors.midnight}
            weight={700}
            size={18}
            css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '24px' }}
          >
            {title}
          </Text>

          {rightIconButton}
        </div>
        {DropdownMenuItems && (
          <Menu offset={{ crossAxis: -53 }}>
            <Menu.Target>
              <div css={{ position: 'fixed', right: '8px', top: '20px' }}>
                <IconEllipsis />
              </div>
            </Menu.Target>
            <Menu.Dropdown>{DropdownMenuItems}</Menu.Dropdown>
          </Menu>
        )}
      </div>
    </div>
  )
}
