import { Group, Loader, Modal, NumberInput, Switch } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TLngLat } from '@semios/app-platform-banyan-route-definitions/dist/routes/userAppStartup/fieldAssetKeyTypes'
import type { TWindMachineDetailsElement } from '@semios/app-platform-banyan-route-definitions/dist/routes/windMachine'
import { Button } from 'components/Button/Button'
import { IconEdit } from 'components/icons/IconEdit'
import { IconPower } from 'components/icons/IconPower'
import { IconWmWarning } from 'components/icons/IconWmWarning'
import { translate } from 'i18n/i18n'
import { isEmpty, isNil } from 'lodash'
import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'
import { checkAuthorization } from 'utils/checkAuthorization'
import { unitConverter } from 'utils/unitConverter/unitConverter'

export const WindMachineControls = ({
  windMachineName,
  content,
  state,
  fetchData,
}: {
  windMachineName: string
  content: TWindMachineDetailsElement['windMachineDetails'][0]
  state: {
    dataDate: Record<string, string | null>
    setDataDate: Dispatch<SetStateAction<Record<string, string | null>>>
    windMachineStoppedAt: Record<string, number | null>
    setWindMachineStoppedAt: Dispatch<SetStateAction<Record<string, number | null>>>
    isAuto: Record<string, boolean>
    setIsAuto: Dispatch<SetStateAction<Record<string, boolean>>>
  }
  fetchData: () => void
}) => {
  const isImperial = userDetailsStore.getState().temperature === 'IMPERIAL'
  const lngLat = `POINT(${content.geom.coordinates[0]} ${content.geom.coordinates[1]})` as TLngLat
  const isRunning = content?.equipmentStatus.running
  const defaultStartTemp = isNil(content) ? 0 : content.equipmentSettings.tempStart
  const defaultStopTemp = isNil(content) ? 0 : content.equipmentSettings.tempStop
  const selectedProperty = selectedFieldAssetsStore.useSelector((s) => s.property)
  const [opened, { open, close }] = useDisclosure(false)
  const [editModalOpened, { open: editModalOpen, close: editModalClose }] = useDisclosure(false)
  const [startTemp, setStartTemp] = useState(() => defaultStartTemp ?? 0)
  const [stopTemp, setStopTemp] = useState(() => defaultStopTemp ?? 0)
  const [loading, setLoading] = useState<boolean>(false)
  const [stateUpdated, setStateUpdated] = useState({ start: false, stop: false })
  const { dataDate, windMachineStoppedAt, setWindMachineStoppedAt, isAuto, setIsAuto } = state
  const inputUnit = isImperial ? 'f' : 'c'

  const cleanedStartTemp =
    isNil(startTemp) || startTemp === defaultStartTemp || !stateUpdated.start
      ? defaultStartTemp
      : unitConverter
          .windMachineTemperature(startTemp, { inputUnit, outputUnit: 'c', decimalPlaces: 2 })
          .value()

  const cleanedStopTemp =
    isNil(stopTemp) || stopTemp === defaultStopTemp || !stateUpdated.stop
      ? defaultStopTemp
      : unitConverter
          .windMachineTemperature(stopTemp, { inputUnit, outputUnit: 'c', decimalPlaces: 2 })
          .value()

  const validateTemperature = (temp: number) => {
    if (isImperial) {
      return temp >= -40 && temp <= 104
    }

    return temp >= -40 && temp <= 40
  }

  const isStartTempValid = validateTemperature(startTemp)
  const isStopTempValid = validateTemperature(stopTemp)
  const isTemperatureValid = isStartTempValid && isStopTempValid
  const isTempOrderValid = (cleanedStartTemp ?? 0) < (cleanedStopTemp ?? 0)

  const handleRequest = async () => {
    if (!stateUpdated.start && !stateUpdated.stop) {
      setStateUpdated({ start: false, stop: false })

      return
    }

    setLoading(true)

    try {
      await apiFetch({
        url: '/wind-machine',
        body: {
          windMachineSettings: {
            lngLats: [lngLat],
            settings: {
              tempStart: cleanedStartTemp,
              tempStop: cleanedStopTemp,
            },
          },
        } as routes.WindMachine.Request,
      })
    } catch (error) {
    } finally {
      setLoading(false)

      setStateUpdated({ start: false, stop: false })

      fetchData()

      editModalClose()
    }
  }

  const handleAutoToggle = async (state: boolean) => {
    await apiFetch({
      url: '/wind-machine',
      body: {
        windMachineSettings: {
          lngLats: [lngLat],
          settings: {
            auto: state ? 1 : 0,
          },
        },
      } as routes.WindMachine.Request,
    })

    fetchData()
  }

  const handleCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isAuto[lngLat] && event.target.checked) {
      open()
    } else {
      setIsAuto((prev) => ({ ...prev, [lngLat]: event.target.checked }))

      handleAutoToggle(false)
    }
  }

  const confirmAuto = () => {
    setIsAuto((prev) => ({ ...prev, [lngLat]: true }))

    handleAutoToggle(true)

    close()
  }

  if (
    isEmpty(content) ||
    !checkAuthorization({
      permission: 'PUBLISH_FROST_FAN_OPERATIONAL_DEVICE_SETTINGS',
      entity: Number(selectedProperty),
    })
  ) {
    return null
  }

  const handleManualStop = async () => {
    try {
      const request = await apiFetch({
        url: '/wind-machine',
        body: {
          windMachineSettings: {
            lngLats: [lngLat],
            settings: {
              stop: true,
            },
          },
        } as routes.WindMachine.Request,
      })

      if (!isNil(Object.keys(request?.windMachineSettings || []).length))
        setWindMachineStoppedAt((prev) => ({ ...prev, [lngLat]: +new Date() }))
    } catch (error) {
      return
    }
  }

  let offButtonDisabled = true
  let startStopPending = true
  let autoPending = true

  const wmDataDate = dataDate[lngLat]
  const wmStoppedAt = windMachineStoppedAt[lngLat]
  const wmNewSettings = content?.applicationSettingsNew?.settings
  const wmStatus = content?.applicationSettingsNew?.status

  if (!isNil(wmDataDate)) {
    offButtonDisabled = isNil(wmStoppedAt)
      ? false
      : (offButtonDisabled = +new Date(wmDataDate) < +new Date(wmStoppedAt))

    autoPending =
      isNil(wmNewSettings?.auto) || wmStatus === 'onDevice'
        ? false
        : wmNewSettings?.auto !== content?.equipmentSettings?.auto

    if ((isNil(wmNewSettings.tempStart) && isNil(wmNewSettings.tempStop)) || wmStatus === 'onDevice') {
      startStopPending = false
    } else if (wmStatus === 'new') {
      startStopPending =
        wmNewSettings?.tempStart !== content?.equipmentSettings?.tempStart ||
        wmNewSettings?.tempStop !== content?.equipmentSettings?.tempStop
    }
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderBottom: 'solid 1px #E2E2E2',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          padding: '10px',
          fontSize: '12px',
        }}
      >
        <Switch
          size="lg"
          name="autoMode"
          checked={isAuto[lngLat]}
          onChange={(e) => handleCheckChange(e)}
          styles={{
            label: { cursor: 'unset', fontSize: 16 },
            track: {
              width: 36,
              cursor: 'pointer',
              backgroundColor: isAuto[lngLat] ? `${colors.green} !important` : undefined,
              borderColor: isAuto[lngLat] ? `${colors.green} !important` : undefined,
            },
          }}
        />
        <label
          css={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '10px',
            fontSize: '15px',
          }}
        >
          {translate.phrases.banyanApp('Automatic Start/Stop')}
          {autoPending && <Loader size={10} style={{ marginLeft: 4 }} />}
        </label>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          padding: '10px',
          fontSize: '12px',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h5>{translate.phrases.banyanApp('Temperatures')}</h5>
        <div>
          <p>
            {translate.phrases.banyanApp('Start')}
            <span
              css={{
                marginLeft: 5,
                fontWeight: 'bold',
              }}
            >
              {unitConverter.windMachineTemperature(Number(defaultStartTemp)).valueWithSuffix()}
            </span>
          </p>
        </div>
        <div>
          <p>
            {translate.phrases.banyanApp('Stop')}
            <span
              css={{
                marginLeft: '5px',
                fontWeight: 'bold',
              }}
            >
              {unitConverter.windMachineTemperature(Number(defaultStopTemp)).valueWithSuffix()}
            </span>
          </p>
        </div>

        <Button
          variant={'secondary'}
          css={{
            borderRadius: '100%',
            fontSize: '12px',
            width: '30px',
            height: '30px',
            padding: '0',
          }}
          onClick={editModalOpen}
        >
          {startStopPending ? (
            <Loader
              css={{
                display: 'flex',
              }}
              size={10}
            />
          ) : (
            <div css={{ display: 'flex' }}>
              <IconEdit />
            </div>
          )}
        </Button>
      </div>
      {isRunning === 1 ? (
        <Button
          css={{
            width: '100%',
            marginBottom: '10px',
          }}
          variant="negative"
          leftIcon={<IconPower />}
          onClick={handleManualStop}
          disabled={offButtonDisabled}
        >
          <p css={{ color: colors.midnight }}>{translate.phrases.banyanApp('Turn Fan Off')}</p>
        </Button>
      ) : null}
      <Modal opened={opened} withCloseButton={false} onClose={close} centered padding={0}>
        <h4
          css={{
            padding: '0 0 0 15px',
            margin: '10',
          }}
        >
          {translate.phrases.banyanApp('Automatic Start/Stop')}
        </h4>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            margin: '10px',
            background: 'rgba(235, 76, 76, 1)',
            borderRadius: '3px',
            boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.35)',
            color: '#ffffff',
            fontSize: '18px',
          }}
        >
          <p
            css={{
              padding: '0px 20px 0px 0px',
            }}
          >
            {translate.phrases.banyanApp(
              'WARNING Automatic start/stop enables the machine to start automatically and without warning. Click Confirm if the machine is in operating condition and all personnel are clear of the machine.',
            )}
          </p>
          <span
            css={{
              fontSize: '100px',
            }}
          >
            <IconWmWarning />
          </span>
        </div>
        <Group
          mt="xl"
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            background: 'linear-gradient(0deg, #E2E2E2, #E2E2E2),linear-gradient(0deg, #F8F8F8, #F8F8F8)',
            padding: '10px',
          }}
        >
          <Button variant="link-negative" onClick={close} css={{ fontSize: '16px' }}>
            {translate.phrases.banyanApp('Cancel')}
          </Button>
          <Button variant="primary" onClick={confirmAuto}>
            {translate.phrases.banyanApp('Confirm')}
          </Button>
        </Group>
      </Modal>

      <Modal opened={editModalOpened} withCloseButton={false} onClose={editModalClose} centered padding={0}>
        <Modal.Header
          style={{ justifyContent: 'start', alignContent: 'center', height: '3em', paddingLeft: 5 }}
        >
          <Modal.CloseButton style={{ marginLeft: 0, marginRight: 5, paddingBottom: 3 }} />
          <Modal.Title>
            <div>
              <h4>{windMachineName}</h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <div css={{ padding: '0 15px 15px 15px' }}>
          <p
            css={{
              margin: '0',
              fontSize: '14px',
            }}
          >
            {translate.phrases.banyanApp('UPDATE_THE_START_AND_STOP_TEMPERATURE_AFTER_SAVING_MESSAGE')}
          </p>
          <h5
            css={{
              margin: '10',
            }}
          >
            {translate.phrases.banyanApp('Start/Stop Temperatures')}
          </h5>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              css={{
                display: 'flex',
                marginRight: '10px',
              }}
            >
              <label
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '10px',
                }}
              >
                {translate.phrases.banyanApp('Start')}
              </label>
              <NumberInput
                css={{ maxWidth: '100%' }}
                defaultValue={Number(unitConverter.windMachineTemperature(defaultStartTemp).value())}
                name="startTemp"
                onChange={(newValue: number) => {
                  setStartTemp(newValue)

                  setStateUpdated((prev) => ({ ...prev, start: true }))
                }}
                precision={1}
                styles={{
                  input: {
                    padding: 0,
                    textAlign: 'center',
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                    border:
                      !validateTemperature(startTemp) || !isTempOrderValid
                        ? `solid 2px ${colors.red}`
                        : 'solid 2px #B4B3BB',
                  },
                }}
                hideControls
              />
              <span
                css={{
                  display: 'flex',
                  padding: '0 10px',
                  alignItems: 'center',
                  background: '#B4B3BB',
                  borderTopRightRadius: '3px',
                  borderBottomRightRadius: '3px',
                }}
              >
                {unitConverter.windMachineTemperature().suffix()}
              </span>
            </div>
            <div
              css={{
                display: 'flex',
              }}
            >
              <label
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '10px',
                }}
              >
                {translate.phrases.banyanApp('Stop')}
              </label>
              <NumberInput
                css={{ maxWidth: '100%' }}
                defaultValue={Number(unitConverter.windMachineTemperature(defaultStopTemp).value())}
                precision={1}
                onChange={(newValue: number) => {
                  setStopTemp(newValue)

                  setStateUpdated((prev) => ({ ...prev, stop: true }))
                }}
                styles={{
                  input: {
                    padding: 0,
                    textAlign: 'center',
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                    border:
                      !validateTemperature(stopTemp) || !isTempOrderValid
                        ? `solid 2px ${colors.red}`
                        : 'solid 2px #B4B3BB',
                  },
                }}
                hideControls
              />
              <span
                css={{
                  display: 'flex',
                  padding: '0 10px',
                  alignItems: 'center',
                  background: '#B4B3BB',
                  borderTopRightRadius: '3px',
                  borderBottomRightRadius: '3px',
                }}
              >
                {unitConverter.windMachineTemperature().suffix()}
              </span>
            </div>
          </div>
        </div>
        {(!isTemperatureValid || !isTempOrderValid) && (
          <p
            css={{
              margin: '0',
              color: colors.red,
              fontSize: '12px',
              marginTop: '5px',
              paddingLeft: '15px',
            }}
          >
            {!isStartTempValid || !isStopTempValid
              ? translate.phrases.banyanApp('Temperature must be between {{low}} and {{high}}.', {
                  low: unitConverter.windMachineTemperature(-40).value(),
                  high: unitConverter.windMachineTemperature(40).value(),
                })
              : translate.phrases.banyanApp('Start temperature must be less than stop temperature')}
          </p>
        )}
        <Group
          mt="xl"
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            background: 'linear-gradient(0deg, #E2E2E2, #E2E2E2),linear-gradient(0deg, #F8F8F8, #F8F8F8)',
            padding: '10px',
          }}
        >
          <Button css={{ minWidth: '83px' }} variant="negative" onClick={editModalClose}>
            {translate.phrases.banyanApp('Cancel')}
          </Button>
          <Button
            css={{ minWidth: '83px' }}
            variant="secondary"
            onClick={handleRequest}
            disabled={
              !validateTemperature(startTemp) ||
              !validateTemperature(stopTemp) ||
              !isTempOrderValid ||
              !isTemperatureValid
            }
          >
            {loading ? (
              <Loader
                css={{
                  display: 'flex',
                }}
                size={20}
              />
            ) : (
              translate.phrases.banyanApp('Confirm')
            )}
          </Button>
        </Group>
      </Modal>
    </div>
  )
}
