import { translate } from 'i18n/i18n'
import { checkAuthorization } from 'utils/checkAuthorization'
import type {
  EquipmentActivationPayload,
  PlannedNodeActivationPayload,
  TLocalInstallationState,
} from '../NodeInstallation/types'
import type { TEquipmentInstallationStep, TNodeType } from '../types'
import { InstallationStep } from '../types'
import { TRAP_NODE_TYPES } from './constants/nodeType'
import { getNodeTypeLabel } from './getNodeTypeLabel'

export const isNodeDropping = (config: Partial<EquipmentActivationPayload>) => {
  return !(config as PlannedNodeActivationPayload).pseudonodeId
}

export const getInstallationSteps = (
  payload: Partial<EquipmentActivationPayload>,
  localState: TLocalInstallationState,
): TEquipmentInstallationStep[] => {
  let steps: TEquipmentInstallationStep[] = []

  steps.push({
    type: InstallationStep.LOCATION_CONFIRMATION,
    title: translate.phrases.placeholder('Confirm Location'),
    enabled: payload.nodeIdentifier ? false : true,
    completed: localState.isLocationConfirmed,
    required: true,
  })

  steps.push({
    type: InstallationStep.NODE_ACTIVATION,
    title: isNodeDropping(payload)
      ? translate.phrases.placeholder('Activate Equipment')
      : translate.phrases.placeholder('Activate {{nodeLabel}}', {
          nodeLabel: payload.nodeType && getNodeTypeLabel(payload.nodeType),
        }),
    enabled: localState.isLocationConfirmed && !payload.nodeIdentifier,
    completed: payload.nodeIdentifier ? true : false,
    required: true,
  })

  // TODO - enable this feature once https://github.com/semiosBIO/app-platform/pull/662
  // if (payload.nodeType && isDeviceableNode(payload.nodeType)) {
  //   const nodeDevices = flatNodeDevices(payload.installationConfig.devices)
  //   const isEnabled = payload.nodeIdentifier ? true : false
  //   const isCompleted = isEnabled && localState.isDeviceConfigured === true

  //   steps.push({
  //     type: InstallationStep.DEVICES_INSTALLATION,
  //     title: translate.phrases.placeholder('Manage Devices'),
  //     enabled: isEnabled,
  //     completed: isCompleted,
  //     required: true,
  //   })
  // }

  if (
    payload.propertyId &&
    checkAuthorization({
      permission: 'EDIT_SSC_UPDATE_NODE_CONFIG',
      entity: payload.propertyId,
    }) &&
    TRAP_NODE_TYPES.includes(payload.nodeType as TNodeType)
  ) {
    const isEnabled = payload.nodeIdentifier ? true : false
    const isCompleted = isEnabled && localState.isNodeConfigured === true

    steps.push({
      type: InstallationStep.NODE_CONFIGURATION,
      title: translate.phrases.placeholder('Configure Trap'),
      enabled: isEnabled,
      completed: isCompleted,
      required: true,
    })
  }

  const allStepsCompleted = steps.every((step) => step.completed)

  // TEMPORARY REMOVE CHECKLISTS FROM INSTALLATION WORKFLOW
  // const selectedNodeInstructions =
  //   selectedEquipment && allInstallationChecklists.nodes[selectedEquipment.nodeType.toUpperCase()]
  //
  // if (selectedNodeInstructions?.checklist) {
  //   steps.push({
  //     type: InstallationStep.CHECKLIST,
  //     title: translate.phrases.placeholder('Checklist'),
  //     checklist: selectedNodeInstructions.checklist.map(({ title }) => title),
  //     enabled: allNodeAndDevicesInstalled || false,
  //     completed: false,
  //     required: false,
  //   })
  // }

  steps.push({
    type: InstallationStep.REVIEW,
    title: translate.phrases.placeholder('Review'),
    enabled: allStepsCompleted,
    completed: false,
    required: false,
  })

  return steps
}
