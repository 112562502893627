import { Text } from '@mantine/core'
import type { ModalSettings } from '@mantine/modals/lib/context'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { Button } from 'components/Button/Button'
import { IconWarning } from 'components/icons/IconWarning'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

function createSuccessModal(title: string, message: React.ReactNode, id = 'success'): ModalSettings {
  return {
    modalId: id,
    title,
    centered: true,
    withCloseButton: false,
    children: (
      <div style={{ padding: '0 1rem 1rem' }}>
        <Text size="sm">{message}</Text>
        <Button
          onClick={() => {
            AboveAllModal.close(id)
          }}
          mt="md"
          css={{ display: 'block', width: '100%' }}
        >
          {translate.phrases.placeholder('OK')}
        </Button>
      </div>
    ),
  }
}

function createErrorModal(title: string, message: string, id = 'error'): ModalSettings {
  return {
    modalId: id,
    title: (
      <>
        <div css={{ marginRight: 10, color: colors.red }}>
          <IconWarning />
        </div>{' '}
        {title}
      </>
    ),
    styles: { title: { display: 'flex', alignItems: 'flex-start' } },
    centered: true,
    withCloseButton: false,
    children: (
      <>
        <Text size="sm">{message}</Text>
        <Button onClick={() => AboveAllModal.close(id)} mt="md" css={{ display: 'block', width: '100%' }}>
          {translate.phrases.placeholder('OK')}
        </Button>
      </>
    ),
  }
}

export { createErrorModal, createSuccessModal }
