import { getSprayConditionsTitleAndColor } from 'App/Map/_utils/getSprayConditionsTitleAndColor'
import type { ESprayRecommendation } from 'App/Map/types'
import { useMemo } from 'react'
import type { makeIntervals } from '../makeIntervals'

const LegendItem = (props: { sprayCondition: ESprayRecommendation | null }) => {
  const { color, name } = getSprayConditionsTitleAndColor(props.sprayCondition)

  return (
    <div css={{ display: 'flex', gap: 6, justifyContent: 'center', alignItems: 'center' }}>
      <div css={{ width: 12, height: 12, background: color }} />
      <div>{name}</div>
    </div>
  )
}

export const Legend = (props: { intervals: ReturnType<typeof makeIntervals> }) => {
  const { includeIdeal, includeAvoid, includeDoNot, includeUnknown } = useMemo(() => {
    let includeIdeal = false
    let includeAvoid = false
    let includeDoNot = false
    let includeUnknown = false

    props.intervals.forEach((i) => {
      if (i.value === 'IDEAL') includeIdeal = true
      else if (i.value === 'AVOID') includeAvoid = true
      else if (i.value === 'DO_NOT') includeDoNot = true
      else includeUnknown = true
    })

    return { includeIdeal, includeAvoid, includeDoNot, includeUnknown }
  }, [props.intervals])

  return (
    <div
      css={{
        fontSize: 14,
        fontWeight: 'bold',
        padding: '20px 18px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 22,
      }}
    >
      {!!includeIdeal && <LegendItem sprayCondition={'IDEAL' as ESprayRecommendation} />}
      {!!includeAvoid && <LegendItem sprayCondition={'AVOID' as ESprayRecommendation} />}
      {!!includeDoNot && <LegendItem sprayCondition={'DO_NOT' as ESprayRecommendation} />}
      {!!includeUnknown && <LegendItem sprayCondition={null} />}
    </div>
  )
}
