import { Select, TextInput } from '@mantine/core'
import { Button } from 'components/Button/Button'
import { IconCopy } from 'components/icons/IconCopy'
import { InfoMessageBox } from 'components/InfoMessageBox/InfoMessageBox'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { useMemo, useState } from 'react'
import { colors } from 'settings/colors'
import { showNotification } from 'utils/showNotification'
import { useScreenSize } from 'utils/useScreenSize'
import { getValidatedNumberInputValue } from '../_utils/getValidatedNumberInputValue'
import { EmitterConfigurationInputTemplate } from '../components/EmitterConfigurationInputTemplate'
import { EmitterConfigurationNumberInput } from '../components/EmitterConfigurationNumberInput'
import { useEmitterConfigurationUnit } from '../hooks/useEmitterConfigurationUnit'

type TModalDrawerRateCalculator = {
  opened: boolean
  handleOnClose: () => void
}

type TEmitterFlowRateUnit = 'gallonsPerHour' | 'litersPerHour'

const InputWrapper = ({ children }: { children: React.ReactNode }) => {
  return <div css={{ minWidth: 240 }}>{children}</div>
}

const CONVERSION_RATE_FOR_GALLONS_AND_LITTERS = 3.785

export const ModalDrawerRateCalculator = ({ opened, handleOnClose }: TModalDrawerRateCalculator) => {
  const { isWideScreen } = useScreenSize()
  const [numberOfLines, setNumberOfLines] = useState<number | ''>('')
  const [emitterFlowRate, setEmitterFlowRate] = useState<number | ''>('')
  const [emitterSpacing, setEmitterSpacing] = useState<number | ''>('')
  const [rowSpacing, setRowSpacing] = useState<number | ''>('')
  const [emitterFlowRateUnit, setEmitterFlowRateUnit] = useState<TEmitterFlowRateUnit>('gallonsPerHour')
  const { precision, min } = useEmitterConfigurationUnit()
  const flexDirection = isWideScreen ? 'row' : 'column'
  const gap = 30
  const { unit } = useEmitterConfigurationUnit()

  const emitterSpacingUnit =
    unit === translate.measurements.inchesPerHour.unit()
      ? translate.measurements.inches.unit()
      : translate.measurements.millimeters.unit()

  const isFormError =
    numberOfLines === '' || emitterFlowRate === '' || emitterSpacing === '' || rowSpacing === ''

  const applicationRate = useMemo(() => {
    if (isFormError) {
      return 'NA'
    }

    const convertedEmitterFlowRate =
      emitterFlowRateUnit === 'gallonsPerHour'
        ? emitterFlowRate
        : emitterFlowRate * (1 / CONVERSION_RATE_FOR_GALLONS_AND_LITTERS)

    return (
      ((((43560 / rowSpacing) * 12) / emitterSpacing) * convertedEmitterFlowRate) /
      (27154.25 * numberOfLines)
    ).toFixed(precision)
  }, [numberOfLines, emitterFlowRateUnit, emitterFlowRate, emitterSpacing, rowSpacing])

  return (
    <ModalDrawer
      title={translate.phrases.banyanApp('Rate Calculator')}
      opened={opened}
      onClose={handleOnClose}
      zIndex={300}
    >
      <div css={{ padding: 30 }}>
        <InfoMessageBox>
          {translate.phrases.banyanApp('Please find the flow rate reading from your emitter')}
        </InfoMessageBox>
        <div css={{ display: 'flex', gap: 20, flexDirection: 'column', marginTop: 20 }}>
          <div css={{ display: 'flex', flexDirection, gap, flexWrap: 'wrap' }}>
            <InputWrapper>
              <EmitterConfigurationNumberInput
                label={translate.phrases.banyanApp('Number of lines')}
                value={numberOfLines}
                onChange={(value) => {
                  const validatedValue = getValidatedNumberInputValue({ value, min: 1, allowEmpty: true })

                  setNumberOfLines(validatedValue)
                }}
                precision={0}
              />
            </InputWrapper>
            <InputWrapper>
              <EmitterConfigurationNumberInput
                label={translate.phrases.banyanApp('Emitter flow rate')}
                unit={
                  <Select
                    data={[
                      { value: 'gallonsPerHour', label: translate.measurements.gallonsPerHour.unit() },
                      { value: 'litersPerHour', label: translate.measurements.litersPerHour.unit() },
                    ]}
                    value={emitterFlowRateUnit}
                    onChange={(value) => setEmitterFlowRateUnit(value as TEmitterFlowRateUnit)}
                    css={{ maxWidth: 90 }}
                  />
                }
                value={emitterFlowRate}
                onChange={(value) => {
                  const validatedValue = getValidatedNumberInputValue({ value, min, allowEmpty: true })

                  setEmitterFlowRate(validatedValue)
                }}
              />
            </InputWrapper>
          </div>
          <div css={{ display: 'flex', flexDirection, gap, flexWrap: 'wrap' }}>
            <InputWrapper>
              <EmitterConfigurationNumberInput
                label={translate.phrases.banyanApp('Emitter spacing {{label}}', { label: '(A)' })}
                unit={emitterSpacingUnit}
                value={emitterSpacing}
                onChange={(value) => {
                  const validatedValue = getValidatedNumberInputValue({ value, min, allowEmpty: true })

                  setEmitterSpacing(validatedValue)
                }}
                showSpacingPopover
              />
            </InputWrapper>
            <InputWrapper>
              <EmitterConfigurationNumberInput
                label={translate.phrases.banyanApp('Row spacing {{label}}', { label: '(B)' })}
                unit={translate.measurements.feet.unit()}
                value={rowSpacing}
                onChange={(value) => {
                  const validatedValue = getValidatedNumberInputValue({ value, min, allowEmpty: true })

                  setRowSpacing(validatedValue)
                }}
                showSpacingPopover
              />
            </InputWrapper>
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection,
              gap,
              alignItems: isWideScreen ? 'flex-end' : undefined,
              flexWrap: 'wrap',
            }}
          >
            <InputWrapper>
              <EmitterConfigurationInputTemplate
                label={translate.phrases.banyanApp('Application rate')}
                unit={unit}
              >
                <TextInput disabled={true} value={applicationRate} />
              </EmitterConfigurationInputTemplate>
            </InputWrapper>
            <Button
              variant="secondary"
              css={{
                '&[disabled],&[data-loading="true"]': {
                  svg: {
                    fill: 'white',
                  },
                },
                'maxWidth': 142,
              }}
              leftIcon={
                <div
                  css={{
                    'fontSize': 24,
                    '&:not([disabled],[data-loading="true"]):hover': {
                      color: colors.white,
                    },
                    '&[disabled],&[data-loading="true"]': {
                      color: colors.grey800,
                    },
                  }}
                >
                  <IconCopy />
                </div>
              }
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(String(applicationRate))
                } catch {
                  showNotification({
                    message: translate.phrases.banyanApp('Failed to copy application rate'),
                    type: 'error',
                  })
                }
              }}
              disabled={isFormError}
            >
              {translate.phrases.banyanApp('Copy Rate')}
            </Button>
          </div>
        </div>
      </div>
    </ModalDrawer>
  )
}
