import { css } from '@emotion/css'
import { RefillPointDatesModal } from 'components/RefillPointDatesModal/RefillPointDatesModal'
import { RootZoneDepthsModal } from 'components/RootZoneDepthsModal/RootZoneDepthsModal'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { translate } from 'i18n/i18n'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import type { TPointCategory } from 'stores/selectedFieldAssetsStore'
import type { TDropdownSelectorPointProps } from '../DropdownSelectorPoint/DropdownSelectorPoint'
import { DropdownSelectorPoint } from '../DropdownSelectorPoint/DropdownSelectorPoint'

const pointCategory: TPointCategory = 'soilPoint'

export const SoilAverageDepthsTitleChildren = (
  props: TDropdownSelectorPointProps & { showAverageToggle: boolean },
) => {
  const { showAverageToggle, ...rest } = props

  const { showSelectedSoilDepths } = detailsPanelStore.useSelector((s) => ({
    showSelectedSoilDepths: s?.showSelectedSoilDepths,
  }))

  return (
    <div css={{ display: 'flex', flexWrap: 'wrap', gap: '4px 18px', alignItems: 'center' }}>
      <DropdownSelectorPoint pointCategory={pointCategory} {...rest} />
      {showAverageToggle && (
        <div css={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <div css={{ fontWeight: 'normal' }}>{translate.phrases.banyanApp('All Depths')}</div>
          <ToggleButton
            buttonProps={{ size: 'xs', className: css({ fontSize: 13 }) }}
            data={[
              { label: translate.phrases.banyanApp('On'), value: true },
              { label: translate.phrases.banyanApp('Off'), value: false },
            ]}
            onChange={(newValue) =>
              detailsPanelStore.setState((s) => ({ ...s, showSelectedSoilDepths: newValue }))
            }
            value={showSelectedSoilDepths}
          />
        </div>
      )}
      <RootZoneDepthsModal />
      <RefillPointDatesModal />
    </div>
  )
}
