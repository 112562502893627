import { LoadingOverlay } from '@mantine/core'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import type { Moment } from 'moment-timezone'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import type { Alert, Contact, Group } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { fetchApiCustomer } from 'utils/fetchApiCustomer'
import { loadAppStartupApiCustomer } from 'utils/loadAppStartupApiCustomer'
import { showNotification } from 'utils/showNotification'
import { alertCreateQuery, AlertUpdateQuery } from '../queries/queries'
import type { AlertTypeId, Rule } from '../settings/alertSettings'
import { AlertsSettings } from '../settings/alertSettings'
import { AlertTypeAndPestType } from './AlertForm/AlertTypeAndPestType/AlertTypeAndPestType'
import { AutomatedScheduling } from './AlertForm/AutomatedScheduling/AutomatedScheduling'
import { Contacts } from './AlertForm/Contacts/Contacts'
import { Coverage } from './AlertForm/Coverage/Coverage'
import { WeatherForecastCoverage } from './AlertForm/Coverage/WeatherForecastCoverage/WeatherForecastCoverage'
import { Frequency } from './AlertForm/Frequency/Frequency'
import { Name } from './AlertForm/Name/Name'
import { Scheduling } from './AlertForm/Scheduling/Scheduling'
import { Sleep } from './AlertForm/Sleep/Sleep'
import { Thresholds } from './AlertForm/Thresholds/Thresholds'
import { DefaultSettingsButton } from './DefaultSettingsButton/DefaultSettingsButton'
import convertAPIAlertToInitivalValues from './_utils/convertAPIAlertToInitivalValues'
import type { TVariables } from './_utils/getAlertVariablesForQuery'
import { getAlertVariablesForQuery } from './_utils/getAlertVariablesForQuery'
import { getAlertVariablesForWeatherForecastQuery } from './_utils/getAlertVariablesForWeatherForecastQuery'

export type SleepUnitsType = 'minutes' | 'hours' | 'days'

export type WeatherForecastType = 'rain' | 'temperature' | 'wind' | null

type CreateAlertResponse = {
  data: {
    createAlert: Alert
  }
}

type UpdateAlertRepsonse = {
  data: {
    updateAlert: Alert
  }
}

type TWeatherForecastAlertSaveReponse = {
  id: number
  name: string
  notifyOwnerByEmail: boolean
  notifyOwnerBySms: boolean
}

export const ModalDrawerCreateOrEditAlert = ({
  opened,
  alertId,
  onClose,
  selectedAlert,
}: {
  opened: boolean
  alertId: string | null
  onClose: () => void
  selectedAlert: Alert | null
}) => {
  const initialState = convertAPIAlertToInitivalValues({ initialValues: selectedAlert })
  const [firstRenderComplete, setFirstRenderComplete] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  // STATE FOR AlertTypeId
  const [alertTypeId, setAlertTypeId] = useState<AlertTypeId | null>(initialState.alertTypeId)
  const [insectId, setInsectId] = useState(initialState.insectId)
  // STATE FOR Threshold
  const [thresholdIsValid, setThresholdIsValid] = useState(false)
  const [rules, setRules] = useState<Rule[]>(initialState.rules)
  // STATE FOR Coverage
  const [coverageIsValid, setCoverageIsValid] = useState(false)
  const [coverageProperties, setCoverageProperties] = useState<string[]>(initialState.coverageProperties)
  const [coverageStations, setCoverageStations] = useState<string[]>(initialState.coverageStations)
  const [coverageBlocks, setCoverageBlocks] = useState<string[]>(initialState.coverageBlocks)
  const [coverageType, setCoverageType] = useState(initialState.coverageType)
  // STATE FOR Frequency
  const [frequencyMinutes, setFrequencyMinutes] = useState<number | null>(initialState.frequencyMinutes)
  // STATE FOR Sleep
  const [sleepIsValid, setSleepIsValid] = useState(true)
  const [contactsIsValid, setContactsIsValid] = useState(true)
  const [sleepMinutes, setSleepMinutes] = useState<number | null>(initialState.sleepMinutes)
  const [sleep, setSleep] = useState<boolean>(!!initialState.sleepMinutes) // whether or not the alert will use the sleepMinutes in the configuration
  const [sleepUnits, setSleepUnits] = useState<SleepUnitsType>(initialState.sleepUnits) // whether or not the alert will use the sleepMinutes in the configuration
  // STATE FOR Scheduling
  const [scheduleIsValid, setScheduleIsValid] = useState(true)
  // STATE FOR Weather Forecast
  const [weatherForecastThresholdType, setWeatherForecastThresholdType] = useState<WeatherForecastType>(null)

  const [limitedSchedule, setLimitedSchedule] = useState(
    !!(selectedAlert && selectedAlert.startDate && selectedAlert.endDate),
  )

  const [endDate, setEndDate] = useState<Moment | null>(initialState.endDate)
  const [startDate, setStartDate] = useState<Moment | null>(initialState.startDate)
  // STATE FOR Contacts
  const [contacts, setContacts] = useState<Contact[]>(initialState.contacts)
  const [groups, setGroups] = useState<Group[]>(initialState.groups)
  const [notifyOwnerByEmail, setNotifyOwnerByEmail] = useState(initialState.notifyOwnerByEmail)
  const [notifyOwnerBySMS, setNotifyOwnerBySMS] = useState(initialState.notifyOwnerBySMS)
  // STATE FOR Name
  const [nameIsValid, setNameIsValid] = useState(false)
  const [name, setName] = useState<string | null>(initialState.name)
  // STATE FOR KEEPING TRACK OF WHETHER OR NOT WE SENT IT YET
  const [triedToSubmit, setTriedToSubmit] = useState(false)
  const { contacts: allContacts, groups: allContactGroups } = userDetailsStore.useSelector((s) => s)
  const rulesHaveForecast = !!rules.find((r) => r.key.toLowerCase().includes('forecast'))

  useEffect(() => {
    setFirstRenderComplete(true)
  }, [])

  useEffect(() => {
    const updatedGroups = groups
      .map((group) => {
        const groupInAllGroups = allContactGroups.find((g) => g.id === group.id)

        if (groupInAllGroups) return { ...groupInAllGroups, alertsContactMethods: group.alertsContactMethods }

        return null
      })
      .filter((g) => g !== null)

    const updatedContacts = contacts
      .map((contact) => {
        const contactInAllContacts = allContacts.find((c) => c.id === contact.id)

        if (contactInAllContacts)
          return { ...contactInAllContacts, alertsContactMethods: contact.alertsContactMethods }

        return null
      })
      .filter((c) => c !== null)

    setGroups(updatedGroups)

    setContacts(updatedContacts)
  }, [allContacts, allContactGroups])

  useEffect(() => {
    if (!alertTypeId) return

    if (
      rulesHaveForecast &&
      firstRenderComplete &&
      (alertTypeId === 'degreeDays' || alertTypeId === 'fireBlight')
    ) {
      setFrequencyMinutes(AlertsSettings.frequencyMinutesDefault[`${alertTypeId}Forecast`])
    } else setFrequencyMinutes(AlertsSettings.frequencyMinutesDefault[alertTypeId])
  }, [rulesHaveForecast])

  useEffect(() => {
    const newInitialState = convertAPIAlertToInitivalValues({ initialValues: selectedAlert })

    setAlertTypeId(newInitialState.alertTypeId)

    setInsectId(newInitialState.insectId)

    setRules(newInitialState.rules)

    setCoverageProperties(newInitialState.coverageProperties)

    setCoverageStations(newInitialState.coverageStations)

    setCoverageBlocks(newInitialState.coverageBlocks)

    setCoverageType(newInitialState.coverageType)

    setFrequencyMinutes(newInitialState.frequencyMinutes)

    setSleepMinutes(newInitialState.sleepMinutes)

    setSleep(!!newInitialState.sleepMinutes)

    setSleepUnits(newInitialState.sleepUnits)

    setEndDate(newInitialState.endDate)

    setStartDate(newInitialState.startDate)

    setContacts(newInitialState.contacts)

    setGroups(newInitialState.groups)

    setNotifyOwnerByEmail(newInitialState.notifyOwnerByEmail)

    setNotifyOwnerBySMS(newInitialState.notifyOwnerBySMS)

    setName(newInitialState.name)

    setLimitedSchedule(!!(selectedAlert && newInitialState.startDate && newInitialState.endDate))
  }, [selectedAlert])

  const onSetAlertTypeId = (value: AlertTypeId) => {
    setAlertTypeId(value)

    // handle insect
    setInsectId(null)

    // unvalidate the alert and reset its rules
    setRules([])

    setThresholdIsValid(false)

    // reset the coverage
    setCoverageProperties([])

    setCoverageStations([])

    setCoverageBlocks([])

    setCoverageType('all')

    setCoverageIsValid(true)

    setContactsIsValid(true)

    setName(null)

    // reset the frequencyMinutes
    setFrequencyMinutes(AlertsSettings.frequencyMinutesDefault[value])

    // handle sleep
    if (AlertsSettings.allowSleep[value]) {
      let newSleepUnits: SleepUnitsType = 'minutes'

      if (AlertsSettings.frequencyMinutesDefault[value] === 60) newSleepUnits = 'hours'

      if (AlertsSettings.frequencyMinutesDefault[value] === 1440) newSleepUnits = 'days'

      if (newSleepUnits !== sleepUnits) {
        setSleepMinutes(null)

        setSleepUnits(newSleepUnits)
      }
    } else {
      setSleep(false)

      setSleepMinutes(null)

      setSleepIsValid(true)
    }

    if (!AlertsSettings.allowScheduling[value]) {
      setLimitedSchedule(false)

      setScheduleIsValid(true)
    }

    setTriedToSubmit(false)
  }

  const handleOnClose = () => {
    setAlertTypeId(null)

    onClose()
  }

  const requiresInsectId = alertTypeId && AlertsSettings.alertTypesWithPestTypes[alertTypeId]
  const hasTypeAndPest = alertTypeId && (!requiresInsectId || insectId)
  const showThreshold = hasTypeAndPest
  const showCoverage = hasTypeAndPest

  const showFrequency =
    hasTypeAndPest && AlertsSettings.frequencyMinutesOptions[alertTypeId].length && !rulesHaveForecast

  const showAutomatedScheduling = hasTypeAndPest && AlertsSettings.automatedScheduling[alertTypeId]
  const showSleep = hasTypeAndPest && AlertsSettings.allowSleep[alertTypeId]
  const showScheduling = hasTypeAndPest && AlertsSettings.allowScheduling[alertTypeId]
  const showContacts = !!hasTypeAndPest
  const showName = !!hasTypeAndPest
  const showSubmission = !!hasTypeAndPest

  const configurationIsValid =
    thresholdIsValid &&
    coverageIsValid &&
    scheduleIsValid &&
    sleepIsValid &&
    contactsIsValid &&
    nameIsValid &&
    alertTypeId &&
    name

  const handleSubmit = () => {
    if (!triedToSubmit && !configurationIsValid) setTriedToSubmit(true)

    if (configurationIsValid) {
      const variables = getAlertVariablesForQuery({
        alertId: alertId ?? undefined,
        alertTypeId,
        coverageBlocks,
        coverageProperties,
        coverageStations,
        coverageType,
        endDate: limitedSchedule && startDate && endDate ? endDate : null,
        frequencyMinutes,
        groups,
        name,
        contacts,
        notifyOwnerByEmail,
        notifyOwnerBySMS,
        insectId,
        rules: rules.map(({ key, operator, unit, value }) => ({
          key,
          operator,
          unit,
          value: String(value),
        })),
        sleepMinutes: sleep && sleepMinutes ? sleepMinutes : null,
        startDate: limitedSchedule && startDate && endDate ? startDate : null,
      })

      alertId ? handleEditAlert(variables) : handleCreateAlert(variables)
    }
  }

  const handleEditAlert = async (variables: TVariables) => {
    setIsSubmitting(true)

    try {
      const response: UpdateAlertRepsonse = await fetchApiCustomer({
        body: {
          query: AlertUpdateQuery,
          variables,
        },
      })

      const updatedAlertId = response?.data?.updateAlert?.id

      if (updatedAlertId) {
        showNotification({
          type: 'success',
          message: translate.phrases.banyanApp('Alert successfully updated'),
        })

        await loadAppStartupApiCustomer({ includeAlerts: true })

        handleOnClose()
      } else {
        showNotification({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to update alert'),
        })

        onClose()
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to update alert'),
      })

      onClose()
    }

    setIsSubmitting(false)
  }

  const handleCreateAlert = async (variables: TVariables) => {
    setIsSubmitting(true)

    try {
      const response: CreateAlertResponse = await fetchApiCustomer({
        body: {
          query: alertCreateQuery,
          variables,
        },
      })

      const newAlertId = response?.data?.createAlert?.id

      if (newAlertId) {
        showNotification({
          type: 'success',
          message: translate.phrases.banyanApp('Alert successfully created'),
        })

        await loadAppStartupApiCustomer({ includeAlerts: true })

        handleOnClose()
      } else {
        showNotification({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to create alert'),
        })
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to create alert'),
      })
    }

    setIsSubmitting(false)
  }

  const handleWeatherForecastSubmit = async () => {
    if (!triedToSubmit && !configurationIsValid) setTriedToSubmit(true)

    if (!configurationIsValid) return

    const variables = getAlertVariablesForWeatherForecastQuery({
      alertId: typeof alertId === 'string' ? Number(alertId) : undefined,
      alertTypeId,
      coverageBlocks,
      coverageProperties,
      coverageStations,
      coverageType,
      endDate: limitedSchedule && startDate && endDate ? endDate : null,
      groups,
      name,
      contacts,
      notifyOwnerByEmail,
      notifyOwnerBySMS,
      rules: rules.map(({ key, operator, unit, value }) => ({
        key,
        operator,
        unit,
        value: String(value),
      })),
      startDate: limitedSchedule && startDate && endDate ? startDate : null,
    })

    const successText = !alertId
      ? translate.phrases.banyanApp('Alert successfully created')
      : translate.phrases.banyanApp('Alert successfully updated')

    const errorText = !alertId
      ? translate.phrases.banyanApp('Failed to create alert')
      : translate.phrases.banyanApp('Failed to update alert')

    setIsSubmitting(true)

    try {
      const response: TWeatherForecastAlertSaveReponse = await fetchApiCustomer({
        body: variables,
        restApiUrl: 'weather-forecast-alert-save',
      })

      const newAlertId = response.id

      if (newAlertId) {
        showNotification({
          type: 'success',
          message: successText,
        })

        await loadAppStartupApiCustomer({ includeAlerts: true })

        handleOnClose()
      } else {
        showNotification({
          type: 'error',
          message: errorText,
        })
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: errorText,
      })
    }

    setIsSubmitting(false)
  }

  const submitButtonText = alertId
    ? translate.phrases.banyanApp('Update Alert')
    : translate.phrases.banyanApp('Create Alert')

  const titleText = alertId
    ? translate.phrases.banyanApp('Edit Alert "{{name}}"', { name: initialState.name })
    : translate.phrases.banyanApp('Create New Alert')

  return (
    <ModalDrawer
      title={titleText}
      opened={opened}
      onClose={handleOnClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 100}
      primaryButtonText={showSubmission ? submitButtonText : undefined}
      primaryButtonOnPress={
        alertTypeId === 'weatherForecastAlert' ? handleWeatherForecastSubmit : handleSubmit
      }
      primaryButtonDisabled={isSubmitting}
    >
      <LoadingOverlay visible={isSubmitting} />

      <div css={{ padding: 20 }}>
        {/* TYPE */}
        <AlertTypeAndPestType
          alertTypeId={alertTypeId}
          onSetAlertTypeId={onSetAlertTypeId}
          insectId={insectId}
          setInsectId={setInsectId}
          alertId={alertId}
          defaultSettingsButton={
            <DefaultSettingsButton
              alertTypeId={alertTypeId}
              insectId={insectId}
              hasTypeAndPest={!!hasTypeAndPest}
              sleep={sleep}
              sleepMinutes={sleepMinutes}
              startDate={startDate}
              endDate={endDate}
              frequencyMinutes={frequencyMinutes}
              limitedSchedule={limitedSchedule}
              rules={rules}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setFrequencyMinutes={setFrequencyMinutes}
              setRules={setRules}
              setSleep={setSleep}
              setSleepMinutes={setSleepMinutes}
              setLimitedSchedule={setLimitedSchedule}
            />
          }
        />

        {/* THRESHOLD */}
        {!!showThreshold && (
          <Thresholds
            alertTypeId={alertTypeId}
            rules={rules}
            setWeatherForecastThresholdType={setWeatherForecastThresholdType}
            setRules={setRules}
            setThresholdIsValid={setThresholdIsValid}
            triedToSubmit={triedToSubmit}
            insectId={insectId}
          />
        )}

        {/* COVERAGE */}
        {!!showCoverage &&
          (alertTypeId !== 'weatherForecastAlert' ? (
            <Coverage
              alertTypeId={alertTypeId}
              coverageBlocks={coverageBlocks}
              coverageIsValid={coverageIsValid}
              coverageProperties={coverageProperties}
              coverageStations={coverageStations}
              coverageType={coverageType}
              insectId={insectId}
              rules={rules}
              setCoverageBlocks={setCoverageBlocks}
              setCoverageIsValid={setCoverageIsValid}
              setCoverageProperties={setCoverageProperties}
              setCoverageStations={setCoverageStations}
              setCoverageType={setCoverageType}
              setRules={setRules}
              triedToSubmit={triedToSubmit}
            />
          ) : (
            <WeatherForecastCoverage
              alertTypeId={alertTypeId}
              coverageBlocks={coverageBlocks}
              coverageIsValid={coverageIsValid}
              coverageProperties={coverageProperties}
              coverageStations={coverageStations}
              coverageType={coverageType}
              insectId={insectId}
              rules={rules}
              setCoverageBlocks={setCoverageBlocks}
              setCoverageIsValid={setCoverageIsValid}
              setCoverageProperties={setCoverageProperties}
              setCoverageStations={setCoverageStations}
              setCoverageType={setCoverageType}
              setRules={setRules}
              triedToSubmit={triedToSubmit}
              weatherForecastThresholdType={weatherForecastThresholdType}
            />
          ))}
        {/* FREQUENCY */}
        {!!showFrequency && (
          <Frequency
            alertTypeId={alertTypeId}
            frequencyMinutes={frequencyMinutes}
            setFrequencyMinutes={setFrequencyMinutes}
          />
        )}
        {/* AUTOMATED SCHEDULING (degreeDays only) */}
        {!!showAutomatedScheduling && <AutomatedScheduling rulesHaveForecast={rulesHaveForecast} />}
        {/* SLEEP */}
        {!!showSleep && (
          <Sleep
            alertTypeId={alertTypeId}
            setSleep={setSleep}
            setSleepIsValid={setSleepIsValid}
            setSleepMinutes={setSleepMinutes}
            sleep={sleep}
            sleepMinutes={sleepMinutes}
            sleepUnits={sleepUnits}
            setSleepUnits={setSleepUnits}
            triedToSubmit={triedToSubmit}
          />
        )}

        {/* SCHEDULING */}
        {!!showScheduling && (
          <Scheduling
            alertTypeId={alertTypeId}
            endDate={endDate}
            limitedSchedule={limitedSchedule}
            setEndDate={setEndDate}
            setLimitedSchedule={setLimitedSchedule}
            setScheduleIsValid={setScheduleIsValid}
            setStartDate={setStartDate}
            startDate={startDate}
            triedToSubmit={triedToSubmit}
          />
        )}
        {/* CONTACTS */}
        {!!showContacts && (
          <Contacts
            allContacts={allContacts}
            allContactGroups={allContactGroups}
            contacts={contacts}
            contactsIsValid={contactsIsValid}
            groups={groups}
            notifyOwnerByEmail={notifyOwnerByEmail}
            notifyOwnerBySMS={notifyOwnerBySMS}
            setContacts={setContacts}
            setContactsIsValid={setContactsIsValid}
            setGroups={setGroups}
            setNotifyOwnerByEmail={setNotifyOwnerByEmail}
            setNotifyOwnerBySMS={setNotifyOwnerBySMS}
          />
        )}
        {/* NAME */}
        {!!showName && (
          <Name
            alertTypeId={alertTypeId}
            name={name}
            setName={setName}
            setNameIsValid={setNameIsValid}
            triedToSubmit={triedToSubmit}
          />
        )}
      </div>
    </ModalDrawer>
  )
}
