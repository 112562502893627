import { useDisclosure } from '@mantine/hooks'
import { sortByKey } from '@semios/app-platform-common'
import { ContactsAndGroups } from 'App/Map/UserSettingsMenu/Shared/ContactsAndGroups/ContactsAndGroups'
import { Button } from 'components/Button/Button'
import { IconContact } from 'components/icons/IconContact'
import { IconGroups } from 'components/icons/IconGroups'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import type { Contact, Group, ReportContactMethod } from 'stores/userDetailsStore'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { ErrorTextWrapper } from '../../components/ErrorTextWrapper'
import { ContactList } from './ContactList'

const CONTACT_KEY = 'CONTACT'
const GROUP_KEY = 'GROUP'

const contactOptionsGenerator = (contacts: Contact[]) => {
  return contacts
    .map((c) => ({
      value: c.id,
      label: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div css={{ fontSize: 18, marginRight: 5, display: 'flex', alignItems: 'center' }}>
            <IconContact />
          </div>
          {c.name}
        </div>
      ),
      id: `${c.id}-${CONTACT_KEY}`,
    }))
    .sort(sortByKey('label'))
}

const groupOptionsGenerator = (groups: Group[]) => {
  return groups
    .map((g) => ({
      value: g.id,
      label: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div css={{ fontSize: 18, marginRight: 5, display: 'flex', alignItems: 'center' }}>
            <IconGroups />
          </div>
          {g.name}
        </div>
      ),
      id: `${g.id}-${GROUP_KEY}`,
    }))
    .sort(sortByKey('label'))
}

export const ReportContacts = ({
  allContacts,
  allContactGroups,
  contacts,
  contactsIsValid,
  groups,
  notifyOwnerByEmail,
  setContacts,
  setContactsIsValid,
  setGroups,
  setNotifyOwnerByEmail,
}: {
  allContacts: Contact[]
  allContactGroups: Group[]
  contacts: Contact[]
  contactsIsValid: boolean
  groups: Group[]
  notifyOwnerByEmail: boolean
  setContacts: Dispatch<SetStateAction<Contact[]>>
  setContactsIsValid: Dispatch<SetStateAction<boolean>>
  setGroups: Dispatch<SetStateAction<Group[]>>
  setNotifyOwnerByEmail: Dispatch<SetStateAction<boolean>>
}) => {
  const [opened, { open, close }] = useDisclosure(false)

  const validateStatusError =
    contacts.some((c) => !(c.reportsContactMethods || []).length) &&
    translate.phrases.banyanApp('Contacts must have at least one report delivery method selected')

  useEffect(() => {
    setContactsIsValid(!validateStatusError)
  }, [validateStatusError])

  const multiSelectOptions = () => {
    const emailEnabledContacts = allContacts.filter((contact) =>
      contact.reportsContactMethods ? contact.reportsContactMethods.includes('email') : false,
    )

    emailEnabledContacts.sort((a, b) => a.name.localeCompare(b.name))

    allContactGroups.sort((a, b) => a.name.localeCompare(b.name))

    const contactOptions = contactOptionsGenerator(emailEnabledContacts)
    const groupOptions = groupOptionsGenerator(allContactGroups)

    return [...contactOptions, ...groupOptions]
  }

  const handleOnchange = (selectedValues: TOptions[]) => {
    const sContacts = selectedValues
      .filter((option) => option.id?.includes(CONTACT_KEY))
      .map((option) => option.value)

    const newContactsToUse = sContacts.flatMap((id) => {
      const contactInExistingContacts = contacts.find((c) => c.id === id)

      if (!!contactInExistingContacts) return contactInExistingContacts

      const contactInGlobalContacts = allContacts.find((c) => c.id === id)

      if (!!contactInGlobalContacts) return contactInGlobalContacts

      return []
    })

    setContacts(newContactsToUse)

    const sGroups = selectedValues
      .filter((option) => option.id?.includes(GROUP_KEY))
      .map((option) => option.value)

    const newGroupsToUse = sGroups.flatMap((id) => {
      const groupInExistingGroups = groups.find((g) => g.id === id)

      if (!!groupInExistingGroups) return groupInExistingGroups

      const groupInGlobalGroups = allContactGroups.find((g) => g.id === id)

      if (!!groupInGlobalGroups) {
        const defaultReportsContactMethods = ['email'] as ReportContactMethod[]

        return { ...groupInGlobalGroups, reportsContactMethods: defaultReportsContactMethods }
      }

      return []
    })

    setGroups(newGroupsToUse)
  }

  const sortContacts = (contacts: Contact[]) =>
    contacts.sort((a, b) => {
      const valueA = typeof a?.name === 'string' ? a?.name : String(a?.name)
      const valueB = typeof b?.name === 'string' ? b?.name : String(b?.name)

      return valueA.localeCompare(valueB)
    })

  const sortGroups = (groups: Group[]) =>
    groups.sort((a, b) => {
      const valueA = typeof a?.name === 'string' ? a?.name : String(a?.name)
      const valueB = typeof b?.name === 'string' ? b?.name : String(b?.name)

      return valueA.localeCompare(valueB)
    })

  const sortedContacts = sortContacts(contacts)
  const sortedGroups = sortGroups(groups)
  const selectedContacts = contactOptionsGenerator(sortedContacts)
  const selectedGroups = groupOptionsGenerator(sortedGroups)
  const selectedData = [...selectedContacts, ...selectedGroups]

  return (
    <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
      <h4>{translate.phrases.banyanApp('Contacts')}</h4>
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '5px 0 20px' }}>
        <Button variant="link" onClick={open}>
          {translate.phrases.banyanApp('Manage Contacts And Groups')}
        </Button>
      </div>
      <MultiSelect
        data={multiSelectOptions()}
        selectedData={selectedData}
        placeholder=""
        onChange={handleOnchange}
      />
      <ContactList
        contacts={contacts}
        groups={groups}
        setContacts={setContacts}
        setGroups={setGroups}
        notifyOwnerByEmail={notifyOwnerByEmail}
        setNotifyOwnerByEmail={setNotifyOwnerByEmail}
        contactsIsValid={contactsIsValid}
      />
      <ErrorTextWrapper>{helpTextErrorHelper(validateStatusError)}</ErrorTextWrapper>
      <ModalDrawer
        opened={opened}
        onClose={close}
        zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 250}
        size="45%"
        title={translate.phrases.banyanApp('Contacts')}
      >
        <ContactsAndGroups />
      </ModalDrawer>
    </div>
  )
}
