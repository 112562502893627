import { LoadingOverlay } from '@mantine/core'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { Button } from 'components/Button/Button'
import { ErrorText } from 'components/ErrorText/ErrorText'
import { IconCalculatorWhite } from 'components/icons/IconCalculatorWhite'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { getErrorStyle } from '../../Alerts/_utils/getErrorStyle'
import { handleUpdateEmitterConfiguration } from '../_utils/handleUpdateEmitterConfiguration'
import { EmitterConfigurationNumberInput } from '../components/EmitterConfigurationNumberInput'
import { useEmitterConfigurationUnit } from '../hooks/useEmitterConfigurationUnit'
import { useZonesFilter } from '../hooks/useZonesFilter'
import type { TEmitterConfiguration } from '../types'
import { ZoneSelectionSection } from './ZoneSelectionSection'

type TModalDrawerBulkEdit = {
  opened: boolean
  handleOnClose: () => void
  onRateCalculatorButtonClick: () => void
  emitterConfigurations: TEmitterConfiguration
}

export type TSelectedZones = {
  emitterType: string
  id: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  zoneId: number
  propertyId: number
}

export const ModalDrawerBulkEdit = ({
  opened,
  handleOnClose,
  onRateCalculatorButtonClick,
  emitterConfigurations,
}: TModalDrawerBulkEdit) => {
  const { filterComponent, filteredAndSortedList } = useZonesFilter({
    initialProperties: Object.values(emitterConfigurations),
  })

  const [selectedZones, setSelectedZones] = useState<TSelectedZones[]>([])
  const [value, setValue] = useState<'' | number>('')
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const { unit, dbUnitPerHour } = useEmitterConfigurationUnit()

  const handleSelectAll = () => {
    const allZones: TSelectedZones[] = filteredAndSortedList.reduce((acc: TSelectedZones[], item) => {
      const { irrigationZones, propertyId } = item

      const zones = irrigationZones.flatMap((zone) => {
        const { zoneId, flowRate } = zone

        return Object.values(flowRate).map((rate) => ({
          emitterType: rate.emitterType,
          id: rate.id as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
          zoneId,
          propertyId,
        }))
      })

      return [...acc, ...zones]
    }, [])

    setSelectedZones(allZones)
  }

  const optimisticUpdate = () => {
    fieldAssetStore.setState((prev) => {
      let newProperties = { ...prev.properties }

      selectedZones.forEach((zone) => {
        const { propertyId, id } = zone

        if (newProperties?.[propertyId]?.irrigationZoneEmitters?.[id]?.flowRate) {
          newProperties = {
            ...newProperties,
            [propertyId]: {
              ...newProperties[propertyId],
              irrigationZoneEmitters: {
                ...newProperties[propertyId].irrigationZoneEmitters,
                [id]: {
                  ...(newProperties?.[propertyId]?.irrigationZoneEmitters?.[id] || {}),
                  flowRate: {
                    ...(newProperties?.[propertyId]?.irrigationZoneEmitters?.[id]?.flowRate || {}),
                    rate: Number(value),
                    unitsPerHour: dbUnitPerHour,
                  },
                },
              },
            },
          }
        }
      })

      return {
        ...prev,
        properties: newProperties,
      }
    })

    setSelectedZones([])

    setValue('')

    handleOnClose()
  }

  const handleApply = async () => {
    if (value !== '') {
      setLoading(true)

      const params = selectedZones.map((zone) => ({
        ...zone,
        flowRate: value,
        unitPerHour: dbUnitPerHour,
      }))

      await handleUpdateEmitterConfiguration(params, optimisticUpdate)

      setLoading(false)
    }
  }

  const validateValue = (value: number | '') => {
    setIsValid(typeof value === 'number' && value > 0)
  }

  const errorStyle = getErrorStyle()

  return (
    <ModalDrawer
      title={translate.phrases.banyanApp('Bulk Edit')}
      opened={opened}
      onClose={handleOnClose}
      zIndex={300}
      primaryButtonText={translate.phrases.banyanApp('Apply')}
      primaryButtonOnPress={handleApply}
      primaryButtonDisabled={selectedZones.length === 0 || value === ''}
    >
      {loading && <LoadingOverlay visible />}
      <div css={{ padding: 30 }}>
        <div css={{ display: 'flex', justifyContent: 'space-between', gap: 20, flexWrap: 'wrap' }}>
          <EmitterConfigurationNumberInput
            label={translate.phrases.banyanApp('Application rate')}
            unit={unit}
            value={value}
            onChange={(value) => {
              setValue(value)

              validateValue(value)
            }}
            classNames={{ input: !isValid ? errorStyle : undefined }}
          />
          <Button
            variant="primary"
            leftIcon={
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <IconCalculatorWhite />
              </div>
            }
            onClick={onRateCalculatorButtonClick}
          >
            {translate.phrases.banyanApp('Rate Calculator')}
          </Button>
        </div>
        {!isValid && (
          <div css={{ marginTop: 5 }}>
            <ErrorText>{translate.phrases.banyanApp('Value must be greater than 0')}</ErrorText>
          </div>
        )}
        <div css={{ marginTop: 30 }}>
          <div css={{ fontWeight: 500, fontSize: 16, marginBottom: 5 }}>
            {translate.phrases.banyanApp('Apply to the following zones')}
          </div>
          {filterComponent}
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '15px 0',
            alignItems: 'center',
          }}
        >
          <div>
            {translate.phrases.banyanApp('{{count}} zone(s) selected', {
              count: selectedZones.length,
            })}
          </div>
          <div css={{ display: 'flex' }}>
            <Button
              variant="link"
              onClick={handleSelectAll}
              disabled={selectedZones.length >= filteredAndSortedList.length}
            >
              {translate.phrases.banyanApp('Select All')}
            </Button>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <div
                css={{
                  borderLeft: '1px solid black',
                  height: 22,
                }}
              />
            </div>
            <Button variant="link" onClick={() => setSelectedZones([])} disabled={selectedZones.length === 0}>
              {translate.phrases.banyanApp('Clear All')}
            </Button>
          </div>
        </div>
        {filteredAndSortedList.map((item) => {
          const { propertyId, irrigationZones, propertyName } = item

          return (
            <ZoneSelectionSection
              key={propertyId}
              propertyId={propertyId}
              propertyName={propertyName}
              irrigationZones={irrigationZones}
              selectedZones={selectedZones}
              setSelectedZones={setSelectedZones}
            />
          )
        })}
      </div>
    </ModalDrawer>
  )
}
