import { Select, Stack, TextInput } from '@mantine/core'
import { ConfirmationSettingsModal } from 'components/ConfirmationSettingsModal/ConfirmationSettingsModal'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { getLanguageLabels, globalLanguageStore, translate } from 'i18n/i18n'
import { isEmpty, isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'
import type { TAdminUser, TOrgAdmin } from '../../utils/useAdminEntites'
import { CancelSaveButtonGroup } from '../CancelSaveButtonGroup/CancelSaveButtonGroup'

const locationLevel1GroupedByCountry = {
  AU: [
    { label: 'Australian Capital Territory (ACT)', value: 'ACT' },
    { label: 'New South Wales (NSW)', value: 'NSW' },
    { label: 'Northern Territory (NT)', value: 'NT' },
    { label: 'Queensland (QLD)', value: 'QLD' },
    { label: 'South Australia (SA)', value: 'SA' },
    { label: 'Tasmania (TAS)', value: 'TAS' },
    { label: 'Victoria (VIC)', value: 'VIC' },
    { label: 'Western Australia (WA)', value: 'WA' },
  ],
  CA: [
    { label: 'BC', value: 'BC' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Other', value: 'Other' },
  ],
  US: [
    { label: 'California', value: 'CA' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Washington', value: 'WA' },
    { label: 'Other', value: 'Other' },
  ],
}

// TODO: share validation schema from route defs
const FIRST_NAME_LENGTH_MIN = 1
const FIRST_NAME_LENGTH_MAX = 150
const LAST_NAME_LENGTH_MIN = 1
const LAST_NAME_LENGTH_MAX = 150
const countryToLocationLevel1Map = new Map(Object.entries(locationLevel1GroupedByCountry))

const countryiesByCode = {
  AU: 'Australia',
  CA: 'Canada',
  DE: 'Germany',
  IT: 'Italy',
  ES: 'Spain',
  US: 'United States',
}

const accountTypes = {
  CUSTOMER: 'Customer',
  EMPLOYEE: 'Employee - Semios Employee',
  TESTER: 'Tester - External software QA tester',
  DEMO: 'Demo - For sales demos',
  SERVICES: 'Services - Semios employee-administered accounts that operate on behalf of another user',
}

type TUser = Omit<TAdminUser, 'status'>

const DEFAULT_UNITS = 'IMPERIAL'
const DEFAULT_ACCOUNT_TYPE = 'CUSTOMER'

export const ViewEditUserProfile = ({
  user,
  opened,
  onClose,
  orgAdmin,
}: {
  user: TUser | null
  opened: boolean
  onClose: (hasChanges?: boolean) => void
  orgAdmin?: TOrgAdmin
}) => {
  const [userProfile, setUserProfile] = useState<TUser | null>(user)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setHasErrors] = useState<Partial<Record<keyof TUser, string | null>>>({})
  const [showConfirmCancelWithoutSaving, setShowConfirmCancelWithoutSaving] = useState(false)
  const [fieldTouched, setFieldTouched] = useState<Partial<Record<keyof TUser, boolean>>>({})
  const isOrgAdmin = orgAdmin // if not org admin then user is a super admin
  const DEFAULT_LANGUAGE = globalLanguageStore.getState()

  const handleResetAndClose = (hasChanges?: boolean) => {
    setUserProfile(user)

    setHasChanges(false)

    setHasErrors({})

    setFieldTouched({})

    onClose(hasChanges)
  }

  useEffect(() => {
    if (user && opened) {
      setUserProfile({ ...user })
    } else {
      setUserProfile(null)

      setHasErrors({})

      setHasChanges(false)
    }
  }, [opened, user])

  useEffect(() => {
    setHasChanges(!isEqual(userProfile, user))

    setHasErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      const { email, firstName, lastName, country, phoneNumber, smsNumber } = userProfile || {}
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (!email?.trim()?.length) {
        newErrors.email = translate.phrases.validation('{{label}} is required', {
          label: translate.phrases.banyanApp('Email'),
        })
      } else if (email && !emailRegex.test(email)) {
        newErrors.email = translate.phrases.validation('{{label}} is invalid', {
          label: translate.phrases.banyanApp('Email'),
        })
      } else {
        newErrors.email = null
      }

      if (firstName?.trim()?.length) {
        if (firstName.trim().length < FIRST_NAME_LENGTH_MIN) {
          newErrors.firstName = translate.phrases.validation('{{label}} must be >= {{value}}', {
            label: translate.phrases.banyanApp('First Name'),
            value: translate.phrases.validation('{{count}} character(s)', { count: FIRST_NAME_LENGTH_MIN }),
          })
        } else if (firstName.trim().length > FIRST_NAME_LENGTH_MAX) {
          newErrors.firstName = translate.phrases.validation('{{label}} must be <= {{value}}', {
            label: translate.phrases.banyanApp('First Name'),
            value: translate.phrases.validation('{{count}} character(s)', { count: FIRST_NAME_LENGTH_MAX }),
          })
        } else {
          newErrors.firstName = null
        }
      } else {
        newErrors.firstName = translate.phrases.validation('{{label}} is required', {
          label: translate.phrases.banyanApp('First Name'),
        })
      }

      if (lastName?.trim()?.length) {
        if (lastName.trim().length < FIRST_NAME_LENGTH_MIN) {
          newErrors.lastName = translate.phrases.validation('{{label}} must be >= {{value}}', {
            label: translate.phrases.banyanApp('Last Name'),
            value: translate.phrases.validation('{{count}} character(s)', { count: LAST_NAME_LENGTH_MIN }),
          })
        } else if (lastName.trim().length > FIRST_NAME_LENGTH_MAX) {
          newErrors.lastName = translate.phrases.validation('{{label}} must be <= {{value}}', {
            label: translate.phrases.banyanApp('Last Name'),
            value: translate.phrases.validation('{{count}} character(s)', { count: LAST_NAME_LENGTH_MAX }),
          })
        } else {
          newErrors.lastName = null
        }
      } else {
        newErrors.lastName = translate.phrases.validation('{{label}} is required', {
          label: translate.phrases.banyanApp('Last Name'),
        })
      }

      newErrors.country = country?.trim()?.length
        ? null
        : translate.phrases.validation('{{label}} is required', {
            label: translate.phrases.banyanApp('Country'),
          })

      const phoneRegex = /^\+\d{11,}$/

      // validate phone numbers
      if (phoneNumber && !phoneRegex.test(phoneNumber)) {
        newErrors.phoneNumber = translate.phrases.banyanApp(
          'Phone number must be in international format (e.g. +18885551234)',
        )
      } else {
        newErrors.phoneNumber = null
      }

      if (smsNumber && !phoneRegex.test(smsNumber)) {
        newErrors.smsNumber = translate.phrases.banyanApp(
          'Phone number must be in international format (e.g. +18885551234)',
        )
      } else {
        newErrors.smsNumber = null
      }

      return newErrors
    })
  }, [userProfile, opened])

  const preventAutoCompleteProps = { autoComplete: 'off', name: 'searchHack' }

  const handleUserClickClose = () => {
    if (hasChanges) {
      setShowConfirmCancelWithoutSaving(true)
    } else {
      handleResetAndClose()
    }
  }

  const handleUpdate = (key: keyof TUser, value: TUser[keyof TUser]) => {
    setUserProfile((prev) => {
      if (prev) {
        return { ...prev, [key]: value }
      }

      return { [key]: value } as TUser
    })
  }

  const handleBlur = (key: keyof TUser) => {
    setFieldTouched((prev) => ({ ...prev, [key]: true }))
  }

  const handleSave = async () => {
    const hasErrors = Object.values(errors).some(Boolean)

    if (hasErrors || !userProfile || DEFAULT_LANGUAGE === '') {
      setFieldTouched({
        email: true,
        firstName: true,
        lastName: true,
        country: true,
        phoneNumber: true,
        smsNumber: true,
      })

      return
    }

    if (!userProfile.locationLevel1) {
      userProfile.locationLevel1 = null
    }

    if (!userProfile.accountType) {
      userProfile.accountType = DEFAULT_ACCOUNT_TYPE
    }

    if (!userProfile.units) {
      userProfile.units = DEFAULT_UNITS
    }

    if (!userProfile.language) {
      userProfile.language = DEFAULT_LANGUAGE
    }

    if (isEmpty(userProfile.phoneNumber?.trim())) {
      userProfile.phoneNumber = null
    }

    if (isEmpty(userProfile.smsNumber?.trim())) {
      userProfile.smsNumber = null
    }

    if (userProfile.id) {
      const result = await apiFetch({ url: '/admin-update-user-profile', body: userProfile })

      if (result.data) {
        showNotification({
          type: 'success',
          message: translate.phrases.banyanApp('User profile saved successfully'),
        })

        handleResetAndClose(true)
      } else {
        showNotification({
          type: 'error',
          message: translate.phrases.validation('{{label}} could not be updated', {
            label: translate.phrases.banyanApp('User'),
          }),
        })
      }
    } else {
      const result = await apiFetch({
        url: '/admin-invite-user',
        body: userProfile,
      })

      if (result.data) {
        showNotification({
          type: 'success',
          message: translate.phrases.banyanApp('User successfully created'),
        })

        if (orgAdmin) {
          userDetailsStore.setState((prev) => {
            if (!result.data) return prev

            return {
              ...prev,
              permissions: {
                ...prev.permissions,
                ADMIN_EDIT_USER: {
                  ...(prev.permissions.ADMIN_EDIT_USER || {}),
                  [result.data]: true,
                },
                ADMIN_VIEW_USER: {
                  ...(prev.permissions.ADMIN_VIEW_USER || {}),
                  [result.data]: true,
                },
              },
            }
          })
        }

        handleResetAndClose(true)
      } else {
        const error = result.errors[0]

        if (error) {
          showNotification({
            type: 'error',
            message: translate.phrases.banyanApp(error.messageTranslationString),
          })
        } else {
          showNotification({
            type: 'error',
            message: translate.phrases.validation('{{label}} could not be created', {
              label: translate.phrases.banyanApp('User'),
            }),
          })
        }
      }
    }
  }

  const languageLabels = getLanguageLabels()

  const modalTitle = userProfile
    ? translate.phrases.banyanApp('View/Edit User')
    : translate.phrases.banyanApp('Create New User')

  return (
    <ModalDrawer
      title={modalTitle}
      opened={opened}
      onClose={handleUserClickClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 10}
    >
      {
        <form css={{ padding: 10 }}>
          <Stack spacing="lg">
            <TextInput
              {...preventAutoCompleteProps}
              label={translate.phrases.banyanApp('Email')}
              required
              type="email"
              defaultValue={userProfile?.email ?? ''}
              onChange={(e) => handleUpdate('email', e.target.value)}
              error={fieldTouched.email && errors.email}
              onBlur={() => handleBlur('email')}
            />

            <TextInput
              {...preventAutoCompleteProps}
              autoComplete="off"
              label={translate.phrases.banyanApp('First Name')}
              required
              defaultValue={userProfile?.firstName ?? ''}
              onChange={(e) => handleUpdate('firstName', e.target.value)}
              error={fieldTouched.firstName && errors.firstName}
              onBlur={() => handleBlur('firstName')}
            />

            <TextInput
              {...preventAutoCompleteProps}
              label={translate.phrases.banyanApp('Last Name')}
              required
              defaultValue={userProfile?.lastName ?? ''}
              onChange={(e) => handleUpdate('lastName', e.target.value)}
              error={fieldTouched.lastName && errors.lastName}
              onBlur={() => handleBlur('lastName')}
            />

            <TextInput
              {...preventAutoCompleteProps}
              label={
                <translate.Phrases.validation
                  k="{{label}} <i>(optional)</i>"
                  c={{ i: <i /> }}
                  v={{ label: translate.phrases.banyanApp('Phone Number') }}
                />
              }
              defaultValue={userProfile?.phoneNumber ?? ''}
              onChange={(e) => handleUpdate('phoneNumber', e.target.value)}
              error={fieldTouched.phoneNumber && errors.phoneNumber}
              onBlur={() => handleBlur('phoneNumber')}
            />

            <TextInput
              {...preventAutoCompleteProps}
              label={
                <translate.Phrases.validation
                  k="{{label}} <i>(optional)</i>"
                  c={{ i: <i /> }}
                  v={{ label: translate.phrases.banyanApp('SMS Number') }}
                />
              }
              defaultValue={userProfile?.smsNumber ?? ''}
              onChange={(e) => handleUpdate('smsNumber', e.target.value)}
              error={fieldTouched.smsNumber && errors.smsNumber}
              onBlur={() => handleBlur('smsNumber')}
            />

            {!isOrgAdmin && (
              <Select
                label={translate.phrases.banyanApp('Account Type')}
                required
                defaultValue={userProfile?.accountType ?? DEFAULT_ACCOUNT_TYPE}
                onChange={(value: TUser['accountType']) => handleUpdate('accountType', value)}
                data={Object.entries(accountTypes).map(([accountType, name]) => ({
                  value: accountType,
                  label: name,
                }))}
              ></Select>
            )}

            <Select
              label={translate.phrases.banyanApp('Country')}
              required
              defaultValue={userProfile?.country ?? ''}
              onChange={(value: TUser['country']) => handleUpdate('country', value)}
              data={Object.entries(countryiesByCode).map(([code, name]) => ({ value: code, label: name }))}
              error={fieldTouched.country && errors.country}
              onBlur={() => handleBlur('country')}
            ></Select>

            {userProfile?.country && countryToLocationLevel1Map.has(userProfile.country) && (
              <Select
                label={
                  <translate.Phrases.validation
                    k="{{label}} <i>(optional)</i>"
                    c={{ i: <i /> }}
                    v={{ label: translate.phrases.banyanApp('State/Province') }}
                  />
                }
                defaultValue={userProfile.locationLevel1 ?? null}
                onChange={(value: TUser['locationLevel1']) => handleUpdate('locationLevel1', value)}
                data={countryToLocationLevel1Map.get(userProfile.country) || []}
              ></Select>
            )}

            <Select
              required
              label={translate.phrases.banyanApp('Language')}
              defaultValue={userProfile?.language ?? globalLanguageStore.getState()}
              onChange={(value: TUser['language']) => handleUpdate('language', value)}
              // TODO: might need to add all the languages here not just the front-end ones
              data={Object.entries(languageLabels).map(([value, label]) => ({ value, label }))}
              error={fieldTouched.language && errors.language}
            />
            <Select
              required
              label="Units"
              defaultValue={userProfile?.units ?? DEFAULT_UNITS}
              onChange={(value: TUser['units']) => handleUpdate('units', value)}
              data={[
                { value: 'IMPERIAL', label: translate.phrases.banyanApp('Imperial') },
                { value: 'METRIC', label: translate.phrases.banyanApp('Metric') },
              ]}
            />
          </Stack>
          <CancelSaveButtonGroup
            onCancel={handleUserClickClose}
            onSave={handleSave}
            saveDisabled={Object.values(errors).some(Boolean)}
          />
        </form>
      }
      <ConfirmationSettingsModal
        confirmModalOpened={showConfirmCancelWithoutSaving}
        setConfirmModalOpened={setShowConfirmCancelWithoutSaving}
        handleResetAndClose={handleResetAndClose}
        handleUpdate={handleSave}
      />
    </ModalDrawer>
  )
}
