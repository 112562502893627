import { openConfirmModal } from '@mantine/modals'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { THandleRefillPointDeleteClickProps } from 'components/RefillPointDatesModal/types'
import { translate } from 'i18n/i18n'
import { apiFetch } from 'utils/apiFetch'
import { updateStoresCurrentMADValue } from 'utils/refillPointDatesUtils/updateStoresCurrentMADValue'
import { showNotification } from 'utils/showNotification'
import { ONGOING_KEY } from './ongoingKey'

export const handleDeleteClick = ({
  id,
  propertyId,
  refillPointDates,
  lngLat,
  setRefreshChartAndSummary,
  closeEditModal,
  setAllStationsRPDsData,
  setIsDeleteModalShown,
}: THandleRefillPointDeleteClickProps) => {
  if (id === null) {
    showNotification({
      message: translate.phrases.banyanApp('Failed to delete {{label}}', {
        label: translate.phrases.banyanApp('Refill Point Date'),
      }),
      type: 'error',
    })

    return
  }

  setIsDeleteModalShown(true)

  openConfirmModal({
    styles: {
      root: {
        padding: 10,
      },
    },
    title: (
      <strong>
        {translate.phrases.banyanApp('Are you sure you want to delete this {{label}}?', {
          label: translate.phrases.banyanApp('Refill Point Date'),
        })}
      </strong>
    ),
    labels: {
      confirm: translate.phrases.banyanApp('Confirm'),
      cancel: translate.phrases.banyanApp('Cancel'),
    },
    withCloseButton: false,
    onCancel: () => {
      setIsDeleteModalShown(false)
    },
    onConfirm: async () => {
      const response = await apiFetch({
        url: routes.FieldAssetSettingsSet.path,
        body: {
          soilMADDelete: [id],
        },
      })

      if (!Array.isArray(response?.soilMADDelete) || !response?.soilMADDelete?.[0]?.id) {
        showNotification({
          message: translate.phrases.banyanApp('Failed to delete {{label}}', {
            label: translate.phrases.banyanApp('Refill Point Date'),
          }),
          type: 'error',
        })
      } else {
        const deletedId = response.soilMADDelete[0].id

        const newRefillPointDates = refillPointDates
          .filter((rpd) => rpd.id !== deletedId)
          .map((refillPointDate, index, sortedDates) => ({
            ...refillPointDate,
            endDate: index === 0 ? ONGOING_KEY : sortedDates[index - 1].startDate,
          }))

        setAllStationsRPDsData((prev) => {
          return prev.map((station) => {
            if (station.lngLat === lngLat) {
              return {
                ...station,
                refillPointDates: newRefillPointDates,
              }
            }

            return station
          })
        })

        updateStoresCurrentMADValue(newRefillPointDates, propertyId, lngLat)

        showNotification({
          message: translate.phrases.banyanApp('Successfully deleted {{label}}', {
            label: translate.phrases.banyanApp('Refill Point Date'),
          }),
          type: 'success',
        })

        if (!!closeEditModal) {
          closeEditModal()
        }

        setRefreshChartAndSummary(true)
      }

      setIsDeleteModalShown(false)
    },
  })
}
