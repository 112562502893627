import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export type NodeServiceIssueStatusUpdateMeta = routes.ServiceCenterNodeServiceIssueStatusUpdate.Request

export const request = async (meta: NodeServiceIssueStatusUpdateMeta) => {
  return await apiFetch({ url: '/service-center-node-service-issue-status-update', body: meta })
}

export const serviceCenterNodeServiceIssueStatusUpdate = {
  request,
}
