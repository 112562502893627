import { WindMachineButtons } from 'App/Map/CurrentValuesMap/caches/WindMachineValuesCache/WindMachineBadgeComponent/WindMachineButtons'
import { WindMachineDetails } from 'App/Map/CurrentValuesMap/caches/WindMachineValuesCache/WindMachineBadgeComponent/WindMachineDetails'
import { useWindMachineDetails } from 'App/Map/CurrentValuesMap/caches/WindMachineValuesCache/WindMachineBadgeComponent/_utils/useWindMachineDetails'
import type { WIND_MACHINE_META } from 'App/Map/CurrentValuesMap/caches/WindMachineValuesCache/WindMachineValuesCache'
import { MapCard } from 'components/MapCard/MapCard'

export const WindMachine = (props: WIND_MACHINE_META & { onClose: () => void }) => {
  const { detailsWindMachineLoading, detailsWindMachineData } = useWindMachineDetails({
    lngLat: props.lngLat,
  })

  // TODO: this could have a better loading state - flickers a bit now

  return (
    <MapCard
      latLng={props.latLng.toJSON()}
      onClose={props.onClose}
      title={props.name}
      footer={null}
      targetCSS={{ width: 44, height: 39, transform: 'translate(-50%, 0%)' }} // TODO: This assumes same width, height, and transform as TrapMapIcon in TrapBadgeComponent
      key={props.lngLat}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          padding: 10,
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        <WindMachineDetails
          propertyId={props.propertyId}
          windMachineDetailsDataObject={detailsWindMachineData}
          background="transparent"
          loading={detailsWindMachineLoading}
        />
        <WindMachineButtons
          lngLat={props.lngLat}
          propertyId={props.propertyId}
          windMachineDetailsDataObject={detailsWindMachineData}
        />
      </div>
    </MapCard>
  )
}
