import { useMantineTheme } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'

// Transform RSSI (-XX dBm) to a signal level (1-5)
export const getSignalLevelByRSSI = (rssi: number): number => {
  if (rssi > 0) throw new Error('RSSI must be negative')

  if (rssi > -60) {
    // Great signal
    return 5
  } else if (rssi > -70) {
    // Good signal
    return 4
  } else if (rssi > -80) {
    // Okay signal
    return 3
  } else if (rssi > -90) {
    // Poor signal
    return 2
  } else if (rssi === -255) {
    // No signal/unknown
    return 0
  } else {
    // Bad signal
    return 1
  }
}

// Transform RSRP & RSRQ (-XX dBm) to a signal level (1-5)
export const getSignalLevelByRSRPAndRSRQ = (rsrp: number, rsrq: number): number => {
  if (rsrp > 0 || rsrq > 0) throw new Error('RSRP and RSRQ must be negative')

  if (rsrp > -80 && rsrq > -10) {
    // Great signal
    return 5
  } else if (rsrp > -90 && rsrq > -15) {
    // Good signal
    return 4
  } else if (rsrp > -100 && rsrq > -20) {
    // Okay signal
    return 3
  } else if (rsrp > -110 && rsrq > -25) {
    // Poor signal
    return 2
  } else if (rsrp === -255 || rsrq === -255) {
    // No signal/unknown
    return 0
  } else {
    // Bad signal
    return 1
  }
}

export const getSignalStrengthColors: (lv: number) => string = (lv: number) => {
  const theme = useMantineTheme()

  const SIGNAL_STRENGTH_COLORS: { [lv: number]: string } = {
    5: theme.colors.green[0],
    4: theme.colors.green[0],
    3: theme.colors.orange[6],
    2: theme.colors.orange[6],
    1: theme.colors.red[0],
  }

  return SIGNAL_STRENGTH_COLORS[lv]
}

export const getSignalLabel = ({
  signalRSSI,
  signalRSRP,
  signalRSRQ,
}: {
  signalRSSI?: number
  signalRSRP?: number
  signalRSRQ?: number
}): string => {
  switch (true) {
    case !!signalRSSI:
      return translate.phrases.placeholder('RSSI: {{signalRSSI}} dBm', {
        signalRSSI: signalRSSI?.toString(),
      })

    case !!signalRSRP && !!signalRSRQ:
      return translate.phrases.placeholder('RSRP: {{signalRSRP}} dBm and RSRQ: {{signalRSRQ}} dBm', {
        signalRSRP: signalRSRP?.toString(),
        signalRSRQ: signalRSRQ?.toString(),
      })

    default:
      // Unknown signals
      Sentry.captureException(
        new Error(
          `Unknown Signal RSSI: "${signalRSSI}", RSRP: "${signalRSRP}" dBm and RSRQ: "${signalRSRQ}"`,
        ),
      )

      return translate.phrases.placeholder(
        'Signal RSSI: {{signalRSSI}}, RSRP: {{signalRSRP}} and RSRQ: {{signalRSRQ}}',
        {
          signalRSSI: signalRSSI ? signalRSSI.toString() : 'unknown',
          signalRSRP: signalRSRP ? signalRSRP.toString() : 'unknown',
          signalRSRQ: signalRSRQ ? signalRSRQ.toString() : 'unknown',
        },
      )
  }
}
