import type { BLOCK_VALUE_META } from 'App/Map/CurrentValuesMap/caches/BlockValuesCache/BlockValuesCache'
import type { OVERLAY_VALUE_META } from 'App/Map/CurrentValuesMap/caches/ImageOverlaysCache/ImageOverlaysCache'
import type { STATION_META } from 'App/Map/CurrentValuesMap/caches/StationValuesCache/StationValuesCache'
import type { TRAP_META } from 'App/Map/CurrentValuesMap/caches/TrapValuesCache/TrapValuesCache'
import type { WIND_MACHINE_META } from 'App/Map/CurrentValuesMap/caches/WindMachineValuesCache/WindMachineValuesCache'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'

type TMapCardStoreState = {
  metaStation?: STATION_META
  metaTrap?: TRAP_META
  metaBlock?: BLOCK_VALUE_META
  metaNdvi?: OVERLAY_VALUE_META
  metaWindMachine?: WIND_MACHINE_META
}

const keys: Record<keyof TMapCardStoreState, boolean> = {
  metaStation: false,
  metaTrap: false,
  metaBlock: false,
  metaNdvi: false,
  metaWindMachine: false,
}

export const mapSelectedCardStore = {
  ...persistentInit<TMapCardStoreState>({
    initialState: {},
    keysToPutInURL: keys,
    keysToPutInPersistentStorage: keys,
    storeName: 'mapSelectedCardStore',
  }),
  actions: {
    hide: () => {
      mapSelectedCardStore.setState(() => ({}))
    },
    showWindMachine: (metaWindMachine: WIND_MACHINE_META) => {
      mapSelectedCardStore.setState(() => ({ metaWindMachine }))
    },
    showStation: (metaStation: STATION_META) => {
      mapSelectedCardStore.setState(() => ({ metaStation }))
    },
    showTrap: (metaTrap: TRAP_META) => {
      mapSelectedCardStore.setState(() => ({ metaTrap }))
    },
    showBlock: (metaBlock: BLOCK_VALUE_META) => {
      mapSelectedCardStore.setState(() => ({ metaBlock }))
    },
    showNdvi: (metaNdvi: OVERLAY_VALUE_META) => {
      mapSelectedCardStore.setState(() => ({ metaNdvi }))
    },
  },
}
