import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import { generateDefaultHeatmapHighLowColors } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { generateUsualStyleGetCacheUpdatesFromResponse } from './_utils/generateUsualStyleGetCacheUpdatesFromResponse'
import { getValueType } from './_utils/getValueType'

const heatmapExtremesForAllPropertiesInVisibleRegions = true
const blockValueKey = 'value'
const valueGroup = 'almond_bloom'

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
    },
  })
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch({ url: '/values-current', body: args })

  return generateUsualStyleGetCacheUpdatesFromResponse({
    cacheKeys,
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    response,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
      unitConverterFunction: (val?: number | null) => unitConverter.almondBloom(val),
      valueKey: blockValueKey,
    },
    heatmapColoring: generateDefaultHeatmapHighLowColors().reverse(),
  })
}
