import { Input } from '@mantine/core'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { validateStatusErrorHelper } from '../../../../_utils/validateStatusErrorHelper'
import { getErrorStyle } from '../../../_utils/getErrorStyle'
import { AlertSection } from '../../../components/AlertSection'
import { ErrorTextWrapper } from '../../../components/ErrorTextWrapper'
import { mapAlertIdTypeToLabel } from '../../../mapAlertIdTypeToLabel'
import type { AlertTypeId } from '../../../settings/alertSettings'
import { helpTextErrorHelper } from '../_utils/helpTextErrorHelper'

const getPlaceHolderText = (alertTypeId: AlertTypeId) => {
  switch (alertTypeId) {
    case 'almondHullSplit':
      return translate.phrases.banyanApp("e.g. 15 Days 'til Nonpareil - John/Jane")

    case 'alternaria':
      return translate.phrases.banyanApp('e.g. High risk, requires treatment - John/Jane')

    case 'degreeDays':
      return translate.phrases.banyanApp('e.g. CM DD Forecast #% Egg Hatch - John/Jane')

    case 'fireBlight':
      return translate.phrases.banyanApp('e.g. TRV Forecast - Medium risk - John/Jane')

    case 'frost':
      return translate.phrases.banyanApp('e.g. Positive Inversion - Medium Risk - Jane/John')

    case 'leafWetness':
      return translate.phrases.banyanApp('e.g. Long hours wet - John/Jane')

    case 'temperature':
      return translate.phrases.banyanApp('e.g. High temperature in above height - John/Jane')

    case 'trapCatches':
      return translate.phrases.banyanApp('e.g. Trap catches CM >=3 in the last 7 days - John/Jane')

    case 'wetBulb':
      return translate.phrases.banyanApp('e.g. High risk for immediate irrigation - John/Jane')

    default:
      return translate.phrases.banyanApp('e.g. My {{alertType}} Alert', {
        alertType: mapAlertIdTypeToLabel(alertTypeId),
      })
  }
}

export const Name = ({
  alertTypeId,
  name,
  setName,
  triedToSubmit,
  setNameIsValid,
}: {
  alertTypeId: AlertTypeId
  name: string | null
  setName: Dispatch<SetStateAction<string | null>>
  setNameIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  const [nameTouched, setNameTouched] = useState(false)
  const placeholder = getPlaceHolderText(alertTypeId)

  let helpText = ''
  let validateStatusError = false

  const nameIsDirty = nameTouched || name !== null || triedToSubmit

  if (!name || !name.trim() || name.trim().length < 4 || name.length > 45) {
    helpText = translate.phrases.banyanApp('The alert name must be between 4 and 45 characters long')

    validateStatusError = true
  }

  const help = helpTextErrorHelper(nameIsDirty && helpText)
  const validateStatus = validateStatusErrorHelper(nameIsDirty && validateStatusError)
  const errorStyle = getErrorStyle()

  useEffect(() => {
    setNameIsValid(!validateStatusError)
  }, [name])

  return (
    <AlertSection title={translate.phrases.banyanApp('Name')} isLastSection={true}>
      <Input
        type="text"
        value={name || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value)
        }}
        onBlur={() => {
          setNameTouched(true)
        }}
        placeholder={placeholder}
        style={{ width: '100%' }}
        classNames={{ input: validateStatus ? errorStyle : undefined }}
      />
      {help && <ErrorTextWrapper>{help}</ErrorTextWrapper>}
    </AlertSection>
  )
}
