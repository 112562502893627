import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const serviceCenterNodeServiceIssuesGet = async (
  params: Partial<routes.ServiceCenterNodeServiceIssuesGet.Request>,
) => {
  const serviceIssues = await apiFetch({
    url: '/service-center-node-service-issues-get',
    body: params,
  })

  return serviceIssues
}
