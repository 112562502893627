import { Group, Stack, TextInput } from '@mantine/core'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { Button } from 'components/Button/Button'
import { ConfirmationSettingsModal } from 'components/ConfirmationSettingsModal/ConfirmationSettingsModal'
import { HierarchicalSelection } from 'components/HierarchicalSelection/HierarchicalSelection'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'
import { sortByKey } from 'utils/sortByKey'
import type { TAdminPermission, TAdminRegion } from '../../../utils/useAdminEntites'
import { PermissionRow } from '../../PermissionRow/PermissionRow'
import { RegionGeometry } from './RegionGeometry'

export const ViewEditRegionModal = ({
  region: regionProp,
  permissions: permissionsArg,
  opened,
  onClose,
}: {
  region: TAdminRegion | null
  permissions: TAdminPermission[]
  opened: boolean
  onClose: (hasChanges?: boolean) => void
}) => {
  const [regionName, setRegionName] = useState<string | null>(regionProp?.name ?? null)
  const [geometryString, setGeometryString] = useState<string | null>(regionProp?.geometry ?? null)
  const [regionPermissions, setRegionPermissions] = useState<string[]>(regionProp?.permissions ?? [])
  const [hasChanges, setHasChanges] = useState(false)
  const [searchString, setSearchString] = useState<string | undefined>()
  const [showOnlyChecked, setShowOnlyChecked] = useState(false)
  const [permissions, setPermissions] = useState(permissionsArg)

  const [permissionsToCreate, setPermissionsToCreate] = useState(
    new Set<routes.AdminUpdatePropertyPermissions.Request['permissionsToCreate'][number]>(),
  )

  const [permissionsToDelete, setPermissionsToDelete] = useState(
    new Set<routes.AdminUpdatePropertyPermissions.Request['permissionsToDelete'][number]>(),
  )

  const [errors, setHasErrors] = useState<
    Partial<{ name: string | null; geom: string | null; permissions: string | null }>
  >({})

  const [showConfirmCancelWithoutSaving, setShowConfirmCancelWithoutSaving] = useState(false)

  const handleResetAndClose = (hasChanges?: boolean) => {
    setRegionName(regionProp?.name ?? null)

    setGeometryString(regionProp?.geometry ?? null)

    setRegionPermissions(regionProp?.permissions ?? [])

    setPermissionsToCreate(new Set())

    setPermissionsToDelete(new Set())

    setHasChanges(false)

    setHasErrors({})

    onClose(hasChanges)
  }

  useEffect(() => {
    if (regionProp && opened) {
      setRegionName(regionProp.name)

      setGeometryString(regionProp.geometry)

      setRegionPermissions(regionProp.permissions)
    } else {
      setRegionName(null)

      setGeometryString(null)

      setRegionPermissions([])
    }
  }, [regionProp, opened])

  useEffect(() => {
    let filteredPermissions = arrayOfObjectsSearch(permissionsArg, searchString ?? '', ['id'])

    if (showOnlyChecked) {
      filteredPermissions = filteredPermissions.filter((permission) =>
        regionPermissions.includes(permission.id),
      )
    }

    setPermissions(filteredPermissions)
  }, [searchString, showOnlyChecked, permissionsArg])

  useEffect(() => {
    setHasChanges(
      regionProp?.name !== regionName ||
        regionProp?.geometry !== geometryString ||
        permissionsToCreate.size > 0 ||
        permissionsToDelete.size > 0,
    )

    setHasErrors((prevErrors) => {
      // these errors aren't being shown in UI yet, will translate when needed
      const newErrors = { ...prevErrors }

      newErrors.name = regionName ? null : 'A region name is required'

      newErrors.geom = geometryString ? null : 'A region geometry is required'

      return newErrors
    })
  }, [regionProp, regionName, geometryString, permissionsToCreate, permissionsToDelete])

  const preventAutoCompleteProps = { autoComplete: 'off', name: 'searchHack' }

  const handleClickClose = () => {
    if (hasChanges) {
      setShowConfirmCancelWithoutSaving(true)
    } else {
      handleResetAndClose()
    }
  }

  const handleSave = async () => {
    const hasErrors = Object.values(errors).some(Boolean)

    if (hasErrors) {
      return
    }

    const params: routes.AdminUpsertRegion.Request = {
      id: regionProp?.id,
      name: regionName ?? '',
      permissions: {
        toCreate: Array.from(permissionsToCreate),
        toDelete: Array.from(permissionsToDelete),
      },
    }

    if (regionProp?.geometry !== geometryString || !regionProp?.geometry) {
      params.geometry = geometryString
    }

    const response = await apiFetch({
      url: '/admin-upsert-region',
      body: params,
    })

    if (response?.data) {
      showNotification({
        type: 'success',
        message: translate.phrases.banyanApp('Region successfully updated'),
      })

      handleResetAndClose(true)
    } else {
      let errorMessage = translate.phrases.validation('{{label}} could not be updated', {
        label: translate.phrases.banyanApp('Region'),
      })

      if (response?.errors.some((error) => error.errorCode === 'INVALID_GEOMETRY')) {
        errorMessage = translate.phrases.validation('{{label}} is invalid', {
          label: translate.phrases.banyanApp('Geometry string'),
        })
      }

      showNotification({
        type: 'error',
        message: errorMessage,
      })
    }
  }

  const modalTitle = regionProp?.id
    ? translate.phrases.banyanApp('View/Edit Region')
    : translate.phrases.banyanApp('Create New Region')

  return (
    <ModalDrawer
      title={modalTitle}
      opened={opened}
      onClose={handleClickClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 10}
    >
      {
        <div css={{ marginBottom: 15 }}>
          <form css={{ padding: 10 }}>
            <Stack spacing="lg">
              <TextInput
                {...preventAutoCompleteProps}
                label={translate.phrases.banyanApp('Name')}
                required
                value={regionName ?? ''}
                onChange={(e) => setRegionName(e.currentTarget.value)}
              />
            </Stack>

            <div>
              <RegionGeometry
                regionGeometry={geometryString}
                centroidString={regionProp?.centroid}
                setRegionGeometry={setGeometryString}
                setPreventSave={(preventSave: boolean) => {
                  setHasErrors((prevErrors) => {
                    const newErrors = { ...prevErrors }

                    newErrors.geom = preventSave ? 'Invalid geometry' : null

                    return newErrors
                  })
                }}
              />
            </div>
            <Group css={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="secondary" onClick={handleClickClose}>
                {'Cancel'}
              </Button>
              <Button variant="primary" disabled={Object.values(errors).some(Boolean)} onClick={handleSave}>
                {'Submit'}
              </Button>
            </Group>
          </form>
          <HierarchicalSelection
            searchString={searchString ?? ''}
            setSearchString={setSearchString}
            setShowOnlyChecked={setShowOnlyChecked}
            showOnlyChecked={showOnlyChecked}
            title={translate.phrases.banyanApp('Permissions')}
            width="500px"
            height="300px"
          >
            {permissions.sort(sortByKey('id')).map((permission) => {
              const isSelected = regionPermissions.includes(permission.id)

              return (
                <PermissionRow
                  key={permission.id}
                  permission={permission}
                  selected={Boolean(isSelected)}
                  hasEditPermission={true}
                  setSelected={(selected) => {
                    if (selected) {
                      setPermissionsToCreate((prev) => new Set(prev).add(permission.id))

                      setPermissionsToDelete((prev) => {
                        const newSet = new Set(prev)

                        newSet.delete(permission.id)

                        return newSet
                      })
                    } else {
                      setPermissionsToCreate((prev) => {
                        const newSet = new Set(prev)

                        newSet.delete(permission.id)

                        return newSet
                      })

                      setPermissionsToDelete((prev) => new Set(prev).add(permission.id))
                    }

                    setRegionPermissions((prev) => {
                      if (selected) {
                        return [...prev, permission.id]
                      } else {
                        return prev.filter((p) => p !== permission.id)
                      }
                    })
                  }}
                />
              )
            })}
          </HierarchicalSelection>
        </div>
      }
      <ConfirmationSettingsModal
        confirmModalOpened={showConfirmCancelWithoutSaving}
        setConfirmModalOpened={setShowConfirmCancelWithoutSaving}
        handleResetAndClose={handleResetAndClose}
        handleUpdate={handleSave}
      />
    </ModalDrawer>
  )
}
