import { getWindMachineActivityTitleAndColor } from 'App/Map/_utils/getWindMachineActivityTitleAndColor'
import type { EWindmachineActivity } from 'App/Map/types'
import { useMemo } from 'react'
import type { makeIntervals } from '../makeIntervals'

const LegendItem = (props: { windMachineActivity: EWindmachineActivity | null }) => {
  const { color, name } = getWindMachineActivityTitleAndColor(props.windMachineActivity)

  return (
    <div css={{ display: 'flex', gap: 6, justifyContent: 'center', alignItems: 'center' }}>
      <div css={{ width: 12, height: 12, background: color }} />
      <div>{name}</div>
    </div>
  )
}

export const Legend = (props: { intervals: ReturnType<typeof makeIntervals> }) => {
  const {
    includeOff,
    includeFullRun,
    includeNoConnection,
    includeUnknown,
    includeWarmUp,
    includeCoolDown,
    includeError,
  } = useMemo(() => {
    let includeOff = true
    let includeFullRun = true
    let includeNoConnection = false
    let includeUnknown = true
    let includeWarmUp = true
    let includeCoolDown = true
    let includeError = true

    props.intervals.forEach((i) => {
      if (i.value === 'OFF') includeOff = true
      else if (i.value === 'ON') includeFullRun = true
      else if (i.value === 'NO CONNECTION') includeNoConnection = true
      else if (i.value === 'WARM UP') includeWarmUp = true
      else if (i.value === 'COOL DOWN') includeCoolDown = true
      else if (i.value === 'ERROR') includeError = true
      else includeUnknown = true
    })

    return {
      includeOff,
      includeFullRun,
      includeNoConnection,
      includeUnknown,
      includeWarmUp,
      includeCoolDown,
      includeError,
    }
  }, [props.intervals])

  return (
    <div
      css={{
        fontSize: 14,
        fontWeight: 'bold',
        padding: '20px 18px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 22,
      }}
    >
      {includeOff && <LegendItem windMachineActivity={'OFF' as EWindmachineActivity} />}
      {includeFullRun && <LegendItem windMachineActivity={'ON' as EWindmachineActivity} />}
      {includeWarmUp && <LegendItem windMachineActivity={'WARM UP' as EWindmachineActivity} />}
      {includeCoolDown && <LegendItem windMachineActivity={'COOL DOWN' as EWindmachineActivity} />}
      {includeError && <LegendItem windMachineActivity={'ERROR' as EWindmachineActivity} />}
      {includeNoConnection && <LegendItem windMachineActivity={'NO CONNECTION' as EWindmachineActivity} />}
      {includeUnknown && <LegendItem windMachineActivity={null} />}
    </div>
  )
}
