import { useContext, useEffect } from 'react'
import { apiFetch } from 'utils/apiFetch'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { AlmaContext } from '../AlmaContext/AlmaContext'
import { AlmaMessage } from '../AlmaMessage/AlmaMessage'
import { almaRequestBodyData } from './utils/almaRequestBodyData'
import { pickProperty } from './utils/pickProperty'

export const AlmaSession = () => {
  const { sessionId, setSessionId, properties, insects, defaultTimeRange, hasOnlyOneProperty } =
    useContext(AlmaContext)

  const property = pickProperty(hasOnlyOneProperty, properties)

  // hack - show property on map as a workaround of details panel not showing when user interacts with Alma chat straight after login
  useEffect(() => {
    if (property) setSelectedFieldAsset({ property: property.propertyId })
  }, [])

  useEffect(() => {
    apiFetch({
      url: '/alma-chat-llm',
      body: {
        user_info: {
          available_views: almaRequestBodyData.availableViews(),
          available_properties: almaRequestBodyData.availableProperties(properties),
          available_pests: almaRequestBodyData.availablePests(insects),
          default_start_date: defaultTimeRange.dateFrom.split('T')[0],
          default_end_date: defaultTimeRange.dateTo.split('T')[0],
          current_date: defaultTimeRange.dateCurrent.split('T')[0],
          default_property: property?.propertyId,
        },
      },
    })
      .then((json) => {
        setSessionId?.(json.session_id)
      })
      .catch((e) => console.error('[Alma Chat] Fail to start session', e)) // eslint-disable-line no-console
  }, [])

  if (!sessionId) return <AlmaMessage showAvatar showEllipsis />

  return (
    <>
      <AlmaMessage showAvatar textContent="Welcome to Alma!" />
      <AlmaMessage textContent="I’m here to help you to quickly get the information you are looking for." />
    </>
  )
}
