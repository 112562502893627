import { Text } from '@mantine/core'
import type { ButtonProps } from 'components/Button/Button'
import { Button } from 'components/Button/Button'
import type { FC } from 'react'
import { colors } from 'settings/colors'
import { BackIcon } from '../BackIcon/BackIcon'
import { CloseIcon } from '../CloseIcon/CloseIcon'

type NarrowHeaderProps = {
  onClose: () => void
  title: string
  secondaryButtonDisabled?: boolean
  secondaryButtonText?: string
  secondaryButtonOnPress?: () => void
  secondaryButtonVariant?: ButtonProps['variant']
  isSecondaryModal?: boolean
}

export const NarrowHeader: FC<NarrowHeaderProps> = ({
  onClose,
  title,
  secondaryButtonDisabled,
  secondaryButtonOnPress,
  secondaryButtonText,
  secondaryButtonVariant = 'tertiary',
  isSecondaryModal,
}) => {
  return (
    <div
      css={{
        boxSizing: 'content-box',
        paddingTop: 'max(18px, calc(8px + env(safe-area-inset-top)))',
        paddingBottom: 18,
        backgroundColor: colors.grey50,
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div css={{ paddingLeft: 20 }} onClick={onClose}>
          {isSecondaryModal ? <BackIcon /> : <CloseIcon />}
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 35,
            flex: 1,
          }}
        >
          <Text color={colors.midnight} weight={700} size={18} css={{ lineHeight: '24px' }}>
            {title}
          </Text>
        </div>
      </div>

      {secondaryButtonText && (
        <div css={{ display: 'flex', justifyContent: 'center', marginTop: 18 }}>
          <Button
            disabled={secondaryButtonDisabled}
            variant={secondaryButtonVariant}
            css={{ width: '95%' }}
            onClick={secondaryButtonOnPress}
          >
            {secondaryButtonText}
          </Button>
        </div>
      )}
    </div>
  )
}
