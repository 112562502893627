import type { HighchartsOptionsWithSemiosAdditions } from 'components/StackedChart/types'
import type { StockChart } from 'highcharts'
import type { MouseEventHandler, TouchEventHandler } from 'react'
import { useEffect, useRef } from 'react'
import { Highcharts } from './_utils/highcharts-init'

export { Highcharts }

export const HighchartsChart = ({
  chartOptions,
  id,
  className,
  onMouseMove,
  onMouseLeave,
  onTouchMove,
}: {
  id?: string
  className?: string
  chartOptions: HighchartsOptionsWithSemiosAdditions
  onMouseMove?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  onTouchMove?: TouchEventHandler
}) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const chartInstanceRef = useRef<StockChart | null>(null)

  useEffect(() => {
    if (!chartRef.current) return

    if (!chartInstanceRef.current) {
      Highcharts.setOptions({
        lang: {
          thousandsSep: ',',
        },
      })

      chartInstanceRef.current = Highcharts.stockChart(chartRef.current, chartOptions)
    } else if (chartOptions) {
      chartInstanceRef.current.update(chartOptions, true, true)
    }
  }, [chartOptions, chartRef.current, chartInstanceRef.current])

  useEffect(() => {
    // tear down/clean up
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()

        chartInstanceRef.current = null
      }
    }
  }, [])

  return (
    <div
      className={className}
      css={{ width: '100%' }}
      id={id}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onTouchMove={onTouchMove}
      ref={chartRef}
    />
  )
}
