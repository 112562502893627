import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import type { TActiveNode } from 'App/ServiceCenter/types'
import { apiFetch } from 'utils/apiFetch'

export type NodeRemoveMeta = routes.ServiceCenterNodeRemove.Request

export const request = async (param: NodeRemoveMeta) => {
  await apiFetch({ url: '/service-center-node-remove', body: param })

  return param
}

export const callback = async (param: NodeRemoveMeta) => {
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  const updatedNodes = activeNodes.filter(
    (node) => (node as TActiveNode).nodeIdentifier !== param.nodeIdentifier,
  )

  serviceCenterStore.actions.setActiveNodes(updatedNodes)
}

export const serviceCenterNodeRemove = {
  request,
  callback,
}
