import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TRefillPointDates } from '../../components/RefillPointDatesModal/types'
import { ONGOING_KEY } from './ongoingKey'

export const updateStoresCurrentMADValue = (
  refillPointDates: TRefillPointDates,
  propertyId: TFieldAssetKeyTypes.TPropertyId,
  lngLat: TFieldAssetKeyTypes.TLngLat,
) => {
  const timezone = getTimezoneForSelectedPropertyOrRegion({ propertyId })
  const currentTime = moment.tz(timezone).toDate()

  let currentRefillPointDateAWC = 70

  if (refillPointDates.length !== 0) {
    const currentRefillPointDate = refillPointDates.find((refillPointDate) => {
      const startDate = new Date(refillPointDate.startDate)

      const endDate =
        refillPointDate.endDate === ONGOING_KEY ? currentTime : new Date(refillPointDate.endDate)

      return startDate <= currentTime && currentTime <= endDate
    })

    currentRefillPointDateAWC = currentRefillPointDate?.soilMoisture ?? 70
  }

  if (!!propertyId) {
    fieldAssetStore.setState((prev) => {
      let updatedPoints = {
        ...prev.properties?.[Number(propertyId)]?.points,
      }

      updatedPoints[lngLat] = {
        ...updatedPoints[lngLat],
        configuration: {
          ...updatedPoints[lngLat]?.configuration,
          soilCurrentManagementAllowableDepletionPercent: currentRefillPointDateAWC,
        },
      }

      return {
        ...prev,
        properties: {
          ...prev.properties,
          [String(propertyId)]: {
            ...prev.properties?.[Number(propertyId)],
            points: updatedPoints,
          },
        },
      }
    })
  }
}
