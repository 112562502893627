import React, { useState } from 'react'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import type { HelpGuideGroup } from '../../types'
import { HelpGuideGroupCard } from '../Card/Card'

interface HelpGuideListProps {
  expandedCard?: string

  onSelectGuide(group: HelpGuideGroup, guideAlias: string): void
}

export const HelpGuideList: React.FC<HelpGuideListProps> = ({
  expandedCard: initialExpandedCard = null,
  onSelectGuide,
}) => {
  const [expandedCard, setExpandedCard] = useState<string | null>(initialExpandedCard)
  const helpGuides = serviceCenterStore.useSelector(serviceCenterStore.selectors.getHelpGuides)

  const toggleCard = (guideKey: string) => {
    if (expandedCard === guideKey) {
      setExpandedCard(null)
    } else {
      setExpandedCard(guideKey)
    }
  }

  return (
    <div css={{ padding: 10 }}>
      {Object.keys(helpGuides).map((group) => (
        <HelpGuideGroupCard
          key={group}
          group={group as HelpGuideGroup}
          guides={helpGuides[group as HelpGuideGroup]}
          expanded={expandedCard === group}
          onToggleExpand={() => toggleCard(group)}
          onSelectGuide={(equipment: string) => onSelectGuide(group as HelpGuideGroup, equipment)}
        />
      ))}
    </div>
  )
}
