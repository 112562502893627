import { LoadingOverlay } from '@mantine/core'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { useScreenSize } from 'utils/useScreenSize'
import { getEmitterConfiguration } from '../_utils/getEmitterConfiguration'
import { handleUpdateEmitterConfiguration } from './_utils/handleUpdateEmitterConfiguration'
import { EmitterConfigurationHeader } from './EmitterConfigurationHeader/EmitterConfigurationHeader'
import { EmitterConfigurationList } from './EmitterConfigurationList/EmitterConfigurationList'
import { useEmitterConfigurationUnit } from './hooks/useEmitterConfigurationUnit'
import { ModalDrawerBulkEdit } from './ModalDrawerBulkEdit/ModalDrawerBulkEdit'
import { ModalDrawerRateCalculator } from './ModalDrawerRateCalculator/ModalDrawerRateCalculator'

export type ModifiedZone = {
  emitterType: string
  id: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  zoneId: number
  propertyId: number
  flowRate: number
}

export const EmitterConfiguration = () => {
  const [rateCalculatorOpened, setRateCalculatorOpened] = useState(false)
  const [bulkEditOpened, setBulkEditOpened] = useState(false)
  const [modifiedZones, setModifiedZones] = useState<ModifiedZone[]>([])
  const [loadingSave, setLoadingSave] = useState(false)
  const { isWideScreen } = useScreenSize()

  const { properties } = fieldAssetStore.useSelector((s) => ({
    properties: s?.properties ?? [],
  }))

  const { permissions } = userDetailsStore.useSelector((s) => ({
    permissions: s.permissions,
  }))

  const emitterConfigurations = getEmitterConfiguration(properties, permissions)
  const buttonDisabled = modifiedZones.length === 0
  const isError = modifiedZones.some((zone) => zone.flowRate <= 0)
  const { dbUnitPerHour } = useEmitterConfigurationUnit()

  if (loadingSave) {
    return <LoadingOverlay visible />
  }

  const optimisticUpdate = () => {
    fieldAssetStore.setState((prev) => {
      let newProperties = { ...prev.properties }

      modifiedZones.forEach((zone) => {
        const { propertyId, flowRate, id } = zone

        if (newProperties?.[propertyId]?.irrigationZoneEmitters?.[id]?.flowRate) {
          newProperties = {
            ...newProperties,
            [propertyId]: {
              ...newProperties[propertyId],
              irrigationZoneEmitters: {
                ...newProperties[propertyId].irrigationZoneEmitters,
                [id]: {
                  ...(newProperties?.[propertyId]?.irrigationZoneEmitters?.[id] || {}),
                  flowRate: {
                    ...(newProperties?.[propertyId]?.irrigationZoneEmitters?.[id]?.flowRate || {}),
                    rate: flowRate,
                    unitsPerHour: dbUnitPerHour,
                  },
                },
              },
            },
          }
        }
      })

      return {
        ...prev,
        properties: newProperties,
      }
    })

    setModifiedZones([])
  }

  const handleSave = async () => {
    const params = modifiedZones.map((zone) => ({
      ...zone,
      unitPerHour: dbUnitPerHour,
    }))

    setLoadingSave(true)

    await handleUpdateEmitterConfiguration(params, optimisticUpdate)

    setLoadingSave(false)
  }

  return (
    <>
      <EmitterConfigurationHeader
        onRateCalculatorButtonClick={() => setRateCalculatorOpened(true)}
        onBulkEditButtonClick={() => setBulkEditOpened(true)}
      />
      <EmitterConfigurationList
        setModifiedZones={setModifiedZones}
        modifiedZones={modifiedZones}
        emitterConfigurations={emitterConfigurations}
      />
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: colors.grey50,
          position: 'fixed',
          width: isWideScreen ? 'calc(100% - 400px)' : '100%',
          bottom: 0,
          left: isWideScreen ? 400 : 0,
          padding: '10px 20px',
          border: `1px solid ${colors.grey200}`,
        }}
      >
        <Button variant="secondary" disabled={buttonDisabled} onClick={() => setModifiedZones([])}>
          {translate.phrases.banyanApp('Cancel')}
        </Button>
        <Button variant="primary" disabled={buttonDisabled || isError} onClick={handleSave}>
          {translate.phrases.banyanApp('Save')}
        </Button>
      </div>
      <ModalDrawerBulkEdit
        opened={bulkEditOpened}
        handleOnClose={() => setBulkEditOpened(false)}
        onRateCalculatorButtonClick={() => setRateCalculatorOpened(true)}
        emitterConfigurations={emitterConfigurations}
      />
      <ModalDrawerRateCalculator
        opened={rateCalculatorOpened}
        handleOnClose={() => setRateCalculatorOpened(false)}
      />
    </>
  )
}
