import { SettingsSearchNotFound } from 'App/Map/UserSettingsMenu/components/SettingsSearchNotFound/SettingsSearchNotFound'
import { IconGroups } from 'components/icons/IconGroups'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import type { Group } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { fetchApiCustomer } from 'utils/fetchApiCustomer'
import { showNotification } from 'utils/showNotification'
import { useScreenSize } from 'utils/useScreenSize'
import { showConfirmSettingModal } from '../../_utils/showConfirmSettingModal'
import { groupDeleteQuery } from '../_utils/queries'
import type { NotificationType } from '../_utils/useRelativeNotification'
import { ContactsAndGroupsRow } from '../components/ContactsAndGroupsRow'
import { useSearchGroupsAndContacts } from '../components/useSearchGroupsAndContacts'

type DeleteGroupResponse = {
  data: {
    deleteGroup: boolean
  }
}

export const Groups = ({
  onEdit,
  setSelectedGroup,
  showRelativeNotification,
}: {
  onEdit: () => void
  setSelectedGroup: Dispatch<SetStateAction<Group | null>>
  showRelativeNotification: ({ type, message }: { type: NotificationType; message: string }) => void
}) => {
  const { isWideScreen } = useScreenSize()
  const notificationFunctionToUse = isWideScreen ? showRelativeNotification : showNotification

  const { allGroups, allContacts } = userDetailsStore.useSelector((s) => ({
    allGroups: s.groups,
    allContacts: s.contacts,
  }))

  const { SearchInputComponent, filteredAndSortedList, isSearchMatch } = useSearchGroupsAndContacts({
    initialList: allGroups,
    placeholder: translate.phrases.banyanApp('Search Groups'),
  })

  const handleDeleteGroup = async (groupId: string) => {
    try {
      const response: DeleteGroupResponse = await fetchApiCustomer({
        body: {
          query: groupDeleteQuery,
          variables: {
            id: groupId,
          },
        },
      })

      const success = response?.data?.deleteGroup

      if (success) {
        notificationFunctionToUse({
          type: 'success',
          message: translate.phrases.banyanApp('Group successfully deleted'),
        })

        const deletedGroupId = groupId

        const newContacts = allContacts.map((contact) => {
          const isContactInDeletedGroup = contact.groups.some((group) => group.id === deletedGroupId)

          if (isContactInDeletedGroup) {
            return {
              ...contact,
              groups: contact.groups.filter((group) => group.id !== deletedGroupId),
            }
          }

          return contact
        })

        const newGroups = allGroups.filter((group) => group.id !== deletedGroupId)

        userDetailsStore.setState((s) => {
          return {
            ...s,
            contacts: newContacts,
            groups: newGroups,
          }
        })
      } else {
        notificationFunctionToUse({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to delete group'),
        })
      }
    } catch (error) {
      notificationFunctionToUse({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to delete group'),
      })
    }
  }

  const onDelete = (groupId: string, groupName: string) => {
    showConfirmSettingModal({
      title: translate.phrases.banyanApp('Delete Group'),
      content: (
        <div>
          <p>
            {translate.phrases.banyanApp('Are you sure you want to delete {{groupName}} as a group?', {
              groupName,
            })}
          </p>
        </div>
      ),
      onConfirm: () => handleDeleteGroup(groupId),
      confirmButtonText: translate.phrases.banyanApp('Delete Group'),
    })
  }

  return (
    <>
      {SearchInputComponent}
      {isSearchMatch ? (
        (filteredAndSortedList as Group[]).map((group) => {
          return (
            <ContactsAndGroupsRow
              id={group.id}
              key={group.id}
              icon={<IconGroups />}
              name={group.name}
              onEdit={() => {
                onEdit()

                setSelectedGroup(group)
              }}
              onDelete={onDelete}
              badgeNumber={group.contacts.length}
            />
          )
        })
      ) : (
        <SettingsSearchNotFound />
      )}
    </>
  )
}
