import type { TWindMachineDetailsElement } from '@semios/app-platform-banyan-route-definitions/dist/routes/windMachine'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { StatusTile } from '../DeviceStatus/StatusTile'

export const DeviceHeader = ({
  windMachineName,
  currentWindMachineValues,
  propertyId,
}: {
  windMachineName: string
  currentWindMachineValues: TWindMachineDetailsElement['windMachineDetails'][0]
  propertyId: number | null
}) => {
  const propertyTimeZone = getTimezoneForSelectedPropertyOrRegion({ propertyId })
  const updatedDeviceTime = moment.tz(currentWindMachineValues?.equipmentStatus?.date, propertyTimeZone)
  const currentStatus = currentWindMachineValues?.engineStatus

  return (
    <div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 10px 0px 10px',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <h2
            css={{
              fontSize: '16px',
              margin: '0 10px 0 0',
            }}
          >
            {windMachineName}
          </h2>
          <StatusTile status={currentStatus} auto={1} />
        </div>
      </div>

      <p
        css={{
          marginTop: '10px',
          marginBottom: '0',
          marginLeft: '10px',
          fontSize: '10px',
          color: '#464B53',
        }}
      >
        {translate.phrases.templates('{{label}}: {{value}}', {
          label: translate.phrases.banyanApp('Last Updated'),
          value: updatedDeviceTime
            ? translate.dates.format(updatedDeviceTime, 'MMM D, YYYY h:mm a (z)')
            : translate.phrases.banyanApp('N/A'),
        })}
      </p>
    </div>
  )
}
