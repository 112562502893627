import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { roundToDecimalPlaces } from '@semios/app-platform-common'
import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import { PREDICTION_KEY } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/soil/_utils/predictionKey'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { getSoilAWCBackgroundColor } from 'App/Map/_utils/getSoilAWCBackgroundColor'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { EAggregationInterval } from 'App/Map/types'
import type {
  GridTableContentSection,
  GridTableContentSectionItem,
  PossibleValuesRecord,
} from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isEmpty, isNil } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { isAggregatedType } from 'utils/isAggregatedValueType'
import { removeCmOrInFromSoilProbeDepths } from 'utils/removeCmOrInFromSoilProbeDepths'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { NaPopover } from '../../../../../../../components/NaPopover/NaPopover'
import { BadgeCell } from '../../../BadgeCell/BadgeCell'
import { defaultValuesRowHeight } from '../_utils'
import { SoilTitleChildren } from './SoilTitleChildren'

type CommonValueType = PossibleValuesRecord & { madValue: number }

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_SOIL_MOISTURE_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  soilMoisture: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const points = fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.points

  if (!points) return {}

  const soilStationsForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(points),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  if (!selectedValueGroups.soil || isEmpty(soilStationsForProperty)) {
    return {}
  }

  const lngLats = soilStationsForProperty
    .map((soilStation) => soilStation.lngLat)
    // this is done in a weird way to keep TypeScript happy
    .filter(Boolean) as [TFieldAssetKeyTypes.TLngLat, ...TFieldAssetKeyTypes.TLngLat[]]

  if (!lngLats.length) return {}

  return {
    points: {
      lngLats,
      valuesRequested,
    },
  }
}

const generateItem = ({
  id,
  values,
  probeName,
  favoriteDepthsSelected,
}: {
  id: string
  values: Record<string, CommonValueType>
  probeName: string
  favoriteDepthsSelected: boolean
}): GridTableContentSectionItem => ({
  id,
  label: probeName,
  height: defaultValuesRowHeight,
  labelMinWidth: 190,
  valueMinWidth: 120,
  render: (dataPoint: { madValue: number; value: number }) => {
    if (dataPoint === undefined || dataPoint === null) {
      if (!favoriteDepthsSelected) return <NaPopover />
      else {
        return translate.phrases.templates('-')
      }
    }

    const valueToUse = unitConverter.soilAwc(dataPoint.value)
    const backgroundColor = getSoilAWCBackgroundColor(valueToUse.value(), dataPoint.madValue)

    return <BadgeCell backgroundColor={backgroundColor}>{valueToUse.valueWithSuffix()}</BadgeCell>
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: translate.phrases.banyanApp('Soil Root Zone Average'),
    titleChildren: <DropdownSelectorProperty />,
    id: 'summary-grid-soil',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { property } = selectedFieldAssetsStore.getState()
  const points = fieldAssetStore.getState()?.properties?.[Number(property)]?.points ?? {}

  const soilStationsForProperty = filterFieldAssetsByValueTypes({
    fieldAssets: Object.values(points),
    valuesTimeseries: Object.keys(valuesRequested),
  })

  let showIconWarningOnSettings = false

  const items = soilStationsForProperty
    .map((soilStation) => {
      const timestampsAndTheirData: Record<string, { values: number[]; madValue: number }> = {}

      const defaultDepthsForProbe = soilStation.configuration.soilDefaultProbeDepths?.map(
        removeCmOrInFromSoilProbeDepths,
      )

      const defaultDepthsForProbeLength = defaultDepthsForProbe?.length ?? 0

      if (defaultDepthsForProbeLength === 0) {
        showIconWarningOnSettings = true
      }

      data?.points?.[soilStation.lngLat]?.values?.soilMoisture?.forEach((smProbe) => {
        const isFavoriteDepth = defaultDepthsForProbe?.includes(Number(smProbe.metadata.depth))
        // prediction is currently calculated using default (favorite) depths
        const isPrediction = smProbe.metadata.depth === PREDICTION_KEY

        if (!isFavoriteDepth && !isPrediction) return

        if (defaultDepthsForProbeLength === 0 && isPrediction) return

        // sorted in descending order of startDate, then filtered to only include MAD dates that match the current station
        const sortedAndFilteredMadDates = smProbe.metadata.MADDates.sort(
          (a: { startDate: string }, b: { startDate: string }) =>
            +new Date(b.startDate) - +new Date(a.startDate),
        ).filter((madDate) => madDate.lngLat === soilStation.lngLat)

        smProbe.timeseries.forEach((timeseriesData) => {
          if (!isAggregatedType(timeseriesData.value)) return

          const looksLikeWeHaveAReading = timeseriesData?.timestamp && !isNil(timeseriesData?.value?.average)

          if (!looksLikeWeHaveAReading) return

          const timestamp = +new Date(timeseriesData.timestamp)

          const madValueForThisReading = sortedAndFilteredMadDates.find(
            (madDate) => +new Date(madDate.startDate) <= timestamp,
          )?.soilMoisture

          const madValue = madValueForThisReading ? Number(madValueForThisReading) : 70

          if (!timestampsAndTheirData[timeseriesData.timestamp]) {
            timestampsAndTheirData[timeseriesData.timestamp] = {
              values: [],
              madValue,
            }
          }

          timestampsAndTheirData[timeseriesData.timestamp].values.push(
            timeseriesData.value?.average as number,
          )
        })
      })

      const values = Object.entries(timestampsAndTheirData).reduce(
        (
          a: Record<string, { value: number | null; madValue: number }>,
          [timestamp, { madValue, values }],
        ) => {
          a[timestamp] = {
            madValue,
            value: roundToDecimalPlaces(
              values.reduce((a, b) => a + b, 0) / values.length,
              unitConverter.soilAwc().defaultNumberOfDecimalPlaces(),
            ),
          }

          return a
        },
        {},
      ) as Record<string, CommonValueType>

      return generateItem({
        id: soilStation.lngLat,
        probeName: soilStation.name ?? translate.phrases.banyanApp('Unnamed Station'),
        values,
        favoriteDepthsSelected: defaultDepthsForProbeLength > 0,
      })
    })
    .filter(Boolean)
    .sort(sortByKey('label'))

  return {
    ...commonReturnItems,
    items,
    titleChildren: <SoilTitleChildren showIconWarning={showIconWarningOnSettings} />,
  }
}
