import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconControlWindSpeed = () => {
  return (
    <svg
      {...makeBaseSvgIconProps('0 0 16 16')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
    >
      <path
        d="M1 6.52171H7.25698C7.76536 6.5487 8.78212 6.23836 8.78212 4.7811C8.78212 3.16192 7.41341 3 7.13966 3C6.47486 3 5.37989 3.2024 5.37989 4.86206M2.13408 8.09839H13.038C13.692 8.13312 15 7.73377 15 5.8586C15 3.77507 13.2392 3.56671 12.8871 3.56671C12.0319 3.56671 10.6233 3.82716 10.6233 5.96277M1.54749 9.47829H9.13408C9.64246 9.4513 10.6592 9.76164 10.6592 11.2189C10.6592 12.8381 9.2905 13 9.01676 13C8.35196 13 7.25698 12.7976 7.25698 11.1379"
        stroke="#464B53"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
