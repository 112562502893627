import { Select, Switch } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import type { TInsect } from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup/fieldAssetValueTypes'
import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { sortByKey } from '@semios/app-platform-common'
import type { TPhrases } from '@semios/app-platform-i18n-core'
import type { TPermission } from '@semios/app-platform-value-type-definitions'
import { Authorization } from 'components/Authorization/Authorization'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { defaultDates } from '../../_utils/defaultDates'
import type { TPestSelected } from '../../_utils/formTypesAndStyles'
import { getDateFromReport } from '../../_utils/getDateFromReport'
import { ErrorTextWrapper } from '../../components/ErrorTextWrapper'
import { PropertiesSubtitle } from '../ReportContent/components/PropertiesSubtitle'

const propertyOptionGenerator = (properties: TProperty[]) => {
  const propertiesData = properties
    .map((property: TProperty) => ({
      label: property.propertyName,
      value: property.propertyId.toString(),
      id: `${property.propertyId}-PROPERTY`,
    }))
    .sort(sortByKey('label'))

  return [...propertiesData]
}

export const ReportPestTypes = ({
  selectedReportProperties,
  properties,
  pestType,
  setPestType,
  pestsSelected,
  setPestsSelected,
  setPestsIsValid,
  triedToSubmit,
}: {
  selectedReportProperties: TOptions[]
  properties: TProperty[]
  pestType: string
  setPestType: Dispatch<SetStateAction<string>>
  pestsSelected: TPestSelected | null
  setPestsSelected: Dispatch<SetStateAction<TPestSelected | null>>
  setPestsIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  const { pestTypes } = fieldAssetStore.useSelector((s) => ({
    pestTypes: s.insects,
  }))

  const propertyIds = selectedReportProperties
    ? Object.values(selectedReportProperties).map((obj) => obj.value as string)
    : null

  let propertiesToCheck = selectedReportProperties

  if (selectedReportProperties.length === 0) {
    propertiesToCheck = propertyOptionGenerator(properties)
  }

  const hasSouthernHemisphereProperties = Object.values(properties ?? {}).some((p) => p.isSouthernHemisphere)
  const hasNorthernHemisphereProperties = Object.values(properties ?? {}).some((p) => !p.isSouthernHemisphere)

  const pestReportPermissions = pestTypes
    ? Object.values(pestTypes).map((item: TInsect) => {
        const filteredPropertiesByPermission = Object.values(propertiesToCheck).filter(
          (property: TOptions) => {
            return checkAuthorization({
              permission: `CREATE_REPORT_PESTS_TRAP_CATCHES_ID_${item.insectId}` as TPermission,
              entity: Number(property.value),
            })
          },
        )

        return { name: item.name, insect: item.insectId, properties: filteredPropertiesByPermission }
      })
    : null

  const hasProperties = pestReportPermissions
    ? pestReportPermissions.some((permission) => permission.properties.length > 0)
    : false

  let helpText = ''
  let validateStatusError = false

  const currentPestSelection = pestsSelected || {}
  const hasPestSelected = Object.values(currentPestSelection ?? {}).some((p) => p?.trapCatches)

  if (
    pestType === translate.phrases.banyanApp('Include the following selected pests') &&
    !hasPestSelected &&
    triedToSubmit
  ) {
    helpText = translate.phrases.banyanApp('At least one pest must be selected')

    validateStatusError = true
  } else if (
    pestType === translate.phrases.banyanApp('Include the following selected pests') &&
    !hasPestSelected
  ) {
    validateStatusError = true
  } else if (pestType === translate.phrases.banyanApp('Include all available pests with traps')) {
    validateStatusError = false

    helpText = ''
  }

  useEffect(() => {
    setPestsIsValid(!validateStatusError)
  }, [pestType, pestsSelected, triedToSubmit])

  return (
    <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
      <h4>{translate.phrases.banyanApp('Pest Types')}</h4>
      <Select
        data={[
          translate.phrases.banyanApp('Include all available pests with traps'),
          translate.phrases.banyanApp('Include the following selected pests'),
        ]}
        value={pestType}
        onChange={(pestType: string) => setPestType(pestType)}
      />
      {validateStatusError && <ErrorTextWrapper>{helpText}</ErrorTextWrapper>}

      {pestType === translate.phrases.banyanApp('Include the following selected pests') ? (
        <Authorization requires={hasProperties}>
          <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey500}` }}>
            {pestReportPermissions
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((pest) => {
                if (pest.properties.length < 1) {
                  return null
                }

                const currentSelection = pestsSelected
                  ? Object.values(pestsSelected)?.filter((selectedPests) => {
                      return selectedPests ? selectedPests.insectId === pest.insect.toString() : null
                    })
                  : null

                const selectValue = !currentSelection?.[0]?.trapCatches || false

                const defaultStart =
                  hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                    ? defaultDates?.southernHemisphere?.pestStartDate
                    : defaultDates?.northernHemisphere?.pestStartDate

                const defaultEnd =
                  hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                    ? defaultDates?.southernHemisphere?.pestEndDate
                    : defaultDates?.northernHemisphere?.pestEndDate

                return (
                  <div key={pest.name}>
                    <h4>
                      {translate.phrases.dbInsectName(pest.name as TPhrases['dbInsectName'])}
                      {PropertiesSubtitle({
                        permission: `CREATE_REPORT_PESTS_TRAP_CATCHES_ID_${pest.insect}` as TPermission,
                        propertyIds: propertyIds,
                      })}
                    </h4>
                    <Switch
                      size="lg"
                      label={translate.phrases.banyanApp(
                        'Include trap catches for the last three weeks for {{pestName}}',
                        { pestName: translate.phrases.dbInsectName(pest.name as TPhrases['dbInsectName']) },
                      )}
                      styles={{
                        label: { cursor: 'unset', fontSize: 16 },
                        track: {
                          width: 36,
                          cursor: 'pointer',
                          backgroundColor: currentSelection?.[0]?.trapCatches
                            ? `${colors.green} !important`
                            : undefined,
                          borderColor: currentSelection?.[0]?.trapCatches
                            ? `${colors.green} !important`
                            : undefined,
                        },
                      }}
                      checked={
                        currentSelection?.[0]?.trapCatches ? currentSelection?.[0]?.trapCatches : false
                      }
                      onChange={(e) =>
                        setPestsSelected((prevState) =>
                          e.target.checked
                            ? {
                                ...prevState,
                                [pest.insect]: {
                                  insectId: pest.insect.toString(),
                                  trapCatches: selectValue,
                                  startDate:
                                    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                                      ? defaultDates?.southernHemisphere?.pestStartDate
                                      : defaultDates?.northernHemisphere?.pestStartDate,
                                  endDate:
                                    hasSouthernHemisphereProperties && !hasNorthernHemisphereProperties
                                      ? defaultDates?.southernHemisphere?.pestEndDate
                                      : defaultDates?.northernHemisphere?.pestEndDate,
                                },
                              }
                            : (() => {
                                const newState = { ...prevState }

                                delete newState[pest.insect]

                                return newState
                              })(),
                        )
                      }
                    />

                    <div css={{ marginTop: '15px', display: 'flex' }}>
                      <DatePickerInput
                        valueFormat={translate.dates.getMomentFormat('MMM D')}
                        clearable={false}
                        placeholder={translate.phrases.banyanApp('Select start date')}
                        monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                        value={getDateFromReport(
                          currentSelection?.[0]?.startDate ? currentSelection?.[0]?.startDate : defaultStart,
                        )}
                        onChange={(e) =>
                          setPestsSelected((prevState) => ({
                            ...prevState,
                            [pest.insect]: {
                              ...currentSelection?.[0],
                              startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                            },
                          }))
                        }
                        css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
                        disabled={!currentSelection?.[0]?.trapCatches}
                      />

                      <DatePickerInput
                        valueFormat={translate.dates.getMomentFormat('MMM D')}
                        clearable={false}
                        placeholder={translate.phrases.banyanApp('Select end date')}
                        value={getDateFromReport(
                          currentSelection?.[0]?.endDate ? currentSelection?.[0]?.endDate : defaultEnd,
                        )}
                        monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                        onChange={(e) =>
                          setPestsSelected((prevState) => ({
                            ...prevState,
                            [pest.insect]: {
                              ...currentSelection?.[0],
                              endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                            },
                          }))
                        }
                        css={{ maxWidth: '100%', width: '240px' }}
                        disabled={!currentSelection?.[0]?.trapCatches}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
        </Authorization>
      ) : null}
    </div>
  )
}
