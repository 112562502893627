import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty, isNil } from '@semios/app-platform-common'
import type { VC } from '@semios/app-platform-value-type-definitions'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import type { TValuesCurrentBlocksValueTypes } from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'

type TValueObject =
  VC.DomainTypes.ImageryOverlay.TCurrentValuesReturnIgnoringKeying['blocks']['imageryOverlayNDVI_current']

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  const { mapVisualValueGroup } = mapControlsStore.getState()
  const valueType = mapVisualValueGroup?.[MAP_VISUAL?.IMAGE_OVERLAY]?.ndvi?.valueType
  const returner: routes.ValuesCurrent.Request = {}

  if (!!valueType && !!processedCaches.imageOverlayValues.itemsWithinView.length) {
    returner.blocks = {
      blockIds: processedCaches.imageOverlayValues.itemsWithinView.map((b) => Number(b.id)),
      values: { [valueType]: true },
    }
  }

  return returner
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch({
    url: '/values-current',
    body: args,
  })

  const { mapVisualValueGroup } = mapControlsStore.getState()
  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const { itemsWithinView } = processedCaches.imageOverlayValues

  if (itemsWithinView.length > 0) {
    const valueTypeForBlock = mapVisualValueGroup?.[MAP_VISUAL.IMAGE_OVERLAY]?.ndvi
      ?.valueType as TValuesCurrentBlocksValueTypes

    const itemsWithinViewThatNowHaveValues = itemsWithinView.flatMap((block) => {
      const valueObject = response?.blocks?.[block.id]?.[valueTypeForBlock] as TValueObject
      const value = valueObject?.value ?? null

      if (isNil(value)) return []

      return {
        id: String(block.id),
        value: { [String(valueTypeForBlock)]: value },
      }
    })

    returner.imageOverlays = {
      itemIdsWithinView: itemsWithinView.map((block) => block.id),
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.blockCacheKey,
    }
  }

  return returner
}
