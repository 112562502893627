import type { CSSObject } from '@emotion/react'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import type { ReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { makeReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import type { TWindMachineBadgeComponentProps } from './WindMachineBadgeComponent/WindMachineBadgeComponent'
import { WindMachineBadgeComponent } from './WindMachineBadgeComponent/WindMachineBadgeComponent'

export type WIND_MACHINE_META = {
  propertyId: TFieldAssetKeyTypes.TPropertyId
  lngLat: TFieldAssetKeyTypes.TLngLat
  name: string
  latLng: google.maps.LatLng
  isOutOfBlock: boolean
}

export type TWindMachineValueTypesToPropsDictionary = Record<
  string,
  {
    baseZIndex?: number
    children: string
    getContainerCSS: () => CSSObject
    getSize?: () => number | null
    shouldAddDataSourceOverlay?: boolean
  }
>

export class WindMachineValuesCache extends MapItemsCache<
  WIND_MACHINE_META,
  TWindMachineValueTypesToPropsDictionary,
  ReactComponentOverlayView<TWindMachineBadgeComponentProps>
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        return makeReactComponentOverlayView({
          component: WindMachineBadgeComponent,
          latLng: o.meta.latLng,
          maps: o.maps,
          map: o.map,
        })
      },
      renderOverlay: (o) => {
        o.overlay.render({ ...o, valueTypesToPropsDictionary: o.value })
      },
      hideOverlay: (o) => {
        o.overlay.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
