import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { getEmitterTypeFromValueType, roundToDecimalPlaces } from '@semios/app-platform-common'
import { VC } from '@semios/app-platform-value-type-definitions'
import { getIrrigationStatusColors } from 'App/Map/_utils/getIrrigationStatusColors'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { isEmpty, isNil } from 'lodash'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type {
  TValuesCurrentIrrigationZoneValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
} from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'
import { getConvertedWaterVolume } from 'utils/getConvertedWaterVolume'
import { minutesToHoursAndMinutes } from 'utils/minutesToHoursAndMinutes'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { isValueTypeForStatus } from './_utils/getExternalValueTypeForIrrigation'
import { getHeatmapCSS } from './_utils/getHeatmapCSS'
import { getValueType } from './_utils/getValueType'
import { setHeatmapExtremesFromArrayOfValues } from './_utils/setHeatmapExtremesFromArrayOfValues'

const MILLISECONDS_IN_HOUR = 3_600_000

// TODO: perhaps we could expose this type differently
type TIrrigationDeviceStatus = NonNullable<
  NonNullable<routes.ValuesCurrent.Response['points']>[string]['irrigationIsOn_status']
>['value']

const IRRIGATION_ZONE_ACTIVITY_PUBLISHED_EVENT_DURATION_VALUE_TYPE_PREFIX =
  'irrigationActivity_currentPublishedEventDurationSum_'

const IRRIGATION_ZONE_ACTIVITY_APPLIED_EVENT_DURATION_VALUE_TYPE_PREFIX =
  'irrigationActivity_currentAppliedEventDurationSum_'

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  const pointValueType = getValueType(MAP_VISUAL.POINT, 'irrigation_activity')
  const propertyValueType = getValueType(MAP_VISUAL.PROPERTY, 'irrigation_activity')

  const irrigationZoneValueType = getValueType(
    MAP_VISUAL.IRRIGATION_ZONE,
    'irrigation_activity',
  ) as TValuesCurrentIrrigationZoneValueTypes

  const returner: routes.ValuesCurrent.Request = {}
  const pointsToFetch: TFieldAssetKeyTypes.TLngLat[] = []
  const irrigationZoneIdsToFetch: TFieldAssetKeyTypes.TIrrigationZoneId[] = []

  if (
    pointValueType &&
    VC.valuesCurrentValueTypes_Points.includes(pointValueType) &&
    !!processedCaches.stationValues.itemsWithinView.length
  ) {
    processedCaches.stationValues.itemsWithinView.forEach((s) => {
      pointsToFetch.push(s.meta.lngLat)
    })

    if (!!pointsToFetch.length) {
      returner.points = { lngLats: pointsToFetch, values: { [pointValueType]: true } }
    }
  }

  if (
    irrigationZoneValueType &&
    VC.valuesCurrentValueTypes_IrrigationZoneEmitters.includes(irrigationZoneValueType) &&
    !!processedCaches.irrigationZoneValues.itemsWithinView.length
  ) {
    processedCaches.irrigationZoneValues.itemsWithinView.forEach((s) => {
      irrigationZoneIdsToFetch.push(s.meta.irrigationZoneId)
    })

    if (!!irrigationZoneIdsToFetch.length) {
      returner.irrigation_zones = {
        irrigationZoneIds: [...new Set(irrigationZoneIdsToFetch)],
        values: { [irrigationZoneValueType]: true },
      }

      const isAnyEmitterStatus = irrigationZoneValueType === 'irrigationIsOn_status'

      if (isValueTypeForStatus(irrigationZoneValueType) && !isAnyEmitterStatus) {
        const emitterType = irrigationZoneValueType.split('_')[1]

        if (emitterType && returner.irrigation_zones) {
          returner.irrigation_zones.values[
            `${IRRIGATION_ZONE_ACTIVITY_PUBLISHED_EVENT_DURATION_VALUE_TYPE_PREFIX}${emitterType}_ms` as TValuesCurrentIrrigationZoneValueTypes
          ] = true

          returner.irrigation_zones.values[
            `${IRRIGATION_ZONE_ACTIVITY_APPLIED_EVENT_DURATION_VALUE_TYPE_PREFIX}${emitterType}_ms` as TValuesCurrentIrrigationZoneValueTypes
          ] = true
        }
      }
    }
  }

  if (
    propertyValueType &&
    VC.valuesCurrentValueTypes_Properties.includes(propertyValueType) &&
    !!processedCaches.propertyValues.itemsWithinView.length
  ) {
    returner.properties = {
      propertyIds: processedCaches.propertyValues.itemsWithinView.map((p) => Number(p.id)),
      values: { [propertyValueType]: true },
    }
  }

  return returner
}

const Z_INDEX_NOT_APPLICABLE = -1
const Z_INDEX_OFF = 0
const Z_INDEX_NO_DATA = 1
const Z_INDEX_ON = 2
const Z_INDEX_HAS_DATA = 2

const getIrrigationStatusReturn = (status: TIrrigationDeviceStatus) => {
  if (status === 'on') {
    return {
      baseZIndex: Z_INDEX_ON,
      children: translate.phrases.banyanApp('On'),
      getContainerCSS: () => getIrrigationStatusColors(status),
    }
  }

  if (status === 'off') {
    return {
      baseZIndex: Z_INDEX_OFF,
      children: translate.phrases.banyanApp('Off'),
      getContainerCSS: () => getIrrigationStatusColors(status),
    }
  }

  return {
    baseZIndex: Z_INDEX_NO_DATA,
    children: translate.phrases.templates('-'),
    getContainerCSS: () => getIrrigationStatusColors(status),
  }
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch({ url: '/values-current', body: args })

  const pointValueType = getValueType(
    MAP_VISUAL.POINT,
    'irrigation_activity',
  ) as TValuesCurrentPointsValueTypes

  const propertyValueType = getValueType(
    MAP_VISUAL.PROPERTY,
    'irrigation_activity',
  ) as TValuesCurrentPropertiesValueTypes

  const irrigationZoneValueType = getValueType(
    MAP_VISUAL.IRRIGATION_ZONE,
    'irrigation_activity',
  ) as TValuesCurrentIrrigationZoneValueTypes

  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const valueTypeIsForStatus = isValueTypeForStatus(pointValueType)
  const appliedIrrigationActivityDisplay = smallStore.getState().appliedIrrigationActivityDisplay
  const allValuesForHeatmap: number[] = []
  const isImperial = userDetailsStore.getState().rain === 'IMPERIAL'
  // we will use the properties later to find the flow rates for devices, since we don't put that in station meta data at this time
  const properties = fieldAssetStore.getState().properties
  /**
   * the emitters for value types are written in camelCase.
   * We can hackily extract them and convert them to kebab-case
   * this way, so we can later use the kebab-case version to
   * build out and work with pressureTransducerIds
   */
  const kebabCasedEmitter = getEmitterTypeFromValueType(pointValueType)

  if (!!processedCaches.stationValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.stationValues.itemsWithinView.flatMap(
      (station) => {
        const point = properties?.[station.meta.propertyId]?.points?.[station.meta.lngLat]

        if (
          kebabCasedEmitter &&
          !point?.configuration.irrigationEmitterTypesAvailable?.includes(kebabCasedEmitter)
        )
          return []

        itemIdsWithinView.push(station.id)

        // TODO: we should probably have better typing on this
        const value = response?.points?.[station.meta.lngLat]?.[pointValueType]?.value ?? null

        // would be nice to do an early return on nulls here instead, but TypeScript is tricky for it
        if (!isNil(value)) {
          if (valueTypeIsForStatus) {
            return {
              id: String(station.id),
              value: {
                [pointValueType]: getIrrigationStatusReturn(value as TIrrigationDeviceStatus),
              },
            }
          }

          /**
           * when working with applied amounts of water, we will show it as
           * hours on the map xor volume applied using an hourly flow rate
           */
          const valueAsNumber = Number(value)
          const durationHours = valueAsNumber / MILLISECONDS_IN_HOUR

          if (appliedIrrigationActivityDisplay === 'DURATION') {
            const durationHoursRounded = Number(roundToDecimalPlaces(durationHours, 1))

            allValuesForHeatmap.push(durationHoursRounded)

            return {
              id: String(station.id),
              value: {
                [pointValueType]: {
                  baseZIndex: durationHours > 0 ? Z_INDEX_HAS_DATA : Z_INDEX_OFF,
                  children: durationHoursRounded,
                  getContainerCSS: () => getHeatmapCSS({ value: durationHours }),
                },
              },
            }
          }

          /**
           * in order to calculate the applied volume, we need to multiply
           * the duration water was applied by the flow rate of the
           * respective device's irrigation zone. To get this flow rate, we
           * can do the following:
           *   1. find the irrigation zone for the respective pressureTransducer
           *   2. find the flow rate's mmPerHour value for the respective irrigation zone
           */
          if (appliedIrrigationActivityDisplay === 'VOLUME') {
            const property = properties?.[Number(station.meta.propertyId)]

            const foundIrrigationZoneEmitterId = (
              property?.points?.[station.meta.lngLat]?.irrigationEmitterZoneIds ?? []
            )
              // note: this approach will only work if there is not more than one emitter type per point (shouldnt ever be?
              .find((zoneEmitter) => kebabCasedEmitter && zoneEmitter.includes(kebabCasedEmitter))

            const flowRateMillimetresPerHour =
              foundIrrigationZoneEmitterId &&
              property?.irrigationZoneEmitters?.[foundIrrigationZoneEmitterId]?.flowRate?.mmPerHour

            if (flowRateMillimetresPerHour) {
              const millimetresApplied = flowRateMillimetresPerHour * durationHours

              const appliedAmountFinalUnit =
                getConvertedWaterVolume({
                  waterApplied: millimetresApplied,
                  volumeUnitToDisplay: isImperial ? 'INCH' : 'MILLIMETER',
                  flowUnitPerHour: 'MILLIMETER',
                  decimalPlacesToRound: 1,
                }) || 0

              allValuesForHeatmap.push(appliedAmountFinalUnit)

              return {
                id: String(station.id),
                value: {
                  [pointValueType]: {
                    children: !!millimetresApplied ? appliedAmountFinalUnit : 0,
                    getContainerCSS: () => getHeatmapCSS({ value: appliedAmountFinalUnit }),
                    // nice to make non-zero amounts pop out more
                    baseZIndex: millimetresApplied > 0 ? Z_INDEX_HAS_DATA : Z_INDEX_OFF,
                  },
                },
              }
            }
          }
        }

        return {
          id: String(station.id),
          value: {
            [pointValueType]: {
              children: translate.phrases.templates('-'),
              getContainerCSS: () => ({ backgroundColor: colors.grey200, color: colors.midnight }),
              baseZIndex: Z_INDEX_NO_DATA,
            },
          },
        }
      },
    )

    returner.stations = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.stationCacheKey,
      itemIdsWithinView,
    }
  }

  if (processedCaches.irrigationZoneValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.irrigationZoneValues.itemsWithinView.flatMap(
      (iZone) => {
        itemIdsWithinView.push(iZone.id)

        const { irrigationZoneId } = iZone.meta
        const irrigationZoneValuesFromResponse = response?.irrigation_zones?.[+irrigationZoneId]
        const value = irrigationZoneValuesFromResponse?.[irrigationZoneValueType]?.value ?? null

        if (!isNil(value)) {
          if (valueTypeIsForStatus) {
            const emitterType = irrigationZoneValueType.split('_')[1]

            const pubishedEventDuration = (irrigationZoneValuesFromResponse?.[
              `${IRRIGATION_ZONE_ACTIVITY_PUBLISHED_EVENT_DURATION_VALUE_TYPE_PREFIX}${emitterType}_ms` as TValuesCurrentIrrigationZoneValueTypes
            ]?.value ?? null) as number | null

            const appliedEventDuration = (irrigationZoneValuesFromResponse?.[
              `${IRRIGATION_ZONE_ACTIVITY_APPLIED_EVENT_DURATION_VALUE_TYPE_PREFIX}${emitterType}_ms` as TValuesCurrentIrrigationZoneValueTypes
            ]?.value ?? null) as number | null

            const statusValue = irrigationZoneValuesFromResponse?.[irrigationZoneValueType]?.value ?? null

            if (!pubishedEventDuration) {
              return {
                id: String(iZone.id),
                value: {
                  [irrigationZoneValueType]: getIrrigationStatusReturn(
                    statusValue as TIrrigationDeviceStatus,
                  ),
                },
              }
            }

            const formatedTotalMinutes = minutesToHoursAndMinutes(pubishedEventDuration / 60000)

            const formatedPassedMinutes = appliedEventDuration
              ? minutesToHoursAndMinutes(appliedEventDuration / 60000)
              : 0

            const progressText = translate.phrases.templates('{{passedTime}} of {{totalTime}}', {
              passedTime: formatedPassedMinutes,
              totalTime: formatedTotalMinutes,
            })

            const percentPassed = appliedEventDuration
              ? Math.round((appliedEventDuration / pubishedEventDuration) * 100)
              : 0

            return {
              id: iZone.id,
              value: {
                [irrigationZoneValueType]: {
                  ...getIrrigationStatusReturn(statusValue as TIrrigationDeviceStatus),
                  progressText,
                  progressPercent: percentPassed,
                },
              },
            }
          }

          const valueAsNumber = Number(value)
          const durationHours = valueAsNumber / MILLISECONDS_IN_HOUR

          if (appliedIrrigationActivityDisplay === 'DURATION') {
            const durationHoursRounded = Number(roundToDecimalPlaces(durationHours, 1))

            allValuesForHeatmap.push(durationHoursRounded)

            return {
              id: String(iZone.id),
              value: {
                [irrigationZoneValueType]: {
                  // nice to make non-zero amounts pop out more
                  baseZIndex: durationHours > 0 ? Z_INDEX_HAS_DATA : Z_INDEX_OFF,
                  children: durationHoursRounded,
                  getContainerCSS: () => getHeatmapCSS({ value: durationHours }),
                },
              },
            }
          }

          if (appliedIrrigationActivityDisplay === 'VOLUME') {
            const property = properties?.[Number(iZone.meta.propertyId)]

            const flowRateMillimetresPerHour =
              property?.irrigationZoneEmitters?.[iZone.meta.irrigationZoneEmitterId]?.flowRate?.mmPerHour

            if (flowRateMillimetresPerHour) {
              const millimetresApplied = flowRateMillimetresPerHour * durationHours

              const appliedAmountFinalUnit =
                getConvertedWaterVolume({
                  waterApplied: millimetresApplied,
                  volumeUnitToDisplay: isImperial ? 'INCH' : 'MILLIMETER',
                  flowUnitPerHour: 'MILLIMETER',
                  decimalPlacesToRound: 1,
                }) || 0

              allValuesForHeatmap.push(appliedAmountFinalUnit)

              return {
                id: String(iZone.id),
                value: {
                  [irrigationZoneValueType]: {
                    children: !!millimetresApplied ? appliedAmountFinalUnit : 0,
                    getContainerCSS: () => getHeatmapCSS({ value: appliedAmountFinalUnit }),
                    baseZIndex: millimetresApplied > 0 ? Z_INDEX_HAS_DATA : Z_INDEX_OFF,
                  },
                },
              }
            }
          }
        }

        return {
          id: String(iZone.id),
          value: {
            [irrigationZoneValueType]: getIrrigationStatusReturn(null as TIrrigationDeviceStatus),
          },
        }
      },
    )

    returner.irrigationZones = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.irrigationZoneCacheKey,
      itemIdsWithinView,
    }
  }

  const showValuesForPropertyRatherThanName =
    propertyValueType &&
    !processedCaches.stationValues.itemsWithinView.length &&
    (appliedIrrigationActivityDisplay === 'DURATION' || valueTypeIsForStatus)

  const itemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.map((property) => {
    if (!showValuesForPropertyRatherThanName) {
      return {
        id: String(property.id),
        value: {
          [propertyValueType]: {
            baseZIndex: Z_INDEX_NOT_APPLICABLE,
            children: property.meta.propertyName,
            getContainerCSS: () => ({ backgroundColor: colors.midnight, color: colors.white }),
            onHover: false,
          },
        },
      }
    }

    // TODO: we should probably have better typing on this
    const value =
      // @ts-ignore
      response?.properties?.[Number(property.id)]?.[propertyValueType]?.[
        valueTypeIsForStatus ? 'value' : 'max_value'
      ] ?? null

    if (!isNil(value)) {
      if (valueTypeIsForStatus) {
        return {
          id: String(property.id),
          value: {
            [propertyValueType]: {
              ...getIrrigationStatusReturn(value as TIrrigationDeviceStatus),
              onHover: true,
            },
          },
        }
      }

      const valueAsNumber = Number(value)
      const durationHours = valueAsNumber / MILLISECONDS_IN_HOUR
      const durationHoursRounded = Number(roundToDecimalPlaces(durationHours, 1))
      // note that we will never show applied amounts at the property level, just duration

      allValuesForHeatmap.push(durationHoursRounded)

      return {
        id: String(property.id),
        value: {
          [propertyValueType]: {
            // nice to make non-zero amounts pop out more
            baseZIndex: durationHours > 0 ? Z_INDEX_HAS_DATA : Z_INDEX_OFF,
            children: durationHoursRounded,
            getContainerCSS: () => getHeatmapCSS({ value: durationHours }),
            onHover: true,
          },
        },
      }
    }

    /**
     * if the selected value type is for an applied amount, then it is
     * for a specific emitter type, but if the property doesn't have
     * that emitter type, we should just show "N/A" for the property.
     * We can determine if the emitter is present for the property by
     * checking the property from the fieldAssetStore's properties
     * object
     */
    const propertyPressureTransducers = Object.values(properties?.[Number(property.id)]?.points || {}).filter(
      (p) => !isEmpty(p.configuration.irrigationEmitterTypesAvailable),
    )

    const propertyHasChosenEmitter = Object.values(properties?.[Number(property.id)]?.points || {}).some(
      (p) => p.valuesCurrent.includes(pointValueType),
    )

    if (isEmpty(propertyPressureTransducers) || (kebabCasedEmitter && !propertyHasChosenEmitter)) {
      return {
        id: String(property.id),
        value: {
          [propertyValueType]: {
            children: translate.phrases.abbreviations('Not Applicable'),
            getContainerCSS: () => ({ backgroundColor: colors.grey200, color: colors.midnight }),
            baseZIndex: Z_INDEX_NOT_APPLICABLE,
            onHover: true,
          },
        },
      }
    }

    return {
      id: String(property.id),
      value: {
        [propertyValueType]: {
          children: translate.phrases.templates('-'),
          getContainerCSS: () => ({ backgroundColor: colors.grey200, color: colors.midnight }),
          baseZIndex: Z_INDEX_NO_DATA,
          onHover: true,
        },
      },
    }
  })

  setHeatmapExtremesFromArrayOfValues({
    heatmapColoring: [
      colors.irrigationHeatmapSoftBlue,
      colors.irrigationHeatmapMediumBlue,
      colors.irrigationHeatmapStrongBlue,
    ],
    values: allValuesForHeatmap,
  })

  returner.properties = {
    itemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  return returner
}
