import { isNil } from '@semios/app-platform-common'
import { translate } from 'i18n/i18n'
import React from 'react'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { DefaultHeatmapRow } from '../DefaultHeatmapRow/DefaultHeatmapRow'

export const WindMachineHeatmapRow = () => {
  const valueType = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.wind_machine?.valueType,
  )

  let customConverterToUse

  if (valueType === 'windMachine_rpm') {
    return windMachineRPMHeatmapRow()
  } else if (valueType === 'windMachine_batteryVoltage_v') {
    return windMachineVoltageHeatmapRow()
  } else if (valueType === 'windMachine_temperature_c') {
    customConverterToUse = unitConverter.windMachineTemperature
  }

  return (
    <>
      {!isNil(customConverterToUse) && (
        <DefaultHeatmapRow
          unitConverterFunctionForRendering={customConverterToUse}
          unitConverterFunctionForSaving={customConverterToUse}
        />
      )}
    </>
  )
}

const windMachineRPMHeatmapRow = () => {
  const RPMHeatmapColours = [colors.midnight, colors.warmUpBadge, colors.fullRunBadge]

  return (
    <div css={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div css={{ width: '100%', background: colors.grey50, borderRadius: 3, display: 'flex', padding: 10 }}>
        {RPMHeatmapColours.map((item) => (
          <div key={item} css={{ flex: 1, height: 12, background: item }} />
        ))}
      </div>
      <div
        css={{
          marginTop: 6,
          fontSize: 12,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{'0'}</div>
        <div>{translate.phrases.banyanApp('RPM')}</div>
        <div>{'350+'}</div>
      </div>
    </div>
  )
}

const windMachineVoltageHeatmapRow = () => {
  const RPMHeatmapColours = [colors.errorBadge, colors.fullRunBadge]

  return (
    <div css={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div css={{ width: '100%', background: colors.grey50, borderRadius: 3, display: 'flex', padding: 10 }}>
        {RPMHeatmapColours.map((item) => (
          <div key={item} css={{ flex: 1, height: 12, background: item }} />
        ))}
      </div>
      <div
        css={{
          marginTop: 6,
          fontSize: 12,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <translate.Phrases.templates
          k="< {{value}} {{unitSymbol}}"
          v={{
            unitSymbol: translate.phrases.banyanApp('V'),
            value: 11.8,
          }}
        />
        <div>{translate.phrases.banyanApp('V')}</div>
        <translate.Phrases.templates
          k=">= {{value}} {{unitSymbol}}"
          v={{
            unitSymbol: translate.phrases.banyanApp('V'),
            value: 11.8,
          }}
        />
      </div>
    </div>
  )
}
