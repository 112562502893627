import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import { getHeatmapCSS } from 'App/Map/CurrentValuesMap/_utils/by-domain/_utils/getHeatmapCSS'
import { getPropertyIdsForAllPropertiesInVisibleRegions } from 'App/Map/CurrentValuesMap/_utils/by-domain/_utils/getPropertyIdsForAllPropertiesInVisibleRegions'
import { setHeatmapExtremesFromArrayOfValues } from 'App/Map/CurrentValuesMap/_utils/by-domain/_utils/setHeatmapExtremesFromArrayOfValues'
import { getWindMachineActivityTitleAndColor } from 'App/Map/_utils/getWindMachineActivityTitleAndColor'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { colors } from 'settings/colors'
import { generateDefaultHeatmapHighLowColors } from 'stores/mapControlsStore/mapControlsStore'
import type { TValuesCurrentPropertiesValueTypes } from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import type { TUnitConverterFunction } from 'utils/unitConverter/unitConverter'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { getValueType } from './_utils/getValueType'

const heatmapExtremesForAllPropertiesInVisibleRegions = true
const valueGroup = 'wind_machine'

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
    },
  })
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch({ url: '/values-current', body: args })
  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const allValues: (number | string | null)[] = []
  const heatmapColoring = generateDefaultHeatmapHighLowColors()

  const propertyArg:
    | {
        decimalPlaces?: 0 | 1
        valueType: TValuesCurrentPropertiesValueTypes
        unitConverterFunction: TUnitConverterFunction
      }
    | undefined = {
    valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
    unitConverterFunction: unitConverter.windMachineTemperature,
  }

  if (!!processedCaches.windMachineValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.windMachineValues.itemsWithinView.flatMap(
      (station) => {
        const keyToUse = getValueType(MAP_VISUAL.POINT, valueGroup)

        let decimalPlacesToUse = 1

        if (!keyToUse) return []

        // @ts-ignore TODO: coordinate types a bit better
        const value = response?.points?.[station.meta.lngLat]?.[keyToUse]?.value as number | null

        itemIdsWithinView.push(station.id)

        allValues.push(value)

        let children
        let containerCSS = getHeatmapCSS({ value })

        if (keyToUse === 'windMachine_batteryVoltage_v') {
          const WMVoltageValue = unitConverter
            .windMachineVoltage(value, { decimalPlaces: decimalPlacesToUse ?? 1 })
            .value()

          let presumedState = 'ON'

          if (isNil(WMVoltageValue) || WMVoltageValue < 11.8) {
            presumedState = 'ERROR'
          }

          const { color, textColor } = getWindMachineActivityTitleAndColor(presumedState)

          containerCSS = { backgroundColor: color, color: textColor }

          children = unitConverter
            .windMachineVoltage(value, { decimalPlaces: decimalPlacesToUse ?? 1 })
            .valueAsString()
        } else if (keyToUse === 'windMachine_isRunning') {
          const runningValue = value as unknown as boolean
          const runningState = value ? 'ON' : 'OFF'

          if (isNil(runningValue)) {
            children = '-'

            containerCSS = { backgroundColor: colors.midnight, color: colors.white }
          } else {
            children = translate.phrases.banyanApp(runningState)

            containerCSS =
              runningState === 'ON'
                ? { backgroundColor: colors.green, color: colors.midnight }
                : { backgroundColor: colors.midnight, color: colors.white }
          }
        } else if (keyToUse === 'windMachine_rpm') {
          const WMRPMValue = unitConverter.windMachineRPM(value, { decimalPlaces: 0 }).value() || 0

          let presumedState = 'OFF'

          if (WMRPMValue >= 350) {
            presumedState = 'ON'
          } else if (WMRPMValue >= 150) {
            presumedState = 'WARM UP'
          }

          const { color, textColor } = getWindMachineActivityTitleAndColor(presumedState)

          containerCSS = { backgroundColor: color, color: textColor }

          children = unitConverter.windMachineRPM(value, { decimalPlaces: 0 }).valueAsString()
        } else if (keyToUse === 'windMachine_status') {
          const statusValue = value as unknown as string
          const { color, name, textColor } = getWindMachineActivityTitleAndColor(statusValue)

          containerCSS = { backgroundColor: color, color: textColor }

          children = name
        } else {
          children = unitConverter
            .windMachineTemperature(value, { decimalPlaces: decimalPlacesToUse ?? 1 })
            .valueAsString()
        }

        return {
          id: String(station.id),
          value: {
            [keyToUse]: {
              children,
              getContainerCSS: () => containerCSS,
              getSize: () => {
                return 40
              },
              shouldAddDataSourceOverlay: false,
              isWeatherStation: true,
            },
          },
        }
      },
    )

    returner.windMachines = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.stationCacheKey,
      itemIdsWithinView,
    }
  }

  const itemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.flatMap(
    (property) => {
      return {
        id: String(property.id),
        value: {
          [String(propertyArg?.valueType)]: {
            children: property.meta.propertyName,
            onHover: false,
            getContainerCSS: () => ({ backgroundColor: colors.midnight, color: 'white' }),
            baseZIndex: undefined,
          },
        },
      }
    },
  )

  returner.properties = {
    itemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  let valuesToUseForExtremes = allValues.filter((value) => typeof value !== typeof '') as (number | null)[]

  if (heatmapExtremesForAllPropertiesInVisibleRegions && propertyArg?.valueType) {
    const visiblePropertyIds = processedCaches.propertyValues.itemsWithinView.map((s) => Number(s.id))
    const allPropertyIdsInVisibleRegions = getPropertyIdsForAllPropertiesInVisibleRegions(visiblePropertyIds)

    if (allPropertyIdsInVisibleRegions.length > 1) {
      const valuesForAllPropertiesInVisibleRegion: number[] = []

      allPropertyIdsInVisibleRegions.forEach((propertyId) => {
        const valueTypeResponseForProperty = response?.properties?.[propertyId]?.[propertyArg?.valueType]

        if (!isNil(valueTypeResponseForProperty)) {
          const foundValueForProperty =
            'median_value' in valueTypeResponseForProperty
              ? valueTypeResponseForProperty.median_value
              : valueTypeResponseForProperty.value

          if (!isNil(foundValueForProperty) && typeof foundValueForProperty === 'number') {
            valuesForAllPropertiesInVisibleRegion.push(foundValueForProperty)
          }
        }
      })

      if (valuesForAllPropertiesInVisibleRegion.length > 1) {
        valuesToUseForExtremes = valuesForAllPropertiesInVisibleRegion
      }
    }
  }

  setHeatmapExtremesFromArrayOfValues({ values: valuesToUseForExtremes, heatmapColoring })

  return returner
}
