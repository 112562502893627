import { Tabs, useMantineTheme } from '@mantine/core'
import type { TAvailablePreset } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { useCallback, useState } from 'react'
import { colors } from 'settings/colors'
import { SettingsCreateNewButton } from '../../components/SettingsCreateNewButton/SettingsCreateNewButton'
import { ItemHeader } from '../../WideScreen/ItemHeader/ItemHeader'
import { newPresetInitialStateMaker } from './_utils/newPresetInitialStateMaker'
import { ModalDrawerStep1 } from './ModalDrawerStep1/ModalDrawerStep1'
import { ModalDrawerStep2 } from './ModalDrawerStep2/ModalDrawerStep2'
import { ModalDrawerStep3 } from './ModalDrawerStep3/ModalDrawerStep3'
import { SemiosDefinedPresets } from './SemiosDefinedPresets/SemiosDefinedPresets'
import { UserDefinedPresets } from './UserDefinedPresets/UserDefinedPresets'

type TTabOption = 'SEMIOS' | 'USER_DEFINED'

export type TModalDrawerStep = 1 | 2 | 3

export type TNewPresetState = Partial<
  Pick<
    TAvailablePreset,
    'name' | 'dayOffsetDateFrom' | 'dayOffsetDateTo' | 'selectedValueGroups' | 'valueGroupForMap'
  >
> & { editingIdHash?: string }

export const Presets = () => {
  const [activeTab, setActiveTab] = useState<TTabOption>('SEMIOS')
  const [modalDrawerOpen, setModalDrawerOpen] = useState(false)
  const [modalDrawerStep, setModalDrawerStep] = useState<TModalDrawerStep>(1)
  const [newPresetState, setNewPresetState] = useState<TNewPresetState>(newPresetInitialStateMaker)
  const theme = useMantineTheme()

  const duplicatePreset = useCallback(
    (existingPreset: TAvailablePreset) => {
      const { name, dayOffsetDateFrom, dayOffsetDateTo, selectedValueGroups, valueGroupForMap } =
        existingPreset

      setNewPresetState({ name, dayOffsetDateFrom, dayOffsetDateTo, selectedValueGroups, valueGroupForMap })

      setModalDrawerOpen(true)
    },
    [setNewPresetState, setModalDrawerOpen],
  )

  const editPreset = useCallback(
    (existingPreset: TAvailablePreset) => {
      const { idHash, name, dayOffsetDateFrom, dayOffsetDateTo, selectedValueGroups, valueGroupForMap } =
        existingPreset

      setNewPresetState({
        editingIdHash: idHash,
        name,
        dayOffsetDateFrom,
        dayOffsetDateTo,
        selectedValueGroups,
        valueGroupForMap,
      })

      setModalDrawerOpen(true)
    },
    [setNewPresetState, setModalDrawerOpen],
  )

  return (
    <div>
      <ItemHeader>{translate.phrases.banyanApp('Views')}</ItemHeader>
      <div css={{ width: '100%', padding: 40, display: 'flex', flexDirection: 'column' }}>
        <div css={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            {translate.phrases.banyanApp('Only views that are selected will show up on the Semios menu.')}
          </div>
          <SettingsCreateNewButton
            label={translate.phrases.banyanApp('New View')}
            onClick={() => setModalDrawerOpen(true)}
          />
        </div>
        <Tabs
          css={{ marginTop: 24 }}
          styles={{
            tabsList: {
              button: {
                'fontSize': 15,
                'padding': 10,
                'color': theme.colors.primary[0],
                '&:hover': { background: 'transparent', fontWeight: 600 },
                '&[data-active]': {
                  'fontWeight': 700,
                  'borderBottom': `4px solid ${colors.midnight}`,
                  '&:hover': { fontWeight: 700, borderColor: colors.midnight },
                },
                '&:not(:first-of-type)': { marginLeft: 20 },
              },
            },
          }}
          value={activeTab}
          onTabChange={(val: TTabOption) => setActiveTab(val)}
        >
          <Tabs.List>
            <Tabs.Tab value="SEMIOS">{translate.phrases.banyanApp('Semios Views')}</Tabs.Tab>
            <Tabs.Tab value="USER_DEFINED">{translate.phrases.banyanApp('Custom Views')}</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="SEMIOS">
            <SemiosDefinedPresets duplicatePreset={duplicatePreset} />
          </Tabs.Panel>
          <Tabs.Panel value="USER_DEFINED">
            <UserDefinedPresets duplicatePreset={duplicatePreset} editPreset={editPreset} />
          </Tabs.Panel>
        </Tabs>
      </div>
      <ModalDrawerStep1
        newPresetState={newPresetState}
        opened={modalDrawerOpen && modalDrawerStep === 1}
        setModalDrawerOpen={setModalDrawerOpen}
        setModalDrawerStep={setModalDrawerStep}
        setNewPresetState={setNewPresetState}
        step={modalDrawerStep}
      />
      <ModalDrawerStep2
        newPresetState={newPresetState}
        opened={modalDrawerOpen && modalDrawerStep === 2}
        setModalDrawerOpen={setModalDrawerOpen}
        setModalDrawerStep={setModalDrawerStep}
        setNewPresetState={setNewPresetState}
        step={modalDrawerStep}
      />
      <ModalDrawerStep3
        newPresetState={newPresetState}
        opened={modalDrawerOpen && modalDrawerStep === 3}
        setModalDrawerOpen={setModalDrawerOpen}
        setModalDrawerStep={setModalDrawerStep}
        setNewPresetState={setNewPresetState}
        step={modalDrawerStep}
      />
    </div>
  )
}
