import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { WindMachineInfo } from 'App/Map/PanelDetails/_utils/by-domain/windMachine/WindMachineViewDetailsMenu/MenuComponents/DeviceInfo/WindMachineInfo'
import { StatusTile } from 'App/Map/PanelDetails/_utils/by-domain/windMachine/WindMachineViewDetailsMenu/MenuComponents/DeviceStatus/StatusTile'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { colors } from 'settings/colors'

export const WindMachineDetails = ({
  propertyId,
  windMachineDetailsDataObject,
  loading,
  background = colors.grey50,
}: {
  propertyId: number
  windMachineDetailsDataObject: routes.WindMachine.Response
  loading: boolean
  background?: string
}) => {
  const windMachineDetails =
    windMachineDetailsDataObject?.windMachineDetails?.[propertyId.toString()]?.windMachineDetails?.[0]

  const propertyTimezone = getTimezoneForSelectedPropertyOrRegion({ propertyId })

  const updatedDeviceTime = windMachineDetails?.equipmentStatus?.date
    ? moment.tz(windMachineDetails?.equipmentStatus?.date, propertyTimezone)
    : null

  const currentStatus = windMachineDetails?.engineStatus

  return (
    <div>
      <p
        css={{
          marginTop: '0',
          marginBottom: '10px',
          marginLeft: '0',
          fontSize: '10px',
          color: colors.grey500,
        }}
      >
        {!loading &&
          translate.phrases.templates('{{label}}: {{value}}', {
            label: translate.phrases.banyanApp('Last Updated'),
            value: updatedDeviceTime
              ? translate.dates.format(updatedDeviceTime, 'MMM D, YYYY h:mm a (z)')
              : translate.phrases.banyanApp('N/A'),
          })}
      </p>

      <div
        css={{
          display: 'grid',
          alignItems: 'center',
          backgroundColor: background,
          padding: '0px 0px 10px 10px',
          borderBottom: `1px solid ${colors.gray}`,
        }}
      >
        <div css={{ gridColumn: 1, gridRow: 1, justifyItems: 'center' }}>
          {!loading && <StatusTile status={currentStatus} auto={1} />}
        </div>
        <div css={{ gridColumn: 2, gridRow: 1 }}>
          <WindMachineInfo content={windMachineDetails} background={background} loading={loading} />
        </div>
      </div>
    </div>
  )
}
