import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const updateUserProfile = async (
  paramsToUpdate: Partial<routes.UpdateUserProfile.Request>,
): Promise<boolean> => {
  const params: routes.UpdateUserProfile.Request = paramsToUpdate
  const response = apiFetch({ url: '/update-user-profile', body: params })

  return response
}
