import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { apiFetch } from 'utils/apiFetch'

export type PlannedNodesRepositionMeta = routes.ServiceCenterPlannedNodesReposition.Request

const request = async (meta: PlannedNodesRepositionMeta): Promise<void> => {
  await apiFetch({ url: '/service-center-planned-nodes-reposition', body: meta })
}

const callback = (meta: PlannedNodesRepositionMeta) => {
  serviceCenterStore.actions.repositionPseudoNodes(meta.pseudonodes)
}

export const serviceCenterPlannedNodesReposition = {
  request,
  callback,
}
