import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { useApiREST } from 'utils/useApiREST'

export const TrapImageWithBoundingBoxes = ({
  catchCount,
  imageHash,
  imgSrc,
}: {
  catchCount: number
  imageHash: string
  imgSrc: string
}) => {
  const [imageDimensions, setImageDimensions] = useState({ width: 640, height: 480 })
  const [imageLoaded, setImageLoaded] = useState(false)

  const { data: trapCatchBoundingBoxes } = useApiREST({
    body: { trapCatchBoundingBoxes: [imageHash] },
    initialState: [],
    preventFetch: !imageHash,
    shaper: (data) => data.trapCatchBoundingBoxes?.[imageHash] ?? [],
    url: '/trap-catch-images',
    watchers: [imageHash],
  })

  useEffect(() => {
    if (!!imageHash) {
      const trapImage = new Image()

      trapImage.src = imgSrc

      trapImage.onload = () => {
        setImageDimensions({ width: trapImage.width, height: trapImage.height })

        setImageLoaded(true)
      }
    }
  }, [imgSrc])

  return (
    <div css={{ position: 'relative', display: 'inline-block', width: '100%' }}>
      <img
        alt={translate.phrases.banyanApp('Trap catch image with {{count}} catches.', { count: catchCount })}
        css={{ display: 'flex', height: imageLoaded ? 'unset' : 274.5, width: '100%' }}
        src={imgSrc}
      />
      <svg
        viewBox={`0 0 ${imageDimensions.width} ${imageDimensions.height}`}
        css={{ position: 'absolute', top: 0, left: 0 }}
      >
        {(trapCatchBoundingBoxes ?? []).map((b, i) => {
          const topLeft_x = b.tl_x
          const topLeft_y = b.tl_y
          const bottomRight_x = b.br_x
          const bottomRight_y = b.br_y

          return (
            <rect
              key={i}
              x={topLeft_x}
              y={topLeft_y}
              width={bottomRight_x - topLeft_x}
              height={bottomRight_y - topLeft_y}
              stroke="#FFFF00"
              fill="none"
            />
          )
        })}
      </svg>
    </div>
  )
}
