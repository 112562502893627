import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { apiFetch } from 'utils/apiFetch'
import type { GeoJSONPoint } from 'wellknown'
import { parse } from 'wellknown'
import { getIdentifier } from '../getIdentifier'
import type { MetaWithNodeIdentifier } from './queue'

export type NodeDropInstallMeta = routes.ServiceCenterNodeDropInstall.Request & MetaWithNodeIdentifier

const request = async (meta: NodeDropInstallMeta): Promise<NodeDropInstallMeta> => {
  await apiFetch({ url: '/service-center-node-drop-install', body: meta })

  return meta
}

const callback = (meta: NodeDropInstallMeta): void => {
  const { nodeIdentifier, nodeType, propertyId, maintenanceOwnerId, location, blockId = null } = meta
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  const updatedActiveNodes = [...activeNodes].filter((node) => getIdentifier(node) !== nodeIdentifier)
  const parsedLocation = parse(location) as GeoJSONPoint

  parsedLocation.coordinates[0] = parseFloat(parsedLocation.coordinates[0].toFixed(6))

  parsedLocation.coordinates[1] = parseFloat(parsedLocation.coordinates[1].toFixed(6))

  const newActiveNode = {
    nodeIdentifier,
    location: JSON.stringify(parsedLocation),
    nodeType,
    propertyId,
    blockId,
    maintenanceOwnerId,
    name: meta.name || null,
  }

  serviceCenterStore.actions.setActiveNodes([...updatedActiveNodes, newActiveNode])
}

export const serviceCenterNodeDropInstall = {
  request,
  callback,
}
