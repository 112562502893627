import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { Button } from 'components/Button/Button'
import { ClickHereLink } from 'components/ClickHereLink/ClickHereLink'
import { IconPencil } from 'components/icons/IconPencil'
import { IconPlusInCircle } from 'components/icons/IconPlusInCircle'
import { IconTrashCan } from 'components/icons/IconTrashCan'
import { SummaryTable } from 'components/SummaryTable/SummaryTable'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { useScreenSize } from 'utils/useScreenSize'
import { handleDeleteClick } from '../../../utils/refillPointDatesUtils/handleRefillPointDeleteClick'
import { ONGOING_KEY } from '../../../utils/refillPointDatesUtils/ongoingKey'
import { AddRefillPointDateModal } from '../RefillPointDatesSubModals/AddRefillPointDateModal'
import { EditRefillPointDateModal } from '../RefillPointDatesSubModals/EditRefillPointDateModal'
import type { TRefillPointDate, TRefillPointDatesTableProps } from '../types'

export const RefillPointDatesTable = ({
  stationName,
  refillPointDates,
  lngLat,
  setRefreshChartAndSummary,
  propertyId,
  setAllStationsRPDsData,
}: TRefillPointDatesTableProps) => {
  const [showAddRPDModal, setShowAddRPDModal] = useState(false)
  const [showEditRPDModal, setShowEditRPDModal] = useState(false)

  const [editRPDData, setEditRPDData] = useState<{
    id: number | null
    startDate: string | null
    soilMoisture: number | null
  }>({
    id: null,
    startDate: null,
    soilMoisture: null,
  })

  const [editRPDOriginalStartDate, setEditRPDOriginalStartDate] = useState<string | null>(null)
  const timezone = getTimezoneForSelectedPropertyOrRegion()

  const formatDate = (dateString: string) => {
    if (dateString === ONGOING_KEY) {
      return translate.phrases.banyanApp('Ongoing')
    } else {
      return translate.dates.format(moment.tz(dateString, timezone), 'MMM D, YYYY')
    }
  }

  const handleEditClick = (id: number, startDate: string, soilMoisture: number) => {
    setEditRPDData({
      id: id,
      startDate: startDate,
      soilMoisture: soilMoisture,
    })

    setShowEditRPDModal(true)

    setEditRPDOriginalStartDate(startDate)
  }

  const EditRefillPointDateLink = ({
    id,
    startDate,
    soilMoisture,
  }: {
    id: number
    startDate: string
    soilMoisture: number
  }) => {
    return (
      <div
        onClick={() => {
          handleEditClick(id, startDate, soilMoisture)
        }}
        css={{
          cursor: 'pointer',
          color: colors.blue800,
          display: 'inline-flex',
          wrap: 'nowrap',
          alignItems: 'center',
        }}
      >
        <IconPencil />
        <span
          css={{
            marginLeft: '6px',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          {translate.phrases.banyanApp('Edit')}
        </span>
      </div>
    )
  }

  const DeleteRefillPointDateLink = ({ id }: { id: number }) => {
    const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)

    return (
      <div
        css={{
          color: colors.blue800,
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
        }}
        onClick={() => {
          !isDeleteModalShown &&
            handleDeleteClick({
              id: id,
              propertyId: propertyId,
              refillPointDates: refillPointDates,
              lngLat: lngLat,
              setRefreshChartAndSummary: setRefreshChartAndSummary,
              setAllStationsRPDsData: setAllStationsRPDsData,
              setIsDeleteModalShown: setIsDeleteModalShown,
            })
        }}
      >
        <IconTrashCan />
        <span css={{ textDecoration: 'underline', fontWeight: 'bold', marginLeft: '5px' }}>
          {translate.phrases.banyanApp('Delete')}
        </span>
      </div>
    )
  }

  const { isWideScreen } = useScreenSize()

  const columns = [
    {
      title: translate.phrases.banyanApp('Start'),
      key: 'startDate',
      dataIndex: 'startDate',
      width: isWideScreen ? 160 : 100,
      render: (value: string) => {
        return formatDate(value)
      },
    },
    {
      title: translate.phrases.banyanApp('End'),
      key: 'endDate',
      dataIndex: 'endDate',
      width: isWideScreen ? 160 : 100,
      render: (value: string) => {
        return formatDate(value)
      },
    },
    {
      title: unitConverter.soilAwc().categoryTitle(),
      key: 'soilMoisture',
      dataIndex: 'soilMoisture',
      width: 100,
    },
    {
      title: translate.phrases.banyanApp('Action'),
      key: 'action',
      dataIndex: 'id',
      width: 150,
      render: (value: number, record: TRefillPointDate) => (
        <div css={{ display: 'flex', gap: '20px' }}>
          <EditRefillPointDateLink
            id={value}
            startDate={record.startDate}
            soilMoisture={record.soilMoisture}
          />
          <DeleteRefillPointDateLink id={value} />
        </div>
      ),
    },
  ]

  return (
    <>
      <div css={{ maxWidth: '90%', margin: 'auto', marginBottom: '35px' }}>
        <div
          css={{
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            marginTop: '20px',
            marginBottom: '10px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          <span>{stationName}</span>
          <Button
            variant="secondary"
            onClick={() => {
              setShowAddRPDModal(true)
            }}
          >
            <span css={{ display: 'flex', alignItems: 'center' }}>
              <span css={{ display: 'flex', alignItems: 'center', marginRight: '7px' }}>
                <IconPlusInCircle />
              </span>
              {translate.phrases.banyanApp('Add')}
            </span>
          </Button>
        </div>
        <div css={{ height: '100%' }}>
          <SummaryTable
            key={JSON.stringify(refillPointDates)}
            columns={columns}
            data={refillPointDates}
            noDataText={
              <>
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: colors.black,
                    fontWeight: 500,
                    marginTop: '5px',
                  }}
                >
                  {translate.phrases.banyanApp('No Refill Point Date')}
                </div>
                <div
                  css={{ display: 'flex', justifyContent: 'center', color: colors.black, fontWeight: 500 }}
                >
                  <span>
                    <translate.Phrases.banyanApp
                      k="Please <Link>add Refill Point Date</Link>"
                      c={{
                        Link: (
                          <ClickHereLink
                            onClick={() => {
                              setShowAddRPDModal(true)
                            }}
                            fontSize={16}
                          >
                            {null}
                          </ClickHereLink>
                        ),
                      }}
                    />
                  </span>
                </div>
                <div css={{ borderBottom: `1px solid ${colors.grey200}`, marginTop: '20px' }}></div>
              </>
            }
            rowCSS={() => {
              return { height: '50px' }
            }}
          ></SummaryTable>
        </div>
      </div>
      <AddRefillPointDateModal
        opened={showAddRPDModal}
        setShowAddRPDModal={setShowAddRPDModal}
        lngLat={lngLat}
        setRefreshChartAndSummary={setRefreshChartAndSummary}
        refillPointDates={refillPointDates}
        propertyId={propertyId}
        setAllStationsRPDsData={setAllStationsRPDsData}
      />
      <EditRefillPointDateModal
        opened={showEditRPDModal}
        setShowEditRPDModal={setShowEditRPDModal}
        setEditRPDData={setEditRPDData}
        id={editRPDData.id}
        startDate={editRPDData.startDate}
        soilMoisture={editRPDData.soilMoisture}
        originalStartDate={editRPDOriginalStartDate}
        setRefreshChartAndSummary={setRefreshChartAndSummary}
        refillPointDates={refillPointDates}
        propertyId={propertyId}
        lngLat={lngLat}
        setAllStationsRPDsData={setAllStationsRPDsData}
      />
    </>
  )
}
