import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { apiFetch } from 'utils/apiFetch'

const pollIntervalMilliseconds = 10 * 60 * 1000 // ten minutes

function usePrevious<T>(value: T, shouldStore: boolean): T | undefined {
  const ref = useRef<T>()

  useEffect(() => {
    if (shouldStore) {
      ref.current = value
    }
  }, [value, shouldStore])

  return ref.current
}

export const useApiCurrentValues = ({
  args,
  preventFetch,
  watchers = [],
}: {
  args: routes.ValuesCurrent.Request
  preventFetch?: boolean
  watchers?: (string | number | boolean)[]
}) => {
  const [refreshTime, setRefreshTime] = useState(+new Date())
  const [error, setError] = useState(false)
  const [responseValues, setResponseValues] = useState({})
  const [requestsLoading, setRequestsLoading] = useState(false)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const shouldPoll = !document.hidden && document.visibilityState === 'visible'

      if (shouldPoll) setRefreshTime(+new Date())
    }, pollIntervalMilliseconds)

    return () => clearInterval(intervalId)
  }, [])

  const thingsToTriggerAFetch = useMemo(
    () => ({ args, watchers, refreshTime, preventFetch }),
    [args, watchers, refreshTime, preventFetch],
  )

  const fetchValuesData = useCallback(async () => {
    setRequestsLoading(true)

    try {
      const response = await apiFetch({ url: '/values-current', body: args })

      setResponseValues(response)
    } catch (err) {
      setError(true)
    } finally {
      setRequestsLoading(false)
    }
  }, [args])

  const previousThingsToTriggerAFetch = usePrevious(thingsToTriggerAFetch, !preventFetch)

  useEffect(() => {
    if (isEqual(thingsToTriggerAFetch, previousThingsToTriggerAFetch) || preventFetch) {
      return
    }

    fetchValuesData()
  }, [thingsToTriggerAFetch])

  return {
    data: responseValues,
    loading: requestsLoading,
    error,
  }
}
