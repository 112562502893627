import { Grid } from '@mantine/core'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React from 'react'
import { getActiveNodeStatus, NODE_STATUS_COLORS } from '../../../Map/_utils/getActiveNodeStatus'
import { ToggleSymbolValue } from '../../../ToggleSymbolValue/ToggleSymbolValue'
import type { TNodeType, TSnNodeLog } from '../../../types'
import { renderWithDefaultValue } from '../../../utils/renderers'
import { BatteryStrength } from './BatteryStrength'
import { SignalStrength } from './SignalStrength'

interface SnNodeLogProps {
  log: TSnNodeLog | undefined
  nodeType: TNodeType
}

export const SnNodeLog: React.FC<SnNodeLogProps> = ({ log, nodeType }) => {
  const lastLogTimeAgoText = log ? (
    <ToggleSymbolValue value={moment.tz(log.stamp, moment.tz.guess()).format('lll z')}>
      {moment.tz(log.stamp, 'UTC').fromNow()}
    </ToggleSymbolValue>
  ) : (
    translate.phrases.placeholder('Never')
  )

  const nodeStatus = getActiveNodeStatus(log)
  const nodeStatusColor = NODE_STATUS_COLORS[nodeStatus]

  const latestPhotoText = log?.latestPhoto ? (
    <ToggleSymbolValue value={moment.tz(log.latestPhoto, moment.tz.guess()).format('lll z')}>
      {moment.tz(log.latestPhoto, 'UTC').fromNow()}
    </ToggleSymbolValue>
  ) : (
    translate.phrases.placeholder('Never')
  )

  return (
    <Grid css={{ fontSize: '14px' }}>
      <Grid.Col span={4}>{translate.phrases.placeholder('Last Log')}</Grid.Col>
      <Grid.Col span={8}>
        <span css={{ color: nodeStatusColor, fontWeight: 500 }}>
          {renderWithDefaultValue(lastLogTimeAgoText)}
        </span>
      </Grid.Col>
      {!!log && (
        <>
          <Grid.Col span={4}>{translate.phrases.placeholder('Signal Strength')}</Grid.Col>
          <Grid.Col span={8}>
            <ToggleSymbolValue value={`${log.signal ? `${log.signal * -1} dBm` : 'unknown'}`}>
              <SignalStrength signalRSSI={log.signal * -1} />
            </ToggleSymbolValue>
          </Grid.Col>
          {log.battery && (
            <>
              <Grid.Col span={4}>{translate.phrases.placeholder('Battery')}</Grid.Col>
              <Grid.Col span={8}>
                <span css={{ fontWeight: 500 }}>
                  <ToggleSymbolValue value={`${log.battery}`}>
                    <BatteryStrength nodeType={nodeType} voltage={log.battery} />
                  </ToggleSymbolValue>
                </span>
              </Grid.Col>
            </>
          )}
          {(nodeType === 'rpt' || nodeType === 'y') && (
            <>
              {log.lithium && (
                <>
                  <Grid.Col span={4}>{translate.phrases.placeholder('Lithium')}</Grid.Col>
                  <Grid.Col span={8}>
                    <span css={{ fontWeight: 500 }}>
                      <ToggleSymbolValue value={`${log.lithium}`}>
                        <BatteryStrength nodeType={nodeType} voltage={log.lithium} />
                      </ToggleSymbolValue>
                    </span>
                  </Grid.Col>
                </>
              )}
              {log.solar && (
                <>
                  <Grid.Col span={4}>{translate.phrases.placeholder('Solar')}</Grid.Col>
                  <Grid.Col span={8}>
                    <span css={{ fontWeight: 500 }}>
                      <ToggleSymbolValue value={`${log.solar}`}>
                        <BatteryStrength nodeType={nodeType} voltage={log.solar} />
                      </ToggleSymbolValue>
                    </span>
                  </Grid.Col>
                </>
              )}
            </>
          )}

          {nodeType === 'trp' && (
            <>
              <Grid.Col span={4}>{translate.phrases.placeholder('Latest Photo')}</Grid.Col>
              <Grid.Col span={8} css={{ fontWeight: 500 }}>
                {latestPhotoText}
              </Grid.Col>
              <Grid.Col span={4}>{translate.phrases.placeholder('Image Count')}</Grid.Col>
              <Grid.Col span={8} css={{ fontWeight: 500 }}>
                {renderWithDefaultValue(log.imageCount)}
              </Grid.Col>
            </>
          )}
        </>
      )}
    </Grid>
  )
}
