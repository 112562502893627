import { IconControlBattery } from 'components/icons/IconControlsInfo/IconControlBattery'
import { IconControlFuel } from 'components/icons/IconControlsInfo/IconControlFuel'
import { IconControlRPM } from 'components/icons/IconControlsInfo/IconControlRPM'
import { IconControlTemperature } from 'components/icons/IconControlsInfo/IconControlTemperature'
import { IconControlWindSpeed } from 'components/icons/IconControlsInfo/IconControlWindSpeed'
import React from 'react'
import { colors } from 'settings/colors'

const iconMap: Record<string, React.FC> = {
  temperature: IconControlTemperature,
  rpm: IconControlRPM,
  fuel: IconControlFuel,
  battery: IconControlBattery,
  windSpeed: IconControlWindSpeed,
}

const useIcon = (iconName: string): React.ReactNode => {
  const IconComponent = iconMap[iconName]

  return IconComponent ? <IconComponent /> : null
}

interface DeviceInfoBlockProps {
  data: number | string
  icon: string
  isLow?: boolean
}

export const DeviceInfoBlock: React.FC<DeviceInfoBlockProps> = ({ data, icon, isLow }) => {
  const displayData = isLow ? (
    <span
      css={{
        background: colors.red,
        padding: '5px 10px',
        borderRadius: '10px',
      }}
    >
      {data}
    </span>
  ) : (
    data
  )

  return (
    <div
      css={{
        'display': 'flex',
        'flexDirection': 'row',
        'justifyContent': 'space-between',
        'alignItems': 'center',
        '@media (max-width: 600px)': {
          padding: '5px 10px',
        },
      }}
    >
      {useIcon(icon)}
      <p css={{ marginLeft: '2px', marginTop: '0px', marginBottom: '0px', marginRight: '3px' }}>
        {displayData}
      </p>
    </div>
  )
}
