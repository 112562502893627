import { Radio as MantineRadio, NumberInput, Select } from '@mantine/core'
import { Radio } from 'components/Radio/Radio'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { fadeInOutStylesMaker } from 'utils/fadeInOutStylesMaker'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { AlertsValidators, isNumberOrNumericString } from '../../../../AlertValidators/AlertValidators'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import type { Rule } from '../../../../settings/alertSettings'
import { AlertsSettings, unitSpeedDisplay, unitTempDisplay } from '../../../../settings/alertSettings'
import type { WeatherForecastType } from '../../../ModalDrawerCreateOrEditAlert'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'

const thresholdRules: string[] = [
  'windGreaterThanOrEqualToKph',
  'hasRain',
  'temperatureGreaterThanOrEqualToCelsius',
  'temperatureLessThanOrEqualToCelsius',
  'includeAllDevices',
  'measurementHeights',
]

const rulesToInitialize = thresholdRules.reduce((a: Record<string, Partial<Rule> | null>, b: string) => {
  a[b] = null

  return a
}, {})

export const WeatherForecastThreshold = ({
  rules,
  setRules,
  setThresholdIsValid,
  setWeatherForecastThresholdType,
  triedToSubmit,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  setWeatherForecastThresholdType: Dispatch<SetStateAction<WeatherForecastType>>
  triedToSubmit: boolean
}) => {
  const [threshold, setThreshold] = useState(AlertsSettings.weatherForecastAlertThresholdItems.WIND)
  const [hourOfDay, setHourOfDay] = useState<Rule['value']>(null)
  const [wind, setWind] = useState<Rule['value']>(null)
  const [temperature, setTemperature] = useState<Rule['value']>(null)
  const [temperatureOperator, setTemperatureOperator] = useState<string | null>('>=')
  const windUnit = unitSpeedDisplay()
  const tempUnit = unitTempDisplay()

  useEffect(() => {
    if (!rules || !rules.length) {
      const rules = AlertsSettings.emptyRules.weatherForecastAlert

      setRules(rules)

      setHourOfDay(rules[0].value)
    }
  }, [])

  // set the value of the temperature + operator from the existing rules upon mount, if applicable
  useEffect(() => {
    try {
      const hourOfDayRule = rules.find((r) => r.key === 'hourOfDay')

      if (hourOfDayRule) {
        setHourOfDay(hourOfDayRule.value)
      }

      // When rules has Wind threshold rule
      if (rules.find((r) => r.key === 'windGreaterThanOrEqualToKph')) {
        const wind = rules.find((r) => r.key === 'windGreaterThanOrEqualToKph')

        if (wind) {
          setWind(wind.value)
        }

        setThreshold(AlertsSettings.weatherForecastAlertThresholdItems.WIND)

        setWeatherForecastThresholdType(AlertsSettings.weatherForecastAlertThresholdItems.WIND)
      }

      // When rules has Rain threshold rule
      if (rules.find((r) => r.key === 'hasRain')) {
        setThreshold(AlertsSettings.weatherForecastAlertThresholdItems.RAIN)

        setWeatherForecastThresholdType(AlertsSettings.weatherForecastAlertThresholdItems.RAIN)
      }

      // When rules has Temperature threshold rule
      if (rules.find((r) => r.key === 'temperatureGreaterThanOrEqualToCelsius')) {
        const temperature = rules.find((r) => r.key === 'temperatureGreaterThanOrEqualToCelsius')

        if (temperature) {
          setTemperature(temperature.value)
        }

        setThreshold(AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE)

        setTemperatureOperator('>=')

        setWeatherForecastThresholdType(AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE)
      }

      if (rules.find((r) => r.key === 'temperatureLessThanOrEqualToCelsius')) {
        const temperature = rules.find((r) => r.key === 'temperatureLessThanOrEqualToCelsius')

        if (temperature) {
          setTemperature(temperature.value)
        }

        setThreshold(AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE)

        setTemperatureOperator('<=')

        setWeatherForecastThresholdType(AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE)
      }
    } catch (err) {}
  }, [])

  // Get Wind error
  let windError: null | string | boolean = null
  let windValidateStatus = ''
  let windHelp: string | boolean = ''

  if (threshold === AlertsSettings.weatherForecastAlertThresholdItems.WIND) {
    windError = AlertsValidators.wind({ wind })

    if (windError && triedToSubmit) {
      windValidateStatus = 'error'

      windHelp = helpTextErrorHelper(windError)
    }
  }

  // Get temperature error
  let temperatureError: null | string | boolean = null
  let temperatureValidateStatus = ''
  let temperatureHelp: string | boolean = ''

  if (threshold === AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE) {
    const temperatureError = AlertsValidators.temperature({ temperature })

    if (temperatureError && triedToSubmit) {
      temperatureValidateStatus = 'error'

      temperatureHelp = helpTextErrorHelper(temperatureError) || false
    }
  }

  const errorStyle = getErrorStyle()

  useEffect(() => {
    setThresholdIsValid(!windError && !temperatureError)
  }, [windError, temperatureError])

  const timeSelectOptions = Array.from({ length: 24 }, (_, i) => {
    let value = i.toString()

    if (value.length === 1) value = `0${value}`

    return { value, label: translate.dates.format(moment.tz().startOf('year').add(i, 'hours'), 'h:mm a') }
  })

  return (
    <>
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="Send alerts at <selectInput/> to 7 days in the future when the following conditions are met"
          c={{
            selectInput: (
              <TranslateComponentWrapper>
                <Select
                  css={{ width: 125, margin: '0 5px' }}
                  onChange={(value) => {
                    setHourOfDay(value)

                    updateAlertRules({ rules, setRules, rulesToChange: { hourOfDay: { value } } })
                  }}
                  data={timeSelectOptions}
                  value={typeof hourOfDay === 'string' ? hourOfDay : null}
                />
                <div />
              </TranslateComponentWrapper>
            ),
          }}
        />
      </TranslatePhraseWrapper>
      <MantineRadio.Group
        value={threshold as string}
        onChange={(val) => {
          setThreshold(val as WeatherForecastType)

          setWeatherForecastThresholdType(val as WeatherForecastType)

          setWind(null)

          setTemperature(null)

          setTemperatureOperator('>=')

          // Using rulesToInitialize to delete all existing rules
          const rulesToInitializeClone: Record<string, Partial<Rule> | null> = { ...rulesToInitialize }

          // Rule always has hasRasin: true when user chooses Rain threshold
          if (val === AlertsSettings.weatherForecastAlertThresholdItems.RAIN)
            rulesToInitializeClone.hasRain = { value: true, operator: '=' }

          // Rule always has includeAllDevices and measurementHeights when user chooses Temperature threshold
          if (val === AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE) {
            rulesToInitializeClone.includeAllDevices = { value: false, operator: '=' }

            rulesToInitializeClone.measurementHeights = { value: ['aboveCanopyTemperature'], operator: '=' }
          }

          updateAlertRules({
            rules,
            setRules,
            rulesToChange: rulesToInitializeClone,
          })
        }}
      >
        {/**WIND */}
        <div css={{ marginTop: 20 }}>
          <Radio label={translate.phrases.banyanApp('Wind')} css={{ fontWeight: 500 }} value={'wind'} />
          <div
            css={{
              alignItems: 'center',
              display: 'flex',
              marginTop: 10,
              ...fadeInOutStylesMaker(threshold === AlertsSettings.weatherForecastAlertThresholdItems.WIND),
            }}
          >
            <TranslatePhraseWrapper>
              <translate.Phrases.banyanApp
                k="Send alert when wind speed is forecast to be greater than or equal to <numberInput/> {{windUnit}}"
                c={{
                  numberInput: (
                    <TranslateComponentWrapper>
                      <NumberInput
                        css={{ width: 80, marginLeft: 5, marginRight: 5 }}
                        onChange={(val) => {
                          setWind(val)

                          if (threshold === AlertsSettings.weatherForecastAlertThresholdItems.WIND) {
                            updateAlertRules({
                              rules,
                              setRules,
                              rulesToChange: { windGreaterThanOrEqualToKph: { operator: '>=', value: val } },
                            })
                          }
                        }}
                        type="number"
                        classNames={{ input: windValidateStatus ? errorStyle : undefined }}
                        value={isNumberOrNumericString(wind) ? Number(wind) : ''}
                        precision={10}
                        removeTrailingZeros={true}
                      />
                      <div />
                    </TranslateComponentWrapper>
                  ),
                }}
                v={{ windUnit }}
              />
            </TranslatePhraseWrapper>
          </div>
          {windHelp && <ErrorTextWrapper>{windHelp}</ErrorTextWrapper>}
        </div>
        {/**RAIN */}
        <div css={{ marginTop: 20, marginBottom: 20 }}>
          <Radio label={translate.phrases.banyanApp('Rain')} css={{ fontWeight: 500 }} value={'rain'} />
          <div
            css={{
              alignItems: 'center',
              display: 'flex',
              marginTop: 10,
              ...fadeInOutStylesMaker(threshold === AlertsSettings.weatherForecastAlertThresholdItems.RAIN),
            }}
          >
            <TranslatePhraseWrapper>
              <div>
                {translate.phrases.banyanApp('Send alert when rain is forecasted within the next 7 days.')}
              </div>
            </TranslatePhraseWrapper>
          </div>
        </div>
        {/**TEMPERATURE */}
        <div>
          <Radio
            label={translate.phrases.banyanApp('Temperature')}
            css={{ fontWeight: 500 }}
            value="temperature"
          />
          <div
            css={{
              alignItems: 'center',
              display: 'flex',
              marginTop: 10,
              ...fadeInOutStylesMaker(
                threshold === AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE,
              ),
            }}
          >
            <TranslatePhraseWrapper>
              <translate.Phrases.banyanApp
                k="Send alert when temperature is forecast to be <selectInput/> <numberInput/>"
                c={{
                  selectInput: (
                    <TranslateComponentWrapper>
                      <Select
                        css={{ marginLeft: 5, marginRight: 5 }}
                        onChange={(val) => {
                          setTemperatureOperator(val)

                          if (threshold !== AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE)
                            return

                          const ruleAttributes = { value: temperature, operator: val, unit: 'temperature' }
                          const rulesToUpdate: Record<string, Partial<Rule> | null> = {}

                          if (val === '>=') {
                            rulesToUpdate.temperatureGreaterThanOrEqualToCelsius = ruleAttributes

                            rulesToUpdate.temperatureLessThanOrEqualToCelsius = null
                          }

                          if (val === '<=') {
                            rulesToUpdate.temperatureLessThanOrEqualToCelsius = ruleAttributes

                            rulesToUpdate.temperatureGreaterThanOrEqualToCelsius = null
                          }

                          updateAlertRules({
                            rules,
                            setRules,
                            rulesToChange: rulesToUpdate,
                          })
                        }}
                        data={[
                          { value: '>=', label: translate.phrases.banyanApp('greater than or equal to') },
                          { value: '<=', label: translate.phrases.banyanApp('less than or equal to') },
                        ]}
                        value={temperatureOperator}
                      />
                      <div />
                    </TranslateComponentWrapper>
                  ),

                  numberInput: (
                    <TranslateComponentWrapper>
                      <NumberInput
                        value={isNumberOrNumericString(temperature) ? Number(temperature) : ''}
                        css={{ width: 80, marginLeft: 5, marginRight: 5 }}
                        onChange={(val) => {
                          setTemperature(val)

                          if (threshold !== AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE)
                            return

                          const ruleAttributes = {
                            value: val,
                            operator: temperatureOperator,
                            unit: 'temperature',
                          }

                          const rulesToUpdate: Record<string, Partial<Rule> | null> = {}

                          if (temperatureOperator === '>=') {
                            rulesToUpdate.temperatureGreaterThanOrEqualToCelsius = ruleAttributes

                            rulesToUpdate.temperatureLessThanOrEqualToCelsius = null
                          }

                          if (temperatureOperator === '<=') {
                            rulesToUpdate.temperatureLessThanOrEqualToCelsius = ruleAttributes

                            rulesToUpdate.temperatureGreaterThanOrEqualToCelsius = null
                          }

                          updateAlertRules({
                            rules,
                            setRules,
                            rulesToChange: rulesToUpdate,
                          })
                        }}
                        type="number"
                        classNames={{ input: temperatureValidateStatus ? errorStyle : undefined }}
                        precision={10}
                        removeTrailingZeros={true}
                      />
                      <div>{tempUnit}</div>
                    </TranslateComponentWrapper>
                  ),
                }}
              />
            </TranslatePhraseWrapper>
          </div>
          {temperatureHelp && <ErrorTextWrapper>{temperatureHelp}</ErrorTextWrapper>}
        </div>
      </MantineRadio.Group>
    </>
  )
}
