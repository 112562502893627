import type { VV } from '@semios/app-platform-value-type-definitions'
import { moderateDeficitThreshold } from 'App/Map/_utils/moderateDeficitThreshold'
import { isNil } from 'lodash'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { calculateBoundaryPairs } from './calculateBoundaryPairs'
import { normalizeSoilMoistureHeatmap } from './normalizeSoilMoistureHeatmap'

export const makeAwcHeatmapSeries = ({
  data,
}: {
  data?: VV.DomainTypes.Soil.TValuesReturnWithMetaIgnoringKeying['points']['soilMoisture']
}) => {
  const depthUnitSuffix = unitConverter.soilDepth().suffix()

  const moistureZoneBoundaries = {
    extremeDeficit: 0,
    moderateDeficit: 30,
    ideal: 70,
    saturated: 100,
  }

  // Steps are based on midpoints or n + 1 discreet steps minus ends
  const numSteps = Object.keys(moistureZoneBoundaries).length - 1
  // Normalizing the data between 0 and 100 into numSteps + 1 equal bins
  const normalizingRange = 100 / (numSteps + 1)
  const MADDates = data?.[0]?.metadata?.MADDates ?? []

  const soilAwcHeatmapData =
    data

      ?.sort((a, b) => {
        const depthA = a.metadata.depth
        const depthB = b.metadata.depth

        return Number(depthA) - Number(depthB)
      })

      .flatMap((d, yAxisIndexForHeatmap) => {
        const convertedDepth = unitConverter.soilDepth(Number(d.metadata.depth)).value() || 0
        const formattedDepthValue = Math.ceil(convertedDepth)
        const yAxisLabel = `${formattedDepthValue} ${depthUnitSuffix}`

        const mappedData = d.timeseries.map(({ timestamp, value }) => {
          const pointTimestamp = +new Date(timestamp)

          if (isNil(value)) {
            return {
              x: pointTimestamp,
              y: yAxisIndexForHeatmap,
              value: null,
              name: yAxisLabel,
              type: 'heatmap',
            }
          }

          let moisture: number | null = null

          if (typeof value === 'number') {
            moisture = value
          } else if (typeof value === 'object') {
            moisture = value?.average
          }

          if (moisture === null) {
            return {
              x: pointTimestamp,
              y: yAxisIndexForHeatmap,
              value: moisture,
              name: yAxisLabel,
              type: 'heatmap',
            }
          }

          let normalizedMoisture = null
          let idealValue = moistureZoneBoundaries.ideal
          let moderateDeficitValue = moistureZoneBoundaries.moderateDeficit

          d.metadata.MADDates.forEach((m) => {
            if (+new Date(m.startDate) < pointTimestamp) {
              idealValue = Number(m.soilMoisture)

              moderateDeficitValue = idealValue * (1 - moderateDeficitThreshold)
            }
          })

          const updatedZoneBoundaries = {
            ...moistureZoneBoundaries,
            ideal: idealValue,
            moderateDeficit: moderateDeficitValue,
          }

          const midpointPairs: { [key: string]: { high: number; low: number; step: number } } =
            calculateBoundaryPairs(updatedZoneBoundaries)

          if (moisture >= moistureZoneBoundaries.saturated) {
            normalizedMoisture = moistureZoneBoundaries.saturated
          } else if (moisture >= moistureZoneBoundaries.extremeDeficit) {
            Object.values(midpointPairs).forEach(({ high, low, step }) => {
              if (high > (moisture ?? 0) && low <= (moisture ?? 0)) {
                normalizedMoisture = normalizeSoilMoistureHeatmap(
                  moisture ?? 0,
                  low,
                  high,
                  normalizingRange,
                  step,
                )
              }
            })
          } else if (moisture < moistureZoneBoundaries.extremeDeficit) {
            normalizedMoisture = moistureZoneBoundaries.extremeDeficit
          }

          normalizedMoisture = moisture === null ? null : Math.round((normalizedMoisture ?? 0) * 100) / 100

          return {
            x: pointTimestamp,
            y: yAxisIndexForHeatmap,
            value: normalizedMoisture,
            name: yAxisLabel,
            type: 'heatmap',
          }
        })

        return mappedData
      }) ?? []

  return { soilAwcHeatmapData, MADDates }
}
