import type { GridTableColumn, GridTableContentSection } from '../types'
import { Section } from './Section/Section'

export const Bottom = ({
  columns,
  containerWidth,
  content,
  tableWidth,
  widthForLabelColumn,
  widthForValueColumns,
}: {
  columns: GridTableColumn[]
  containerWidth: number
  content: GridTableContentSection[]
  tableWidth: number
  widthForLabelColumn: number
  widthForValueColumns: number
}) => {
  return (
    <div
      css={{
        width: tableWidth,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {content
        .map((section) => {
          return (
            <Section
              columns={columns}
              containerWidth={containerWidth}
              key={section.id}
              section={section}
              widthForLabelColumn={widthForLabelColumn}
              widthForValueColumns={widthForValueColumns}
            />
          )
        })
        .filter(Boolean)}
    </div>
  )
}
