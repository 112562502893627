import { Group, Input, Select } from '@mantine/core'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { OrganizationSelect } from 'components/OrganizationSelect/OrganizationSelect'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { apiFetch } from 'utils/apiFetch'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage'
import { useErrorMessage } from '../../ErrorMessage/useErrorMessage'
import type { TOrganizationKcDateRange, TOrganizationKcModel } from '../types'
import { KcModelTable } from './KcModelTable/KcModelTable'
import { filterValidKcRanges } from './_utils/filterValidKcRanges'
import { validateKcRanges } from './_utils/validateKcRanges'

export type TKcRangeRow = TOrganizationKcDateRange & { id: string }

export const NewOrEditKcModelModal = ({
  title,
  primaryButtonText,
  opened,
  onClose,
  organizationSelectData,
  cropsByOrganization,
  selectedModelToEdit,
  setData,
  data,
}: {
  title: string
  primaryButtonText: string
  opened: boolean
  onClose: () => void
  organizationSelectData: { value: string; label: string }[]
  cropsByOrganization: { [key: string]: number[] }
  selectedModelToEdit: null | TOrganizationKcModel
  setData: React.Dispatch<React.SetStateAction<TOrganizationKcModel[]>>
  data: TOrganizationKcModel[]
}) => {
  const timezone = moment.tz.guess()

  const defaultKcRanges: TKcRangeRow[] = [
    {
      dateRangeStart: null,
      dateRangeEnd: null,
      kc: undefined,
      id: `${+Date.now()}`,
    },
  ]

  const [selectedOrganizationId, setSelectedOrganizationId] = useState<null | number>(null)
  const [modelName, setModelName] = useState<string>('')
  const [selectedCrop, setSelectedCrop] = useState<string | null>(null)
  const [kcRanges, setKcRanges] = useState<TKcRangeRow[]>(defaultKcRanges)
  const { showErrorMessage, triggerErrorMessage, onCloseButtonPress } = useErrorMessage()

  const clearAllState = () => {
    setKcRanges(defaultKcRanges)

    setSelectedOrganizationId(null)

    setModelName('')

    setSelectedCrop(null)
  }

  useEffect(() => {
    if (selectedModelToEdit) {
      setSelectedOrganizationId(selectedModelToEdit.organizationId)

      setModelName(selectedModelToEdit.modelName)

      setSelectedCrop(String(selectedModelToEdit.cropId))

      const newKcRanges = selectedModelToEdit.dateRanges.map((range, i) => {
        return {
          dateRangeStart: range.dateRangeStart,
          dateRangeEnd: range.dateRangeEnd,
          kc: range.kc,
          id: `${+Date.now()}-${i}`,
        }
      })

      setKcRanges(newKcRanges)
    }
  }, [selectedModelToEdit])

  const selectedOrganizationCropsData = selectedOrganizationId
    ? cropsByOrganization[selectedOrganizationId].map((cropId) => ({
        value: String(cropId),
        label: getCropNameFromId(cropId),
      }))
    : []

  const filteredKcRanges = filterValidKcRanges(kcRanges)

  return (
    <ModalDrawer
      title={title}
      primaryButtonOnPress={async () => {
        if (!selectedOrganizationId) return

        // check if group name is blank
        if (!modelName || modelName.trim() === '') {
          triggerErrorMessage(
            translate.phrases.validation('{{label}} is required', {
              label: translate.phrases.banyanApp('Model Name'),
            }),
          )

          return
        }

        // check if model name already exists on org
        // TODO: this should be done on the server
        const modelsForOrganization = data
          .filter((model) => {
            return model.organizationId === Number(selectedOrganizationId)
          })
          // filter out current model if editing
          .filter((model) => {
            return model.modelId !== selectedModelToEdit?.modelId
          })

        const modelNameExists = modelsForOrganization.some((model) => {
          return model.modelName.toLowerCase() === modelName.toLowerCase()
        })

        if (modelNameExists) {
          triggerErrorMessage(
            translate.phrases.validation('{{label}} already exists', {
              label: modelName,
            }),
          )

          return
        }

        try {
          const sortedKcRanges = kcRanges.sort((a, b) =>
            moment.tz(a.dateRangeStart, timezone).diff(moment.tz(b.dateRangeStart, timezone)),
          )

          const validatedKcRanges = validateKcRanges(sortedKcRanges, timezone, triggerErrorMessage)

          if (!validatedKcRanges) return

          const queryBody = filteredKcRanges
            .sort((a, b) => moment.tz(a.dateRangeStart, timezone).diff(moment.tz(b.dateRangeStart, timezone)))
            .map((kcRange) => {
              return {
                dateRangeStart: kcRange.dateRangeStart,
                dateRangeEnd: kcRange.dateRangeEnd,
                kc: kcRange.kc,
                cropId: Number(selectedCrop),
                organizationId: Number(selectedOrganizationId),
                modelName,
                modelId: selectedModelToEdit?.modelId ?? null,
              }
            })

          const response = await apiFetch({
            url: '/field-asset-settings-set',
            body: { kcModelUpsert: queryBody },
          })

          if (response?.kcModelUpsert && Array.isArray(response.kcModelUpsert)) {
            const responseArray = response.kcModelUpsert

            setData((prev) => {
              if (selectedModelToEdit) {
                return prev.map((model) => {
                  if (model.modelId === selectedModelToEdit.modelId) {
                    return {
                      ...selectedModelToEdit,
                      dateRanges: filteredKcRanges.map((range) => {
                        return {
                          dateRangeStart: range.dateRangeStart,
                          dateRangeEnd: range.dateRangeEnd,
                          kc: range.kc,
                        }
                      }),
                      modelName,
                    }
                  }

                  return model
                })
              } else {
                // add new model
                return [
                  ...prev,
                  {
                    modelId: responseArray?.[0]?.modelId,
                    cropId: Number(selectedCrop),
                    dateRanges: filteredKcRanges.map((range) => {
                      return {
                        dateRangeStart: range.dateRangeStart,
                        dateRangeEnd: range.dateRangeEnd,
                        kc: range.kc,
                      }
                    }),
                    organizationId: Number(selectedOrganizationId),
                    modelName,
                    modelType: 'ORGANIZATION_KC_MODEL',
                  },
                ]
              }
            })

            clearAllState()

            setModelName('')

            onClose()
          } else {
            triggerErrorMessage(
              translate.phrases.validation('{{label}} could not be created', {
                label: translate.phrases.banyanApp('Kc Model'),
              }),
            )
          }
        } catch (e) {
          triggerErrorMessage(
            translate.phrases.validation('{{label}} could not be created', {
              label: translate.phrases.banyanApp('Kc Model'),
            }),
          )
        }
      }}
      primaryButtonText={primaryButtonText}
      primaryButtonDisabled={
        !modelName || !selectedOrganizationId || !selectedCrop || filteredKcRanges.length === 0
      }
      opened={opened}
      onClose={() => {
        clearAllState()

        onClose()
      }}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
    >
      <div css={{ margin: 30 }}>
        <ErrorMessage
          message={showErrorMessage}
          onCloseButtonPress={onCloseButtonPress}
          showErrorMessage={!!showErrorMessage}
        />
        <div css={{ width: '100%', marginBottom: 20 }}>
          <div css={{ marginBottom: 10 }}>{translate.phrases.banyanApp('Model Name')}</div>
          <Input
            name="searchHack" // this is a hack to make an icon stop appearing on safari
            autoComplete="off"
            value={modelName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModelName(e.target.value)}
            maxLength={40}
            spellCheck={false}
          />
        </div>
        <Group grow css={{ width: '100%' }}>
          <div>
            <div css={{ marginBottom: 10 }}>{translate.phrases.banyanApp('Organization')}</div>
            <OrganizationSelect
              disabled={!!selectedModelToEdit}
              data={organizationSelectData}
              onChange={(val) => {
                setSelectedOrganizationId(val)

                if (
                  !selectedOrganizationCropsData.some((crop) => {
                    return crop.value === String(selectedCrop)
                  })
                ) {
                  setSelectedCrop(null)
                }
              }}
              selectedOrganizationId={selectedOrganizationId}
            />
          </div>
          <div>
            <div css={{ marginBottom: 10 }}>{translate.phrases.banyanApp('Crop')}</div>
            <Select
              autoComplete="off"
              allowDeselect={false}
              disabled={!selectedOrganizationCropsData.length || !!selectedModelToEdit}
              data={selectedOrganizationCropsData}
              onChange={setSelectedCrop}
              value={selectedCrop}
              placeholder={translate.phrases.banyanApp('Select Crop')}
              styles={{
                ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
                dropdown: { width: 220, marginTop: -7 },
              }}
            />
          </div>
        </Group>
        <KcModelTable kcRanges={kcRanges} setKcRanges={setKcRanges} timezone={timezone} />
      </div>
    </ModalDrawer>
  )
}
