import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { Button } from 'components/Button/Button'
import { IconPower } from 'components/icons/IconPower'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { smallStore } from 'stores/smallStore'
import { apiFetch } from 'utils/apiFetch'
import { checkAuthorization } from 'utils/checkAuthorization'

export const WindMachineButtons = ({
  windMachineDetailsDataObject,
  propertyId,
  lngLat,
}: {
  windMachineDetailsDataObject: routes.WindMachine.Response
  propertyId: number
  lngLat: TFieldAssetKeyTypes.TLngLat
}) => {
  const [windMachineStoppedAt, setWindMachineStoppedAt] = useState<number>()

  const { selectedProperty } = selectedFieldAssetsStore.useSelector((s) => ({
    selectedProperty: s.property,
  }))

  const windMachineDetails =
    windMachineDetailsDataObject?.windMachineDetails?.[propertyId.toString()]?.windMachineDetails?.[0]

  const handleManualStop = async () => {
    try {
      const request = await apiFetch({
        url: routes.WindMachine.path,
        body: {
          windMachineSettings: {
            lngLats: [lngLat],
            settings: {
              stop: true,
            },
          },
        },
      })

      if (!isNil(Object.keys(request?.windMachineSettings || []).length))
        setWindMachineStoppedAt(() => +new Date())
    } catch (error) {
      return
    }
  }

  const isRunning = windMachineDetails?.equipmentStatus?.running
  const dataDate = windMachineDetails?.equipmentStatus?.date

  let offButtonDisabled = true

  if (!isNil(dataDate)) {
    offButtonDisabled = isNil(windMachineStoppedAt)
      ? false
      : (offButtonDisabled = +new Date(dataDate) < +new Date(windMachineStoppedAt))
  }

  return (
    <>
      {checkAuthorization({
        permission: 'PUBLISH_FROST_FAN_OPERATIONAL_DEVICE_SETTINGS',
        entity: Number(selectedProperty),
      }) ? (
        <div css={{ display: 'flex' }}>
          <Button
            variant="secondary"
            type="button"
            css={{ width: '100%', fontSize: '16px', minHeight: '40px' }}
            onClick={() =>
              smallStore.setState((s) => ({
                ...s,
                showWindMachineViewDetails: true,
              }))
            }
          >
            <p>{translate.phrases.banyanApp('View Controls')}</p>
          </Button>
          {isRunning === 1 ? (
            <Button
              css={{
                'width': '100%',
                'marginLeft': '10px',
                'minHeight': '40px',
                'color': colors.white,
                'background': colors.red,
                'border': `2px solid ${colors.red}`,
                '&:hover': {
                  background: `${colors.white} !important`,
                  color: colors.red,
                },
              }}
              onClick={handleManualStop}
              disabled={offButtonDisabled}
            >
              <p css={{ display: 'flex' }}>
                <span css={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                  <IconPower />
                </span>
                {translate.phrases.banyanApp('Turn Fan Off')}
              </p>
            </Button>
          ) : null}
        </div>
      ) : null}
    </>
  )
}
