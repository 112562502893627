import { IconCloud } from 'components/icons/IconCloud'
import { IconFern } from 'components/icons/IconFern'
import { IconInsect } from 'components/icons/IconInsect'
import { IconServiceCenter } from 'components/icons/IconServiceCenter'
import { IconSloshingWaterInWaterDrop } from 'components/icons/IconSloshingWaterInWaterDrop'
import { IconStethoscope } from 'components/icons/IconStethoscope'
import { translate } from 'i18n/i18n'
import type { TSection } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { sortByKey } from 'utils/sortByKey'
import type { TNavigationKey, TNavigationMenuItem } from './generateFlatNavigationList'
import { generateFlatNavigationList } from './generateFlatNavigationList'
import { sortSections } from './generateNavigationMenu'

const sectionToIconMap: Record<TSection, React.ReactNode> = {
  'crop-development': <IconFern />,
  'diseases': <IconStethoscope />,
  'environment': <IconCloud />,
  'insects': <IconInsect />,
  'equipment': <IconServiceCenter />,
  'sprays': <IconCloud />,
  'water-management': <IconSloshingWaterInWaterDrop />,
}

export const makePrettySectionName = (sectionName: TSection) => {
  const prettierNames: Record<TSection, string> = {
    'crop-development': translate.phrases.banyanApp('Crop Development'),
    'diseases': translate.phrases.banyanApp('Diseases'),
    'environment': translate.phrases.banyanApp('Environment'),
    'insects': translate.phrases.banyanApp('Pests'),
    'equipment': translate.phrases.banyanApp('Equipment'),
    'sprays': translate.phrases.banyanApp('Sprays'),
    'water-management': translate.phrases.banyanApp('Water Management'),
  }

  return prettierNames[sectionName]
}

export const generateNavigationMenuGroupedBySection = () => {
  const sections: Partial<
    Record<
      TSection,
      {
        childrenObject: Partial<Record<TNavigationKey, TNavigationMenuItem>>
        icon: React.ReactNode
        key: TSection
        label?: React.ReactNode
        translatedTitle: string
      }
    >
  > = {}

  Object.entries(generateFlatNavigationList())
    .sort(([, a], [, b]) => sortByKey('translatedTitle')(a, b))
    .forEach(([navigationKey, navigationItem]) => {
      const { section } = navigationItem

      sections[section] = sections[section] ?? {
        childrenObject: {},
        key: section,
        icon: sectionToIconMap[section],
        translatedTitle: makePrettySectionName(section),
      }

      if (sections[section]) {
        // @ts-ignore TODO: how do I type this?
        sections[section as TSection].childrenObject[navigationKey as TNavigationKey] = navigationItem
      }
    })

  return Object.values(sections).sort((a, b) => sortSections(a.key, b.key))
}
