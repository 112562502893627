import type { BarcodeScannedEvent, ScanErrorEvent } from '@capacitor-mlkit/barcode-scanning'
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning'
import { App } from '@capacitor/app'
import type { PluginListenerHandle } from '@capacitor/core'
import { NarrowHeader } from 'components/ModalDrawer/NarrowHeader/NarrowHeader'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { init } from 'stores/_utils/simple-store'
import { Crosshairs } from './Crosshairs/Crosshairs'
import { TorchSwitch } from './TorchSwitch/TorchSwitch'

const store = init<QrCodeScannerProps>({ isScanning: false, scanResult: null })

interface QrCodeScannerProps {
  isScanning: boolean
  scanResult: ScanResult | null
}

const startScan = async (callback: (result: ScanErrorEvent | BarcodeScannedEvent) => void) => {
  await BarcodeScanner.addListener('barcodeScanned', (result: BarcodeScannedEvent) => {
    callback(result)
  })

  await BarcodeScanner.addListener('scanError', (result: ScanErrorEvent) => {
    callback(result)
  })

  const options = {
    // formats: [BarcodeFormat.QrCode, BarcodeFormat.DataMatrix] //performance improvement
  }

  await BarcodeScanner.startScan(options)
}

const stopScan = async () => {
  await BarcodeScanner.removeAllListeners()

  await BarcodeScanner.stopScan()
}

const scanSingleBarcode = async () => {
  const res = await new Promise((resolve) => {
    startScan((result) => {
      resolve(result)
    })
  })

  await stopScan()

  return {
    value: (res as BarcodeScannedEvent).barcode.displayValue,
    error: (res as ScanErrorEvent).message,
  }
}

const isQrSupported = async () => {
  try {
    const { supported } = await BarcodeScanner.isSupported()

    return supported
  } catch (e) {
    return false
  }
}

const isTorchAvailable = async () => {
  const { available } = await BarcodeScanner.isTorchAvailable()

  return available
}

const isTorchEnabled = async () => {
  const { enabled } = await BarcodeScanner.isTorchEnabled()

  return enabled
}

const openSettings = async () => {
  await BarcodeScanner.openSettings()
}

const checkPermissions = async () => {
  const { camera } = await BarcodeScanner.checkPermissions()

  return camera
}

const requestPermissions = async () => {
  const { camera } = await BarcodeScanner.requestPermissions()

  return camera
}

const Overlay = () => {
  const settings = store.useSelector((settings) => settings)

  return settings.isScanning ? <Scanner /> : null
}

//When the scanner is called, it will start scanning for QR codes
const Scanner = () => {
  const [torchAvailable, setTorchAvailable] = useState<boolean>(false)
  const [torchEnabled, setTorchEnabled] = useState<boolean>(false)

  const onScan = (arg: ScanResult) => {
    store.setState((state) => ({ ...state, isScanning: false, scanResult: arg }))
  }

  const onError = async (reason: string) => {
    onScan({ status: 'error', value: reason })
  }

  const onCancel = async () => {
    try {
      await stopScan()
    } catch (e) {}

    onScan({ status: 'cancelled', value: '' })
  }

  const toggleTorch = async () => {
    await BarcodeScanner.toggleTorch()

    setTorchEnabled(await isTorchEnabled())
  }

  useEffect(() => {
    let backpressHandler: PluginListenerHandle | null

    async function init() {
      backpressHandler = await App.addListener('backButton', async () => {
        await onCancel()
      })
    }

    init()

    return () => {
      backpressHandler?.remove()
    }
  }, [])

  useEffect(() => {
    const tryToScanSingleBarcode = async () => {
      if (!(await isQrSupported())) {
        await onError(translate.phrases.placeholder('Not supported'))

        return
      }

      if ((await checkPermissions()) !== 'granted') {
        if ((await requestPermissions()) !== 'granted') {
          const openSetting = confirm(
            translate.phrases.placeholder('QR Scanner requires camera permission. Open settings?'),
          )

          if (openSetting) {
            openSettings()
          }

          await onError(translate.phrases.placeholder('Permission not granted'))

          return
        }
      }

      setTorchAvailable(await isTorchAvailable())

      setTorchEnabled(await isTorchEnabled())

      const result = await scanSingleBarcode()

      onScan({ status: 'success', value: result.value })
    }

    tryToScanSingleBarcode()

    return () => {
      onCancel()
    }
  }, [])

  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1000,
      }}
    >
      <div
        css={{
          position: 'relative',
          height: '100vh',
          width: '100vw',
        }}
      >
        <NarrowHeader
          title={translate.phrases.placeholder('Scan QR Code')}
          onClose={() => {
            onCancel()
          }}
        />
        {torchAvailable && (
          <TorchSwitch
            style={{
              position: 'absolute',
              marginTop: '20px',
              right: '20px',
            }}
            checked={torchEnabled}
            onChecked={() => {
              toggleTorch()
            }}
          />
        )}
      </div>
      <div
        css={{
          top: '50vh',
          left: '50vw',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          color: 'white',
          fontSize: 240,
        }}
      >
        <Crosshairs />
      </div>
    </div>
  )
}

type ScanResult = {
  status: 'success' | 'error' | 'cancelled'
  value: string
}

const QrCodeScanner = {
  startScan: () => {
    store.setState(() => ({ isScanning: true, scanResult: null }))
  },
  useScanResult: () => {
    return store.useSelector((state) => state.scanResult)
  },
  useIsScanning: () => {
    return store.useSelector((state) => state.isScanning)
  },
  isSupported: isQrSupported,
  Overlay,
}

export { QrCodeScanner, type ScanResult }
