import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { WindMachineTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/WindMachineTitleChildren/WindMachineTitleChildren'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { StackedChartSection, StackedChartSectionItem } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

type TWindMachineRpmTimeseries = NonNullable<
  VV.DomainTypes.WindMachine.TValuesReturnWithMetaIgnoringKeying['points']['windMachineRPM'][0]['timeseries'][0]
>

type TWindMachineTemperatureTimeseries = NonNullable<
  VV.DomainTypes.WindMachine.TValuesReturnWithMetaIgnoringKeying['points']['windMachineTemperature'][0]['timeseries'][0]
>

type TWindMachineSettingsTimeseries = NonNullable<
  VV.DomainTypes.WindMachine.TValuesReturnWithMetaIgnoringKeying['points']['windMachineSettings'][0]['timeseries'][0]
>

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: 'VIEW_CONTROLLABLE_FROST_FAN_DETAILS' })

export const wmActivity = ({
  data,
  windMachineLngLat,
  defaultValuesRequested,
}: {
  data: routes.Values.Response
  windMachineLngLat: string | null
  defaultValuesRequested: object
}): StackedChartSection => {
  let items = [] as StackedChartSectionItem[]

  const commonReturnItems = {
    title: translate.phrases.banyanApp('Wind Machine Activity'),
    id: 'wind-machine-activity',
    titleChildren: (
      <WindMachineTitleChildren
        valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
        hasControls={true}
      />
    ),
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const { hasExtraRightPadding } = detailsPanelStore.getState()
  const wmdata = data?.points?.[String(windMachineLngLat)]?.values

  if (!hasExtraRightPadding)
    detailsPanelStore.setState((prev) => ({
      ...prev,
      hasExtraRightPadding: true,
    }))

  items.push({
    chartConfig: {
      semiosHighchartsAdditions: {
        id: 'WindMachineActivity',
        firstForecastTimestamp: +new Date(),
      },
      chart: {
        type: 'line',
      },
      tooltip: {
        xDateFormat: '%Z',
      },
      yAxis: [
        {
          opposite: false,
          title: {
            text: unitConverter.windMachineRPM().suffix(),
            offset: 7,
            rotation: 0,
            y: -10,
            align: 'high',
          },
          labels: {
            style: {
              fontSize: '14px',
            },
          },
        },
        {
          opposite: true,
          title: {
            text: unitConverter.windMachineTemperature().suffix(),
            offset: 13,
            rotation: 0,
            y: -10,
            align: 'high',
          },
          labels: {
            style: {
              fontSize: '14px',
            },
          },
        },
      ],
      series: [
        {
          type: 'line',
          id: 'windMachineSpeed',
          name: translate.phrases.banyanApp('Speed'),
          color: colors.awcGreen,
          data: (wmdata?.windMachineRPM?.[0]?.timeseries ?? []).map((d: TWindMachineRpmTimeseries) => {
            return [+moment.tz(d.timestamp, timezone), d.value]
          }),
          yAxis: 0,
          tooltip: {
            valueSuffix: ` ${unitConverter.windMachineRPM().suffix()}`,
          },
        },
        {
          type: 'line',
          id: 'windMachineTemp',
          name: translate.phrases.banyanApp('Air Temperature'),
          data: (wmdata?.windMachineTemperature?.[0]?.timeseries ?? []).map(
            (d: TWindMachineTemperatureTimeseries) => {
              return [
                +moment.tz(d.timestamp, timezone),
                unitConverter.windMachineTemperature(d.value as number).value(),
              ]
            },
          ),
          color: colors.awcOrange,
          yAxis: 1,
          tooltip: {
            valueSuffix: ` ${unitConverter.windMachineTemperature().suffix()}`,
          },
        },
        {
          type: 'line',
          id: 'windMachineStartTemp',
          name: translate.phrases.banyanApp('Start Temperature'),
          data: (wmdata?.windMachineSettings?.[0]?.timeseries ?? []).map(
            (d: TWindMachineSettingsTimeseries) => {
              return [
                +moment.tz(d.timestamp, timezone),
                unitConverter.windMachineTemperature(d.value.tempStart).value(),
              ]
            },
          ),
          color: colors.awcBlue,
          yAxis: 1,
          tooltip: {
            valueSuffix: ` ${unitConverter.windMachineTemperature().suffix()}`,
          },
        },
        {
          type: 'line',
          id: 'windMachineStopTemp',
          name: translate.phrases.banyanApp('Stop Temperature'),
          data: (wmdata?.windMachineSettings?.[0]?.timeseries ?? []).map(
            (d: TWindMachineSettingsTimeseries) => {
              return [
                +moment.tz(d.timestamp, timezone),
                unitConverter.windMachineTemperature(d.value.tempStop).value(),
              ]
            },
          ),
          color: colors.awcPink,
          yAxis: 1,
          tooltip: {
            valueSuffix: ` ${unitConverter.windMachineTemperature().suffix()}`,
          },
        },
      ],
    },
  })

  return {
    ...commonReturnItems,
    items,
  }
}
