import { Select } from '@mantine/core'
import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import { PanelDetailsContext } from 'App/Map/PanelDetails/PanelDetails'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { useContext, useMemo } from 'react'
import { colors } from 'settings/colors'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { makeNameEmitterTypeLabel } from 'utils/makeNameEmitterTypeLabel'
import { mantineSelectSearchFilterProp } from 'utils/mantineSelectSearchFilterProp'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { sortByKey } from 'utils/sortByKey'

type SelectDataItem = {
  value: string
  label: string
  group: string
}

export const DropdownSelectorIrrigationZone = ({
  isPressureSensor = false,
  isExternalZone = false,
}: {
  isPressureSensor?: boolean
  isExternalZone?: boolean
}) => {
  const { selectedIrrigationZoneEmitter } = selectedFieldAssetsStore.useSelector((s) => ({
    selectedIrrigationZoneEmitter: s.irrigationZoneEmitter,
  }))

  const { containerWidth } = useContext(PanelDetailsContext)

  const { properties } = fieldAssetStore.useSelector((s) => ({
    properties: s?.properties ?? [],
  }))

  const data = useMemo<SelectDataItem[]>(() => {
    return Object.values(properties ?? {})
      .sort(sortByKey('propertyName'))
      .flatMap((p: TFieldAssetValueTypes.TProperty) => {
        if (!p.irrigationZoneEmitters) return []

        return Object.entries(p.irrigationZoneEmitters)
          .filter(([, zone]) => {
            const { emitterType } = getIdAndEmitterTypeFromZoneEmitterTypeKey(zone.irrigationZoneEmitterId)

            if (isPressureSensor)
              return !!Object.values(p.points ?? {})?.some(
                (pt) =>
                  pt.configuration.irrigationEmitterTypesAvailable?.includes(emitterType) &&
                  pt.irrigationEmitterZoneIds?.some((ptz) => {
                    return ptz === zone.irrigationZoneEmitterId
                  }),
              )
            else if (isExternalZone) return zone.hasControlProvider
            else return true
          })
          .sort((a, b) => {
            const labelA = mapApiEmitterTypeToLabel(a[1].emitterType)
            const labelB = mapApiEmitterTypeToLabel(b[1].emitterType)

            if (labelA < labelB) return -1

            if (labelA > labelB) return 1

            return 0
          })
          .map(([irrigationZoneId, irrigationZoneEmitter]) => ({
            value: irrigationZoneId,
            label: makeNameEmitterTypeLabel({
              name: irrigationZoneEmitter.name,
              emitterType: irrigationZoneEmitter.emitterType,
            }),

            group: p.propertyName,
            name: irrigationZoneEmitter.name,
          }))
          .sort(sortByKey('name'))
      })
  }, [selectedIrrigationZoneEmitter, properties])

  if (!data.length) return null

  return (
    // can prevent the section from collapsing by passing stopPropagation
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        clearable={false}
        css={{
          width: containerWidth < 600 ? 200 : Math.min(280, Math.round(containerWidth / 3)),
          border: `1px solid ${colors.midnight}`,
          borderRadius: 3,
        }}
        data={data}
        filter={mantineSelectSearchFilterProp}
        onChange={(newSelectedIrrigationZoneEmitter: SharedTypes.TIrrigationZoneEmitterId) => {
          return setSelectedFieldAsset({ irrigationZoneEmitter: newSelectedIrrigationZoneEmitter })
        }}
        placeholder={translate.phrases.banyanApp('Select an Irrigation Zone')}
        searchable
        size="xs"
        spellCheck="false"
        styles={{
          ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
          ...SharedSettings.MANTINE_SELECT_UNDERLINE_SEPARATOR_STYLES,
          dropdown: { marginTop: -6, fontWeight: 'normal' },
          input: { ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input, paddingRight: 28 },
        }}
        value={selectedIrrigationZoneEmitter?.toString()}
        withinPortal
        zIndex={hubConnectorSettings.zIndexForDropdownSelectorInStackemCharts}
      />
    </div>
  )
}
