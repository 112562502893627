/* eslint-disable no-restricted-syntax */
import { getWindMachineActivityTitleAndColor } from 'App/Map/_utils/getWindMachineActivityTitleAndColor'
import type { XrangePointOptionsObject } from 'highcharts'
import { isNil } from 'lodash'
import moment from 'moment-timezone'

const statusMapper = (status: string) => {
  const { color, name } = getWindMachineActivityTitleAndColor(status)

  let pointWidth = 24

  if (status === 'OFF') pointWidth = 10
  else if (status === 'ERROR') pointWidth = 48

  return {
    color,
    name,
    y: 1,
    pointWidth,
  }
}

export const makeIntervals = ({
  timeseries,
}: {
  timeseries?: { timestamp: string; value: { start: string; end: string; value: string } }[]
}): (XrangePointOptionsObject & { value: string })[] => {
  if (isNil(timeseries) || !timeseries.length) return []

  const intervals: (XrangePointOptionsObject & { value: string; pointWidth: number })[] = []

  timeseries.forEach((ts) => {
    const start = +moment.tz(ts.value.start, moment.tz.guess()).format('x') + 1
    const stop = +moment.tz(ts.value.end, moment.tz.guess()).format('x')

    const newItem: typeof intervals[number] = {
      value: ts.value.value,
      x: start,
      x2: stop,
      ...statusMapper(ts.value.value),
    }

    intervals.push(newItem)
  })

  return intervals
}
