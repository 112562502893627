import { Card, LoadingOverlay } from '@mantine/core'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { ConfirmationSettingsModal } from 'components/ConfirmationSettingsModal/ConfirmationSettingsModal'
import { HierarchicalSelection } from 'components/HierarchicalSelection/HierarchicalSelection'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { memo, useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { apiFetch } from 'utils/apiFetch'
import { checkAuthorization } from 'utils/checkAuthorization'
import { ApiResponseError, OfflineRequestError } from 'utils/errorCodes'
import { showNotification } from 'utils/showNotification'
import { sortByKey } from 'utils/sortByKey'
import type { TAdminPermission, TAdminProperty } from '../../../utils/useAdminEntites'
import { CancelSaveButtonGroup } from '../../CancelSaveButtonGroup/CancelSaveButtonGroup'
import { PermissionRow } from '../../PermissionRow/PermissionRow'

type TPropertyWithPermissions = NonNullable<routes.AdminGetPropertyWithPermissions.Response['data']>[number]

export const PermissionsModal = ({
  opened,
  onClose,
  property,
  permissions: permissionsArg,
}: {
  property: TAdminProperty
  permissions: TAdminPermission[]
  opened: boolean
  onClose: (hasChanges?: boolean) => void
}) => {
  const { name, id: propertyId } = property || { name: '', id: 0 }
  const [loading, setIsLoading] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string | undefined>()
  const [showOnlyChecked, setShowOnlyChecked] = useState(false)
  const [permissions, setPermissions] = useState<TAdminPermission[]>(permissionsArg)
  const [showConfirmCancelWithoutSaving, setShowConfirmCancelWithoutSaving] = useState(false)

  const [permissionsToCreate, setPermissionsToCreate] = useState(
    new Set<routes.AdminUpdatePropertyPermissions.Request['permissionsToCreate'][number]>(),
  )

  const [permissionsToDelete, setPermissionsToDelete] = useState(
    new Set<routes.AdminUpdatePropertyPermissions.Request['permissionsToDelete'][number]>(),
  )

  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])

  const [effectivePermissions, setEffectivePermissions] = useState<
    TPropertyWithPermissions['effectivePermissions']
  >([])

  const hasSetPropertyPermissionsPermission = checkAuthorization({
    permission: 'ADMIN_SET_PROPERTY_PERMISSIONS',
    entity: '*',
  })

  const handleResetAndClose = (hasChanges?: boolean) => {
    setSearchString(undefined)

    setShowOnlyChecked(false)

    setPermissionsToCreate(new Set())

    setPermissionsToDelete(new Set())

    onClose(hasChanges)
  }

  const handleClickClose = () => {
    const hasChanges = permissionsToCreate.size > 0 || permissionsToDelete.size > 0

    if (hasChanges) {
      setShowConfirmCancelWithoutSaving(true)
    } else {
      handleResetAndClose()
    }
  }

  useEffect(() => {
    async function loadPropertyData() {
      try {
        setIsLoading(true)

        const result = await apiFetch({
          url: '/admin-get-property-with-permissions',
          body: { propertyId },
          params: { timeout: 180000 },
        })

        if (result?.data) {
          const effectivePermissions = result.data[0]?.effectivePermissions || []

          setEffectivePermissions(effectivePermissions)

          const appliedPermissions = result.data[0]?.permissions || []
          const selectedPermissions = appliedPermissions.map(({ permission_id }) => permission_id)

          setSelectedPermissions(selectedPermissions)
        } else {
          showNotification({
            type: 'error',
            message: translate.phrases.banyanApp('Error: could not load property data'),
          })
        }
      } catch (error) {
        if (error instanceof ApiResponseError && error.statusCode === 403) {
          showNotification({
            type: 'error',
            message: translate.phrases.banyanApp('You do not have permission to view property data.'),
          })
        } else if (!(error instanceof OfflineRequestError)) {
          showNotification({
            type: 'error',
            message: translate.phrases.banyanApp('Error: could not load property data'),
          })
        }
      } finally {
        setIsLoading(false)
      }
    }

    loadPropertyData()
  }, [property])

  useEffect(() => {
    const propertyPermissions = (permissionsArg ?? []).filter((p) => String(p.permissionTypes) === 'PROPERTY')

    let filteredPermissions = arrayOfObjectsSearch<TAdminPermission>(
      propertyPermissions,
      searchString ?? '',
      ['id'],
    )

    if (showOnlyChecked) {
      filteredPermissions = filteredPermissions.filter((p) => selectedPermissions.includes(p.id))
    }

    setPermissions(filteredPermissions)
  }, [searchString, showOnlyChecked, selectedPermissions])

  const modalTitle = translate.phrases.banyanApp('Set Permissions for {{name}} ({{propertyId}})', {
    name,
    propertyId,
  })

  const handleSave = async () => {
    setIsLoading(true)

    await apiFetch({
      url: '/admin-update-property-permissions',
      body: {
        propertyIds: [propertyId],
        permissionsToCreate: Array.from(permissionsToCreate),
        permissionsToDelete: Array.from(permissionsToDelete),
      },
    }).catch(() => {
      showNotification({
        message: translate.phrases.validation('{{label}} could not be updated', {
          label: translate.phrases.banyanApp('Permissions'),
        }),
        type: 'error',
      })
    })

    setIsLoading(false)

    showNotification({
      message: translate.phrases.banyanApp('Permissions successfully updated'),
      type: 'success',
    })

    handleResetAndClose(true)
  }

  return (
    <ModalDrawer
      title={modalTitle}
      opened={opened}
      onClose={handleClickClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 10}
    >
      <LoadingOverlay visible={loading} />
      <div css={{ maxWidth: '100%', width: 1000 }}>
        <Card>
          <h3>{translate.phrases.banyanApp('Attach Permissions to Property')}</h3>

          {!loading && permissions && (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                border: `1px solid ${colors.grey200}`,
                padding: 5,
              }}
            >
              <HierarchicalSelection
                key={property.id}
                searchString={searchString ?? ''}
                setSearchString={setSearchString}
                setShowOnlyChecked={setShowOnlyChecked}
                showOnlyChecked={showOnlyChecked}
                title={translate.phrases.banyanApp('Permissions')}
                width={'100%'}
              >
                {permissions.sort(sortByKey('id')).map((permission) => {
                  return (
                    <PermissionRow
                      key={permission.id}
                      permission={permission}
                      selected={selectedPermissions.includes(permission.id)}
                      hasEditPermission={hasSetPropertyPermissionsPermission}
                      setSelected={(selected) => {
                        if (selected) {
                          setPermissionsToCreate((prev) => new Set(prev).add(permission.id))

                          setPermissionsToDelete((prev) => {
                            const newSet = new Set(prev)

                            newSet.delete(permission.id)

                            return newSet
                          })
                        } else {
                          setPermissionsToCreate((prev) => {
                            const newSet = new Set(prev)

                            newSet.delete(permission.id)

                            return newSet
                          })

                          setPermissionsToDelete((prev) => new Set(prev).add(permission.id))
                        }

                        setSelectedPermissions((prev) => {
                          if (selected) {
                            return [...prev, permission.id]
                          } else {
                            return prev.filter((p) => p !== permission.id)
                          }
                        })
                      }}
                    />
                  )
                })}
              </HierarchicalSelection>
              {hasSetPropertyPermissionsPermission && (
                <CancelSaveButtonGroup onCancel={handleClickClose} onSave={handleSave} />
              )}
            </div>
          )}
        </Card>

        <Card css={{ marginTop: 24 }}>
          <h3>{translate.phrases.banyanApp('Effective Permissions Currently Attached to Property')}</h3>
          {effectivePermissions && (
            <div>
              <span>
                {translate.phrases.banyanApp('Property Permissions ({{count}})', {
                  count: effectivePermissions.length,
                })}
              </span>
              <br />
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                <ul>
                  {effectivePermissions.sort().map(({ permission_id: permissionId, description }) => (
                    <li key={permissionId}>
                      <span css={{ fontWeight: 'bold' }}>{permissionId}</span>
                      {' - '}
                      {description}
                    </li>
                  ))}
                </ul>
              </div>
              <br />
              <br />
            </div>
          )}

          {effectivePermissions.length === 0 && <h4>{translate.phrases.banyanApp('No Permissions')}</h4>}
        </Card>
      </div>
      <ConfirmationSettingsModal
        confirmModalOpened={showConfirmCancelWithoutSaving}
        setConfirmModalOpened={setShowConfirmCancelWithoutSaving}
        handleResetAndClose={() => {
          handleResetAndClose()
        }}
        handleUpdate={handleSave}
      />
    </ModalDrawer>
  )
}

export default memo(PermissionsModal)
