import { apiFetch } from 'utils/apiFetch'

export const serviceCenterServiceIssueResolutionActionsGet = async (nodeIssueTypeId: number) => {
  try {
    const resolutionActions = await apiFetch({
      url: '/service-center-service-issue-resolution-actions-get',
      body: { nodeIssueTypeId },
    })

    return resolutionActions
  } catch (error) {
    throw new Error(`Error fetching list of resolution actions: ${(error as Error).message}`)
  }
}
