import { faShieldAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, LoadingOverlay, Switch, TextInput, Tooltip } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { Authorization } from 'components/Authorization/Authorization'
import { IconEdit } from 'components/icons/IconEdit'
import { IconEmailEnabled } from 'components/icons/IconEmailEnabled'
import { IconInfoLight } from 'components/icons/IconInfoLight'
import { IconTrashCan } from 'components/icons/IconTrashCan'
import { SummaryTable } from 'components/SummaryTable/SummaryTable'
import { getLanguageLabels, translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { apiFetch } from 'utils/apiFetch'
import { checkAuthorization } from 'utils/checkAuthorization'
import { showNotification } from 'utils/showNotification'
import { useSearchInput } from 'utils/useSearchInput'
import type {
  TAdminPermission,
  TAdminProperty,
  TAdminUser,
  TOrgAdmin,
  TRole,
} from '../../utils/useAdminEntites'
import { ActionButton } from '../ActionButton/ActionButton'
import { PermissionsModal } from './PermissionsModal/PermissionsModal'
import { ViewEditUserProfile } from './ViewEditUserProfile'

export const Users = ({
  users,
  loading,
  properties,
  roles,
  permissions,
  orgAdmin,
  onRefresh,
}: {
  users: TAdminUser[]
  loading: boolean
  properties: TAdminProperty[]
  roles: TRole[]
  permissions: TAdminPermission[]
  orgAdmin?: TOrgAdmin
  onRefresh: () => void
}) => {
  const languageLabels: Record<string, string> = getLanguageLabels()
  const PAGE_SIZE = 25
  const [userProfileFormOpen, setUserProfileFormOpen] = useState(false)
  const [userRolePermissionFormOpen, setUserRolePermissionFormOpen] = useState(false)
  const [includeStatisticsColumns, setIncludeStatisticsColumns] = useState(false)
  const [selectedUser, setSelectedUser] = useState<TAdminUser | null>(null)
  const [paginationResetKey, setPaginationResetKey] = useState(`${Math.random()}`)

  const { SearchInput, searchString } = useSearchInput({
    placeholder: 'Search for a user',
  })

  // TODO: add created by column (show first name, last initial)
  // TODO: add statistics columns (if user wants to see them)
  // Numbers: Alerts, Field Notes, API Requests,
  // Date: Last Web App Usage, Last Mobile App Usage
  // X: Field Note Admin?

  const statisticsColumns = [
    {
      key: 'numberOfAPICallsHub',
      dataIndex: 'numberOfAPICallsHub',
      title: (
        <React.Fragment>
          {translate.phrases.banyanApp('Hub API Requests')}&nbsp;&nbsp;
          <Tooltip
            label={translate.phrases.banyanApp(
              "The number of requests to Semios' servers from this app made by this user",
            )}
            withArrow
            position={'right'}
          >
            <span css={{ cursor: 'help' }}>
              <IconInfoLight />
            </span>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'numberOfAPICallsLegacyWeb',
      dataIndex: 'numberOfAPICallsLegacyWeb',
      title: (
        <React.Fragment>
          {translate.phrases.banyanApp('Legacy Web API Requests')}&nbsp;&nbsp;
          <Tooltip
            label={translate.phrases.banyanApp(
              "The number of requests to Semios' servers from legacy web app made by this user",
            )}
            withArrow
            position={'right'}
          >
            <span css={{ cursor: 'help' }}>
              <IconInfoLight />
            </span>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'numberOfAPICallsLegacyMobile',
      dataIndex: 'numberOfAPICallsLegacyMobile',
      title: (
        <React.Fragment>
          {translate.phrases.banyanApp('Legacy Mobile API Requests')}&nbsp;&nbsp;
          <Tooltip
            label={translate.phrases.banyanApp(
              "The number of requests to Semios' servers from legacy mobile app made by this user",
            )}
            withArrow
            position={'right'}
          >
            <span css={{ cursor: 'help' }}>
              <IconInfoLight />
            </span>
          </Tooltip>
        </React.Fragment>
      ),
    },
    {
      key: 'lastUsageHub',
      dataIndex: 'lastUsageHub',
      title: translate.phrases.banyanApp('Last Hub App Usage'),
      render: (value: string | null) =>
        value ? translate.dates.format(moment.tz(value, moment.tz.guess()), 'MMM D, YYYY h:mm a') : null,
    },
    {
      key: 'lastUsageLegacyWeb',
      dataIndex: 'lastUsageLegacyWeb',
      title: translate.phrases.banyanApp('Last Legacy Web App Usage'),
      render: (value: string | null) =>
        value ? translate.dates.format(moment.tz(value, moment.tz.guess()), 'MMM D, YYYY h:mm a') : null,
    },
    {
      key: 'lastUsageLegacyMobile',
      dataIndex: 'lastUsageLegacyMobile',
      title: translate.phrases.banyanApp('Last Legacy Mobile App Usage'),
      render: (value: string | null) =>
        value ? translate.dates.format(moment.tz(value, moment.tz.guess()), 'MMM D, YYYY h:mm a') : null,
    },
  ]

  const deactivateUser = async (record: TAdminUser) => {
    await apiFetch({
      url: '/admin-deactivate-user',
      body: {
        userId: record.id,
      },
    })
      .then((result) => {
        if (result) {
          if (result.errors.length) {
            // TODO: specific errors
            showNotification({
              message: translate.phrases.validation('{{label}} could not be deactivated', {
                label: translate.phrases.banyanApp('User'),
              }),
              type: 'error',
            })
          } else {
            showNotification({
              message: translate.phrases.validation('{{label}} successfully deactivated', {
                label: translate.phrases.banyanApp('User'),
              }),
              type: 'success',
            })
          }

          if (result.data) {
            return result.data
          }
        }

        return result
      })
      .catch(() => {
        showNotification({
          message: translate.phrases.validation('{{label}} could not be deactivated', {
            label: translate.phrases.banyanApp('User'),
          }),
          type: 'error',
        })
      })
      .finally(() => {
        onRefresh()
      })
  }

  const reinviteUser = async (record: TAdminUser) => {
    await apiFetch({
      url: '/admin-reinvite-user',
      body: {
        userId: record.id,
      },
    })
      .then((result) => {
        if (result) {
          if (result.errors.length) {
            // TODO: specific errors
            showNotification({
              message: translate.phrases.validation('{{label}} could not be reinvited', {
                label: translate.phrases.banyanApp('User'),
              }),
              type: 'error',
            })
          } else {
            showNotification({
              message: translate.phrases.validation('{{label}} successfully reinvited', {
                label: translate.phrases.banyanApp('User'),
              }),
              type: 'success',
            })
          }
        }

        if (result.data) {
          return result.data
        }

        return result
      })
      .catch(() => {
        showNotification({
          message: translate.phrases.validation('{{label}} could not be reinvited', {
            label: translate.phrases.banyanApp('User'),
          }),
          type: 'error',
        })
      })
      .finally(() => {
        onRefresh()
      })
  }

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: translate.phrases.banyanApp('ID'),
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: translate.phrases.banyanApp('Email'),
    },
    {
      key: 'firstName',
      dataIndex: 'firstName',
      title: translate.phrases.banyanApp('First Name'),
      width: 110,
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      title: translate.phrases.banyanApp('Last Name'),
      width: 110,
    },
    {
      key: 'accountType',
      dataIndex: 'accountType',
      title: translate.phrases.banyanApp('Account Type'),
    },
    ...(includeStatisticsColumns ? statisticsColumns : []),
    {
      key: 'language',
      dataIndex: 'language',
      title: translate.phrases.banyanApp('Language'),
      width: 110,
      render: (value: string) => (
        <>{Object.keys(languageLabels).includes(String(value)) ? languageLabels[String(value)] : value}</>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: translate.phrases.banyanApp('Status'),
    },
    {
      title: translate.phrases.banyanApp('Created By'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (value: TAdminUser['createdBy']) => {
        const { firstName, lastName } = value

        let returner = translate.phrases.banyanApp('Unknown')

        if (firstName || lastName) returner = `${firstName || ''} ${lastName[0] || ''}`

        return returner
      },
      width: 120,
    },
    {
      key: 'action',
      dataIndex: 'action',
      title: translate.phrases.banyanApp('Actions'),
      preventSort: true,
      render: (_: unknown, record: TAdminUser) => {
        const isDeactivated = record.status === 'DEACTIVATED'

        const userHasEditPermission =
          checkAuthorization({
            permission: 'ADMIN_EDIT_USERS',
            entity: '*',
          }) ||
          checkAuthorization({
            permission: 'ADMIN_EDIT_USER',
            entity: record.id,
          })

        const userHasSetRolesOrPermissions =
          checkAuthorization([
            {
              permission: 'ADMIN_SET_USERS_PERMISSIONS',
              entity: '*',
            },
            {
              permission: 'ADMIN_SET_USERS_ROLES',
              entity: '*',
            },
          ]) || checkAuthorization({ permission: 'ADMIN_EDIT_USER', entity: record.id })

        const userHasDeactivatePermission = userHasEditPermission

        const userHasCreatePermission =
          checkAuthorization({ permission: 'ADMIN_CREATE_USERS', entity: '*' }) ||
          Boolean(orgAdmin?.seatsRemaining)

        const menuItems = isDeactivated
          ? [
              {
                children: <em>{translate.phrases.banyanApp('No Actions Possible (Deactivated User)')}</em>,
              },
            ]
          : [
              ...(userHasCreatePermission && record.status === 'INVITED'
                ? [
                    {
                      icon: <IconEmailEnabled />,
                      children: (
                        <span onClick={async () => reinviteUser(record)}>
                          {translate.phrases.banyanApp('Resend Invite')}
                        </span>
                      ),
                    },
                  ]
                : []),
              ...(userHasEditPermission
                ? [
                    {
                      icon: <IconEdit />,
                      children: (
                        <span
                          onClick={() => {
                            setSelectedUser(record)

                            setUserProfileFormOpen(true)
                          }}
                        >
                          {translate.phrases.banyanApp('View/Edit Profile')}
                        </span>
                      ),
                    },
                  ]
                : []),
              ...(userHasSetRolesOrPermissions
                ? [
                    {
                      icon: <FontAwesomeIcon icon={faShieldAlt} />,
                      children: (
                        <span
                          onClick={() => {
                            setSelectedUser(record)

                            setUserRolePermissionFormOpen(true)
                          }}
                        >
                          {translate.phrases.banyanApp('View/Edit Roles/Permissions')}
                        </span>
                      ),
                    },
                  ]
                : []),
              ...(userHasDeactivatePermission
                ? [
                    {
                      icon: <IconTrashCan />,
                      children: (
                        <span
                          onClick={() => {
                            openConfirmModal({
                              styles: {
                                root: {
                                  padding: 10,
                                },
                              },
                              title: translate.phrases.banyanApp(
                                'Enter {{recordName}} to confirm account deactivation',
                                { recordName: `"${record?.firstName} ${record?.lastName}"` },
                              ),
                              labels: {
                                confirm: translate.phrases.banyanApp('Deactivate'),
                                cancel: translate.phrases.banyanApp('Cancel'),
                              },
                              children: <TextInput required />,
                              onCancel: () => null,
                              onConfirm: async () => {
                                const currentUserDeactivationName =
                                  (document.querySelector('input[required]') as HTMLInputElement)?.value || ''

                                const userNameValidation =
                                  `${record.firstName} ${record.lastName}`.trim() ===
                                  currentUserDeactivationName.trim()

                                if (userNameValidation) {
                                  await deactivateUser(record)
                                } else {
                                  showNotification({
                                    message: translate.phrases.placeholder(
                                      'Deactivation name does not match',
                                    ),
                                    type: 'error',
                                  })
                                }
                              },
                            })
                          }}
                        >
                          {translate.phrases.banyanApp('Deactivate')}
                        </span>
                      ),
                    },
                  ]
                : []),
            ]

        return <ActionButton menuItems={menuItems} />
      },
    },
  ]

  const displayedData = arrayOfObjectsSearch(users, searchString, [
    'email',
    'id',
    'firstName',
    'lastName',
    'accountType',
  ])

  useEffect(() => {
    setPaginationResetKey(`${Math.random()}`)
  }, [searchString])

  const canCreateUser =
    checkAuthorization({ permission: 'ADMIN_CREATE_USERS', entity: '*' }) || Boolean(orgAdmin?.seatsRemaining)

  return (
    <>
      <LoadingOverlay visible={loading} />
      {!loading && (
        <>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <div css={{ margin: 10 }}>{SearchInput}</div>
              <div
                css={{
                  fontSize: '14px',
                  marginLeft: 6,
                  whiteSpace: 'nowrap',
                  padding: '6px',
                  display: 'flex',
                }}
              >
                <Switch
                  styles={{
                    label: {
                      color: colors.midnight,
                      fontSize: 13,
                      paddingLeft: '0.5rem',
                    },
                    track: {
                      backgroundColor: includeStatisticsColumns ? `${colors.green} !important` : undefined,
                      borderColor: includeStatisticsColumns ? `${colors.green} !important` : undefined,
                    },
                  }}
                  checked={includeStatisticsColumns}
                  label={translate.phrases.banyanApp('Include statistics columns')}
                  onChange={() => setIncludeStatisticsColumns(!includeStatisticsColumns)}
                />
              </div>
            </div>
            <Authorization requires={canCreateUser}>
              <Button
                variant="filled"
                onClick={() => {
                  setSelectedUser(null)

                  setUserProfileFormOpen(true)
                }}
              >
                <span>{translate.phrases.banyanApp('Create New User')}</span>
              </Button>
            </Authorization>
          </div>
          <SummaryTable
            autoSortColumns={['id', 'email', 'firstName', 'lastName', 'accountType', 'language', 'status']}
            columns={columns}
            data={displayedData}
            pageSize={PAGE_SIZE}
            paginationResetKey={paginationResetKey}
          />
        </>
      )}
      <ViewEditUserProfile
        user={selectedUser}
        orgAdmin={orgAdmin}
        opened={userProfileFormOpen}
        onClose={(hasChanges) => {
          setUserProfileFormOpen(false)

          if (hasChanges) {
            onRefresh()
          }
        }}
      />
      {selectedUser && (
        <PermissionsModal
          user={selectedUser}
          opened={userRolePermissionFormOpen}
          onClose={(hasChanges) => {
            setUserRolePermissionFormOpen(false)

            if (hasChanges) {
              onRefresh()
            }
          }}
          properties={properties}
          roles={roles}
          permissions={permissions}
        />
      )}
    </>
  )
}
