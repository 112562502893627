import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { useApiREST } from 'utils/useApiREST'

export const useWindMachineDetails = ({ lngLat }: { lngLat: TFieldAssetKeyTypes.TLngLat }) => {
  const { loading, data } = useApiREST({
    url: routes.WindMachine.path,
    body: {
      windMachineDetails: {
        lngLats: [lngLat],
      },
    },
    watchers: [lngLat],
    shaper: (data: routes.WindMachine.Response) => data,
  })

  return { detailsWindMachineLoading: loading, detailsWindMachineData: data }
}
