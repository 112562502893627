import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { closeAlmaChat } from 'App/Map/AlmaChat/utils/closeAlmaChat'
import type { MouseEventHandler } from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { apiFetch } from 'utils/apiFetch'
import type { TSelectedLayer } from '../../AlmaContext/AlmaContext'
import { AlmaContext } from '../../AlmaContext/AlmaContext'
import { navigatePerSelection } from './navigatePerSelection'

export const useLlm = (selectedProperty: TProperty) => {
  const { sessionId, selectedLayer, defaultTimeRange, scrollToBottomOfChat } = useContext(AlmaContext)
  const [isLoading, setIsLoading] = useState(true)
  const [llmResponse, setLlmResponse] = useState<routes.AlmaChatLlm.Response | null>(null)

  const confirmationMessage = useMemo(
    () =>
      `You want to view ${selectedLayer?.translatedTitle} at ${selectedProperty?.propertyName} for ${defaultTimeRange.humanText}?`,
    [],
  )

  useEffect(() => {
    apiFetch({
      url: '/alma-chat-llm',
      body: {
        message: selectedLayer?.translatedTitle,
        user_info: {
          default_property: selectedProperty?.propertyId,
        },
        session_id: sessionId as string,
      },
    }).then((json) => {
      setLlmResponse(json)

      setIsLoading(false)

      scrollToBottomOfChat?.()
    })
  }, [])

  const navigate = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault()

      navigatePerSelection({
        layer: selectedLayer as Exclude<TSelectedLayer, null>,
        llmResponse,
        property: selectedProperty as TProperty,
        dateFrom: defaultTimeRange.dateFrom,
        dateTo: defaultTimeRange.dateTo,
      })

      closeAlmaChat()
    },
    [llmResponse],
  )

  return {
    isLoading,
    confirmationMessage,
    navigate,
  }
}
