import { useEffect, useState } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'

export const useHasAgreedToCurrentAcceptancePackage = () => {
  const [hasAgreedToCurrentAcceptancePackageData, setHasAgreedToCurrentAcceptancePackageData] =
    useState(false)

  const hasAgreedToCurrentAcceptancePackage = userDetailsStore.useSelector(
    (s) => s.hasAgreedToCurrentAcceptancePackage,
  )

  const loadHasAgreedToCurrentAcceptancePackage = async () => {
    const data = await apiFetch({
      url: '/has-agreed-to-current-acceptance-package',
      body: null,
    })

    if (typeof data === 'boolean') {
      userDetailsStore.setState((s) => ({
        ...s,
        hasAgreedToCurrentAcceptancePackage: data,
      }))
    }
  }

  useEffect(() => {
    loadHasAgreedToCurrentAcceptancePackage().finally(() => {
      setHasAgreedToCurrentAcceptancePackageData(true)
    })
  }, [])

  return { hasAgreedToCurrentAcceptancePackage, hasAgreedToCurrentAcceptancePackageData }
}
