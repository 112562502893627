import type { TPresetId } from 'App/Map/types'
import { cloneDeep } from 'lodash'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'

export const togglePreset = async ({
  idHash,
  newIgnored,
  presetType,
}: {
  idHash: TPresetId
  newIgnored: boolean
  presetType: 'semiosDefined' | 'userDefined'
}) => {
  const stateKeyToUse =
    presetType === 'semiosDefined' ? 'availableSemiosDefinedPresets' : 'availableUserDefinedPresets'

  const appPresetsManagementAPIKeyToUse =
    presetType === 'semiosDefined' ? 'ignoredSemiosDefinedPresets' : 'ignoredUserDefinedPresets'

  userDetailsStore.setState((s) => {
    const newAvailablePresets = cloneDeep(s[stateKeyToUse])

    if (newAvailablePresets?.[idHash]) {
      newAvailablePresets[idHash].ignored = newIgnored
    }

    return {
      ...s,
      [stateKeyToUse]: newAvailablePresets,
    }
  })

  smallStore.setState((s) => ({
    ...s,
    selectedPreset: s.selectedPreset === idHash ? null : s.selectedPreset,
  }))

  apiFetch({
    url: '/app-presets-management',
    body: { [appPresetsManagementAPIKeyToUse]: { [idHash]: newIgnored } },
  }).catch(() => {
    // TODO: errorLogger
  })
}
