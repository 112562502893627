import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import type { TDeviceConfiguration } from 'App/ServiceCenter/DevicesManagement/types'
import { cloneDeep, omit } from 'lodash'
import { apiFetch } from 'utils/apiFetch'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import { getIdentifier } from '../getIdentifier'
import type { MetaWithNodeIdentifier } from './queue'

export type NodeDeviceAddOrInstallMeta = MetaWithNodeIdentifier & TDeviceConfiguration

const request = async (meta: NodeDeviceAddOrInstallMeta) => {
  await apiFetch({
    url: '/service-center-node-device-install',
    body: omit(meta, ['maintenanceOwnerId', 'managementType']), //Not supported yet
  })
}

const callback = async (response: void, meta: NodeDeviceAddOrInstallMeta) => {
  const { nodeIdentifier, connector, channel } = meta
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  // Update local state with new device
  const updatedNode = cloneDeep(activeNodes.find((node) => getIdentifier(node) === nodeIdentifier))

  if (updatedNode) {
    if (!updatedNode.devices) updatedNode.devices = {}

    if (!updatedNode.devices[connector]) updatedNode.devices[connector] = {}

    // Remove planned device from node (if different from installed device)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (connector && channel && updatedNode.devices[connector] && updatedNode.devices[connector]![channel]) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      delete updatedNode.devices[connector]![channel]
    }

    // Add installed device to node
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    updatedNode.devices[connector]![channel] = {
      ...meta,
      installationStatus: SharedTypes.NodeDeviceInstallationStatus.INSTALLED,
    }

    serviceCenterStore.actions.updateActiveNode(updatedNode)
  }
}

export const serviceCenterNodeDeviceInstall = {
  request,
  callback,
}
