import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { VIEW_PESTS_DEGREE_DAYS_ID_insectId } from '@semios/app-platform-value-type-definitions'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { regionLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/regionLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { EAggregationInterval } from 'App/Map/types'
import type { TXAxisPlotLinesOptionsWithTooltipDisplay } from 'components/StackedChart/_utils/lineChartTooltipFormatter/buildTooltipPlotLinesIfAny'
import type { StackedChartPestSection, TChartSeries } from 'components/StackedChart/types'
import moment from 'moment-timezone'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { INSECT_DEGREE_DAY_VALUE_KEY_PREFIX } from 'utils/insectRequestValueKeyPrefix'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { isRegionDataEnabledForCharts } from 'utils/useIsRegionDataEnabled'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import {
  makeRegionalSeries,
  makeRegionalSeriesFromRegularSeries,
} from '../_utils/makeRegionalSeriesFromRegularSeries'
import { generateBiofixPlotLinesAndSeries } from './generateBiofixPlotLinesAndSeries'
import { Summary } from './Summary'

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY } as const

const getValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}` as VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged
}

const getValuesRequestedForRegions = ({
  selectedFieldAssets,
  selectedValueGroups,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const selectedRegion = selectedFieldAssets.region

  if (!selectedRegion || !isRegionDataEnabledForCharts()) return {}

  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  const valuesForRegion = fieldAssetStore.getState()?.regions?.[selectedRegion]?.valuesTimeseries

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('degree_days_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (
        !selectedRegionHasPermission({
          permission: VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId),
          regionId: selectedRegion,
        })
      )
        return

      if (!valuesForRegion?.includes(getValueTypeIdForInsectId(insectId))) return

      valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
    }
  })

  const valuesToBeRequested: Partial<routes.Values.Request> = {
    regions: {
      regionIds: [selectedRegion],
      valuesRequested,
    },
  }

  return valuesToBeRequested
}

const getValuesRequestedForBlocks = ({
  selectedFieldAssets,
  selectedValueGroups,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedFieldAssets.block) return {}

  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.DegreeDaysInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('degree_days_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (!selectedPropertyHasPermission({ permission: VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId) })) return

      valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
    }
  })

  if (Object.keys(valuesRequested).length === 0) return {}

  const valuesToBeRequested: Partial<routes.Values.Request> = {}

  if (
    Object.keys(valuesRequested).length &&
    doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  )
    valuesToBeRequested.blocks = {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    }

  return valuesToBeRequested
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}) => {
  const blockValues = getValuesRequestedForBlocks({ selectedFieldAssets, selectedValueGroups })
  const regionValues = getValuesRequestedForRegions({ selectedFieldAssets, selectedValueGroups })

  return { ...blockValues, ...regionValues }
}

export const content = ({
  data,
  selectedValueGroups,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): StackedChartPestSection[] => {
  const insects = fieldAssetStore.getState()?.insects

  if (!insects) return []

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssets.property
  const selectedRegion = selectedFieldAssets.region
  const blockId = String(selectedFieldAssets.block)
  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const { dateFrom, dateTo } = detailsPanelStore.getState()

  const degreeDaysContent: StackedChartPestSection[] = Object.values(insects)
    .filter((insect) => selectedValueGroups[`degree_days_insect_id_${insect.insectId}` as TValueGroup])
    // TODO: sort based on t() of insect
    // TODO: rename to insectName
    .sort(sortByKey('name'))
    .map(({ insectId }) => {
      const permission = VIEW_PESTS_DEGREE_DAYS_ID_insectId(insectId)
      const regionHasPermission = selectedRegionHasPermission({ permission, regionId: selectedRegion })

      const hasPermission =
        selectedPropertyHasPermission({
          permission,
        }) ||
        (isUserOnlyAFreeRegionalUser() && regionHasPermission)

      const valueTypeKey = getValueTypeIdForInsectId(insectId)
      const pestSectionCategory: TPestSectionCategory = 'degree-days'

      const commonReturnItems = {
        title: unitConverter.insectDegreeDaysCumulative(null, { insectId }).titleWithoutUnit(),
        titleChildren: <DropdownSelectorBlock valuesTimeseriesToFilterOn={[valueTypeKey]} />,
        id: `insect-degree-days-insect-id-${insectId}-${blockId}`,
        pestSectionCategory,
        insectId,
      }

      if (!hasPermission && selectedProperty) {
        return { ...propertyLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      if (!hasPermission && !selectedProperty) {
        return { ...regionLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      const blockHasValueTypes = doesSelectedBlockHaveValueTypes({ valuesTimeseries: [valueTypeKey] })

      if (!blockHasValueTypes && selectedProperty) {
        return { ...commonReturnItems, defaultCollapsed: true, items: [] }
      }

      const series: TChartSeries[] = []

      let biofixPlotLines: TXAxisPlotLinesOptionsWithTooltipDisplay[] = []

      if (blockHasValueTypes) {
        const insectDegreeDaysCumulativeSeriesForBlock: TChartSeries & { color?: TRGBAColorWith1AtTheEnd } = {
          name: unitConverter.insectDegreeDaysCumulative().categoryTitleWithoutUnit(),
          id: `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}`,
          tooltip: {
            valueDecimals: unitConverter.insectDegreeDaysCumulative().defaultNumberOfDecimalPlaces(),
            valueSuffix: ` ${unitConverter.insectDegreeDaysCumulative().suffix()}`,
          },
          yAxis: 0,
          data: (data?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.timeseries ?? []).map(
            (d: { timestamp: string; value: number | null }) => [
              +new Date(d.timestamp),
              unitConverter.insectDegreeDaysCumulative(d.value).value(),
            ],
          ),
          type: 'line',
          marker: {
            enabled: false,
          },
        }

        series.push(insectDegreeDaysCumulativeSeriesForBlock)

        if (compareSeasonsInterval) {
          series.push(
            makeCompareSeasonsSeriesFromRegularSeries(insectDegreeDaysCumulativeSeriesForBlock, {
              data: (
                compareSeasonsData?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.timeseries ?? []
              ).map((d: { timestamp: string; value: number | null }) => [
                +new Date(d.timestamp),
                unitConverter.insectDegreeDaysCumulative(d.value).value(),
              ]),
            }),
          )
        }

        const { biofixSeriesForAddingToggle, biofixPlotLines: blockBiofixPlotLines } =
          generateBiofixPlotLinesAndSeries({
            biofixDates: {
              baseSeason: data?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.metadata?.biofixDates ?? [],
              comparisonSeason: !compareSeasonsInterval
                ? []
                : compareSeasonsData?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.metadata?.biofixDates ??
                  [],
            },
            dateFrom,
            dateTo,
            intervalForCompareSeasonsOffset: compareSeasonsInterval,
            timezone,
          })

        if (biofixSeriesForAddingToggle) series.push(biofixSeriesForAddingToggle)

        biofixPlotLines = blockBiofixPlotLines ?? []
      }

      const showRegions = isRegionDataEnabledForCharts() && regionHasPermission && selectedRegion

      if (showRegions) {
        const regionName = fieldAssetStore.getState()?.regions?.[selectedRegion]?.name

        const regionData = (
          data?.regions?.[selectedRegion]?.values?.[valueTypeKey]?.[0]?.timeseries ?? []
        ).map((d: { timestamp: string; value: number | null }) => [
          +new Date(d.timestamp),
          unitConverter.insectDegreeDaysCumulative(d.value).value(),
        ])

        if (series.length) {
          const baseSeries = series[0] as TChartSeries & { color?: TRGBAColorWith1AtTheEnd }

          const regionalSeries = makeRegionalSeriesFromRegularSeries(baseSeries, {
            name: regionName,
            data: regionData,
          })

          series.push(regionalSeries)
        } else {
          const seriesName = blockHasValueTypes
            ? regionName
            : unitConverter.insectDegreeDaysCumulative().categoryTitleWithoutUnit()

          const insectDegreeDaysCumulativeSeriesForRegion: TChartSeries & {
            color?: TRGBAColorWith1AtTheEnd
          } = {
            name: seriesName,
            id: `${INSECT_DEGREE_DAY_VALUE_KEY_PREFIX}${insectId}-region`,
            tooltip: {
              valueDecimals: unitConverter.insectDegreeDaysCumulative().defaultNumberOfDecimalPlaces(),
              valueSuffix: ` ${unitConverter.insectDegreeDaysCumulative().suffix()}`,
            },
            yAxis: 0,
            data: (data?.regions?.[selectedRegion]?.values?.[valueTypeKey]?.[0]?.timeseries ?? []).map(
              (d: { timestamp: string; value: number | null }) => [
                +new Date(d.timestamp),
                unitConverter.insectDegreeDaysCumulative(d.value).value(),
              ],
            ),
            type: 'line',
            marker: {
              enabled: false,
            },
          }

          series.push(makeRegionalSeries(insectDegreeDaysCumulativeSeriesForRegion))
        }
      }

      const allForecastMoments = [
        data?.blocks?.[blockId]?.values?.[valueTypeKey]?.[0]?.metadata?.forecastStartsAt,
        data?.regions?.[String(selectedRegion)]?.values?.[valueTypeKey]?.[0]?.metadata?.forecastStartsAt,
      ]
        .filter(Boolean)
        .map((d) => moment.tz(d, timezone))

      const firstForecastTimestamp = allForecastMoments.length ? +moment.min(allForecastMoments) : +new Date()

      return {
        ...commonReturnItems,
        items: [
          {
            childrenUpper: (
              <div css={{ textAlign: 'right' }}>
                <Summary propertyId={selectedProperty} regionId={selectedRegion} insectId={insectId} />
              </div>
            ),
            chartConfig: {
              semiosHighchartsAdditions: {
                id: unitConverter.insectDegreeDaysCumulative(null, { insectId }).titleWithoutUnit(),
                firstForecastTimestamp,
              },
              chart: {
                type: 'line',
              },
              tooltip: {
                xDateFormat: getXDateFormat(),
              },
              xAxis: {
                plotLines: biofixPlotLines,
              },
              series,
            },
          },
        ],
      }
    })

  return degreeDaysContent
}
