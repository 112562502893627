import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'
import { parse as parseWKT } from 'wellknown'

export const serviceCenterActiveNodesGet = async (propertyIds: number[]) => {
  const activeNodes = await apiFetch({
    url: '/service-center-active-nodes-get',
    body: { propertyIds },
  })

  const shapedActiveNodes = activeNodes.map((node) => ({
    ...node,
    // Transform string WKT to serialized GeoJSON
    location: JSON.stringify(parseWKT(node.location as string)),
  })) as routes.ServiceCenterActiveNodesGet.Response

  return shapedActiveNodes
}
