import { apiFetch } from 'utils/apiFetch'

export const serviceCenterNodeServiceIssueDetailGet = async (serviceIssueId: number) => {
  const serviceIssue = await apiFetch({
    url: '/service-center-node-service-issue-detail-get',
    body: { serviceIssueId },
  })

  return serviceIssue
}
