import { openConfirmModal } from '@mantine/modals'
import { Button } from 'components/Button/Button'
import { IconTrashCan } from 'components/icons/IconTrashCan'
import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'
import { EditButton } from '../../IrrigationSchedulerSettings/Tabs/EditButton'
import type { TOrganizationKcModel } from '../types'

export const OrganizationSection = ({
  organizationId,
  organizationName,
  organizationKcModels,
  setSelectedModelToEdit,
  openEditModal,
  setData,
}: {
  organizationId: string
  organizationName: string
  organizationKcModels: TOrganizationKcModel[]
  setSelectedModelToEdit: (model: TOrganizationKcModel) => void
  openEditModal: () => void
  setData: React.Dispatch<React.SetStateAction<TOrganizationKcModel[]>>
}) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <div key={organizationId}>
      <SectionTitleBar
        key={organizationId}
        collapsed={collapsed}
        onCollapse={() => setCollapsed((prev) => !prev)}
        title={organizationName}
      />
      <div
        css={{
          padding: collapsed ? 0 : '10px 0',
          opacity: collapsed ? 0 : 1,
          height: collapsed ? 0 : '100%',
          overflow: collapsed ? 'hidden' : 'unset',
          boxSizing: 'border-box',
          borderCollapse: 'collapse',
          transition: 'height 80ms, opacity 80ms',
        }}
      >
        {organizationKcModels
          .sort((a, b) => {
            return a.modelName.localeCompare(b.modelName)
          })
          .map((model, i) => {
            return (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: 10,
                  borderBottom: i === organizationKcModels.length - 1 ? '' : `0.5px solid ${colors.grey200}`,
                }}
                key={model.modelId}
              >
                <div
                  css={{
                    marginLeft: 5,
                    wordBreak: 'break-word',
                  }}
                >
                  {model.modelName}
                </div>
                <div css={{ textWrap: 'nowrap', whiteSpace: 'nowrap' }}>
                  <Button
                    onClick={() => {
                      openConfirmModal({
                        styles: {
                          root: {
                            padding: 10,
                          },
                        },
                        title: (
                          <strong>
                            {translate.phrases.banyanApp('Are you sure you want to delete this {{label}}?', {
                              label: translate.phrases.banyanApp('Kc Model'),
                            })}
                          </strong>
                        ),
                        labels: {
                          confirm: translate.phrases.banyanApp('Confirm'),
                          cancel: translate.phrases.banyanApp('Cancel'),
                        },
                        children: (
                          <translate.Phrases.banyanApp
                            k="Deleting <strong>{{label}}</strong> will remove it from all properties in your organization"
                            v={{
                              label: translate.phrases.templates('{{label}}: {{value}}', {
                                label: translate.phrases.banyanApp('Kc Model'),
                                value: model.modelName,
                              }),
                            }}
                          />
                        ),
                        onCancel: () => null,
                        onConfirm: async () => {
                          const response = await apiFetch({
                            url: '/field-asset-settings-set',
                            body: {
                              kcModelDelete: [
                                {
                                  kcModelId: model.modelId,
                                  organizationId: model.organizationId,
                                },
                              ],
                            },
                          }).catch(() => {
                            showNotification({
                              message: translate.phrases.validation('{{label}} could not be deleted', {
                                label: translate.phrases.banyanApp('Kc Model'),
                              }),
                              type: 'error',
                            })
                          })

                          if (response?.kcModelDelete && Array.isArray(response.kcModelDelete)) {
                            const arrayOfDeletedIds = response.kcModelDelete

                            setData((prev) =>
                              prev.filter((m) => arrayOfDeletedIds.some((d) => d.modelId !== m.modelId)),
                            )
                          }
                        },
                      })
                    }}
                    leftIcon={<IconTrashCan />}
                    variant="link"
                  >
                    {translate.phrases.banyanApp('Delete')}
                  </Button>
                  <EditButton
                    openModal={() => {
                      setSelectedModelToEdit(model)

                      openEditModal()
                    }}
                  />
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
