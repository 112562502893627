import { Flex, LoadingOverlay, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { sortByKey } from '@semios/app-platform-common'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import { translate } from 'i18n/i18n'
import { isEqual } from 'lodash'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import type { Group } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { fetchApiCustomer } from 'utils/fetchApiCustomer'
import { loadAppStartupApiCustomer } from 'utils/loadAppStartupApiCustomer'
import { showNotification } from 'utils/showNotification'
import { useScreenSize } from 'utils/useScreenSize'
import type { CreateGroupResponse, GroupFormType, UpdateGroupResponse } from '../_utils/formTypesAndStyles'
import { useStyles } from '../_utils/formTypesAndStyles'
import { getMultiSelectOptions } from '../_utils/getMultiSelectOptions'
import { groupCreateQuery, groupUpdateQuery } from '../_utils/queries'
import type { NotificationType } from '../_utils/useRelativeNotification'
import { groupValidationSchema } from '../_utils/validationSchemas'
import { ContactAndGroupSubmitButton } from '../components/ContactAndGroupSubmitButton'

export const ModalDrawerCreateOrEditGroup = ({
  opened,
  onClose,
  isEdit,
  selectedGroup,
  setSelectedGroup,
  showRelativeNotification,
}: {
  opened: boolean
  onClose: () => void
  isEdit: boolean
  selectedGroup: Group | null
  setSelectedGroup: Dispatch<SetStateAction<Group | null>>
  showRelativeNotification: ({ type, message }: { type: NotificationType; message: string }) => void
}) => {
  const { classes } = useStyles()
  const { isWideScreen } = useScreenSize()
  const notificationFunctionToUse = isWideScreen ? showRelativeNotification : showNotification
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { allGroups, allContacts } = userDetailsStore.useSelector((s) => ({
    allGroups: s.groups,
    allContacts: s.contacts,
  }))

  const groupMemberOptions = getMultiSelectOptions(
    allContacts.map((contact) => ({
      id: contact.id,
      name: contact.name,
    })),
  )

  const form = useForm<GroupFormType>({
    initialValues: {
      name: '',
      contacts: [],
    },

    validate: zodResolver(groupValidationSchema(allGroups, selectedGroup?.id)),
    validateInputOnBlur: true,
  })

  const getDefaultFormState = () => ({
    name: selectedGroup?.name || '',
    contacts: selectedGroup?.contacts
      ? getMultiSelectOptions(
          selectedGroup.contacts.map((contact) => ({
            id: contact.id,
            name: contact.name,
          })),
        )
      : [],
  })

  useEffect(() => {
    form.setValues(getDefaultFormState())
  }, [selectedGroup, opened])

  const handleOnClose = () => {
    onClose()

    form.reset()

    setSelectedGroup(null)
  }

  const handleCreateGroup = async () => {
    const values = form.values
    const { name, contacts } = values

    setIsSubmitting(true)

    try {
      const response: CreateGroupResponse = await fetchApiCustomer({
        body: {
          query: groupCreateQuery,
          variables: {
            name,
            contactIds: contacts.map((contact) => contact.value),
          },
        },
      })

      const groupId = response.data.createGroup

      if (groupId) {
        notificationFunctionToUse({
          type: 'success',
          message: translate.phrases.banyanApp('Group successfully created'),
        })

        loadAppStartupApiCustomer()

        handleOnClose()
      } else {
        notificationFunctionToUse({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to create a new group'),
        })
      }
    } catch (error) {
      notificationFunctionToUse({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to create a new group'),
      })
    }

    setIsSubmitting(false)
  }

  const handleEditGroup = async () => {
    const values = form.values
    const { name, contacts } = values
    const contactIds = contacts.map((contact) => contact.value)

    setIsSubmitting(true)

    try {
      const response: UpdateGroupResponse = await fetchApiCustomer({
        body: {
          query: groupUpdateQuery,
          variables: {
            id: selectedGroup?.id,
            name,
            contactIds,
          },
        },
      })

      const editedGroupId = response?.data?.updateGroup

      if (editedGroupId) {
        notificationFunctionToUse({
          type: 'success',
          message: translate.phrases.banyanApp('Group successfully updated'),
        })

        loadAppStartupApiCustomer()

        handleOnClose()
      } else {
        notificationFunctionToUse({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to update group'),
        })
      }
    } catch (error) {
      notificationFunctionToUse({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to update group'),
      })
    }

    setIsSubmitting(false)
  }

  const groupMembersNumber = form.getInputProps('contacts').value.length || 0
  const horizontalGap = isWideScreen ? 50 : 10
  const isFormDirty = !isEqual(form.values, getDefaultFormState())

  return (
    <ModalDrawer
      title={
        isEdit ? translate.phrases.banyanApp('Group Details') : translate.phrases.banyanApp('Create Group')
      }
      opened={opened}
      onClose={handleOnClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 300}
      size="lg"
    >
      <LoadingOverlay visible={isSubmitting} />

      <div css={{ paddingBottom: 90 }}>
        <form onSubmit={form.onSubmit(() => (isEdit ? handleEditGroup() : handleCreateGroup()))}>
          <div css={{ padding: `15px ${horizontalGap}px` }}>
            <TextInput
              label={translate.phrases.banyanApp('Group Name')}
              {...form.getInputProps('name')}
              classNames={{
                input: form.errors.name ? classes.invalid : undefined,
              }}
            />
            <div>
              <Flex mt="lg" mb="3px" direction="row" css={{ fontSize: 16, fontWeight: 500 }}>
                <div>{translate.phrases.banyanApp('Add Group Member(s)')}</div>
                <div css={{ marginLeft: 5 }}>{`(${groupMembersNumber})`}</div>
              </Flex>
              <MultiSelect
                data={groupMemberOptions}
                selectedData={form.getInputProps('contacts').value.sort(sortByKey('label'))}
                placeholder=""
                {...form.getInputProps('contacts')}
              />
            </div>
          </div>
          <ContactAndGroupSubmitButton
            label={
              isEdit
                ? translate.phrases.banyanApp('Update Group')
                : translate.phrases.banyanApp('Create Group')
            }
            disabled={isEdit && !isFormDirty}
          />
        </form>
      </div>
    </ModalDrawer>
  )
}
