import { NumberInput, Switch } from '@mantine/core'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { fadeInOutStylesMaker } from 'utils/fadeInOutStylesMaker'
import { validateStatusErrorHelper } from '../../../../../_utils/validateStatusErrorHelper'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { AlertsValidators, isNumberOrNumericString } from '../../../../AlertValidators/AlertValidators'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import type { Rule } from '../../../../settings/alertSettings'
import { AlertsSettings, unitTempDisplay } from '../../../../settings/alertSettings'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'

export const LeafWetnessThreshold = ({
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  useEffect(() => {
    if (!rules || !rules.length) setRules(AlertsSettings.emptyRules.leafWetness)
  })

  const {
    hours: hoursRule,
    leafWetness: leafWetnessRule,
    temperature: temperatureRule,
  } = alertRulesObjectFromArray(rules)

  const hours = hoursRule ? hoursRule.value : null
  const leafWetness = leafWetnessRule ? leafWetnessRule.value : null
  const temperature = temperatureRule ? temperatureRule.value : null
  const temperatureActive = !!temperatureRule
  // check if user-enterable fields are dirty
  const leafWetnessIsDirty = leafWetness !== null || triedToSubmit
  const hoursIsDirty = hours !== null || triedToSubmit
  const temperatureIsDirty = temperature !== null || triedToSubmit
  // validate the user-entered values
  const leafWetnessError = AlertsValidators.leafWetness({ leafWetness })
  const hoursError = AlertsValidators.leafWetnessHours({ hours })
  const temperatureError = temperatureActive && AlertsValidators.temperature({ temperature: temperature })
  // attach a status
  const leafWetnessValidateStatus = validateStatusErrorHelper(leafWetnessIsDirty && !!leafWetnessError)
  const hoursValidateStatus = validateStatusErrorHelper(hoursIsDirty && !!hoursError)

  const temperatureValidateStatus = validateStatusErrorHelper(
    !!temperatureActive && !!temperatureIsDirty && !!temperatureError,
  )

  // attach an error message
  const leafWetnessHelp = helpTextErrorHelper(leafWetnessIsDirty && leafWetnessError)
  const hoursHelp = helpTextErrorHelper(hoursIsDirty && hoursError)
  const temperatureHelp = helpTextErrorHelper(temperatureActive && temperatureIsDirty && temperatureError)

  // update the rules upon changes
  useEffect(() => {
    setThresholdIsValid(!leafWetnessError && !hoursError && !temperatureError)
  }, [leafWetness, hours, temperatureActive, temperature])

  const unitTemp = unitTempDisplay()

  const setTemperatureActive = (truthVal: boolean) => {
    if (truthVal)
      updateAlertRules({
        rules,
        setRules,
        rulesToChange: { temperature: { operator: '>=', value: null, unit: 'temperature' } },
      })
    else updateAlertRules({ rules, setRules, rulesToChange: { temperature: null } })
  }

  const errorStyle = getErrorStyle()

  return (
    <>
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="Send alert when leaf wetness is greater than or equal to <numberInput/> %"
          c={{
            numberInput: (
              <TranslateComponentWrapper>
                <NumberInput
                  onChange={(value) =>
                    updateAlertRules({ rules, setRules, rulesToChange: { leafWetness: { value } } })
                  }
                  css={{ width: 80, margin: '0 5px' }}
                  type="number"
                  classNames={{ input: leafWetnessValidateStatus ? errorStyle : undefined }}
                  value={isNumberOrNumericString(leafWetness) ? Number(leafWetness) : ''}
                  precision={10}
                  removeTrailingZeros={true}
                />
                <div />
              </TranslateComponentWrapper>
            ),
          }}
        />
      </TranslatePhraseWrapper>
      {leafWetnessHelp && <ErrorTextWrapper>{leafWetnessHelp}</ErrorTextWrapper>}
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="for <numberInput/> hours or longer"
          c={{
            numberInput: (
              <TranslateComponentWrapper>
                <NumberInput
                  onChange={(value) =>
                    updateAlertRules({ rules, setRules, rulesToChange: { hours: { value } } })
                  }
                  css={{ width: 80, margin: '0 5px' }}
                  type="number"
                  classNames={{ input: hoursValidateStatus ? errorStyle : undefined }}
                  value={isNumberOrNumericString(hours) ? Number(hours) : ''}
                />
                <div />
              </TranslateComponentWrapper>
            ),
          }}
        />
      </TranslatePhraseWrapper>
      {hoursHelp && <ErrorTextWrapper>{hoursHelp}</ErrorTextWrapper>}
      <div
        css={{ marginTop: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}
      >
        <Switch checked={temperatureActive} onChange={() => setTemperatureActive(!temperatureActive)} />
        <span css={{ cursor: 'pointer' }} onClick={() => setTemperatureActive(!temperatureActive)}>
          &nbsp;{translate.phrases.banyanApp('and the temperature is')}&nbsp;
        </span>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            ...fadeInOutStylesMaker(temperatureActive),
          }}
        >
          {translate.phrases.banyanApp('greater than or equal to')}&nbsp;
          <TranslateComponentWrapper>
            <NumberInput
              onChange={(value) =>
                updateAlertRules({ rules, setRules, rulesToChange: { temperature: { value } } })
              }
              css={{ width: 80, margin: '0 5px' }}
              type="number"
              classNames={{ input: temperatureValidateStatus ? errorStyle : undefined }}
              value={isNumberOrNumericString(temperature) ? Number(temperature) : ''}
              precision={10}
              removeTrailingZeros={true}
            />
          </TranslateComponentWrapper>
          &nbsp;
          {unitTemp}
        </div>
      </div>
      <div>{temperatureHelp && <ErrorTextWrapper>{temperatureHelp}</ErrorTextWrapper>}</div>
    </>
  )
}
