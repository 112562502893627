import { DropdownSelectorProperty } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorProperty/DropdownSelectorProperty'
import type { TValuesTimeseries } from 'App/Map/types'
import { Authorization } from 'components/Authorization/Authorization'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { smallStore } from 'stores/smallStore'
import { DropdownSelectorPoint } from '../DropdownSelectorPoint/DropdownSelectorPoint'

export type TDropdownSelectorPointProps = {
  valuesTimeseriesToFilterOn?: TValuesTimeseries[]
  hasControls?: boolean
  isPoint?: boolean
}

const publishFrostFanPermission = 'PUBLISH_FROST_FAN_OPERATIONAL_DEVICE_SETTINGS'

export const WindMachineTitleChildren = ({
  valuesTimeseriesToFilterOn,
  hasControls,
  isPoint = true,
}: TDropdownSelectorPointProps) => {
  const selectedProperty = selectedFieldAssetsStore.useSelector((s) => s.property)

  return (
    <>
      {isPoint ? (
        <DropdownSelectorPoint
          valuesTimeseriesToFilterOn={valuesTimeseriesToFilterOn}
          pointCategory="windMachinePoint"
        />
      ) : (
        <DropdownSelectorProperty valuesTimeseriesToFilterOn={valuesTimeseriesToFilterOn} />
      )}

      {selectedProperty && hasControls && (
        <Authorization requires={{ permission: publishFrostFanPermission, entity: selectedProperty }}>
          <>
            <Button
              variant="tertiary"
              type="button"
              onClick={() =>
                smallStore.setState((s) => ({
                  ...s,
                  showWindMachineViewDetails: true,
                }))
              }
            >
              {translate.phrases.placeholder('Controls')}
            </Button>
          </>
        </Authorization>
      )}
    </>
  )
}
