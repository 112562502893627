import { appMetaDataMaker } from 'App/Authentication/_utils/appMetaMaker'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { IconRightArrowCircle } from 'components/icons/IconRightArrowCircle'
import { SemiosHeaderTemplate } from 'components/SemiosHeaderTemplate/SemiosHeaderTemplate'
import { StaticAssetsLink } from 'components/StaticAssetsLink/StaticAssetsLink'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'
import { TranslatePhraseWrapper } from '../UserSettingsMenu/Shared/Alerts/components/TranslatePhraseWrapper'

export const PrivacyConsentScreen = () => {
  const [isChecked, setIsChecked] = useState(false)

  const handleAccept = async () => {
    try {
      const response = await apiFetch({
        url: '/agree-to-current-acceptance-package',
        body: { appMeta: appMetaDataMaker() },
      })

      if (response) {
        userDetailsStore.setState((prevState) => {
          return {
            ...prevState,
            hasAgreedToCurrentAcceptancePackage: true,
          }
        })
      }
    } catch (error) {
      showNotification({ type: 'error', message: translate.phrases.banyanApp('There was an error') })
    }
  }

  return (
    <SemiosHeaderTemplate>
      <div css={{ display: 'flex', gap: 30, flexDirection: 'column' }}>
        <div css={{ fontSize: 24 }}>{translate.phrases.banyanApp('Semios Policy Update')}</div>
        <div css={{ display: 'flex', flexDirection: 'row', maxWidth: 300, gap: 10 }}>
          <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} size="md" />
          <TranslatePhraseWrapper>
            <translate.Phrases.banyanApp
              k="By checking this box you confirm that you have read and agree to our <privacyPolicy/> and <sula/>"
              c={{
                privacyPolicy: (
                  <StaticAssetsLink asset="privacy" label={translate.phrases.banyanApp('Privacy Policy')} />
                ),
                sula: (
                  <StaticAssetsLink
                    asset="sula"
                    label={translate.phrases.banyanApp('Software User License Agreement')}
                  />
                ),
              }}
            />
          </TranslatePhraseWrapper>
        </div>
        <Button
          rightIcon={
            <div css={{ height: 16 }}>
              <IconRightArrowCircle />
            </div>
          }
          disabled={!isChecked}
          onClick={handleAccept}
        >
          {translate.phrases.banyanApp('Accept & Continue')}
        </Button>
      </div>
    </SemiosHeaderTemplate>
  )
}
