import { useApiCurrentValues } from 'App/Map/PanelDetails/_utils/useApiCurrentValues'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

type WindPointData = {
  windSpeed_mps: {
    inserted_at_timestamp: string
    value: number
    timestamp: string
  }
}

type WindApiResponse = {
  points?: Record<string, WindPointData>
}

export const useCurrentValuesWindSpeed = () => {
  const selectedOOBStation = selectedFieldAssetsStore.getState().outOfBlockPoint

  const { data: closestWindData }: { data: WindApiResponse } = useApiCurrentValues({
    args: {
      points: {
        lngLats: selectedOOBStation ? [selectedOOBStation] : [],
        values: {
          windSpeed_mps: true,
        },
      },
    },
    preventFetch: !Boolean(selectedOOBStation),
  })

  const windPoints = closestWindData?.points

  let windSpeed

  if (windPoints && selectedOOBStation) {
    const windSpeedValue = windPoints[selectedOOBStation]?.windSpeed_mps?.value

    windSpeed = unitConverter.windSpeed(windSpeedValue, { decimalPlaces: 0 }).valueWithSuffix()
  }

  return { windSpeed }
}
