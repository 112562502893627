import { baseZIndex } from 'components/GridTable/consts'
import type {
  GridTableColumn,
  GridTableContentSectionItem,
  PossibleValuesRecord,
} from 'components/GridTable/types'
import { useMemo } from 'react'
import { colors } from 'settings/colors'
import { LabelCell } from './LabelCell/LabelCell'

export const ItemRow = ({
  columns,
  height,
  label,
  labelCellCSS,
  render,
  values,
  widthForLabelColumn,
  widthForValueColumns,
}: {
  columns: GridTableColumn[]
  height: number
  label: React.ReactNode
  labelCellCSS?: GridTableContentSectionItem['labelCellCSS']
  render: GridTableContentSectionItem['render']
  // TODO: use the generic to get the columns' keys
  values: Record<string, PossibleValuesRecord>
  widthForLabelColumn: number
  widthForValueColumns: number
}) => {
  const valueCells = useMemo(
    () =>
      columns.map((column) => (
        <div
          key={column.key}
          css={{
            width: widthForValueColumns,
            height: '100%',
            boxSizing: 'border-box',
            border: `1px solid ${colors.grey200}`,
            borderCollapse: 'collapse',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '17px',
            fontWeight: 500,
            fontSize: '14px',
            zIndex: baseZIndex - 3,
            ...column.valueCellCSS,
          }}
        >
          {render(values[column.dataIndex], column, values)}
        </div>
      )),
    [columns, render, values, widthForValueColumns],
  )

  return (
    <div
      css={{
        height,
        display: 'flex',
        transition: 'height 200ms, opacity 200ms',
      }}
    >
      <LabelCell cellCSS={labelCellCSS} height={height} width={widthForLabelColumn}>
        {label}
      </LabelCell>
      {valueCells}
    </div>
  )
}
