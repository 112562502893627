import type { GalleryPhoto, Photo } from '@capacitor/camera'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { OfflineRequestError } from 'utils/errorCodes'
import { ApiRequestQueue, ApiRequestType } from './api/queue'
import type { PreUploadImageMeta } from './api/serviceCenterImagePreUpload'
import type { AddNodeImagesMeta } from './api/serviceCenterNodeImagesAdd'

export async function uploadInstallationImage(
  nodeIdentifier: string,
  image: Photo | GalleryPhoto,
  nodeAction: 'EQUIP_INSTALL_PSEUDO' | 'EQUIP_INSTALL_DROP' | 'EQUIP_SWAP_IN',
) {
  try {
    // Queue a request to add the image to the node (will be sent after the image is uploaded)
    const { id } = await ApiRequestQueue.createRequest(
      ApiRequestType.ADD_NODE_IMAGES,
      {
        nodeIdentifier,
        nodeAction,
        utcDate: new Date().toISOString().substring(0, 10),
        imagesCount: 1,
        imageUrls: [],
      } as AddNodeImagesMeta,
      { wait: false },
    )

    // Get a pre-signed upload url (will automatically queue another request to upload the image)
    await ApiRequestQueue.createRequest(ApiRequestType.PRE_UPLOAD_IMAGE, {
      ...image,
      linkedRequestId: id,
      type: routes.ServiceCenterImageUpload.ImageTypes.NODE,
    } as PreUploadImageMeta)
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      // TODO: Return a user-friendly error message
      throw error
    }
  }
}
