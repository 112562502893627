import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { closeAlmaChat } from 'App/Map/AlmaChat/utils/closeAlmaChat'
import { translate } from 'i18n/i18n'
import type { MouseEventHandler } from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { apiFetch } from 'utils/apiFetch'
import { AlmaContext } from '../../AlmaContext/AlmaContext'
import { navigatePerLlm } from './navigatePerLlm'

export const useLlm = (userInput: string) => {
  const { sessionId, scrollToBottomOfChat } = useContext(AlmaContext)
  const [isLoading, setIsLoading] = useState(true)
  const [llmResponse, setLlmResponse] = useState<routes.AlmaChatLlm.Response | null>(null)

  useEffect(() => {
    apiFetch({
      url: '/alma-chat-llm',
      body: {
        message: userInput,
        session_id: sessionId as string,
        user_info: {
          default_property: selectedFieldAssetsStore.getState().property as number,
        },
      },
    })
      .then((json) => {
        setLlmResponse(json)

        setIsLoading(false)

        scrollToBottomOfChat?.()
      })
      .catch(() => {
        setIsLoading(false)

        scrollToBottomOfChat?.()
      })
  }, [])

  const navigate = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault()

      navigatePerLlm(llmResponse)

      closeAlmaChat()
    },
    [llmResponse],
  )

  const canNavigate = useMemo(() => {
    if (isLoading) return false

    if (!llmResponse) return false

    if (!llmResponse.views) return false

    if (!llmResponse.property_id) return false

    return true
  }, [isLoading, llmResponse])

  let responseText = llmResponse?.response || ''

  if (responseText === 'An error occurred') {
    responseText = translate.phrases.banyanApp('Sorry… Something went wrong.')
  } else if (!isLoading && !responseText) {
    responseText = translate.phrases.banyanApp('Sorry… Something went wrong.')
  }

  return {
    isLoading,
    responseText,
    canNavigate,
    navigate,
  }
}
