import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TNodeLures } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import type { TActiveNode } from 'App/ServiceCenter/types'
import { cloneDeep } from 'lodash'
import { apiFetch } from 'utils/apiFetch'
import { TRAP_NODE_TYPES } from '../constants/nodeType'
import { getIdentifier } from '../getIdentifier'

export type NodeConfigUpdateMeta = routes.ServiceCenterNodeConfigUpdate.Request

const request = async (payload: routes.ServiceCenterNodeConfigUpdate.Request) => {
  await apiFetch({
    url: '/service-center-node-config-update',
    body: payload,
    params: {
      method: 'PUT',
    },
  })

  return payload
}

const callback = (meta: NodeConfigUpdateMeta): void => {
  const {
    nodeIdentifiers,
    trap: { targetInsectId, cameraOrientation, lureIds, consumableTypes, consumableOperationType } = {},
  } = meta

  const [nodeIdentifierToUpdate] = nodeIdentifiers
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  let nodeToUpdate = cloneDeep(activeNodes.find((node) => getIdentifier(node) === nodeIdentifierToUpdate))

  if (!nodeToUpdate) return

  // trap update
  if (TRAP_NODE_TYPES.includes((nodeToUpdate as TActiveNode).nodeType)) {
    //@ts-ignore - trap is not defined when drop installing a node
    if (!nodeToUpdate.trap) nodeToUpdate.trap = {}

    if (cameraOrientation) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      nodeToUpdate.trap!.cameraOrientation = cameraOrientation
    }

    if (consumableTypes) {
      if (consumableOperationType === 'SET' && lureIds) {
        const updatedLures: TNodeLures['active'] = {}

        lureIds.forEach((lureId, i) => {
          updatedLures[lureId] = {
            lureId,
            primary: i === 0,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            maintenanceOwnerId: (nodeToUpdate as TActiveNode).maintenanceOwnerId!,
            activeStart: new Date().toISOString(),
          }
        })

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        nodeToUpdate.trap!.lures = {
          active: updatedLures,
        }
      } else if (consumableOperationType === 'REMOVE') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        nodeToUpdate.trap!.lures = {
          active: {},
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    nodeToUpdate.trap!.targetInsectId = targetInsectId || null
  }

  serviceCenterStore.actions.updateActiveNode(nodeToUpdate)
}

export const serviceCenterNodeConfigSet = {
  request,
  callback,
}
