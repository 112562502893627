import { apiFetch } from 'utils/apiFetch'
import type { MetaWithImageUrls, MetaWithNodeIdentifier } from './queue'

export type AddNodeImagesMeta = MetaWithImageUrls & {
  nodeAction: string
  utcDate: string
} & MetaWithNodeIdentifier

export type AddNodeImagesResult = void

const preRequest = (meta: AddNodeImagesMeta): boolean | Error => {
  if (!meta.imageUrls || meta.imageUrls.length < meta.imagesCount) {
    const missingImages = meta.imagesCount - (meta.imageUrls?.length || 0)

    return new Error(`Waiting for ${missingImages} images to upload`)
  }

  return true
}

const request = async (meta: AddNodeImagesMeta): Promise<AddNodeImagesResult> => {
  await apiFetch({ url: '/service-center-node-images-add', body: meta })
}

export const serviceCenterNodeImagesAdd = {
  preRequest,
  request,
}
