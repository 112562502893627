import { Modal } from '@mantine/core'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { apiFetch } from 'utils/apiFetch'
import { ErrorMessage } from '../../../ErrorMessage/ErrorMessage'
import { useErrorMessage } from '../../../ErrorMessage/useErrorMessage'

export const DeleteEmitterGroupModal = ({
  selectedZoneGroupDelete,
  setSelectedZoneGroupDelete,
}: {
  selectedZoneGroupDelete: (TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null
  setSelectedZoneGroupDelete: React.Dispatch<
    React.SetStateAction<(TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null>
  >
}) => {
  const { showErrorMessage, triggerErrorMessage, onCloseButtonPress } = useErrorMessage()
  const closeModal = () => setSelectedZoneGroupDelete(null)

  return (
    <Modal
      title={
        <div>
          <h4>{translate.phrases.banyanApp('Delete Group')}</h4>
        </div>
      }
      opened={!!selectedZoneGroupDelete}
      onClose={closeModal}
    >
      {!!selectedZoneGroupDelete && (
        <>
          <ErrorMessage
            message={showErrorMessage}
            onCloseButtonPress={onCloseButtonPress}
            showErrorMessage={!!showErrorMessage}
          />
          <div>
            <translate.Phrases.banyanApp
              k="Are you sure you want to delete <name>{{name}}</name>?"
              c={{ name: <strong /> }}
              v={{ name: selectedZoneGroupDelete.groupName }}
            />
          </div>
          <div css={{ marginTop: 20, marginBottom: 30 }}>
            {translate.phrases.banyanApp('Deleting this group will remove it from your list.')}
          </div>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="link" onClick={closeModal}>
              {translate.phrases.banyanApp('Cancel')}
            </Button>
            <Button
              onClick={async () => {
                try {
                  const response = await apiFetch({
                    url: '/irrigation-zone-emitter-group-delete',
                    body: { groupId: selectedZoneGroupDelete.groupId },
                  })

                  const success = typeof response === 'number'

                  if (success) {
                    fieldAssetStore.setState((s) => {
                      if (!s || !selectedZoneGroupDelete.propertyId) return s

                      const irrigationZoneEmitterGroupsWithDeletedGroupRemoved = Object.entries(
                        s.properties?.[selectedZoneGroupDelete.propertyId]?.irrigationZoneEmitterGroups ?? {},
                      ).reduce<Record<string, TFieldAssetValueTypes.TIrrigationZoneEmitterGroup>>(
                        (acc, [groupId, group]) => {
                          if (Number(groupId) !== selectedZoneGroupDelete.groupId) {
                            acc[groupId] = group
                          }

                          return acc
                        },
                        {},
                      )

                      return {
                        ...s,
                        properties: {
                          ...s.properties,
                          [String(selectedZoneGroupDelete.propertyId)]: {
                            ...s.properties?.[selectedZoneGroupDelete.propertyId],
                            irrigationZoneEmitterGroups: Object.keys(
                              irrigationZoneEmitterGroupsWithDeletedGroupRemoved,
                            ).length
                              ? irrigationZoneEmitterGroupsWithDeletedGroupRemoved
                              : null,
                          },
                        },
                      }
                    })

                    closeModal()
                  } else {
                    triggerErrorMessage(translate.phrases.banyanApp('Failed to delete group'))
                  }
                } catch (e) {
                  triggerErrorMessage(translate.phrases.banyanApp('Failed to delete group'))
                }
              }}
            >
              {translate.phrases.banyanApp('Delete Group')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}
