import { apiFetch } from 'utils/apiFetch'
import type { TNodeConsumablesStatusGetParams } from '../../types'

export const serviceCenterNodeConsumablesStatusGet = async ({
  propertyId,
  nodeIdentifier,
  lastService,
}: TNodeConsumablesStatusGetParams) => {
  const nodeConsumablesStatus = await apiFetch({
    url: '/service-center-node-consumables-status-get',
    body: {
      propertyId,
      nodeIdentifier,
      lastService,
    },
  })

  return nodeConsumablesStatus
}
