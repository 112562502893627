import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TNodeType } from '../types'
import { HelpGuideGroup } from '../types'
import { getNodeTypeLabel } from './getNodeTypeLabel'

export function getHelpGuideGroupTitle(group: HelpGuideGroup, plural?: boolean): string {
  switch (group) {
    case HelpGuideGroup.USER_GUIDES:
      return plural
        ? translate.phrases.placeholder('User Guides')
        : translate.phrases.placeholder('User Guide')

    case HelpGuideGroup.DEVICE_INSTALLATION:
      return plural
        ? translate.phrases.placeholder('Devices Installation')
        : translate.phrases.placeholder('Device Installation')

    case HelpGuideGroup.STATION_INSTALLATION:
      return plural
        ? translate.phrases.placeholder('Stations Installation')
        : translate.phrases.placeholder('Station Installation')

    default: {
      Sentry.captureException(`Help guide group "${group}" has not been implemented`)

      return group
    }
  }
}

export function getHelpGuideTitle(group: HelpGuideGroup, guideAlias: string) {
  const allDevices = fieldAssetStore.getState().devices

  switch (group) {
    case HelpGuideGroup.USER_GUIDES:
      return getNodeTypeLabel(guideAlias.toLowerCase() as TNodeType)

    case HelpGuideGroup.DEVICE_INSTALLATION:
      return allDevices[guideAlias]?.name || guideAlias

    default:
      return guideAlias
  }
}
