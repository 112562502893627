import { DropdownButton } from 'components/DropdownButton/DropdownButton'
import { IconGear } from 'components/icons/IconGear'
import { IconWarning } from 'components/icons/IconWarning/IconWarning'
import { translate } from 'i18n/i18n'
import { detailsPanelStore } from 'stores/detailsPanelStore'

type SoilSettingsDropdownButtonProps = {
  useIconWarning?: boolean
}

export const SoilSettingsDropdownButton = ({ useIconWarning = false }: SoilSettingsDropdownButtonProps) => {
  return (
    <DropdownButton
      options={[
        {
          content: (
            <span style={{ fontSize: '15px', fontWeight: 500 }}>
              {translate.phrases.banyanApp('Refill Point (MAD)')}
            </span>
          ),
          onClick: () => {
            detailsPanelStore.setState((prev) => {
              return {
                ...prev,
                refillPointDatesSettingsOpened: true,
              }
            })
          },
        },
        {
          content: (
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              {useIconWarning && (
                <span style={{ display: 'inline-flex', alignItems: 'center', marginRight: '7px' }}>
                  <IconWarning />
                </span>
              )}
              <span style={{ fontSize: '15px', fontWeight: 500 }}>
                {translate.phrases.banyanApp('Root Zone Depths')}
              </span>
            </span>
          ),
          onClick: () => {
            detailsPanelStore.setState((prev) => {
              return {
                ...prev,
                soilSettingsOpened: true,
              }
            })
          },
        },
      ]}
      leftIcon={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {!useIconWarning && <IconGear />}
          {useIconWarning && <IconWarning />}
        </span>
      }
      style={{ paddingLeft: '10px', paddingRight: '5px' }}
      showCaretIcon={true}
      variant="secondary"
    >
      <span>{translate.phrases.banyanApp('Settings')}</span>
    </DropdownButton>
  )
}
