import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { apiFetch } from 'utils/apiFetch'

export type GatewayRepositionMeta = routes.ServiceCenterGatewayReposition.Request

const request = async (meta: GatewayRepositionMeta): Promise<GatewayRepositionMeta> => {
  await apiFetch({ url: '/service-center-gateway-reposition', body: meta })

  return meta
}

const callback = (param: GatewayRepositionMeta) => {
  serviceCenterStore.actions.repositionActiveNode({
    gatewayIdentifier: param.gatewayIdentifier,
    location: param.location,
  })
}

export const serviceCenterGatewayReposition = {
  request,
  callback,
}
