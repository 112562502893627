import { UnstyledButton, useMantineTheme } from '@mantine/core'
import { closeModal, openModal } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { isBleSupported } from 'App/ServiceCenter/BluetoothLowEnergy/util/utility'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'
import { IconHelpCircle } from 'components/icons/IconHelpCircle'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import _ from 'lodash'
import { useState } from 'react'
import type { GeoJSONPoint } from 'wellknown'
import { stringify } from 'wellknown'
import { openHelpGuideModal } from '../HelpGuideModal/HelpGuideModal'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type { TNodeDevices } from '../types'
import { HelpGuideGroup, InstallationStep } from '../types'
// import { BleDeviceConnectionKeeper } from '../utils/BleConnectionKeeper'
import { DEVICEABLE_NODE_TYPES, isBleNode } from '../utils/constants/nodeType'
import { createSuccessModal } from '../utils/createCommonModals'
import { dropInstall } from '../utils/dropNodeInstall'
import { getInstallationSteps, isNodeDropping } from '../utils/getInstallationSteps'
import { getNodeTypeLabel } from '../utils/getNodeTypeLabel'
import { installPlannedNode } from '../utils/installPlannedNode'
import { repositionPlannedGateway } from '../utils/repositionPlannedGateway'
import { repositionPlannedNodes } from '../utils/repositionPlannedNodes'
import { useNavigator } from '../utils/useNavigator'
import { DeviceInstallation } from './DeviceInstallation/DeviceInstallation'
import type { NodeActivationPayload } from './EquipmentActivation/NodeActivation'
import { NodeActivation } from './EquipmentActivation/NodeActivation'
import { InstallationReview } from './InstallationReview/InstallationReview'
import { LocationConfirmation } from './LocationConfirmation/LocationConfirmation'
import { Overview } from './Overview/Overview'
import { TrapConfiguration } from './TrapConfiguration/TrapConfiguration'
import type {
  EquipmentActivationPayload,
  NodeDroppingActivationPayload,
  PlannedNodeActivationPayload,
  TLocalInstallationState,
} from './types'

export function openNodeInstallationModal(param: {
  payload: Partial<EquipmentActivationPayload>
  localState: TLocalInstallationState
}) {
  const INSTALLATION_OVERVIEW_MODAL_ID = 'node-installation-overview'

  openModal({
    modalId: INSTALLATION_OVERVIEW_MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: (
      <ErrorBoundary>
        <NodeInstallation {...param} onClose={() => closeModal(INSTALLATION_OVERVIEW_MODAL_ID)} />
      </ErrorBoundary>
    ),
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

const NodeInstallation = ({
  payload,
  localState,
  onClose,
}: {
  payload: Partial<EquipmentActivationPayload>
  localState: TLocalInstallationState
  onClose: () => void
}) => {
  const theme = useMantineTheme()

  const { page, navigateTo } = useNavigator<InstallationStep, undefined>(
    localState.isLocationConfirmed ? InstallationStep.OVERVIEW : InstallationStep.LOCATION_CONFIRMATION,
  )

  const [currentPayload, setCurrentPayload] = useState<Partial<EquipmentActivationPayload>>(payload)
  const [currentLocalState, setCurrentLocalState] = useState<TLocalInstallationState>(localState)
  const steps = getInstallationSteps(currentPayload, currentLocalState)
  const title = getTitle(page, currentPayload)
  const prevPage = getPrevPage(page, currentLocalState)
  const nodeType = currentPayload.nodeType
  // const nodeIdentifier = currentPayload.nodeIdentifier
  const isBleRequired = !!nodeType && isBleSupported() && isBleNode(nodeType)

  const handleLocationConfirmed = async (geolocation: GeoJSONPoint) => {
    try {
      if (_.isEqual(geolocation.coordinates, currentPayload.location?.coordinates)) {
        setCurrentLocalState((p) => ({ ...p, isLocationConfirmed: true }))

        return
      }

      if (isNodeDropping(currentPayload)) {
        //There's no need to reposition the drop node
        setCurrentPayload((p) => ({ ...p, location: geolocation }))

        setCurrentLocalState((p) => ({ ...p, isLocationConfirmed: true }))

        return
      }

      const id = (currentPayload as PlannedNodeActivationPayload).pseudonodeId
      const isGateway = id.includes('gtwy')
      const pseudoId = parseInt(isGateway ? id.split('-')[1] : id)
      const stringLocation = stringify(geolocation)

      if (isGateway) {
        const params = {
          pseudogatewayId: pseudoId,
          location: stringLocation,
        }

        await repositionPlannedGateway(params)
      } else {
        const params = {
          [pseudoId]: stringify(geolocation),
        }

        await repositionPlannedNodes(params)
      }

      //TODO, ble, this should be put into callback in API call,
      serviceCenterStore.actions.repositionPseudoNodes({ [id]: stringLocation })

      setCurrentPayload((p) => ({ ...p, location: geolocation }))

      setCurrentLocalState((p) => ({ ...p, isLocationConfirmed: true }))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      notifications.show({
        title: 'Error',
        message: 'Failed to reposition node',
        color: 'red',
      })
    }
  }

  const handleActivateNode = async (payload: NodeActivationPayload) => {
    const updatedPayload = {
      ...currentPayload,
      ...payload,
    }

    if (isNodeDropping(updatedPayload)) {
      await dropInstall(updatedPayload as NodeDroppingActivationPayload)
    } else {
      await installPlannedNode(updatedPayload as PlannedNodeActivationPayload)
    }

    setCurrentPayload(updatedPayload)
  }

  const handleDeviceConfigured = async (devices?: TNodeDevices) => {
    try {
      setCurrentPayload((p) => ({ ...p, devices: devices }))

      setCurrentLocalState((p) => ({ ...p, isDeviceConfigured: true }))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      notifications.show({
        title: 'Error',
        message: 'Failed to configure device',
        color: 'red',
      })
    }
  }

  const handleReviewFinish = async () => {
    AboveAllModal.open(
      createSuccessModal(
        translate.phrases.placeholder('Installation Completed'),
        <>
          {translate.phrases.placeholder(
            'Now that the installation is completed your station will appear as active equipment.',
          )}
          <br />
          <br />
          {translate.phrases.placeholder('The station status should be updated within 10-15 minutes.')}
        </>,
      ),
    )
  }

  const handleTrapConfigured = () => {
    setCurrentLocalState((p) => ({ ...p, isNodeConfigured: true }))
  }

  return (
    <>
      <WideHeader
        title={title}
        style={{ position: 'sticky', zIndex: 1 }}
        rightIconButton={
          nodeType && (
            <UnstyledButton
              onClick={() => {
                if (DEVICEABLE_NODE_TYPES.includes(nodeType)) {
                  openHelpGuideModal({
                    group: HelpGuideGroup.STATION_INSTALLATION,
                  })
                } else {
                  openHelpGuideModal({
                    group: HelpGuideGroup.USER_GUIDES,
                    alias: nodeType,
                  })
                }
              }}
            >
              <span css={{ fontSize: 20, color: theme.colors.midnight[0] }}>
                <IconHelpCircle />
              </span>
            </UnstyledButton>
          )
        }
        onClose={() => {
          if (prevPage) {
            navigateTo(prevPage)
          } else {
            onClose()
          }
        }}
        isSecondaryModal={!!prevPage}
      />

      {/* TODO: Uncomment when BLE feature is ready */}
      {/*{isBleRequired && <BleDeviceConnectionKeeper nodeIdentifier={nodeIdentifier} />}*/}

      <div>
        {page === InstallationStep.LOCATION_CONFIRMATION && (
          <LocationConfirmation
            payload={currentPayload}
            onSubmit={handleLocationConfirmed}
            isBleRequired={isBleRequired}
            onClose={(isSubmit) => {
              //Initial location confirmation, no need to navigate to Overview
              if (!localState.isLocationConfirmed) {
                if (isSubmit) {
                  navigateTo(InstallationStep.OVERVIEW)
                } else {
                  onClose()
                }
              } else {
                //Location confirmation is already done, navigate to Overview
                navigateTo(InstallationStep.OVERVIEW)
              }
            }}
          />
        )}
        {page === InstallationStep.OVERVIEW && (
          <Overview
            payload={currentPayload}
            steps={steps}
            isBleRequired={isBleRequired}
            onAction={(p) => {
              navigateTo(p)
            }}
            onClose={onClose}
          />
        )}
        {page === InstallationStep.NODE_ACTIVATION && (
          <NodeActivation
            payload={currentPayload}
            onSubmit={handleActivateNode}
            isBleRequired={isBleRequired}
            onClose={() => {
              navigateTo(InstallationStep.OVERVIEW)
            }}
          />
        )}
        {page === InstallationStep.DEVICES_INSTALLATION && (
          <DeviceInstallation
            payload={currentPayload}
            onSubmit={handleDeviceConfigured}
            isBleRequired={isBleRequired}
            onClose={() => {
              navigateTo(InstallationStep.OVERVIEW)
            }}
          />
        )}
        {page === InstallationStep.NODE_CONFIGURATION && (
          <TrapConfiguration
            onSubmit={handleTrapConfigured}
            onClose={() => {
              navigateTo(InstallationStep.OVERVIEW)
            }}
          />
        )}
        {page === InstallationStep.REVIEW && (
          <InstallationReview
            payload={currentPayload}
            onSubmit={handleReviewFinish}
            isBleRequired={isBleRequired}
            onClose={(isSubmit) => (!isSubmit ? navigateTo(InstallationStep.OVERVIEW) : onClose())}
          />
        )}
      </div>
    </>
  )
}

const getPrevPage: (key: InstallationStep, value: TLocalInstallationState) => InstallationStep | undefined = (
  pageKey: InstallationStep,
  value: TLocalInstallationState,
) => {
  if (pageKey === InstallationStep.LOCATION_CONFIRMATION) {
    if (value.isLocationConfirmed) {
      return InstallationStep.OVERVIEW
    } else {
      return undefined
    }
  } else if (pageKey === InstallationStep.OVERVIEW) {
    return undefined
  } else if (pageKey === InstallationStep.NODE_ACTIVATION) {
    return InstallationStep.OVERVIEW
  } else if (pageKey === InstallationStep.DEVICES_INSTALLATION) {
    return InstallationStep.OVERVIEW
  } else if (pageKey === InstallationStep.REVIEW) {
    return InstallationStep.OVERVIEW
  } else if (pageKey === InstallationStep.NODE_CONFIGURATION) {
    return InstallationStep.OVERVIEW
  } else {
    return undefined
  }
}

const getTitle: (key: InstallationStep, value: Partial<EquipmentActivationPayload>) => string = (
  pageKey: InstallationStep,
  value: Partial<EquipmentActivationPayload>,
) => {
  const nodeType = value.nodeType

  if (pageKey === InstallationStep.OVERVIEW) {
    return translate.phrases.placeholder('Install {{nodeType}}', {
      nodeType: nodeType ? getNodeTypeLabel(nodeType) : 'Equipment',
    })
  } else if (pageKey === InstallationStep.LOCATION_CONFIRMATION) {
    return translate.phrases.placeholder('Confirm Location')
  } else if (pageKey === InstallationStep.NODE_ACTIVATION) {
    return translate.phrases.placeholder('Activate {{nodeType}}', {
      nodeType: nodeType ? getNodeTypeLabel(nodeType) : 'Equipment',
    })
  } else if (pageKey === InstallationStep.DEVICES_INSTALLATION) {
    return translate.phrases.placeholder('Manage Devices')
  } else if (pageKey === InstallationStep.REVIEW) {
    return translate.phrases.placeholder('Review')
  } else if (pageKey === InstallationStep.NODE_CONFIGURATION) {
    return translate.phrases.placeholder('Configure Trap')
  } else {
    return ''
  }
}
