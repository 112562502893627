import { Divider } from '@mantine/core'
import { getZoomThresholds, ZOOM_VISIBILITY } from 'App/Map/CurrentValuesMap/_utils/zoomVisibility'
import { isNdviValueType } from 'App/Map/CurrentValuesMap/caches/ImageOverlaysCache/_utils/isNdviValueType'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { mapStore } from 'stores/mapStore'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { NdviHeatmapRow } from '../../HeatmapControlsRow/NdviHeatmapRow/NdviHeatmapRow'
import { NDVIDropdown } from '../../ValueRow/NDVIDropdown/NDVIDropdown'
import { includeNdviSwitch } from '../includeNdviSwitch'
import { NdviSwitch } from './NdviSwitch/NdviSwitch'

export const NdviMapControls = () => {
  const primaryValueGroup = usePrimaryValueGroup()
  const zoom = mapStore.useSelector((s) => s.zoom)
  const { IMAGE_OVERLAY } = getZoomThresholds(zoom)

  const ndviIsEnabledForThisValueGroupAndInView =
    !primaryValueGroup ||
    (includeNdviSwitch[primaryValueGroup as TValueGroup] && IMAGE_OVERLAY === ZOOM_VISIBILITY.OK)

  const showNdvi = selectedValueGroupsStore.useSelector((s) => !!s?.selectedValueGroups?.ndvi)

  const showHeatmapRow = mapControlsStore.useSelector((s) => {
    const ndviValueType = s.mapVisualValueGroup?.[MAP_VISUAL?.IMAGE_OVERLAY]?.ndvi?.valueType

    return isNdviValueType(ndviValueType)
  })

  return ndviIsEnabledForThisValueGroupAndInView ? (
    <div
      css={{
        marginTop: 12,
      }}
    >
      <Divider />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
          color: colors.white,
          marginTop: 12,
          width: '100%',
        }}
      >
        <NdviSwitch />
        {showNdvi && (
          <>
            <NDVIDropdown />
            {showHeatmapRow && <NdviHeatmapRow />}
          </>
        )}
      </div>
    </div>
  ) : null
}
