import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingOverlay } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { useMemo, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { useApiREST } from 'utils/useApiREST'
import { ItemHeader } from '../../WideScreen/ItemHeader/ItemHeader'
import { NewOrEditKcModelModal } from './NewOrEditKcModelModal/NewOrEditKcModelModal'
import { OrganizationSection } from './OrganizationSection/OrganizationSection'
import type { TOrganizationKcModel } from './types'

export const EvapotranspirationSettings = () => {
  const [modalDrawerOpen, { open, close }] = useDisclosure()
  const properties = fieldAssetStore.useSelector((s) => s.properties)
  const [selectedModelToEdit, setSelectedModelToEdit] = useState<null | TOrganizationKcModel>(null)
  const [data, setData] = useState<TOrganizationKcModel[]>([] as TOrganizationKcModel[])

  const propertiesByOrganization = properties
    ? Object.values(properties).reduce<Record<string, TFieldAssetValueTypes.TProperty[]>>((acc, property) => {
        if (!property.organizationId) return acc

        if (!acc[property.organizationId]) {
          acc[property.organizationId] = []
        }

        acc[property.organizationId].push(property)

        return acc
      }, {})
    : {}

  const cropsByOrganization = properties
    ? Object.values(properties).reduce<Record<string, number[]>>((acc, property) => {
        if (!acc[property.organizationId]) {
          acc[property.organizationId] = []
        }

        property.blocks &&
          Object.values(property.blocks).forEach(({ cropIds }) => {
            cropIds?.forEach((cropId) => {
              if (!acc[property.organizationId].includes(cropId)) {
                acc[property.organizationId].push(cropId)
              }
            })
          })

        return acc
      }, {})
    : {}

  const organizationIds = Object.keys(propertiesByOrganization).map(Number)

  const { loading } = useApiREST({
    url: '/field-asset-settings-get',
    preventFetch: !organizationIds.length,
    body: { kcOrganizationModels: { organizationIds } },
    shaper: (data) => {
      const { kcOrganizationModels } = data

      if (!kcOrganizationModels || !Array.isArray(kcOrganizationModels)) return null

      setData(kcOrganizationModels)

      return null
    },
    watchers: [organizationIds.length],
  })

  const organizationSelectData = useMemo(
    () =>
      Object.keys(propertiesByOrganization)
        .map((organizationId) => {
          return {
            value: organizationId,
            label: propertiesByOrganization[organizationId][0].organizationName,
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    [propertiesByOrganization],
  )

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ItemHeader>{unitConverter.evapotranspiration().categoryTitleWithoutUnit()}</ItemHeader>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 50,
          padding: 10,
        }}
      >
        <div>
          {translate.phrases.banyanApp(
            'All Kc Models are attached to a specific organization and crop and are visible to everyone who has access to that organization.',
          )}
        </div>
        <Button variant="primary" leftIcon={<FontAwesomeIcon icon={faPlusCircle} />} onClick={open}>
          {translate.phrases.banyanApp('New Kc Model')}
        </Button>
      </div>
      {!organizationIds.length || loading ? (
        <LoadingOverlay visible />
      ) : (
        Object.keys(propertiesByOrganization)
          .filter((organizationId) => data.some((model) => model.organizationId === Number(organizationId)))
          .sort((a, b) => {
            const aOrganizationName = propertiesByOrganization[a][0].organizationName
            const bOrganizationName = propertiesByOrganization[b][0].organizationName

            if (!aOrganizationName || !bOrganizationName) return 0

            return aOrganizationName.localeCompare(bOrganizationName)
          })
          .map((organizationId) => {
            return (
              <div key={organizationId}>
                <OrganizationSection
                  setData={setData}
                  organizationName={propertiesByOrganization[organizationId][0].organizationName}
                  organizationId={organizationId}
                  organizationKcModels={
                    data && Array.isArray(data)
                      ? data.filter((model) => model.organizationId === Number(organizationId))
                      : []
                  }
                  openEditModal={open}
                  setSelectedModelToEdit={setSelectedModelToEdit}
                />
              </div>
            )
          })
      )}
      <NewOrEditKcModelModal
        setData={setData}
        data={data}
        title={
          !!selectedModelToEdit
            ? translate.phrases.banyanApp('Edit Kc Model')
            : translate.phrases.banyanApp('New Kc Model')
        }
        primaryButtonText={
          !!selectedModelToEdit
            ? translate.phrases.banyanApp('Save Changes')
            : translate.phrases.banyanApp('Create Kc Model')
        }
        opened={modalDrawerOpen}
        onClose={() => {
          close()

          setSelectedModelToEdit(null)
        }}
        organizationSelectData={organizationSelectData}
        cropsByOrganization={cropsByOrganization}
        selectedModelToEdit={selectedModelToEdit}
      />
    </div>
  )
}
