import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DatePickerInput } from '@mantine/dates'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { NumberInput } from 'components/NumberInput/NumberInput'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { apiFetch } from 'utils/apiFetch'
import { ONGOING_KEY } from 'utils/refillPointDatesUtils/ongoingKey'
import { updateStoresCurrentMADValue } from 'utils/refillPointDatesUtils/updateStoresCurrentMADValue'
import { showNotification } from 'utils/showNotification'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { useScreenSize } from 'utils/useScreenSize'
import type { TAddRefillPointDateModalProps } from '../types'

export const AddRefillPointDateModal = ({
  opened,
  setShowAddRPDModal,
  lngLat,
  setRefreshChartAndSummary,
  refillPointDates,
  propertyId,
  setAllStationsRPDsData,
}: TAddRefillPointDateModalProps) => {
  const { isWideScreen } = useScreenSize()
  const datePickerStyle = isWideScreen ? {} : { width: '100%' }
  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const [selectedDate, setSelectedDate] = useState(moment.tz(timezone).startOf('day').toDate())
  const [awcValue, setAwcValue] = useState<number>(70)

  const createRefillPointDatesInDB = async () => {
    const response = await apiFetch({
      url: routes.FieldAssetSettingsSet.path,
      body: {
        soilMADCreate: [
          {
            geom: lngLat,
            dateStart: selectedDate.toISOString(),
            soilMoistureMAD: awcValue,
          },
        ],
      },
    })

    if (!Array.isArray(response?.soilMADCreate)) {
      response?.soilMADCreate?.error?.includes('management_allowable_depletion_unique_date_start')
        ? showNotification({
            message: translate.phrases.banyanApp(
              'Refill Point Date already exists, please select a different date',
            ),
            type: 'error',
          })
        : showNotification({
            message: translate.phrases.banyanApp('Failed to create {{label}}', {
              label: translate.phrases.banyanApp('Refill Point Date'),
            }),
            type: 'error',
          })
    } else {
      const newRPDFromResponse = response?.soilMADCreate?.[0]

      const newRefillPointDates = [
        ...refillPointDates,
        {
          id: newRPDFromResponse.id,
          startDate: newRPDFromResponse.dateStart,
          endDate: 'TBD',
          soilMoisture: newRPDFromResponse.soilMoisture,
        },
      ]
        .sort((a, b) => {
          return +new Date(b.startDate) - +new Date(a.startDate)
        })
        .map((refillPointDate, index, sortedDates) => ({
          ...refillPointDate,
          endDate: index === 0 ? ONGOING_KEY : sortedDates[index - 1].startDate,
        }))

      setAllStationsRPDsData((prev) => {
        return prev.map((station) => {
          if (station.lngLat === lngLat) {
            return {
              ...station,
              refillPointDates: newRefillPointDates,
            }
          }

          return station
        })
      })

      updateStoresCurrentMADValue(newRefillPointDates, propertyId, lngLat)

      showNotification({
        message: translate.phrases.banyanApp('Successfully created {{label}}', {
          label: translate.phrases.banyanApp('Refill Point Date'),
        }),
        type: 'success',
      })

      setRefreshChartAndSummary(true)

      setShowAddRPDModal(false)
    }
  }

  const disabledDatesInCalendar = refillPointDates.map((refillPointDate) =>
    moment.tz(refillPointDate.startDate, timezone).startOf('day').format('YYYY MM DD'),
  )

  return (
    <ModalDrawer
      opened={opened}
      title={translate.phrases.banyanApp('Create Refill Point Date')}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
      primaryButtonText={translate.phrases.banyanApp('Create')}
      secondaryButtonText={translate.phrases.banyanApp('Cancel')}
      secondaryButtonOnPress={() => setShowAddRPDModal(false)}
      onClose={() => setShowAddRPDModal(false)}
      primaryButtonOnPress={() => {
        createRefillPointDatesInDB()
      }}
    >
      <div css={{ maxWidth: '240px', marginLeft: '15px', marginTop: '20px' }}>
        <div css={{ marginBottom: '15px' }}>
          <span>{translate.phrases.banyanApp('Date')}</span>
          <DatePickerInput
            style={datePickerStyle}
            valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
            rightSection={<FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />}
            value={selectedDate}
            onChange={(date) => {
              setSelectedDate(moment.tz(date, timezone).startOf('day').toDate())
            }}
            excludeDate={(date) => {
              return disabledDatesInCalendar.includes(
                moment.tz(date, timezone).startOf('day').format('YYYY MM DD'),
              )
            }}
          ></DatePickerInput>
        </div>
        <div>
          <span>{unitConverter.soilAwc().categoryTitle()}</span>
          <NumberInput
            value={awcValue}
            onChange={(value) => {
              setAwcValue(value === '' ? 0 : value)
            }}
            max={100}
            min={0}
            hideControls
          ></NumberInput>
        </div>
      </div>
    </ModalDrawer>
  )
}
