import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { apiFetch } from 'utils/apiFetch'

export type NodeRepositionMeta = routes.ServiceCenterNodeReposition.Request

const request = async (meta: NodeRepositionMeta): Promise<NodeRepositionMeta> => {
  await apiFetch({ url: '/service-center-node-reposition', body: meta })

  return meta
}

const callback = (param: NodeRepositionMeta) => {
  serviceCenterStore.actions.repositionActiveNode({
    nodeIdentifier: param.nodeIdentifier,
    location: param.location,
  })
}

export const serviceCenterNodeReposition = {
  request,
  callback,
}
