import { MINIMUM_ZOOM_LEVEL } from 'App/Map/MapContext/MapBase'
import { mapValues, maxBy } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { parse as parseWKT } from 'wellknown'
import { getActiveNodeStatus } from '../../Map/_utils/getActiveNodeStatus'
import type {
  TActiveGateway,
  TActiveNode,
  THelpGuides,
  TInstallationChecklist,
  TLures,
  TNodeLog,
  TNodeServiceIssueSummary,
  TNodeStatus,
  TPlannedGateway,
  TPlannedNode,
  TPropertyOverviewWithLocation,
} from '../../types'
import { getIdentifier } from '../../utils/getIdentifier'
import type {
  NodeRemoveReasons,
  NodeSwapReasons,
  TEquipmentType,
  TNodeDeviceType,
  TServiceCenterStore,
} from '../serviceCenterStore'
import { serviceCenterStore } from '../serviceCenterStore'

export const actions = {
  setMapEquipmentStatuses: (mapEquipmentStatuses: TNodeStatus[]) => {
    serviceCenterStore.setState((s) => ({ ...s, mapEquipmentStatuses }))
  },
  setMapEquipmentTypes: (mapEquipmentTypes: TEquipmentType[]) => {
    serviceCenterStore.setState((s) => ({ ...s, mapEquipmentTypes }))
  },
  setMapEquipmentDevices: (mapEquipmentDevices: TNodeDeviceType[]) => {
    serviceCenterStore.setState((s) => ({ ...s, mapEquipmentDevices }))
  },
  setHelpGuides: (helpGuides: THelpGuides) => {
    serviceCenterStore.setState((s) => ({ ...s, helpGuides }))
  },
  setInstallationChecklists: (installationChecklists: {
    nodes: Record<string, TInstallationChecklist>
    devices: Record<string, TInstallationChecklist>
  }) => {
    serviceCenterStore.setState((s) => ({ ...s, installationChecklists }))
  },
  setPropertiesOverview: (payload: {
    overviewData: Record<number, TPropertyOverviewWithLocation>
    lastLoaded: Date
  }) => {
    serviceCenterStore.setState((s) => ({ ...s, propertiesOverview: payload }))
  },
  setNodeSwapReasons: (swapReasons: NodeSwapReasons) => {
    serviceCenterStore.setState((s) => ({ ...s, swapReasons }))
  },
  setNodeRemoveReasons: (removeReasons: NodeRemoveReasons) => {
    serviceCenterStore.setState((s) => ({ ...s, removeReasons }))
  },
  setPlannedNodes: (plannedNodes: Array<TPlannedNode | TPlannedGateway>) => {
    serviceCenterStore.setState((s) => ({ ...s, plannedNodes }))
  },
  repositionPseudoNodes: (params: { [plannedNodeId: string]: string }) => {
    const relocatingPnodeIds = Object.keys(params)

    const existingPnodes = [
      ...serviceCenterStore.selectors.getPlannedNodes(serviceCenterStore.getState()).filter((pnode) => {
        if (pnode.nodeType.includes('gtwy')) {
          return !relocatingPnodeIds.includes(String((pnode as TPlannedNode)?.pseudogatewayId))
        } else {
          return !relocatingPnodeIds.includes(pnode.id)
        }
      }),
    ]

    const relocatedPnodes: Array<TPlannedNode | TPlannedGateway> = []

    Object.keys(params).forEach((pnodeId) => {
      const plannedNode = [...serviceCenterStore.selectors.getPlannedNodes(serviceCenterStore.getState())]

      if (plannedNode) {
        const [relocatingPnode] = plannedNode.filter(
          (pnode) => pnode.id === pnodeId || (pnode as TPlannedNode)?.pseudogatewayId === parseInt(pnodeId),
        )

        const relocatedPnode = {
          ...relocatingPnode,
          location: JSON.stringify(parseWKT(params[pnodeId] as string)),
        }

        relocatedPnodes.push(relocatedPnode)
      }
    })

    serviceCenterStore.actions.setPlannedNodes([...existingPnodes, ...relocatedPnodes])
  },
  repositionActiveNode: (params: {
    gatewayIdentifier?: string
    nodeIdentifier?: string
    location: string
  }) => {
    let updatingNode

    if (params.gatewayIdentifier) {
      updatingNode = serviceCenterStore
        .getState()
        .activeNodes.filter(
          (gateway) =>
            (gateway as TActiveGateway).gatewayType && gateway.gatewayIdentifier === params.gatewayIdentifier,
        )[0]
    }

    if (params.nodeIdentifier) {
      updatingNode = serviceCenterStore
        .getState()
        .activeNodes.filter((node) => (node as TActiveNode).nodeIdentifier === params.nodeIdentifier)[0]
    }

    if (updatingNode) {
      const shapedActiveNode = {
        ...updatingNode,
        location: JSON.stringify(parseWKT(params.location)),
      }

      serviceCenterStore.actions.updateActiveNode(shapedActiveNode)
    }
  },
  removePlannedNodes(nodeIds: string[]) {
    serviceCenterStore.setState((s) => {
      const { plannedNodes, selectedPlannedEquipmentIds } = s
      const updatedPlannedNodes = plannedNodes.filter((pnode) => !nodeIds.includes(pnode.id))
      const updatedPlannedEquipmentIds = selectedPlannedEquipmentIds.filter((id) => !nodeIds.includes(id))

      return {
        ...s,
        plannedNodes: updatedPlannedNodes,
        selectedPlannedEquipmentIds: updatedPlannedEquipmentIds,
      }
    })
  },
  setActiveNodes: (activeNodes: Array<TActiveNode | TActiveGateway>) => {
    serviceCenterStore.setState((s) => ({ ...s, activeNodes }))
  },
  addActiveNode: (activeNode: TActiveNode | TActiveGateway) => {
    const nodeId = getIdentifier(activeNode)

    serviceCenterStore.setState((s) => ({
      ...s,
      activeNodes: [...s.activeNodes.filter((n) => getIdentifier(n) !== nodeId), activeNode],
    }))
  },
  updateActiveNode: (activeNode: TActiveNode | TActiveGateway) => {
    const nodeId = getIdentifier(activeNode)

    serviceCenterStore.setState((s) => ({
      ...s,
      activeNodes: [...s.activeNodes.filter((n) => getIdentifier(n) !== nodeId), activeNode],
    }))
  },
  setNodeLogs: (nodeLogs: Record<string, TNodeLog>) => {
    const mostRecentLogTime = maxBy(Object.values(nodeLogs), 'stamp')?.stamp || null

    const nodeStatuses: Record<string, TNodeStatus> = mapValues(nodeLogs, (nodeLog) =>
      getActiveNodeStatus(nodeLog),
    )

    serviceCenterStore.setState((s) => ({ ...s, nodeLogs, nodeStatuses, mostRecentLogTime }))
  },
  setServiceIssues: (serviceIssues: Record<number, TNodeServiceIssueSummary[]>) => {
    serviceCenterStore.setState((s) => ({ ...s, serviceIssues }))
  },
  selectEquipment: (equipmentId: string | null, type: 'planned' | 'active') => {
    if (type === 'planned') {
      serviceCenterStore.setState((s) => ({
        ...s,
        selectedPlannedEquipmentIds: equipmentId ? [equipmentId] : [],
        selectedActiveEquipmentIds: [],
      }))
    } else {
      serviceCenterStore.setState((s) => ({
        ...s,
        selectedPlannedEquipmentIds: [],
        selectedActiveEquipmentIds: equipmentId ? [equipmentId] : [],
      }))
    }
  },
  unselectEquipment: () => {
    serviceCenterStore.setState((s) => ({
      ...s,
      selectedPlannedEquipmentIds: [],
      selectedActiveEquipmentIds: [],
    }))
  },
  setLures: (lures: TLures) => {
    serviceCenterStore.setState((s) => ({ ...s, lures }))
  },
  setOfflineContents: (offlineContents: TServiceCenterStore['offlineContents']) => {
    serviceCenterStore.setState((s) => ({
      ...s,
      offlineContents: {
        ...offlineContents,
        // prevent banner visibility state from overwriting
        isBannerOpened: s.offlineContents.isBannerOpened,
      },
    }))
  },
  setOfflineContentsDownloadStatus: (newStatus: string) => {
    const getProgression = (newStatus: string, currentStatus: string) => {
      const parsedNewStatus = parseInt(newStatus)
      const parsedCurrentStatus = parseInt(currentStatus)

      // set status default, error, or progression number
      if (isNaN(parsedNewStatus)) {
        return newStatus
      } else if (isNaN(parsedCurrentStatus) && !isNaN(parsedNewStatus)) {
        return newStatus
      } else {
        return (parsedNewStatus + parsedCurrentStatus).toString()
      }
    }

    serviceCenterStore.setState((s) => ({
      ...s,
      offlineContents: {
        ...s.offlineContents,
        downloadStatus: getProgression(newStatus, s.offlineContents.downloadStatus),
      },
    }))
  },
  setIsOfflineContentBannerOpened: (newState: boolean) => {
    serviceCenterStore.setState((s) => ({
      ...s,
      offlineContents: {
        ...s.offlineContents,
        isBannerOpened: newState,
      },
    }))
  },
  getPropertyIdByMapBounds: (mapBounds: google.maps.LatLngBounds) => {
    const properties = fieldAssetStore.getState().properties

    if (!properties) return

    const foundProperty = Object.values(properties).find((property) => {
      const latLng = {
        lat: property.centroid.coordinates.lat,
        lng: property.centroid.coordinates.lng,
      }

      return mapBounds.contains(latLng)
    })

    return foundProperty?.propertyId || null
  },
  setPropertyOverviewZoomLevel: (zoomLevel?: number | null) => {
    // When user select a property by either clicking property overview card or map search box,
    // map zoom level changes to property's bonnds dynamically.
    // we want to set this value minus 3 to add extra zoom out space for user to get back to property overview mode
    let overviewZoomLevel: number

    if (zoomLevel) {
      // minimum zoom level is 3 so we want to set it to 3 at minimum to transition from detail view to overview view
      overviewZoomLevel = zoomLevel <= 5 ? MINIMUM_ZOOM_LEVEL : zoomLevel - 3
    } else {
      overviewZoomLevel = 10
    }

    serviceCenterStore.setState((s) => ({
      ...s,
      propertyOverviewZoomLevel: overviewZoomLevel,
    }))
  },
  resetNodes: () => {
    serviceCenterStore.setState((s) => ({
      ...s,
      plannedNodes: [],
      activeNodes: [],
      nodeLogs: {},
      serviceIssues: {},
    }))
  },
}
