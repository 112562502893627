import { SettingsSearchNotFound } from 'App/Map/UserSettingsMenu/components/SettingsSearchNotFound/SettingsSearchNotFound'
import { IconContact } from 'components/icons/IconContact'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import type { Contact } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { fetchApiCustomer } from 'utils/fetchApiCustomer'
import { showNotification } from 'utils/showNotification'
import { useScreenSize } from 'utils/useScreenSize'
import { showConfirmSettingModal } from '../../_utils/showConfirmSettingModal'
import { contactDeleteQuery } from '../_utils/queries'
import type { NotificationType } from '../_utils/useRelativeNotification'
import { ContactsAndGroupsRow } from '../components/ContactsAndGroupsRow'
import { useSearchGroupsAndContacts } from '../components/useSearchGroupsAndContacts'

type DeleteContactResponse = {
  data: {
    deleteContact: boolean
  }
}

export const Contacts = ({
  onEdit,
  setSelectedContact,
  showRelativeNotification,
}: {
  onEdit: () => void
  setSelectedContact: Dispatch<SetStateAction<Contact | null>>
  showRelativeNotification: ({ type, message }: { type: NotificationType; message: string }) => void
}) => {
  const { isWideScreen } = useScreenSize()
  const notificationFunctionToUse = isWideScreen ? showRelativeNotification : showNotification

  const { allGroups, allContacts } = userDetailsStore.useSelector((s) => ({
    allGroups: s.groups,
    allContacts: s.contacts,
  }))

  const { SearchInputComponent, filteredAndSortedList, isSearchMatch } = useSearchGroupsAndContacts({
    initialList: allContacts,
    placeholder: translate.phrases.banyanApp('Search Contacts'),
  })

  const onDelete = (contactId: string, contactName: string) => {
    showConfirmSettingModal({
      title: translate.phrases.banyanApp('Delete Contact', { contactName }),
      content: (
        <div>
          <p>
            {translate.phrases.banyanApp('Are you sure you want to delete {{contactName}} as a contact?', {
              contactName,
            })}
          </p>
        </div>
      ),
      onConfirm: () => handleDeleteContact(contactId),
      confirmButtonText: translate.phrases.banyanApp('Delete Contact'),
    })
  }

  const handleDeleteContact = async (contactId: string) => {
    try {
      const response: DeleteContactResponse = await fetchApiCustomer({
        body: {
          query: contactDeleteQuery,
          variables: {
            id: contactId,
          },
        },
      })

      const success = response?.data?.deleteContact

      if (success) {
        notificationFunctionToUse({
          type: 'success',
          message: translate.phrases.banyanApp('Contact successfully deleted'),
        })

        const newContacts = allContacts.filter((contact) => contact.id !== contactId)

        const newGroups = allGroups.map((group) => {
          return {
            ...group,
            contacts: group.contacts.filter((contact) => contact.id !== contactId),
          }
        })

        userDetailsStore.setState((s) => {
          return {
            ...s,
            contacts: newContacts,
            groups: newGroups,
          }
        })
      } else {
        notificationFunctionToUse({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to delete contact'),
        })
      }
    } catch (error) {
      notificationFunctionToUse({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to delete contact'),
      })
    }
  }

  return (
    <>
      {SearchInputComponent}
      {isSearchMatch ? (
        (filteredAndSortedList as Contact[]).map((contact) => {
          return (
            <ContactsAndGroupsRow
              id={contact.id}
              key={contact.id}
              icon={<IconContact />}
              name={contact.name}
              onEdit={() => {
                onEdit()

                setSelectedContact(contact)
              }}
              onDelete={onDelete}
            />
          )
        })
      ) : (
        <SettingsSearchNotFound />
      )}
    </>
  )
}
