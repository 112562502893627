import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { SoilAverageDepthsTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/SoilAverageDepthsTitleChildren/SoilAverageDepthsTitleChildren'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { EAggregationInterval } from 'App/Map/types'
import type { StackedChartSection } from 'components/StackedChart/types'
import { SOIL_MOISTURE_COLORS_ARRAY } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { RootZoneWarning } from '../../../../../../../components/AwcLegendItems/RootZoneWarning'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { checkIfSoilChartHasData } from './_utils/checkIfSoilChartHasData'
import { makeSoilSeries } from './_utils/makeSoilSeries'
import { checkPermission } from './soil'
import { soilPlotOptions } from './soilPlotOptions'

const possibleValueTypes: VV.DomainTypes.Soil.TTimeseriesValueTypeKeysMerged[] = ['soilTemperature']

// TODO: At the moment we are only making line series with the average value. We are not yet making area series out of the min/max values.
export const soilTemperature = ({
  data,
  soilStationLngLat,
  compareSeasonsData,
  soilStationFavoriteDepths,
}: {
  data: routes.Values.Response
  soilStationLngLat: string | null
  compareSeasonsData: routes.Values.Response
  soilStationFavoriteDepths: routes.UserAppStartup.TFieldAssetValueTypes.TSoilSensorDepths[]
}): StackedChartSection => {
  const displayAllDepths = detailsPanelStore.getState().showSelectedSoilDepths
  const displayRootZoneWarning = soilStationFavoriteDepths.length === 0

  const commonReturnItems = {
    title: unitConverter.soilTemperature().titleWithoutUnit(),
    titleChildren: (
      <SoilAverageDepthsTitleChildren
        showAverageToggle={false}
        valuesTimeseriesToFilterOn={possibleValueTypes}
      />
    ),
    id: 'stackem-soil-temperature',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const chartHasSomeData =
    checkIfSoilChartHasData(data?.points?.[String(soilStationLngLat)]?.values?.soilTemperature) ||
    checkIfSoilChartHasData(compareSeasonsData?.points?.[String(soilStationLngLat)]?.values?.soilTemperature)

  const firstForecastTimestamp = +new Date()

  const aggregationInterval =
    data?.points?.[String(soilStationLngLat)]?.values?.soilTemperature?.[0]?.metadata.aggregationInterval

  const soilTemperatureSeries = makeSoilSeries({
    data,
    compareSeasonsData,
    valueType: 'soilTemperature',
    soilStationLngLat,
    soilStationFavoriteDepths,
  })

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp: firstForecastTimestamp,
          },
          chart: {
            type: 'line',
            // tooltip is too big with compare seasons, make chart bigger to accommodate
            height: detailsPanelStore.getState().compareSeasonsInterval ? 350 : 250,
          },
          colors: SOIL_MOISTURE_COLORS_ARRAY,
          series: chartHasSomeData ? soilTemperatureSeries : [],
          plotOptions: soilPlotOptions,
          tooltip: { xDateFormat: getXDateFormat(aggregationInterval === EAggregationInterval.DAILY) },
          legend: { enabled: displayAllDepths },
        },
        childrenLower: displayRootZoneWarning && <RootZoneWarning />,
      },
    ],
    titleChildren: (
      <SoilAverageDepthsTitleChildren showAverageToggle valuesTimeseriesToFilterOn={possibleValueTypes} />
    ),
  }
}
