import { Text } from '@mantine/core'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useState } from 'react'
import { apiFetch } from 'utils/apiFetch'
import { setTokenAndPushHome } from 'utils/setTokenAndPushHome'
import { z } from 'zod'
import type { InputPropsType } from './AuthenticationForm'
import { AuthenticationForm } from './AuthenticationForm'
import { useToast } from './AuthenticationForm/Toast/useToast'
import { appMetaDataMaker } from './_utils/appMetaMaker'

const PASSWORD_LENGTH_MIN = 6
const PASSWORD_LENGTH_MAX = 200

const getValidationSchema = () =>
  z
    .object({
      newPassword: z
        .string()
        .min(PASSWORD_LENGTH_MIN, {
          message: translate.phrases.validation('{{label}} must be at least {{count}} characters', {
            label: translate.phrases.banyanApp('Password'),
            count: PASSWORD_LENGTH_MIN,
          }),
        })
        .max(PASSWORD_LENGTH_MAX, {
          message: translate.phrases.validation('{{label}} must be less than {{count}} characters', {
            label: translate.phrases.banyanApp('Password'),
            count: PASSWORD_LENGTH_MAX,
          }),
        }),
      confirmNewPassword: z.string(),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: translate.phrases.banyanApp('Passwords did not match'),
      path: ['confirmNewPassword'], // path of error
    })

export const ResetPassword: FC = () => {
  const path = window.location.pathname
  const forgotCode = path.match(/\/reset\/(.*)/)?.[1] ?? ''
  const chooseCode = path.match(/\/choosepassword\/(.*)/)?.[1] ?? ''
  const [loading, setLoading] = useState<boolean>(false)

  const { showToast: showErrorToast, Toast: ErrorToast } = useToast({
    durationMillisecond: 3000,
    type: 'error',
  })

  const { showToast: showSuccessToast, Toast: SuccessToast } = useToast({
    durationMillisecond: 3000,
    type: 'success',
  })

  const inputProps: InputPropsType[] = [
    {
      placeholder: translate.phrases.banyanApp('New Password'),
      key: 'newPassword',
      initialValue: '',
      isPasswordInput: true,
    },
    {
      placeholder: translate.phrases.banyanApp('Confirm New Password'),
      key: 'confirmNewPassword',
      initialValue: '',
      isPasswordInput: true,
    },
  ]

  const handleSubmit = async (values: { [k: string]: string }) => {
    setLoading(true)

    try {
      let response

      if (chooseCode) {
        response = await apiFetch({
          url: '/choose-password',
          body: {
            hashCode: chooseCode,
            password: values.newPassword,
            appMeta: appMetaDataMaker(),
          },
        })
      } else {
        response = await apiFetch({
          url: '/reset-password',
          body: {
            forgotCode,
            password: values.newPassword,
            appMeta: appMetaDataMaker(),
          },
        })

        showSuccessToast(translate.phrases.banyanApp('Successfully set your new password'))
      }

      const token = response['x-token']

      if (token) setTokenAndPushHome(token)
    } catch (error) {
      showErrorToast(translate.phrases.banyanApp('Failed to set new password'))
    }

    setLoading(false)
  }

  const isResetForm = !!forgotCode

  const title = isResetForm
    ? translate.phrases.banyanApp('Reset Password')
    : translate.phrases.banyanApp('Choose Password')

  return (
    <>
      <div css={{ marginTop: 20 }}>{SuccessToast}</div>

      <AuthenticationForm
        header={
          <>
            {ErrorToast}
            <Text size={24} weight={300}>
              {title}
            </Text>
            <Text size={14} weight={400}>
              {translate.phrases.validation('{{label}} must be at least {{count}} characters', {
                label: translate.phrases.banyanApp('Password'),
                count: 6,
              })}
            </Text>
          </>
        }
        inputProps={inputProps}
        validationSchema={getValidationSchema()}
        buttonText={title}
        onSubmit={handleSubmit}
        submitLoading={loading}
      />
    </>
  )
}
