import { Input } from '@mantine/core'
import { getSelectOptionsFromEmitters } from 'App/irrigation-scheduler/utils/getSelectOptionsFromEmitters'
import type { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import { useEffect, useMemo, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { apiFetch } from 'utils/apiFetch'
import { ErrorMessage } from '../../../ErrorMessage/ErrorMessage'
import { useErrorMessage } from '../../../ErrorMessage/useErrorMessage'

export const EditEmitterGroupModal = ({
  selectedZoneGroupEdit,
  setSelectedZoneGroupEdit,
}: {
  selectedZoneGroupEdit: (TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null
  setSelectedZoneGroupEdit: React.Dispatch<
    React.SetStateAction<(TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null>
  >
}) => {
  const [groupName, setGroupName] = useState<string>('')
  const [selectedIrrigationZoneEmitters, setSelectedIrrigationZoneEmitters] = useState<TOptions[]>([])
  const { showErrorMessage, triggerErrorMessage, onCloseButtonPress } = useErrorMessage()
  const propertyId = selectedZoneGroupEdit?.propertyId
  const properties = fieldAssetStore.useSelector((s) => s.properties)
  const selectedProperty = properties?.[Number(propertyId)]
  const selectedPropertyEmitterGroups = selectedProperty?.irrigationZoneEmitterGroups ?? {}
  const closeModal = () => setSelectedZoneGroupEdit(null)

  const irrigationZoneEmittersForProperty = useMemo(
    () => getSelectOptionsFromEmitters(selectedProperty?.irrigationZoneEmitters ?? {}),
    [selectedProperty?.irrigationZoneEmitters],
  )

  useEffect(() => {
    if (selectedZoneGroupEdit) {
      const prefilledIrrigationZoneEmitters = irrigationZoneEmittersForProperty.filter((z) =>
        (selectedZoneGroupEdit?.irrigationZoneEmitterIds ?? []).some(
          (selectedZoneEmitter: string) => selectedZoneEmitter === z.value,
        ),
      )

      setGroupName(selectedZoneGroupEdit.groupName)

      setSelectedIrrigationZoneEmitters(prefilledIrrigationZoneEmitters)
    }
  }, [selectedZoneGroupEdit])

  return (
    <ModalDrawer
      title={translate.phrases.banyanApp('Edit Zone Group')}
      primaryButtonDisabled={!groupName || selectedIrrigationZoneEmitters.length === 0}
      primaryButtonOnPress={async () => {
        if (!selectedZoneGroupEdit || !propertyId) return

        // check if group name is blank
        if (!groupName || groupName.trim() === '') {
          triggerErrorMessage(
            translate.phrases.validation('{{label}} is required', {
              label: translate.phrases.banyanApp('Group Name'),
            }),
          )

          return
        }

        if (
          groupName === selectedZoneGroupEdit.groupName &&
          selectedIrrigationZoneEmitters.length === selectedZoneGroupEdit.irrigationZoneEmitterIds.length &&
          selectedIrrigationZoneEmitters.every(({ value }) =>
            selectedZoneGroupEdit.irrigationZoneEmitterIds.includes(
              value as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
            ),
          )
        ) {
          // no changes were made, don't make an api call
          closeModal()

          return
        }

        // check if group name already exists among other groups for this property
        const groupsForPropertyExceptSelectedGroup = Object.values(selectedPropertyEmitterGroups).filter(
          (g) => g.groupId !== selectedZoneGroupEdit?.groupId,
        )

        if (
          groupsForPropertyExceptSelectedGroup?.some(
            (group) => group.groupName.trim().toLowerCase() === groupName.trim().toLowerCase(),
          )
        ) {
          triggerErrorMessage(
            translate.phrases.validation('{{label}} already exists', {
              label: translate.phrases.banyanApp('Group Name'),
            }),
          )

          return
        }

        const updatedZoneEmitterIds = selectedIrrigationZoneEmitters.map(({ value }) => value as string)

        try {
          const response = await apiFetch({
            url: '/irrigation-zone-emitter-group-update',
            body: {
              groupId: selectedZoneGroupEdit?.groupId,
              groupName,
              irrigationZoneEmitterIds: updatedZoneEmitterIds,
            },
          })

          const success = typeof response === 'number'

          if (success) {
            fieldAssetStore.setState((s) => {
              if (!s) return s

              const prevIrrigationZoneEmitterGroups =
                s.properties?.[propertyId]?.irrigationZoneEmitterGroups ?? {}

              return {
                ...s,
                properties: {
                  ...s.properties,
                  [String(propertyId)]: {
                    ...s.properties?.[propertyId],
                    irrigationZoneEmitterGroups: {
                      ...prevIrrigationZoneEmitterGroups,
                      [selectedZoneGroupEdit?.groupId]: {
                        groupId: selectedZoneGroupEdit?.groupId,
                        groupName,
                        irrigationZoneEmitterIds: updatedZoneEmitterIds,
                      },
                    },
                  },
                },
              }
            })

            closeModal()
          } else {
            triggerErrorMessage(translate.phrases.banyanApp('Failed to update group'))
          }
        } catch (e) {
          triggerErrorMessage(translate.phrases.banyanApp('Failed to update group'))
        }
      }}
      primaryButtonText={translate.phrases.banyanApp('Apply')}
      opened={!!selectedZoneGroupEdit}
      onClose={closeModal}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
    >
      <ErrorMessage
        message={showErrorMessage}
        onCloseButtonPress={onCloseButtonPress}
        showErrorMessage={!!showErrorMessage}
      />
      <div css={{ margin: 30 }}>
        <div css={{ marginBottom: 10 }}>{translate.phrases.banyanApp('Group Name')}</div>
        <Input
          value={groupName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGroupName(e.target.value)}
          css={{ maxWidth: 300 }}
          maxLength={40}
        />
      </div>
      <div css={{ margin: 30 }}>
        <div css={{ marginBottom: 10 }}>
          {translate.phrases.banyanApp('Select zone emitters to associate with this group')}
        </div>
        <MultiSelect
          data={irrigationZoneEmittersForProperty}
          selectedData={selectedIrrigationZoneEmitters}
          onChange={setSelectedIrrigationZoneEmitters}
          placeholder={translate.phrases.banyanApp('Select zone emitters to associate with this group')}
        />
      </div>
    </ModalDrawer>
  )
}
