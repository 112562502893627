import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty, isNil } from '@semios/app-platform-common'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { smallStore } from 'stores/smallStore'
import { apiFetch } from 'utils/apiFetch'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import {
  generateUsualStyleGetCacheUpdatesFromResponse,
  getIsDeviceReported,
  populateSCDsForBlocks,
} from './_utils/generateUsualStyleGetCacheUpdatesFromResponse'
import { getHeatmapCSS } from './_utils/getHeatmapCSS'
import { getValueType } from './_utils/getValueType'
import { setHeatmapExtremesFromArrayOfValues } from './_utils/setHeatmapExtremesFromArrayOfValues'

const notInversionColor = colors.grey200

// TODO: these might change with a UX design
const heatmapColoringForInversion = [
  colors.irrigationHeatmapSoftBlue,
  colors.irrigationHeatmapMediumBlue,
  colors.irrigationHeatmapStrongBlue,
]

const getHeatmapCSSForInversion = ({ value }: { value: number | null | undefined }) => {
  if (isNil(value)) return { backgroundColor: colors.midnight, color: 'white' }

  if (value <= 0) return { backgroundColor: notInversionColor, color: colors.midnight }

  return getHeatmapCSS({ value, heatmapColoring: heatmapColoringForInversion })
}

const valueGroup = 'air_temperature'
const baseUnitConverter = unitConverter.temperatureInCanopy
const blockValueKey = 'median_value'
const heatmapExtremesForAllPropertiesInVisibleRegions = true

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  const pointValueType = getValueType(MAP_VISUAL.POINT, valueGroup)

  if (pointValueType !== 'inversion') {
    return generateUsualStyleAPIArgs({
      heatmapExtremesForAllPropertiesInVisibleRegions,
      processedCaches,
      block: {
        valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
      },
      inBlockPoint: {
        valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
      },
      outOfBlockPoint: {
        valueType: getValueType(MAP_VISUAL.POINT, valueGroup, true),
      },
      property: {
        valueType: getValueType(MAP_VISUAL.PROPERTY, valueGroup),
      },
      region: {
        valueType: getValueType(MAP_VISUAL.REGION, valueGroup),
      },
    })
  } else {
    return {
      points: {
        lngLats: processedCaches.stationValues.itemsWithinView.map((station) => station.meta.lngLat),
        values: {
          temperatureInversion_aboveVsBelow_c: true,
          temperatureInversion_aboveVsIn_c: true,
        },
      },
      properties: {
        propertyIds: processedCaches.propertyValues.itemsWithinView.map((property) => Number(property.id)),
        values: {
          temperatureInversion_minMedianMax_aboveVsIn_c: true,
          temperatureInversion_minMedianMax_aboveVsBelow_c: true,
        },
      },
    }
  }
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch({ url: '/values-current', body: args })
  const pointValueType = getValueType(MAP_VISUAL.POINT, valueGroup)

  if (pointValueType !== 'inversion') {
    const result = generateUsualStyleGetCacheUpdatesFromResponse({
      cacheKeys,
      heatmapExtremesForAllPropertiesInVisibleRegions,
      processedCaches,
      response,
      block: {
        decimalPlaces: 1,
        valueType: getValueType(MAP_VISUAL.BLOCK, valueGroup),
        unitConverterFunction: baseUnitConverter,
        valueKey: blockValueKey,
      },
      inBlockPoint: {
        decimalPlaces: 1,
        valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
        unitConverterFunction: baseUnitConverter,
      },
      outOfBlockPoint: {
        decimalPlaces: 1,
        valueType: getValueType(MAP_VISUAL.POINT, valueGroup, true),
        unitConverterFunction: baseUnitConverter,
      },
      property: {
        decimalPlaces: 1,
        valueType: getValueType(MAP_VISUAL.PROPERTY, valueGroup),
        unitConverterFunction: baseUnitConverter,
      },
      region: {
        decimalPlaces: 0,
        valueType: getValueType(MAP_VISUAL.REGION, valueGroup),
        unitConverterFunction: baseUnitConverter,
      },
    })

    return result
  }

  const allValues: (number | null)[] = []
  const itemIdsWithinView: string[] = []
  const properties = fieldAssetStore.getState().properties
  const { showDataSource } = smallStore.getState()
  const fullyProcessedCaches = populateSCDsForBlocks(response, processedCaches)

  // stations
  const stationsWithinViewThatNowHaveValues = fullyProcessedCaches.stationValues.itemsWithinView.flatMap(
    (station) => {
      const valueObject = response?.points?.[station.meta.lngLat] ?? {}
      const aboveVsBelow = valueObject.temperatureInversion_aboveVsBelow_c ?? null
      const aboveVsIn = valueObject.temperatureInversion_aboveVsIn_c ?? null
      const valueRaw = aboveVsBelow ? aboveVsBelow.value : aboveVsIn?.value
      const source = aboveVsBelow ? aboveVsBelow.source : aboveVsIn?.source
      const isDeviceReported = getIsDeviceReported(source ?? null)
      const value = isNil(valueRaw) ? null : valueRaw

      const isVirtual =
        properties?.[station.meta.propertyId]?.points?.[station.meta.lngLat].isVirtual ?? false

      const unitConverterToUse = unitConverter.temperatureInversion

      allValues.push(value)

      itemIdsWithinView.push(station.id)

      const children = unitConverterToUse(value, { decimalPlaces: 1 }).valueAsString()

      return {
        id: String(station.id),
        value: {
          inversion: {
            children,
            getContainerCSS: () => getHeatmapCSSForInversion({ value }),
            getSize: () => {
              let size = 40

              if (children.length > 3) size = size + (children.length - 3) * 6

              return size
            },
            shouldAddDataSourceOverlay: !isNil(value) && !isVirtual && showDataSource && !isDeviceReported,
            isWeatherStation: true,
          },
        },
      }
    },
  )

  //properties
  const propertiesWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.flatMap(
    (property) => {
      const valueObject = response?.properties?.[property.id] ?? {}
      const aboveVsIn = valueObject?.temperatureInversion_minMedianMax_aboveVsIn_c?.max_value
      const aboveVsBelow = valueObject?.temperatureInversion_minMedianMax_aboveVsBelow_c?.max_value
      const propertyValue = aboveVsBelow ? aboveVsBelow : aboveVsIn
      const value = isNil(propertyValue) ? null : propertyValue
      const showValuesForPropertyRatherThanName = !isNil(value) && !stationsWithinViewThatNowHaveValues.length

      allValues.push(value)

      itemIdsWithinView.push(property.id)

      if (!showValuesForPropertyRatherThanName) {
        return {
          id: String(property.id),
          value: {
            inversion: {
              children: property.meta.propertyName,
              onHover: false,
              getContainerCSS: () => ({ backgroundColor: colors.midnight, color: 'white' }),
              baseZIndex: undefined,
            },
          },
        }
      }

      const unitConverterToUse = unitConverter.temperatureInversion
      const children = unitConverterToUse(value, { decimalPlaces: 1 }).valueAsString()

      return {
        id: String(property.id),
        value: {
          inversion: {
            children,
            getContainerCSS: () => getHeatmapCSSForInversion({ value }),
            getSize: () => {
              let size = 40

              if (children.length > 3) size = size + (children.length - 3) * 6

              return size
            },
            shouldAddDataSourceOverlay: !isNil(value),
            isWeatherStation: false,
            onHover: false,
          },
        },
      }
    },
  )

  // intentionally not adding the notInversionColor to the heatmap, since we want to base range only on positive values
  setHeatmapExtremesFromArrayOfValues({
    values: allValues,

    heatmapColoring: heatmapColoringForInversion,
  })

  return {
    stations: {
      itemsWithinViewThatNowHaveValues: stationsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.stationCacheKey,
      itemIdsWithinView,
    },
    heatmapPoints: {
      cacheKey: cacheKeys.scdCacheKey,
      itemIdsWithinView: [],
      itemsWithinViewThatNowHaveValues: [],
    },
    properties: {
      cacheKey: cacheKeys.propertyCacheKey,
      itemIdsWithinView: itemIdsWithinView,
      itemsWithinViewThatNowHaveValues: propertiesWithinViewThatNowHaveValues,
    },
    blocks: {
      cacheKey: cacheKeys.blockCacheKey,
      itemIdsWithinView: [],
      itemsWithinViewThatNowHaveValues: [],
    },
  }
}
