import type { TPublishStatus } from 'components/StackedChart/types'
import moment from 'moment-timezone'
import { minutesToHoursAndMinutes } from 'utils/minutesToHoursAndMinutes'

export const getIrrigationEventForCurrentStamp = ({
  stamp,
  timeseries,
  timezone,
}: {
  stamp: number
  timeseries: {
    x?: number
    x2?: number
    isFuture?: boolean
    publishStatus?: TPublishStatus
  }[]
  timezone: string
}) => {
  const match = timeseries.find((ts) => {
    if (!ts.x || !ts.x2) return false

    return ts.x <= stamp && ts.x2 >= stamp
  })

  if (!match)
    return {
      duration: null,
      isFuture: null,
      publishStatus: null,
    }

  const start = match?.x ? moment.tz(match?.x, timezone) : null
  const end = match?.x2 ? moment.tz(match?.x2, timezone) : null

  return {
    duration: minutesToHoursAndMinutes(moment.duration(end?.diff(start)).asMinutes()),
    isFuture: match?.isFuture,
    publishStatus: match?.publishStatus,
  }
}
