import { LoadingOverlay } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { ValueGroupDictionary } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'
import { refreshMapData } from '../../App/Map/PanelDetails/_utils/refreshMapData'
import { ONGOING_KEY } from '../../utils/refillPointDatesUtils/ongoingKey'
import { RefillPointDatesTable } from './RefillPointDateTable/RefillPointDatesTable'
import type { TAllStationsRPDsData } from './types'

export const RefillPointDatesModal = () => {
  const [refreshChartAndSummary, setRefreshChartAndSummary] = useState(false)
  const [allStationsRPDsData, setAllStationsRPDsData] = useState<TAllStationsRPDsData>([])
  const [loading, setLoading] = useState(false)
  const propertyId = selectedFieldAssetsStore.useSelector((s) => s.property)
  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const refillPointDatesModalOpened = detailsPanelStore.useSelector((s) => {
    return s.refillPointDatesSettingsOpened
  })

  const soilStations =
    propertyId && properties
      ? Object.values(properties[propertyId]?.points || {}).filter(
          (point) => !!point?.configuration?.soilProbeDepthsAvailable,
        )
      : []

  useEffect(() => {
    if (!propertyId || !properties || !refillPointDatesModalOpened) return

    const fetchAllStationsRPDsData = async () => {
      setLoading(true)

      try {
        const response = await apiFetch({
          url: routes.FieldAssetSettingsGet.path,
          body: {
            soilMAD: { propertyId: propertyId },
          },
        })

        const { soilMAD } = response

        if (!soilMAD || 'error' in soilMAD) throw new Error('Failed to fetch soilMAD data')

        const allStationsRPDsData = soilStations.map((station) => {
          const matchingMAD = soilMAD.find((mad) => mad.lnglat === station.lngLat)

          return {
            lngLat: station.lngLat,
            stationName: station.name,
            refillPointDates: matchingMAD
              ? matchingMAD?.mad_dates.map((setting, index) => ({
                  ...setting,
                  endDate: matchingMAD.mad_dates[index - 1]?.startDate ?? ONGOING_KEY,
                }))
              : [],
          }
        })

        setAllStationsRPDsData(allStationsRPDsData)
      } catch (error) {
        showNotification({
          message: translate.phrases.banyanApp(
            'Failed to fetch {{label}}. Please re-open the {{label}} modal to try again',
            {
              label: translate.phrases.banyanApp('Refill Point Dates'),
            },
          ),
          type: 'error',
        })
      } finally {
        setLoading(false)
      }
    }

    fetchAllStationsRPDsData()
  }, [refillPointDatesModalOpened])

  return (
    <ModalDrawer
      title={translate.phrases.banyanApp('Refill Point Dates')}
      onClose={() => {
        if (refreshChartAndSummary) {
          detailsPanelStore.setState((prev) => {
            return {
              ...prev,
              refillPointDatesSettingsOpened: false,
              keyForRefreshing: new Date().toISOString(),
            }
          })

          setRefreshChartAndSummary(false)
        } else {
          detailsPanelStore.setState((prev) => {
            return {
              ...prev,
              refillPointDatesSettingsOpened: false,
            }
          })
        }

        refreshMapData(ValueGroupDictionary.soil)
      }}
      opened={refillPointDatesModalOpened}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
    >
      <LoadingOverlay visible={loading} />
      {!!propertyId &&
        allStationsRPDsData.map((stationRPDsData, index) => (
          <RefillPointDatesTable
            key={index}
            stationName={stationRPDsData.stationName}
            refillPointDates={stationRPDsData.refillPointDates}
            lngLat={stationRPDsData.lngLat}
            setRefreshChartAndSummary={setRefreshChartAndSummary}
            propertyId={propertyId}
            setAllStationsRPDsData={setAllStationsRPDsData}
          ></RefillPointDatesTable>
        ))}
    </ModalDrawer>
  )
}
