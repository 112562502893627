import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'

export const handleUpdateEmitterConfiguration = async (
  params: routes.FieldAssetSettingsSet.Request['emitterConfigurationUpdate'],
  optimisticUpdate: () => void,
) => {
  try {
    const data = await apiFetch({
      url: '/field-asset-settings-set',
      body: {
        emitterConfigurationUpdate: params,
      },
    })

    if (data?.emitterConfigurationUpdate === true) {
      optimisticUpdate()

      showNotification({
        message: translate.phrases.banyanApp('Successfully updated emitter configuration'),
        type: 'success',
      })
    } else {
      showNotification({
        message: translate.phrases.banyanApp('Failed to update emitter configuration'),
        type: 'error',
      })
    }
  } catch {
    showNotification({
      message: translate.phrases.banyanApp('Failed to update emitter configuration'),
      type: 'error',
    })
  }
}
