import type { TRouteMap } from '@semios/app-platform-banyan-route-definitions'
import { useEffect, useState } from 'react'
import { apiFetch } from './apiFetch'

export const useApiREST = <T extends keyof TRouteMap, TResponsePostShaper>({
  body,
  initialLoading = false,
  initialState = null as TResponsePostShaper,
  pollIntervalMilliseconds = null,
  preventFetch = false,
  shaper = (data: TRouteMap[T]['Response']) => data as TResponsePostShaper,
  url,
  watchers = [],
}: {
  body: TRouteMap[T]['Request']
  initialLoading?: boolean
  initialState?: TResponsePostShaper
  pollIntervalMilliseconds?: number | null
  preventFetch?: boolean
  shaper?: (data: TRouteMap[T]['Response']) => TResponsePostShaper
  url: T
  watchers?: (string | number | boolean)[]
}) => {
  const [refreshTime, setRefreshTime] = useState(+new Date())
  const [data, setData] = useState<TResponsePostShaper>(initialState)
  const [loading, setLoading] = useState(initialLoading)
  const [error, setError] = useState(false)

  /**
   * TODO: we could do a more sophisticated polling if desired. For example,
   * having some state like timeToRefreshAt that's set by adding the
   * pollIntervalMilliseconds to the refreshTime and checking to refresh
   * based on an interval of landing on the page as well as a watcher on
   * whether or not the tab is in focus.
   */
  useEffect(() => {
    if (!pollIntervalMilliseconds) return

    const intervalId = setInterval(() => {
      const shouldPoll = !document.hidden && document.visibilityState === 'visible'

      if (shouldPoll) setRefreshTime(+new Date())
    }, pollIntervalMilliseconds)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    // use shouldUpdateState boolean to prevent updating state when we might not want to, e.g. race-condition
    let shouldUpdateState = true

    if (!preventFetch) {
      setLoading(true)

      setError(false)

      apiFetch({ url, body })
        .then((res) => {
          if (shouldUpdateState) {
            if (shaper) {
              setData(shaper(res))
            }

            setLoading(false)
          }
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console

          if (shouldUpdateState) {
            setError(true)

            setData(initialState)

            setLoading(false)
          }
        })
    }

    return () => {
      shouldUpdateState = false
    }
  }, [refreshTime, url, ...watchers])

  const refreshHandle = () => setRefreshTime(+new Date())

  return { data, loading, refreshHandle, refreshTime, setData, error }
}
