import { apiFetch } from 'utils/apiFetch'
import { HelpGuideGroup } from '../../types'

export const serviceCenterHelpGuidesGet = async () => {
  const response = await apiFetch({
    url: '/service-center-help-guides-get',
    body: null,
  })

  return {
    [HelpGuideGroup.USER_GUIDES]: response[HelpGuideGroup.USER_GUIDES],
    [HelpGuideGroup.DEVICE_INSTALLATION]: response[HelpGuideGroup.DEVICE_INSTALLATION],
    [HelpGuideGroup.STATION_INSTALLATION]: response[HelpGuideGroup.STATION_INSTALLATION],
  }
}
