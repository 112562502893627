import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { WindMachineTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/WindMachineTitleChildren/WindMachineTitleChildren'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { StackedChartSection, StackedChartSectionItem } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

type TWindMachineBatteryTimeseries = NonNullable<
  VV.DomainTypes.WindMachine.TValuesReturnWithMetaIgnoringKeying['points']['windMachineBattery'][0]['timeseries'][0]
>

type TWindMachineFuelTimeseries = NonNullable<
  VV.DomainTypes.WindMachine.TValuesReturnWithMetaIgnoringKeying['points']['windMachineFuel'][0]['timeseries'][0]
>

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: 'VIEW_CONTROLLABLE_FROST_FAN_DETAILS' })

export const wmBattery = ({
  data,
  windMachineLngLat,
  defaultValuesRequested,
}: {
  data: routes.Values.Response
  windMachineLngLat: string | null
  defaultValuesRequested: object
}): StackedChartSection => {
  let items = [] as StackedChartSectionItem[]

  const commonReturnItems = {
    title: translate.phrases.banyanApp('Wind Machine Vitals'),
    id: 'wind-machine-vitals',
    titleChildren: (
      <WindMachineTitleChildren
        valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
        hasControls={true}
      />
    ),
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const { hasExtraRightPadding } = detailsPanelStore.getState()
  const wmdata = data?.points?.[String(windMachineLngLat)]?.values

  if (!hasExtraRightPadding)
    detailsPanelStore.setState((prev) => ({
      ...prev,
      hasExtraRightPadding: true,
    }))

  items.push({
    chartConfig: {
      semiosHighchartsAdditions: {
        id: 'WindMachineBattery',
        firstForecastTimestamp: +new Date(),
      },
      chart: {
        type: 'line',
      },
      tooltip: {
        xDateFormat: '%Z',
      },
      yAxis: [
        {
          opposite: false,
          softMin: 10, // 10V is likely to be pretty low for a 14V battery, if standard
          softMax: 18,
          title: {
            text: unitConverter.windMachineVoltage().suffix(),
            offset: 17,
            rotation: 0,
            y: -10,
            align: 'high',
          },
          labels: {
            style: {
              fontSize: '14px',
            },
          },
        },
        {
          opposite: true,
          title: {
            text: '%',
            offset: 17,
            rotation: 0,
            y: -10,
            align: 'high',
          },
          labels: {
            style: {
              fontSize: '14px',
            },
          },
        },
      ],
      series: [
        {
          type: 'line',
          id: 'windMachineBatteryVoltage',
          name: translate.phrases.banyanApp('Battery Voltage'),
          color: colors.awcGreen,
          data: (wmdata?.windMachineBattery?.[0]?.timeseries ?? []).map(
            (d: TWindMachineBatteryTimeseries) => {
              return [+moment.tz(d.timestamp, timezone), d.value]
            },
          ),
          yAxis: 0,
          tooltip: {
            valueSuffix: ` ${unitConverter.windMachineVoltage().suffix()}`,
          },
        },
        {
          type: 'line',
          id: 'windMachineFuelLevel',
          name: translate.phrases.banyanApp('Fuel Level'),
          color: colors.awcOrange,
          data: (wmdata?.windMachineFuel?.[0]?.timeseries ?? []).map((d: TWindMachineFuelTimeseries) => {
            if (d.value === 'Not Connected') {
              return null
            }

            return [+moment.tz(d.timestamp, timezone), d.value]
          }),
          yAxis: 1,
          tooltip: {
            valueSuffix: '%',
          },
        },
      ],
    },
  })

  return {
    ...commonReturnItems,
    items,
  }
}
