import { Tooltip, useMantineTheme } from '@mantine/core'
import React from 'react'
import {
  getSignalLabel,
  getSignalLevelByRSRPAndRSRQ,
  getSignalLevelByRSSI,
  getSignalStrengthColors,
} from '../../utils/getSignalDetails'

interface SignalStrengthProps {
  signalRSSI?: number
  signalRSRP?: number
  signalRSRQ?: number
  signalLevel?: number
}

export const SignalStrength: React.FC<SignalStrengthProps> = ({
  signalRSSI,
  signalRSRP,
  signalRSRQ,
  signalLevel,
}) => {
  const theme = useMantineTheme()

  let signalStrength = signalLevel || 0 // default is unknown

  if (!signalLevel && signalRSSI) {
    signalStrength = getSignalLevelByRSSI(signalRSSI)
  } else if (!signalLevel && signalRSRP && signalRSRQ) {
    signalStrength = getSignalLevelByRSRPAndRSRQ(signalRSRP, signalRSRQ)
  }

  const barColor = getSignalStrengthColors(signalStrength)

  return (
    <Tooltip label={getSignalLabel({ signalRSSI, signalRSRP, signalRSRQ })}>
      <div
        css={{
          display: 'flex',
        }}
      >
        {[1, 2, 3, 4, 5].map((i) => (
          <div
            key={i}
            css={{
              width: 5,
              height: 15,
              borderRadius: 2,
              marginRight: 5,
              backgroundColor: i <= signalStrength ? barColor : theme.colors.grey[2],
            }}
          />
        ))}
      </div>
    </Tooltip>
  )
}
